import React, {useRef, useState} from 'react'
import {Dialog} from 'primereact/dialog'
import {Button} from 'primereact/button'
import {Controller, useForm} from 'react-hook-form'
import {InputText} from 'primereact/inputtext'
import {classNames} from 'primereact/utils'
import DealersService from '../../service/DealersService'
import {Dropdown} from 'primereact/dropdown'
import {Captcha} from 'primereact/captcha'
import {Toast} from 'primereact/toast'

const Dealer = (props) => {
    const {display, onHide} = props
    const [howDidYouHear, setHowDidYouHear] = useState('')
    const [doingClosed, setDoingClosed] = useState('')
    const [token, setToken] = useState(null)

    const toast = useRef(null)
    const captcha = useRef()

    const yesNo = [
        {label: 'Yes', value: 'Yes'},
        {label: 'No', value: 'No'},
    ]
    const defaultValues = {
        id: null,
        name: '',
        company: '',
        status: 'NEW',
        webSiteAddress: '',
        address: '',
        zipCode:'',
        email: '',
        state: '',
        phoneNumber: '',
        marketAreaServed: '',
        businessType: '',
        numberOfEmployees: 0,
        yearsInBusiness: 0,
        annualSales: '',
        doingClosed: '',
        whoAreUsing: '',
        taxNumber: '',
        haveInHouseTeam: '',
        howDidYouHear: '',
        howDidYouHearOther: '',
        salesTaxExempt: '',
        creatorUserId: 0,
        assignedUserId: 0,
        approvedUserId: 0,
        captcha: '',
    }
    const aboutUs = [
        {label: 'Google', value: 'Google'},
        {label: 'Bing', value: 'Bing'},
        {label: 'Yahoo', value: 'Yahoo'},
        {label: 'Facebook', value: 'Facebook'},
        {label: 'Youtube', value: 'Youtube'},
        {label: 'Woodworking Network', value: 'Woodworking Network'},
        {label: 'RedBook', value: 'RedBook'},
        {label: 'Closets Magazine', value: 'Closets Magazine'},
        {label: 'IBS Show', value: 'IBS Show'},
        {label: 'IWF Show', value: 'IWF Show'},
        {label: 'Cabinets and Closets Expo', value: 'Cabinets and Closets Expo'},
        {label: 'AWFS', value: 'AWFS'},
        {label: 'Other', value: 'Other'},
    ]
    const annualSales = [
        {label: '0-500K', value: '0-500K'},
        {label: '500K-1M', value: '500K-1M'},
        {label: '1M-2.5M', value: '1M-2.5M'},
        {label: '2.5M-5M', value: '2.5M-5M'},
        {label: '5M and more', value: '5M and more'},
    ]
    const businessTypeOptions = [
        {label: 'Closet Company', value: 'Closet Company'},
        {label: 'Kitchen and Bath Dealer', value: 'Kitchen and Bath Dealer'},
        {label: 'Cabinetry Company', value: 'Cabinetry Company'},
        {label: 'Home Design Firm', value: 'Home Design Firm'},
        {label: "Manufacturer's Rep", value: "Manufacturer's Rep"},
        {label: 'Blinds & Shutters', value: 'Blinds & Shutters'},
        {label: 'Retail Home Center', value: 'Retail Home Center'},
        {label: 'Showroom Dealer', value: 'Showroom Dealer'},
        {label: 'Contractor', value: 'Contractor'},
        {label: 'Window Treatment', value: 'Window Treatment'},
        {label: 'Design and Build', value: 'Design and Build'},
        {label: 'Builder', value: 'Builder'},
        {label: 'Distributor', value: 'Distributor'},
        {label: 'Architect', value: 'Architect'},
        {label: 'General Contracter', value: 'General Contracter'},
        {label: 'Other', value: 'Other'},
    ]

    const {
        control,
        formState: {errors},
        handleSubmit,
        reset,
        setValue,
        getValues,
    } = useForm({defaultValues: defaultValues})

    const howDidYouHearChange = (e) => {
        setHowDidYouHear(e)
    }

    const doingClosedChange = (e) => {
        setDoingClosed(e)
    }
    const addDealers = (request) => {
        if (!token) {
            toast.current.show({severity: 'error', summary: '', detail: 'Please select I am not robot box', life: 3000})
            return
        }

        request.captcha = token

        request.id == null
            ? DealersService.addDealers(request)
                .then((response) => {
                    toast.current.show({severity: 'success', summary: 'Success Dealer', detail: 'Success Dealer', life: 3000})
                    onHide()
                })
                .catch(function (e) {
                    toast.current.show({severity: 'error', summary: 'Error', detail: e.response.data.message, life: 3000})
                    captcha.current.reset()
                })
            : DealersService.updateDealers(request.id, request)
                .then((response) => {
                    toast.current.show({severity: 'success', summary: 'Success Dealer', detail: 'Success Dealer', life: 3000})
                    onHide()
                })
                .catch(function (e) {
                    toast.current.show({severity: 'error', summary: 'Error', detail: e.response.data.message, life: 3000})
                    captcha.current.reset()
                })
    }
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    }
    const onSubmit = (data) => {
        addDealers(data)
    }

    const showResponse = (e) => {
        setToken(e.response)
    }

    return (
        <div>
            <Toast ref={toast}/>
            <Dialog visible={display} style={{width: '50vw'}} onHide={() => onHide()}>
                <div>
                    <h5 className="text-center">BECOME A DEALER</h5>
                    <div className="">
                        <form onSubmit={handleSubmit(onSubmit)} className="grid p-fluid">
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="name" className={classNames({'p-error': errors.name})}>
                                        Full Name*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="name"
                        control={control}
                        rules={{required: 'Input Is Required.'}}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                autoFocus
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('name')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="company" className={classNames({'p-error': !!errors.company})}>
                                        Company*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="company"
                        control={control}
                        rules={{required: 'Input Is Required.'}}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('company')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="email" className={classNames({'p-error': !!errors.webSiteAddress})}>
                                        Website*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="webSiteAddress"
                        control={control}
                        rules={{
                            required: 'Input Is Required.',
                        }}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('webSiteAddress')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="address" className={classNames({'p-error': !!errors.address})}>
                                        Adress*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="address"
                        control={control}
                        rules={{
                            required: 'Input Is Required.',
                        }}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('address')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="zipCode" className={classNames({'p-error': !!errors.zipCode})}>
                                        Zip Code
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="zipCode"
                        control={control}
                        rules={{
                            required: 'Input Is Required.',
                        }}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('zipCode')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="state" className={classNames({'p-error': !!errors.state})}>
                                        State/Terr/Prov*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="state"
                        control={control}
                        rules={{
                            required: 'Input Is Required.',
                        }}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('state')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="phoneNumber" className={classNames({'p-error': !!errors.phoneNumber})}>
                                        Phone Number*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{
                            required: 'Input Is Required.',
                        }}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('phoneNumber')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="email" className={classNames({'p-error': !!errors.email})}>
                                        Email*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: 'Input Is Required.',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: 'Invalid email address. E.g. example@email.com',
                            },
                        }}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('email')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="businessType" className={classNames({'p-error': errors.businessType})}>
                                        Business Type*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="businessType"
                        control={control}
                        rules={{required: 'Dropdown Is Required.'}}
                        render={({field, fieldState}) => (
                            <Dropdown
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                                options={businessTypeOptions}
                                optionLabel="label"
                                placeholder="Select a Business Type*"
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('businessType')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="email" className={classNames({'p-error': !!errors.marketAreaServed})}>
                                        Market Area Served*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="marketAreaServed"
                        control={control}
                        rules={{
                            required: 'Input Is Required.',
                        }}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('marketAreaServed')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="yearsInBusiness" className={classNames({'p-error': errors.yearsInBusiness})}>
                                        Years In Business*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="yearsInBusiness"
                        control={control}
                        rules={{required: 'Input Is Required.'}}
                        render={({field, fieldState}) => (
                            <InputText
                                type="number"
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('yearsInBusiness')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="numberOfEmployees" className={classNames({'p-error': errors.numberOfEmployees})}>
                                        Number Of Employees*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="numberOfEmployees"
                        control={control}
                        rules={{required: 'Input Is Required.'}}
                        render={({field, fieldState}) => (
                            <InputText
                                type="number"
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('numberOfEmployees')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="annualSales" className={classNames({'p-error': errors.annualSales})}>
                                        Annual Sales *
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="annualSales"
                        control={control}
                        rules={{required: 'Dropdown Is Required.'}}
                        render={({field, fieldState}) => (
                            <Dropdown
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                                options={annualSales}
                                optionLabel="label"
                                placeholder="Select a Annual Sales*"
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('annualSales')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="name" className={classNames({'p-error': errors.haveInHouseTeam})}>
                                        Do you have an in house sales/desing team? *
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="haveInHouseTeam"
                        control={control}
                        rules={{required: 'Dropdown Is Required.'}}
                        render={({field, fieldState}) => (
                            <Dropdown
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                                options={yesNo}
                                optionLabel="label"
                                placeholder="Do you have an in house sales/desing team?"
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('haveInHouseTeam')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="doingClosed" className={classNames({'p-error': errors.doingClosed})}>
                                        Are you doing closets now ?*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="doingClosed"
                        control={control}
                        rules={{required: 'Dropdown Is Required.'}}
                        render={({field, fieldState}) => (
                            <Dropdown
                                id={field.name}
                                {...field}
                                onHide={() => doingClosedChange(getValues('doingClosed'))}
                                className={classNames({'p-invalid': fieldState.invalid})}
                                options={yesNo}
                                optionLabel="label"
                                placeholder="Select a Annual Sales*"
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('doingClosed')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="whoAreUsing" className={classNames({'p-error': errors.whoAreUsing})}>
                                        If so, who are you using
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="whoAreUsing"
                        control={control}
                        rules={{required: {value: doingClosed === 'Yes', message: 'Input Is Required.'}}}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('whoAreUsing')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="salesTaxExempt" className={classNames({'p-error': errors.salesTaxExempt})}>
                                        Tax Number *
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="taxNumber"
                        control={control}
                        rules={{required: 'Input Is Required.'}}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('taxNumber')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="salesTaxExempt" className={classNames({'p-error': errors.salesTaxExempt})}>
                                        Sales Tax Exempt*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="salesTaxExempt"
                        control={control}
                        rules={{required: 'Dropdown Is Required.'}}
                        render={({field, fieldState}) => (
                            <Dropdown
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                                options={yesNo}
                                optionLabel="label"
                                placeholder="Sales Tax Exempt"
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('salesTaxExempt')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="howDidYouHear" className={classNames({'p-error': errors.howDidYouHear})}>
                                        How did you hear us ?*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="howDidYouHear"
                        control={control}
                        rules={{required: 'Dropdown Is Required.'}}
                        render={({field, fieldState}) => (
                            <Dropdown
                                id={field.name}
                                {...field}
                                onHide={() => howDidYouHearChange(getValues('howDidYouHear'))}
                                className={classNames({'p-invalid': fieldState.invalid})}
                                options={aboutUs}
                                optionLabel="label"
                                placeholder="Select a How Did You Hear*"
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('howDidYouHear')}
                                </div>
                            </div>
                            {howDidYouHear == 'Other' && (
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        <label
                                            htmlFor="howDidYouHearOther"
                                            className={classNames({'p-error': errors.howDidYouHearOther})}>
                                            How Did You Hear Other *
                                        </label>
                                        <span className="p-float-label">
                      <Controller
                          name="howDidYouHearOther"
                          control={control}
                          rules={{required: 'Input Is Required.'}}
                          render={({field, fieldState}) => (
                              <InputText
                                  id={field.name}
                                  {...field}
                                  className={classNames({'p-invalid': fieldState.invalid})}
                              />
                          )}
                      />
                    </span>
                                        {getFormErrorMessage('howDidYouHearOther')}
                                    </div>
                                </div>
                            )}

                            <div className="col-12">
                                <Captcha ref={captcha} siteKey="6Lfbdg0gAAAAAIyu2f7TJlDQADtakon4Cn3ZEzko" onResponse={showResponse}/>
                            </div>

                            <Button type="submit" label="Save" className="mt-2"/>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default Dealer
