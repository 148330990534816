export const OrderStatus = {
  OrderCreated: 'ORDER_CREATED',
  ProductionDesignPlanning: 'PRODUCTION_DESIGN_PLANNING',
  OrderDetailRevisionRequested: 'ORDER_DETAIL_REVISION_REQUESTED',
  ProductionDesignPlanned: 'PRODUCTION_DESIGN_PLANNED',
  ProductionDesignInProgress: 'PRODUCTION_DESIGN_IN_PROGRESS',
  ProductionDesignApproval: 'PRODUCTION_DESIGN_APPROVAL',
  ProductionDesignRevisionRequested: 'PRODUCTION_DESIGN_REVISION_REQUESTED',
  WaitingForPrepayment: 'WAITING_FOR_PREPAYMENT',
  WaitingForProductionPlanning: 'WAITING_FOR_PRODUCTION_PLANNING',
  ProductionPlanned: 'PRODUCTION_PLANNED',
  ProductionStarted: 'PRODUCTION_STARTED',
  ProductionFinished: 'PRODUCTION_FINISHED',
  ReadyForPacking: 'READY_FOR_PACKING',
  Packing: 'PACKING',
  BeingPalletized: 'BEING_PALLETIZED',
  ReadyForPalletizing: 'READY_FOR_PALLETIZING',
  ReadyToLoad: 'READY_TO_LOAD',
  Loaded: 'LOADED',
  Unloaded: 'UNLOADED',
  Done: 'DONE',
}

export const statusController = (statues, orderStatus) => {
  return statues.includes(orderStatus)
}
