import http from '../ServiceConfig'

class PalleteService {
  /**
   * Create Pallete
   * @param { orderCodes: string[], paletteBarcode: string, paletteName: string} request
   */
  createPallete(request) {
    return http.post('/palette/create', request)
  }

  /**
   * Update Pallete
   * @param { orderCodes: string[], paletteBarcode: string, paletteName: string} request
   */
  updatePallete(request, palleteId) {
    return http.post('/palette/' + palleteId, request)
  }

  /**
   * Get Pallete List
   * @param {}
   */
  getPalletesWithShipped() {
    return http.get('/palette')
  }

  /**
   * Get Pallete List
   * @param {}
   */
  getPalletesWithoutShipped() {
    return http.get('/palette/without-shipped')
  }

  /**
   * Delete Pallete By Id
   * @param {palleteId: string} request
   */
  deletePalleteById(palleteId) {
    return http.delete('/palette/' + palleteId)
  }

  /**
   * Add box to pallete
   * @param {packageBarcode: string, palletId: string} request
   */
  addBoxToPallete(request) {
    return http.post('/palette/read-barcode', request)
  }

  /**
   * Exclude box from pallete
   * @param {packageBarcode: string, palletId: string} request
   */
  excludeBoxFromPallete(request) {
    return http.post('/palette/remove-package', request)
  }

  /**
   * Get pallete summary for label
   * @param {palleteId: string} request
   */
  getPalleteSummary(palleteId) {
    return http.get('/palette/summary/' + palleteId)
  }
}

export default new PalleteService()
