import React from 'react'
import { useRoleSubmenuManagement } from './useRoleSubmenuManagement'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import EditRoleSubmenusDialog from './components/EditRoleSubmenusDialog'

const RoleSubmenuManagement = () => {
  const {
    t,
    languageKeys,
    setFilter,
    filter,
    loading,
    roles,
    getRoles,
    dt,
    toast,
    onRoleEditClicked,
    isEditRoleDialogVisible,
    onHideEditRoleDialog,
    selectedRole,
  } = useRoleSubmenuManagement()

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t(languageKeys.tableTitle)}</h5>
      <div className="flex">
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setFilter(e.target.value)}
            placeholder={t(languageKeys.searchPlaceholder)}
          />
        </span>
      </div>
    </div>
  )

  const actionCellTemplate = (rowData) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          type="button"
          onClick={(e) => onRoleEditClicked(rowData)}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-secondary mt-2 mr-1"></Button>
      </div>
    )
  }

  const submenusTemplate = (rowData) => {
    return <p style={{ whiteSpace: 'pre-line' }}>{rowData.submenusFlattenAndConverted}</p>
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={roles}
            dataKey="id"
            paginator
            rows={100}
            rowsPerPageOptions={[5, 10, 25, 100, 200]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
            globalFilter={filter}
            header={header}
            responsiveLayout="scroll"
            selectionMode="single"
            loading={loading}>
            <Column
              field="displayName"
              header={t(languageKeys.roleColumn)}
              headerStyle={{ width: '26%', minWidth: '10rem' }}></Column>
            <Column
              field="submenusFlattenAndConverted"
              header={t(languageKeys.submenusColumn)}
              body={submenusTemplate}
              headerStyle={{ width: '44%', minWidth: '10rem' }}></Column>
            <Column
              field="actions"
              header={t(languageKeys.actionColumn)}
              body={actionCellTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          </DataTable>
        </div>
      </div>

      <EditRoleSubmenusDialog
        visible={isEditRoleDialogVisible}
        onHide={onHideEditRoleDialog}
        fetchRoles={getRoles}
        selectedRole={selectedRole}
      />
    </div>
  )
}
export default RoleSubmenuManagement
