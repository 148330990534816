import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'
import '../PreparePalletes.scss'

var ReactBarcode = require('react-barcode')

const PalleteBarcodeModal = ({ show, getValues, onHide }) => {
  const { t } = useTranslation()

  const Print = () => {
    let scrollPosition = document.documentElement.scrollTop
    window.scrollTo(0, 0)
    window.print()
    window.scrollTo(0, scrollPosition)
  }

  return (
    <Dialog
      header={getValues('currentPallete')?.paletteName + ' - ' + t('general.modal.title.Barcode')}
      visible={show}
      style={{}}
      onHide={onHide}>
      <div id="divcontents" className="">
        <div
          className="ifmcontentstoprint relative"
          style={{
            width: '10cm',
            height: '8cm',
            maxWidth: '10cm !important',
            maxHeight: '8cm !important',
            overflow: 'hidden',
            pageBreakAfter: 'always',
          }}>
          <div className="grid p-fluid">
            <div className="col-12 md:col-12">
              <div className="field">
                <div className="p-float-label">
                  <div className="mb-1">
                    <b>{t('palletes.barcode.name.title')}</b> {getValues('currentPallete')?.paletteName}
                  </div>
                  <div className="mb-1">
                    <b>{t('palletes.barcode.orders.title')}</b>
                    {getValues('currentPallete')?.orders?.map((order) => (
                      <div key={order.id}>
                        {order.name} ({order.orderPackings?.length ?? 0} {t('palletes.barcode.boxes')})
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              right: '0',
              margin: 'auto',
              zIndex: '9999',
              width: '10cm',
              textAlign: 'center',
            }}>
            <div className="barcode-container">
              <ReactBarcode
                value={getValues('currentPallete')?.barcode ?? ''}
                displayValue={true}
                renderer={'svg'}
                height={75}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-6 md:col-6"></div>
      <div className="col-6 md:col-6">
        <Button type="submit" className="p-button-primary mr-2 mb-2" onClick={() => Print()}>
          {t('general.modal.title.print')}
        </Button>
      </div>
    </Dialog>
  )
}

export default PalleteBarcodeModal
