import http from '../ServiceConfig'

class LanguageService {
  updateKey(languageKeyId, request) {
    return http.put('/languages/keys/' + languageKeyId, request)
  }

  deleteKey(languageKeyId) {
    return http.delete('/languages/keys/' + languageKeyId)
  }

  getValues(language) {
    return http.get('/languages/values/' + language)
  }

  setValues(language, request) {
    return http.post('/languages/values/' + language, request)
  }

  getKeys(pageId) {
    return http.get('/languages/keys/' + pageId)
  }

  addKey(pageId, request) {
    return http.post('/languages/keys/' + pageId, request)
  }

  getLanguages(language) {
    return http.get('/languages/' + language)
  }

  getAllPages() {
    return http.get('/languages/page')
  }

  addPage(request) {
    return http.post('/languages/page', request)
  }

  updatePage(request) {
    return http.put('/languages/page', request)
  }

  deletePage(id) {
    return http.delete('/languages/page/' + id)
  }

  getAllValuesByKeyList(keyList) {
    return http.post('languages/get-by-keys', keyList)
  }
}

export default new LanguageService()
