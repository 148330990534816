import http from '../ServiceConfig'


class PartnerUserService {
    getPartnerUsers() {
        return http.get('/partnerusers')
    }

    addPartnerUser(request) {
        return http.post('/partnerusers',request)
    }

    updatePartnerUser(partnerId,request) {
        return http.put('/partnerusers/'+partnerId,request)
    }

    getRoleList() {
        return http.get('/partnerusers/roleList')
    }

    getManagerUser(partnerId) {
        return http.get('/partnerusers/getManagerUser/'+partnerId)
    }

}


export default new PartnerUserService()
