/* eslint-disable react-hooks/exhaustive-deps */

import { useMemo, useRef, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import SuggestionService from '../../service/SuggestionService'
import { useForm } from 'react-hook-form'

export const useSuggestions = () => {
  const mounted = useRef(false)
  const toast = useRef(null)
  const dt = useRef(null)
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [isDeleteSuggestionConfirmationDialogVisible, setIsDeleteSuggestionConfirmationDialogVisible] = useState(false)
  const [suggestions, setSuggestions] = useState([])

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const getSuggestions = useCallback(async () => {
    try {
      if (mounted.current === true) setLoading(true)
      const response = await SuggestionService.getSuggestions()
      if (mounted.current === true) setSuggestions(response.data)
    } catch (err) {
      if (mounted.current === true)
        toast.current.show({
          severity: 'error',
          summary: t('general.toast.error.summary'),
          detail: err.response.data.message,
          life: 3000,
        })
    } finally {
      if (mounted.current === true) setLoading(false)
    }
  }, [])

  useEffect(() => {
    getSuggestions()
  }, [getSuggestions])

  const defaultValues = {
    id: null,
    text: '',
  }

  const { reset, getValues, setValue } = useForm({ defaultValues: defaultValues })

  const deleteSuggestion = (id) => {
    setValue('id', id)
    setIsDeleteSuggestionConfirmationDialogVisible(true)
  }

  const dateFormatterForSorting = (date) => {
    let dt = new Date(date)
    return `${dt.getFullYear().toString().padStart(4, '0')}${(dt.getMonth() + 1).toString().padStart(2, '0')}${dt
      .getDate()
      .toString()
      .padStart(2, '0')}${dt.getHours().toString().padStart(2, '0')}${dt.getMinutes().toString().padStart(2, '0')}`
  }

  const dateSorter = (event) => {
    let sortedSuggestionList = [...suggestions]

    sortedSuggestionList.sort((sc1, sc2) => {
      const date1 = dateFormatterForSorting(sc1[event.field])
      const date2 = dateFormatterForSorting(sc2[event.field])

      let result = null

      if (date1 == null && date2 != null) result = -1
      else if (date1 != null && date2 == null) result = 1
      else if (date1 == null && date2 == null) result = 0
      else if (typeof date1 === 'string' && typeof date2 === 'string')
        result = date1.localeCompare(date2, undefined, { numeric: true })
      else result = date1 < date2 ? -1 : date1 > date2 ? 1 : 0

      return event.order * result
    })

    return sortedSuggestionList
  }

  const onHideDeleteSuggestionConfirmationDialog = () => {
    setIsDeleteSuggestionConfirmationDialogVisible(false)
  }

  const onConfirmDeleteSuggestion = useCallback(async () => {
    try {
      await SuggestionService.deleteSuggestion(getValues('id'))
      toast.current.show({
        severity: 'success',
        summary: t('general.toast.success.summary'),
        detail: t('suggestions.toast.success.deletedMsg'),
        life: 3000,
      })

      reset(defaultValues)
      onHideDeleteSuggestionConfirmationDialog()
      getSuggestions()
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: t('general.toast.error.summary'),
        detail: err.response.data.message,
        life: 3000,
      })
    }
  }, [getSuggestions, getValues, reset, defaultValues])

  return useMemo(
    () => ({
      deleteSuggestion,
      toast,
      dt,
      suggestions,
      loading,
      t,
      dateSorter,
      isDeleteSuggestionConfirmationDialogVisible,
      onHideDeleteSuggestionConfirmationDialog,
      onConfirmDeleteSuggestion,
    }),
    [toast, dt, suggestions, loading, isDeleteSuggestionConfirmationDialogVisible]
  )
}
