import http from '../ServiceConfig'


class AdminPanelUserService {
   
    getPanelUsers() {
        return http.get('/users')
    }

    addPanelUser(request) {
        return http.post('/users',request)
    }

    updatePanelUser(request) {
        return http.put('/users/',request)
    }

    deleteUser(userId) {
        return http.delete('/users/'+userId)
    }

    getRoleList() {
        return http.get('/users/roles')
    }

    getCompanies() {
        return http.get('/companies')
    }

    getCompanyManager(companyId,roleName) {
        return http.get('/users/companies/'+companyId+'/managers?role='+ roleName)
    }

}


export default new AdminPanelUserService()
