import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';




const  AddDropDown = ({name, control, errors, label, placeholder, options, optionLabel, optionValue, onHide}) => {
  const { t } = useTranslation();

  return (
    <div className="col-12 md:col-6">
      <div className="field">
        <label htmlFor={name} className={classNames({'p-error': errors[name]})}>
          {label} *
        </label>
        <span className="p-float-label">
          <Controller
            name={name}
            control={control}
            rules={{required: t("general.required.field.msg")}}
            render={({field, fieldState}) => (
              <Dropdown
                id={field[name]}
                {...field}
                onHide={onHide}
                className={classNames({'p-invalid': fieldState.invalid})}
                options={options}
                optionLabel={optionLabel}
                optionValue={optionValue}
                placeholder={placeholder}
              />
            )}
          />
        </span>
        {errors[name] && <span className="p-error">{t(errors[name].message)}</span>}
      </div>
    </div>
  );
};

export default AddDropDown
