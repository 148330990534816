import http from '../ServiceConfig'

class MainMenuService {
  getMainMenus() {
    return http.get('/main-menu')
  }

  createMainMenu(request) {
    return http.post('/main-menu', request)
  }

  updateMainMenu(request) {
    return http.put('/main-menu', request)
  }

  deleteMainMenu(request, mainMenuId) {
    return http.put('/main-menu/' + mainMenuId, request)
  }
}

export default new MainMenuService()
