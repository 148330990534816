import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { Tooltip } from 'primereact/tooltip'

import { useTranslation } from './hooks/useTranslation'
import { AppTopbar } from './AppTopbar'
import { AppFooter } from './AppFooter'
import { AppMenu } from './AppMenu'
import { AppConfig } from './AppConfig'
import { Role } from './helpers/role'

import 'primereact/resources/primereact.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'prismjs/themes/prism-coy.css'
import './assets/layout/layout.scss'
import './App.scss'

import Dashboard from './components/Dashboard'
import PrimeReact from 'primereact/api'
import AuthContext from './context/AuthContext'
import Dealers from './pages/Dealers'
import DealerDetails from './pages/DealerDetails'
import Language from './pages/Language'
import LanguagePageSelect from './pages/LanguagePageSelect'
import Partners from './pages/Partners'
import PartnerUsers from './pages/PartnerUsers'
import AdminPanelUsers from './pages/AdminPanelUsers'
import Orders from './pages/Order/Orders'
import OrderPartList from './pages/Order/OrderPartList'
import PreparePacks from './pages/Order/PreparePacks'
import PreparePalletes from './pages/Order/PreparePalletes'
import LoadPacks from './pages/Order/LoadPacks'
import UnloadPacks from './pages/Order/UnloadPacks'
import CabinetVisionInventory from './pages/CabinetVisionInventory'
import ConfigurationManager from './pages/ConfigurationManager'
import MyOrders from './pages/AdminProjects/MyOrders'
import MyOrderDetail from './pages/AdminProjects/MyOrderDetail/MyOrderDetail'
import { BreadcrumbProvider } from './components/breadcrumb/Breadcrumb'
import MySuggestions from './pages/Suggestions/MySuggestions'
import Suggestions from './pages/Suggestions'
import CompanyManagement from './pages/CompanyManagement'
import CompanyDetails from './pages/CompanyDetails'
import ModuleMenuManagement from './pages/ModuleMenuManagement'
import RoleSubmenuManagement from './pages/RoleSubmenuManagement'

const App = () => {
  const [layoutMode, setLayoutMode] = useState('static')
  const [layoutColorMode, setLayoutColorMode] = useState('light')
  const [inputStyle, setInputStyle] = useState('outlined')
  const [ripple, setRipple] = useState(true)
  const [staticMenuInactive, setStaticMenuInactive] = useState(false)
  const [overlayMenuActive, setOverlayMenuActive] = useState(false)
  const [mobileMenuActive, setMobileMenuActive] = useState(false)
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false)
  const [currentLocation, setCurrentLocation] = useState(null)
  const [menu, setMenu] = useState(null)
  const copyTooltipRef = useRef()
  const location = useLocation()
  const { roleController, isLoggedIn, user } = useContext(AuthContext)

  const languageKeys = useMemo(
    () => ({
      dashboard: 'menuItems.dashboard',
      projectManagement: 'menuItems.project_management',
      orders: 'menuItems.orders',
      logisticManagement: 'menuItems.logistic_management',
      preparePacks: 'menuItems.prepare_packs',
      preparePalletes: 'menuItems.prepare_palletes',
      loadPacks: 'menuItems.load_packs',
      unloadPacks: 'menuItems.unload_packs',
      partnerManagement: 'menuItems.partner_management',
      applications: 'menuItems.applications',
      partners: 'menuItems.partners',
      users: 'menuItems.users',
      systemManagement: 'menuItems.system_management',
      contentManagement: 'menuItems.content_management',
      configurationManager: 'menuItems.configuration_manager',
      suggestions: 'menuItems.suggestions',
      adminManagement: 'menuItems.admin_management',
      adminPanelUsers: 'menuItems.admin_panel_users',
      cabinetVisionInventory: 'menuItems.cabinet_vision_inventory',
      companyManagement: 'menuItems.company_management',
      moduleMenuManagement: 'menuItems.module_menu_management',
      roleSubmenuManagement: 'menuItems.role_submenu_management',
    }),
    []
  )

  const { t } = useTranslation(Object.values(languageKeys))

  const preferedLanguage = user?.preferedLanguage ?? 'EN'

  PrimeReact.ripple = true

  let menuClick = false
  let mobileTopbarMenuClick = false

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, 'body-overflow-hidden')
    } else {
      removeClass(document.body, 'body-overflow-hidden')
    }
  }, [mobileMenuActive])

  const menuItems = useMemo(
    () => [
      {
        items: [
          {
            key: 'DASHBOARD',
            label: t(languageKeys.dashboard, ''),
            icon: 'pi pi-fw pi-home',
            to: '/',
            roles: [
              Role.Admin,
              Role.GeneralManager,
              Role.CountrySalesManager,
              Role.RegionSalesManager,
              Role.CrmManager,
              Role.CrmRepresentative,
              Role.CamManager,
              Role.CamDesigner,
              Role.ProductionPlanningManager,
              Role.ProductionManager,
              Role.LogisticsPlanningManager,
              Role.LogisticWorker,
            ],
          },
        ],
      },
      {
        items: [
          {
            key: 'PROJECT_MANAGEMENT',
            label: t(languageKeys.projectManagement, ''),
            icon: 'pi pi-fw pi-clone',
            items: [
              {
                key: 'PROJECT_MANAGEMENT_MY_ORDERS',
                label: t(languageKeys.orders, ''),
                icon: 'pi pi-shopping-cart',
                to: '/project-management/my-orders',
                roles: [
                  Role.Admin,
                  Role.GeneralManager,
                  Role.CountrySalesManager,
                  Role.RegionSalesManager,
                  Role.CrmManager,
                  Role.CrmRepresentative,
                  Role.CamManager,
                  Role.CamDesigner,
                  Role.ProductionPlanningManager,
                  Role.ProductionManager,
                ],
              },
            ],
            roles: [
              Role.Admin,
              Role.GeneralManager,
              Role.CountrySalesManager,
              Role.RegionSalesManager,
              Role.CrmManager,
              Role.CrmRepresentative,
              Role.CamManager,
              Role.CamDesigner,
              Role.ProductionPlanningManager,
              Role.ProductionManager,
            ],
          },
        ],
      },
      {
        items: [
          {
            key: 'LOGISTIC_MANAGEMENT',
            label: t(languageKeys.logisticManagement, ''),
            icon: 'pi pi-box',
            items: [
              {
                key: 'LOGISTIC_MANAGEMENT_ORDERS',
                label: t(languageKeys.orders, ''),
                icon: 'pi pi-shopping-cart',
                to: '/logistic-management/orders',
                roles: [Role.Admin, Role.GeneralManager],
              },
              {
                key: 'LOGISTIC_MANAGEMENT_PREPARE_PACKS',
                label: t(languageKeys.preparePacks, ''),
                icon: 'pi pi-file',
                to: '/logistic-management/prepare-packs',
                roles: [
                  Role.Admin,
                  Role.GeneralManager,
                  Role.CamManager,
                  Role.CamDesigner,
                  Role.LogisticsPlanningManager,
                  Role.LogisticWorker,
                ],
              },
              {
                key: 'LOGISTIC_MANAGEMENT_PREPARE_PALLETES',
                label: t(languageKeys.preparePalletes, ''),
                icon: 'pi pi-align-justify',
                to: '/logistic-management/prepare-palletes',
                roles: [
                  Role.Admin,
                  Role.GeneralManager,
                  Role.CamManager,
                  Role.CamDesigner,
                  Role.LogisticsPlanningManager,
                  Role.LogisticWorker,
                ],
              },
              {
                key: 'LOGISTIC_MANAGEMENT_LOAD_PACKS',
                label: t(languageKeys.loadPacks, ''),
                icon: 'pi pi-inbox',
                to: '/logistic-management/load-packs',
                roles: [
                  Role.Admin,
                  Role.GeneralManager,
                  Role.CamManager,
                  Role.CamDesigner,
                  Role.LogisticsPlanningManager,
                  Role.LogisticWorker,
                ],
              },
              {
                key: 'LOGISTIC_MANAGEMENT_UNLOAD_PACKS',
                label: t(languageKeys.unloadPacks, ''),
                icon: 'pi pi-tags',
                to: '/logistic-management/unload-packs',
                roles: [
                  Role.Admin,
                  Role.GeneralManager,
                  Role.CamManager,
                  Role.CamDesigner,
                  Role.LogisticsPlanningManager,
                  Role.LogisticWorker,
                ],
              },
            ],
            roles: [
              Role.Admin,
              Role.GeneralManager,
              Role.CamManager,
              Role.CamDesigner,
              Role.LogisticsPlanningManager,
              Role.LogisticWorker,
            ],
          },
        ],
      },
      {
        items: [
          {
            key: 'PARTNER_MANAGEMENT',
            label: t(languageKeys.partnerManagement, ''),
            icon: 'pi pi-fw pi-briefcase',
            items: [
              {
                key: 'PARTNER_MANAGEMENT_APPLICATIONS',
                label: t(languageKeys.applications, ''),
                icon: 'pi pi-fw pi-id-card',
                to: '/partner-management/applications/new',
                roles: [Role.Admin, Role.GeneralManager, Role.CountrySalesManager, Role.RegionSalesManager],
              },
              {
                key: 'PARTNER_MANAGEMENT_PARTNERS',
                label: t(languageKeys.partners, ''),
                icon: 'pi pi-fw pi-user-plus',
                to: '/partner-management/partners',
                roles: [
                  Role.Admin,
                  Role.GeneralManager,
                  Role.CountrySalesManager,
                  Role.RegionSalesManager,
                  Role.CrmManager,
                ],
              },
              {
                key: 'PARTNER_MANAGEMENT_PARTNER_USERS',
                label: t(languageKeys.users, ''),
                icon: 'pi pi-fw pi-users',
                to: '/partner-management/partner-users',
                roles: [
                  Role.Admin,
                  Role.GeneralManager,
                  Role.CountrySalesManager,
                  Role.RegionSalesManager,
                  Role.CrmManager,
                ],
              },
            ],
            roles: [
              Role.Admin,
              Role.GeneralManager,
              Role.CountrySalesManager,
              Role.RegionSalesManager,
              Role.CrmManager,
            ],
          },
        ],
      },
      {
        items: [
          {
            key: 'SYSTEM_MANAGEMENT',
            label: t(languageKeys.systemManagement, ''),
            icon: 'pi pi-fw pi-cog',
            items: [
              {
                key: 'SYSTEM_MANAGEMENT_COMPANY_MANAGEMENT',
                label: t(languageKeys.companyManagement, ''),
                icon: 'pi pi-fw pi-building',
                to: '/system-management/company-management',
                roles: [Role.SuperAdmin, Role.Admin],
              },
              {
                key: 'SYSTEM_MANAGEMENT_MODULE-MENU_MANAGEMENT',
                label: t(languageKeys.moduleMenuManagement, ''),
                icon: 'pi pi-fw pi-server',
                to: '/system-management/module-menu-management',
                roles: [Role.SuperAdmin],
              },
              {
                key: 'SYSTEM_MANAGEMENT_ROLE-SUBMENU_MANAGEMENT',
                label: t(languageKeys.roleSubmenuManagement, ''),
                icon: 'pi pi-users',
                to: '/system-management/role-submenu-management',
                roles: [Role.SuperAdmin],
              },
              {
                key: 'SYSTEM_MANAGEMENT_LANGUAGE',
                label: t(languageKeys.contentManagement, ''),
                icon: 'pi pi-fw pi-flag',
                to: '/system-management/language',
                roles: [Role.SuperAdmin, Role.Admin],
              },
              {
                key: 'SYSTEM_MANAGEMENT_CONFIGURATION_MANAGER',
                label: t(languageKeys.configurationManager, ''),
                icon: 'pi pi-fw pi-id-card',
                to: '/system-management/configuration-manager',
                roles: [Role.SuperAdmin, Role.Admin],
              },
              {
                key: 'SYSTEM_MANAGEMENT_SUGGESTIONS',
                label: t(languageKeys.suggestions, ''),
                icon: 'pi pi-fw pi-plus',
                to: '/system-management/suggestions',
                roles: [
                  Role.SuperAdmin,
                  Role.Admin,
                  Role.GeneralManager,
                  Role.CountrySalesManager,
                  Role.RegionSalesManager,
                  Role.CrmManager,
                  Role.CrmRepresentative,
                ],
              },
            ],
            roles: [
              Role.SuperAdmin,
              Role.Admin,
              Role.GeneralManager,
              Role.CountrySalesManager,
              Role.RegionSalesManager,
              Role.CrmManager,
              Role.CrmRepresentative,
            ],
          },
        ],
      },
      {
        items: [
          {
            key: 'ADMIN_MANAGEMENT',
            label: t(languageKeys.adminManagement, ''),
            icon: 'pi pi-fw pi-book',
            items: [
              {
                key: 'ADMIN_MANAGEMENT_ADMIN_PANEL_USERS',
                label: t(languageKeys.adminPanelUsers, ''),
                icon: 'pi pi-fw pi-users',
                to: '/admin-management/admin-panel-users',
                roles: [Role.Admin, Role.GeneralManager, Role.CountrySalesManager, Role.CrmManager],
              },
              {
                key: 'ADMIN_MANAGEMENT_CABINET_VISION_INVENTORY',
                label: t(languageKeys.cabinetVisionInventory, ''),
                icon: 'pi pi-fw pi-file',
                to: '/admin-management/cabinet-vision-inventory',
                roles: [Role.Admin, Role.GeneralManager, Role.CamManager],
              },
            ],
            roles: [
              Role.Admin,
              Role.GeneralManager,
              Role.CountrySalesManager,
              Role.RegionSalesManager,
              Role.CrmManager,
              Role.CamManager,
              Role.CrmRepresentative,
            ],
          },
        ],
      },
    ],
    [t, languageKeys]
  )

  const filterInnerMenuItems = useCallback(
    (m) => {
      let mi = m
      for (let i = 0; i < m.length; i++) {
        var j = m[i]?.items[0].items?.length
        while (j--) {
          if (!roleController(m[i]?.items[0].items[j].roles)) {
            mi[i].items[0].items.splice(j, 1)
          }
        }
      }

      return mi
    },
    [roleController]
  )

  const menuFilter = useCallback(() => {
    const filteredMenuItems = menuItems.filter((menuItem) => roleController(menuItem.items[0].roles))
    const result = filterInnerMenuItems(filteredMenuItems)
    setMenu(result)
  }, [filterInnerMenuItems, menuItems, roleController])

  useEffect(() => {
    if (isLoggedIn) menuFilter()
  }, [isLoggedIn, menuFilter, preferedLanguage])

  useEffect(() => {
    copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents()
    setCurrentLocation(location)
  }, [location])

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle)
  }

  const onRipple = (e) => {
    PrimeReact.ripple = e.value
    setRipple(e.value)
  }

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode)
  }

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode)
  }

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false)
      setMobileMenuActive(false)
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false)
    }

    mobileTopbarMenuClick = false
    menuClick = false
  }

  const onToggleMenuClick = (event) => {
    menuClick = true

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true)
        }

        setOverlayMenuActive((prevState) => !prevState)
        setMobileMenuActive(false)
      } else if (layoutMode === 'static') {
        setStaticMenuInactive((prevState) => !prevState)
      }
    } else {
      setMobileMenuActive((prevState) => !prevState)
    }

    event.preventDefault()
  }

  const onSidebarClick = () => {
    menuClick = true
  }

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true

    setMobileTopbarMenuActive((prevState) => !prevState)
    event.preventDefault()
  }

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true

    event.preventDefault()
  }

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false)
      setMobileMenuActive(false)
    }
  }
  const isDesktop = () => {
    return window.innerWidth >= 992
  }

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className)
    else element.className += ' ' + className
  }

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className)
    else
      element.className = element.className.replace(
        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      )
  }

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    'layout-theme-light': layoutColorMode === 'light',
  })

  function PrivateRoute({ children, ...rest }) {
    let { isLoggedIn } = useContext(AuthContext)
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isLoggedIn ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }
      />
    )
  }

  const buildRoutes = useMemo(() => {
    return isLoggedIn ? (
      <>
        <PrivateRoute exact path="/partner-management/applications/:type">
          <Dealers />
        </PrivateRoute>
        <PrivateRoute exact path="/system-management/language">
          <Language />
        </PrivateRoute>
        <PrivateRoute exact path="/system-management/lang/:id">
          <LanguagePageSelect />
        </PrivateRoute>
        <PrivateRoute exact path="/partner-management/applications/:type/:id">
          <DealerDetails />
        </PrivateRoute>
        <PrivateRoute exact path="/partner-management/partners">
          <Partners />
        </PrivateRoute>
        <PrivateRoute exact path="/partner-management/partner-users">
          <PartnerUsers />
        </PrivateRoute>
        <PrivateRoute exact path="/admin-management/admin-panel-users">
          <AdminPanelUsers />
        </PrivateRoute>
        <PrivateRoute exact path="/admin-management/cabinet-vision-inventory">
          <CabinetVisionInventory />
        </PrivateRoute>
        <PrivateRoute exact path="/system-management/configuration-manager">
          <ConfigurationManager />
        </PrivateRoute>
        <PrivateRoute exact path="/logistic-management/orders">
          <Orders />
        </PrivateRoute>
        <PrivateRoute exact path="/logistic-management/order-part-list/:id">
          <OrderPartList />
        </PrivateRoute>
        <PrivateRoute exact path="/logistic-management/prepare-packs">
          <PreparePacks />
        </PrivateRoute>
        <PrivateRoute exact path="/logistic-management/prepare-palletes">
          <PreparePalletes />
        </PrivateRoute>
        <PrivateRoute exact path="/logistic-management/load-packs">
          <LoadPacks />
        </PrivateRoute>
        <PrivateRoute exact path="/logistic-management/unload-packs">
          <UnloadPacks />
        </PrivateRoute>
        <PrivateRoute exact path="/project-management/my-orders">
          <MyOrders />
        </PrivateRoute>
        <PrivateRoute exact path="/project-management/my-order-detail/:id">
          <MyOrderDetail />
        </PrivateRoute>
        <PrivateRoute exact path="/my-suggestions">
          <MySuggestions />
        </PrivateRoute>
        <PrivateRoute exact path="/system-management/suggestions">
          <Suggestions />
        </PrivateRoute>
        <PrivateRoute exact path="/system-management/company-management">
          <CompanyManagement />
        </PrivateRoute>
        <PrivateRoute exact path="/system-management/company-management/:id">
          <CompanyDetails />
        </PrivateRoute>
        <PrivateRoute exact path="/system-management/module-menu-management">
          <ModuleMenuManagement />
        </PrivateRoute>
        <PrivateRoute exact path="/system-management/role-submenu-management">
          <RoleSubmenuManagement />
        </PrivateRoute>
      </>
    ) : null
  }, [isLoggedIn])

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      <AppTopbar
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
      </div>

      <div className="layout-main-container">
        <BreadcrumbProvider location={currentLocation}>
          <div className="layout-main">
            <Switch>
              <PrivateRoute path="/" exact>
                <Dashboard colorMode={layoutColorMode} location={location} />
              </PrivateRoute>
              {buildRoutes}
              <PrivateRoute>
                <Redirect to={'/'} />
              </PrivateRoute>
            </Switch>
          </div>
        </BreadcrumbProvider>
        <AppFooter layoutColorMode={layoutColorMode} />
      </div>
      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        layoutColorMode={layoutColorMode}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  )
}

export default App
