import { t } from 'i18next'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import CabinetVisionInventoryService from '../service/CabinetVisionInventoryService'
import AuthService from '../service/AuthService'
import AddDropDown from '../components/addDropDown/addDropDown'
import AddInput from '../components/addInput/addInput'




const CabinetVisionInventory = () => {

  const [deleteElementsDialog, setdeleteElementsDialog] = useState(false);
  const [dataList, setDataList] = useState(null)
  const {id1} = useParams()

  const [first, setFirst] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const [loading, setLoading] = useState(true)
  const [showEditModal, setShowEditModal] = useState(false)
  const dt = useRef(null)
  const toast = useRef(null)
  const [material, setMaterial] = useState([])
  const [uom, setUom] = useState([])
  const [materials, setMaterials] = useState([])
  const [uoms, setUoms] = useState([])

  const [filteredData, setFilteredData] = useState([]);


  const [totalElements, setTotalElements] = useState(0);

  
 
  useEffect(() => {
    
    getData(page,size,id);
    getMaterials();
    getUoms();
  },[])


  const defaultValues = {
    cv_id:'',
    name:'',
    description: '',
    material_type_id: '',
    uom_id: '',
    materialName:'',
    brand_sku:'',
    default_cost: '',
    sell_price:'',
    materials:'',
    uoms:'',

  }


  const {
    control,
    formState: {errors},
    handleSubmit,
    reset,
    setValue,
    getValues
    } = useForm({defaultValues: defaultValues})



  const addNew = async (data) => {
    data.material_type_id =  data.materialid
    data.uom_id = data.uomid
    

    CabinetVisionInventoryService.addPanelInventories(data).then((response) => {
        setShowEditModal(false);
        getData(page, size);
        toast.current.show({severity:'success', summary: 'Success', detail:'Successfully added', life: 3000});
    }).catch(err => {
        toast.current.show({severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000})
    })
}

const [id, setId] = useState("");
const editItem = async (data) => {
    const id = data.id;
    CabinetVisionInventoryService.updatePanelInventories(id, data)
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Success Successfully Updated",
          life: 3000,
        });
        setShowEditModal(false);

        getData(page, size);
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: err.response.data.message,
          life: 3000,
        });
      });
  };

    const onSubmit = async (data) => {
        if(data.id){
            editItem(data);
        }else{
            addNew(data);
        }
    }

  const editModalOpen = (rowData) => {
        reset();
        setShowEditModal(true);
    if(rowData){
        const fields = ['name', 'description', 'brand_sku', "default_cost", "sell_price", "cv_id","material_type_id", "uom_id","uoms", "materials", "id",]; 
        fields.forEach(field => setValue(field, rowData[field]));
 
        setValue("uomid", rowData.uom?.id);

        setValue("materialid", rowData.material?.id);

        setMaterial(rowData.material);
        setUom(rowData.uom);


    }else{
        setMaterial();
        
        setUom();
    }
  }

 
  const DTdataList = () => {
    if (dataList && dataList[0]) {
        const _dataList = [...dataList];
        for (let i of _dataList) {
            const material = materials.find(x => x.id === i.material_type_id);
            const uom = uoms.find(x => x.id === i.uom_id);
            i.materialName = material ? material.name : '';
            i.uomName = uom ? uom.name : '';
        }
        return _dataList;
    }
    return [];
};




const [size, setSize] = useState(20);
const [page, setPage] = useState(0);

  const getData = (page, size, searchTerm) => {
    setLoading(true);
    CabinetVisionInventoryService.getPanelInventories(page, size, searchTerm).then((response) => {
      setLoading(false);
      setDataList(response.data.content);
      setSize(response.data.size);
      setTotalElements(response.data.totalElements);
    });
  }
  



  
  const getMaterials = () => {
    CabinetVisionInventoryService.getMaterials().then((response) => {
        setMaterials(response.data.content.map(item => ({name : item.name, id : item.id, materialType:item.materialType})))

    })
  }
  const getUoms = () => {
    CabinetVisionInventoryService.getUoms().then((response) => {
        setUoms(response.data.content.map(item => ({name : item.name, id : item.id, uomType:item.uomType})))

    })
  }

 


  const confirmdeleteElements = (rowData) => {
    setValue("id",rowData.id);
    setValue("name",rowData.name);
    setdeleteElementsDialog(true);
}

const hideDeleteElementsDialog = () => {
    setdeleteElementsDialog(false);
}

const deleteElements = async (rowData) => {
    CabinetVisionInventoryService.deleteElements(rowData).then((response) => {
        toast.current.show({severity:'success', summary: 'Success', detail:'Success Successfully deleted', life: 3000});
        setdeleteElementsDialog(false);
        getData(page, size, id);
    }).catch(err => {
        toast.current.show({severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000})
    })
}



    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmdeleteElements(rowData)} />
            </>
        )
    }

  


    const header = (
        <div  className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("cabinetVisionInventorys.title")}</h5>
            <div className="flex">

            <Button label={t("general.new")} icon="pi pi-plus" onClick={() => editModalOpen(null)} className="p-button-success "/>        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
        <i className="pi pi-search"/>
        <InputText
  type="search"
  value={searchTerm}
  onChange={(e) => {
    setSearchTerm(e.target.value);
    if (e.target.value.length === 0) {

      getData(page, size, '');
    } else if (e.target.value.length >= 4) {

      getData(page, size, e.target.value);
    }
  }}
/>
        </span>
            </div>
        </div>
    )

    const selectMaterialType = (event) => {
        setMaterial(materials.filter(x => x.id ==event));
        setValue("material",materials.filter(x => x.id ==event)[0]);
        setValue("materialid", event)
      }

      const selectUomId = (event) => {
        setUom(uoms.filter(x => x.id ==event));
        setValue("uom",uoms.filter(x => x.id ==event)[0]);
        setValue("uomid", event)
      }

      const onLazyLoad = (event) => {
        getData(event.first / event.rows, event.rows );

        
    }

  
    const onPage = (e) => {
        setFirst(e.first)
         getData(e.page, e.rows, (searchTerm)? searchTerm : null);
         setSize(e.rows);
        setPage(e.page);
   
      }

  
    


  return (
    <div className="grid crud-demo">
    <div className="col-12">
        <div className="card">
            <Toast ref={toast}/>
                    <DataTable
                        ref={dt}
                        value={DTdataList()}
                        dataKey="id"
                        paginator       
                        onPage={onPage}
                        totalRecords={totalElements}
                        rows={size} 
                        lazy
                        globalFilter={searchTerm}
                        onLazyLoad={onLazyLoad}
                        rowsPerPageOptions={[5, 10, 20,100,200]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown "
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        emptyMessage="No records found."
                        header={header}
                        responsiveLayout="scroll"
                        selectionMode="single"
                        loading={loading}
                        first={first}
                        onRowClick={ e => {
                          editModalOpen(e.data)
                      }}
                        >
                        <Column field="cv_id" header={t("cabinetVisionInventorys.table.cv_id")} headerStyle={{width: '14%', minWidth: '10rem'}} ></Column>
                        <Column field="name"  header={t("cabinetVisionInventorys.table.name")} headerStyle={{width: '14%', minWidth: '10rem'}} ></Column>
                        <Column field="description"  header={t("cabinetVisionInventorys.table.description")} headerStyle={{width: '14%', minWidth: '10rem'}} ></Column>
                        <Column field="materialName" header={t("cabinetVisionInventorys.table.material_type_id")} hidden={false} headerStyle={{width: '14%', minWidth: '10rem'}} ></Column>         
                        <Column field="uomName" header={t("cabinetVisionInventorys.table.uom_id")} hidden={false} headerStyle={{width: '14%', minWidth: '10rem'}} ></Column>
                        <Column field="brand_sku" header={t("cabinetVisionInventorys.table.brand_sku")} headerStyle={{width: '14%', minWidth: '10rem'}} ></Column>
                        <Column field="default_cost" header={t("cabinetVisionInventorys.table.default_cost")} headerStyle={{width: '14%', minWidth: '10rem'}} ></Column>
                        <Column field="sell_price" header={t("cabinetVisionInventorys.table.sell_price")} headerStyle={{width: '14%', minWidth: '10rem'}} ></Column>

                        <Column
                            field="action"
                            header={t("cabinetVisionInventorys.table.action")}
                            body = {actionBodyTemplate}
                            headerStyle={{width: '14%', minWidth: '10rem'}}></Column>
                    </DataTable>

                    <Dialog header={getValues("id") ? t("cabinetVisionInventorys.modal.title.edit") :  t("cabinetVisionInventorys.modal.title.new")} visible={showEditModal} style={{ width: '30vw' }} onHide={() => setShowEditModal(false)}>
                        <div>
                            <div className="">
                                <form onSubmit={handleSubmit(onSubmit)} className="grid p-fluid">





                                <AddInput   label={t("cabinetVisionInventorys.modal.field.cv_id")}
  name="cv_id"
  control={control}
  rules={{ required: "general.required.field.msg" }}
  errors={errors}
  placeholder={t("cabinetVisionInventorys.modal.cv_id.placeholder")} />

                                    <AddInput   label={t("cabinetVisionInventorys.modal.field.name")}
  name="name"
  control={control}
  rules={{ required: "general.required.field.msg" }}
  errors={errors}
  placeholder={t("cabinetVisionInventorys.modal.name.placeholder")} />




                               
<AddInput   label={t("cabinetVisionInventorys.modal.field.description")}
  name="description"
  control={control}
  rules={{ required: "general.required.field.msg" }}
  errors={errors}
  placeholder={t("cabinetVisionInventorys.modal.description.placeholder")}/>



                                      <AddDropDown
  name="material_type_id"
  control={control}
  errors={errors}
  label={t("cabinetVisionInventorys.table.material_type_id")}
  placeholder={t("partners.modal.material_type_id.placeholder")}
  options={materials}
  optionLabel="name"
  optionValue="id"
  onHide={() => selectMaterialType(getValues("material_type_id"))}

/>  


<AddDropDown
  name="uom_id"
  control={control}
  errors={errors}
  label={t("cabinetVisionInventorys.table.uom_id")}
  placeholder={t("partners.modal.uom_id.placeholder")}
  options={uoms}
  optionLabel="name"
  optionValue="id"
  onHide={() => selectUomId(getValues("uom_id"))}

/>  
           


                                    <AddInput   label={t("cabinetVisionInventorys.modal.field.brand_sku")}
  name="brand_sku"
  control={control}
  rules={{ required: "general.required.field.msg" }}
  errors={errors}
  placeholder={t("cabinetVisionInventorys.modal.brand_sku.placeholder")} />



<AddInput   label={t("cabinetVisionInventorys.modal.field.default_cost")}
  name="default_cost"
  control={control}
  rules={{ required: "general.required.field.msg" }}
  errors={errors}
  placeholder={t("cabinetVisionInventorys.modal.default_cost.placeholder")} />


<AddInput   label={t("cabinetVisionInventorys.modal.field.sell_price")}
  name="sell_price"
  control={control}
  rules={{ required: "general.required.field.msg" }}
  errors={errors}
  placeholder={t("cabinetVisionInventorys.modal.sell_price.placeholder")} />

                                                 

                                    <div className="col-12">
                                    <Button type="submit" label="Save" className="mt-2"/>
                                    </div>

                                    </form>
                                </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteElementsDialog} style={{ width: '450px' }} header="Confirm" modal onHide={hideDeleteElementsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Are you sure you want to delete <b>{getValues("name")}</b>? </span>}
                        </div>
                        <footer style={{textAlign:"right"}}>
                        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteElementsDialog} />
                        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => deleteElements(getValues("id"))} />
                        </footer>
                    </Dialog>
                </div>
            </div>
        </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(CabinetVisionInventory)
