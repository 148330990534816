import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import AdminProjectService from '../../service/AdminProjectService'
import AdminDatatable from '../../components/admin/admin-datatable'
import { dateFormat } from '../../helpers/convertor'
import { SortOrder } from '../../helpers/sort-type'

const MyOrders = () => {
  const [dataList, setDataList] = useState(null)
  const [globalFilter, setGlobalFilter] = useState(null)
  const [loading, setLoading] = useState(false)
  const dt = useRef(null)
  const toast = useRef(null)
  const { t } = useTranslation()
  const mountedRef = useRef(false)

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getData = useCallback(async () => {
    try {
      if (mountedRef.current === true) setLoading(true)
      const response = await AdminProjectService.getMyOrders()
      if (mountedRef.current === true) setDataList(response.data)
    } catch (err) {
      if (toast.current) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      }
    } finally {
      if (mountedRef.current === true) setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (mountedRef.current === true) getData()
  }, [getData])

  const exportCSV = () => {
    dt.current.exportCSV()
  }

  const history = useHistory()

  const orderDetailClick = (rowData) => {
    history.push(`/project-management/my-order-detail/` + rowData.id)
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t('order.title')}</h5>
      <div className="flex">
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help"
          style={{ marginRight: '5px' }}
          onClick={exportCSV}
        />
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
        </span>
      </div>
    </div>
  )

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <AdminDatatable
            refeence={dt}
            dataList={dataList}
            rows={100}
            sortField={'code'}
            sortOrder={SortOrder.DESCENDING}
            pages={[5, 10, 25, 100, 200]}
            globalFilter={globalFilter}
            header={header}
            loading={loading}
            onRowClick={(e) => {
              orderDetailClick(e.data)
            }}>
            <Column
              field="code"
              header={t('project.order.table.code')}
              sortable
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="partner"
              sortable
              body={(rowData) => rowData?.partner?.name}
              header={t('project.order.table.partner')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="name"
              header={t('project.order.table.name')}
              sortable
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="createDate"
              sortable
              body={(rowData) => dateFormat(rowData, 'createDate')}
              header={t('project.order.table.createdDate')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="createBy"
              header={t('project.order.table.createdBy')}
              sortable
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="msrp"
              header={t('project.order.table.msrp')}
              sortable
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="finalPrice"
              header={t('project.order.table.finalPrice')}
              sortable
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="dueDate"
              sortable
              body={(rowData) => dateFormat(rowData, 'dueDate')}
              header={t('project.order.table.dueDate')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="orderDisplayStatus"
              sortable
              header={t('project.order.table.status')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          </AdminDatatable>
        </div>
      </div>
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(MyOrders, comparisonFn)
