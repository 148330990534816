import { useState, useMemo } from 'react'
import { useTranslation } from '../../hooks/useTranslation'

export const useModuleMenuManagement = () => {
  const languageKeys = useMemo(
    () => ({
      tabModule: 'moduleMenuManagement.tabMenu.module', // Modül, Module
      tabMainMenu: 'moduleMenuManagement.tabMenu.mainMenu', // Ana Menü, Main Menu
      tabSubmenu: 'moduleMenuManagement.tabMenu.submenu', // Alt Menü, Submenu
    }),
    []
  )

  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const { t } = useTranslation(Object.values(languageKeys))

  return useMemo(
    () => ({
      languageKeys,
      currentTabIndex,
      setCurrentTabIndex,
      t,
    }),
    [languageKeys, currentTabIndex, t]
  )
}
