import { t } from 'i18next'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import AdminPanelUserService from '../service/AdminPanelUserService'
import AuthService from '../service/AuthService'
import AuthContext from '../context/AuthContext'
import { Role } from '../helpers/role'

const AdminPanelUsers = () => {
  const [deleteUserDialog, setDeleteUserDialog] = useState(false)
  const { id } = useParams()
  const [dataList, setDataList] = useState(null)
  const [globalFilter, setGlobalFilter] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showEditModal, setShowEditModal] = useState(false)
  const dt = useRef(null)
  const toast = useRef(null)
  const [role, setRole] = useState([])
  const [roleList, setRoleList] = useState([])
  const [company, setCompany] = useState([])
  const [prefix, setPrefix] = useState([])
  const [manager, setManager] = useState([])
  const [companies, setCompanies] = useState([])
  const [managerList, setManagerList] = useState([])
  //const [isCalendlyRequire, setIsCalendlyRequire] = useState(false)
  const [authorizedRoles] = useState([Role.CrmManager, Role.CrmRepresentative])

  useEffect(() => {
    getData()
    getRoleList()
    getCompanies()
  }, [])

  /* useEffect(() => {
    let roleNames = role.map((item) => item.name)
    setIsCalendlyRequire(roleNames.some((name) => authorizedRoles.indexOf(name) !== -1))
  }, [role]) */

  const defaultValues = {
    id: null,
    prefix: '',
    username: '',
    email: '',
    phone: '',
    company: '',
    manager: null,
    roles: [],
    status: 'PASSIVE',
    managerId: null,
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({ defaultValues: defaultValues, shouldFocusError: false })

  const prefixList = [
    { label: 'MR', value: 'mr' },
    { label: 'MRS', value: 'mrs' },
    { label: 'MISS', value: 'miss' },
  ]

  const addNew = async (data) => {
    AdminPanelUserService.addPanelUser(data)
      .then((response) => {
        setShowEditModal(false)
        getData()
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully added', life: 3000 })
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  const editItem = async (data) => {
    AdminPanelUserService.updatePanelUser(data)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Success Successfully Updated',
          life: 3000,
        })
        setShowEditModal(false)
        getData()
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  const onSubmit = async (data) => {
    if (data.id) {
      editItem(data)
    } else {
      addNew(data)
    }
  }

  const editModalOpen = (rowData) => {
    reset()
    setShowEditModal(true)
    if (rowData) {
      setValue('prefix', rowData.prefix)
      setValue('username', rowData.username)
      setValue('phone', rowData.phone)
      setValue('email', rowData.email)
      setValue('roles', rowData.roles)
      setValue('company', rowData.company)
      setValue('manager', rowData.manager)
      setValue('companies', rowData.company)
      setValue('companyid', rowData.company.id)
      setValue('calendlyUrl', rowData.calendlyUrl)
      setValue('status', rowData.status)
      setValue('managerId', rowData.manager?.id)
      setValue('id', rowData.id)
      setRole(rowData.roles)
      setCompany(rowData.company)
      setPrefix(rowData.prefix)
      setManager(rowData.manager)

      getCompanyManager(rowData.company.id, rowData.roles[0].name)
    } else {
      reset()
      setRole([])
      setCompany()
      setManager()
    }
  }
  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  const getData = () => {
    AdminPanelUserService.getPanelUsers().then((response) => {
      setLoading(false)
      const dataModel = response.data.map((user) => {
        user.fullName = (user.prefix ? user.prefix + ' ' : '') + (user.username ? user.username : '')
        user.roleName = user.roles ? user.roles[0]?.displayName : ''
        return user
      })
      setDataList(dataModel)
    })
  }

  const getRoleList = () => {
    AdminPanelUserService.getRoleList().then((response) => {
      setRoleList(response.data.map((item) => ({ name: item.name, id: item.id, displayName: item.displayName })))
    })
  }

  const getCompanies = () => {
    AdminPanelUserService.getCompanies().then((response) => {
      setCompanies(response.data.map((item) => ({ name: item.name, id: item.id, companyType: item.companyType })))
    })
  }

  const getCompanyManager = (companyId, roleName) => {
    AdminPanelUserService.getCompanyManager(companyId, roleName)
      .then((response) => {
        AuthService.getProfile()
          .then((userInfo) => {
            var a = response.data.filter((item) => item.id !== userInfo.data.id)
            setManagerList(a.map((item) => ({ name: item.username, id: item.id })))
          })
          .catch((err) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
            setManagerList([])
          })
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
        setManagerList([])
      })
  }

  const confirmDeleteUser = (rowData) => {
    setValue('id', rowData.id)
    setValue('name', rowData.name)
    setDeleteUserDialog(true)
  }

  const hideDeleteUserDialog = () => {
    setDeleteUserDialog(false)
  }

  const deleteUser = async (rowData) => {
    AdminPanelUserService.deleteUser(rowData)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Success Successfully deleted',
          life: 3000,
        })
        setDeleteUserDialog(false)
        getData()
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t('adminPanelUsers.title')}</h5>
      <div className="flex">
        <Button
          label={t('general.new')}
          icon="pi pi-plus"
          onClick={() => editModalOpen(null)}
          className="p-button-success "
        />
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
        </span>
      </div>
    </div>
  )

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {/* <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary" onClick={() => editModalOpen(rowData)} aria-label="Edit" /> */}
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => confirmDeleteUser(rowData)}
        />
      </>
    )
  }

  const statusTemplate = (rowData) => {
    return (
      <>
        <span className={'customer-badge status-' + rowData.status}>{rowData.status}</span>
      </>
    )
  }

  const setRoleFunc = (rList) => {
    setRole(rList)
    setValue('roles', rList)
    if (rList.length > 0) setValue('roleName', rList[0].name)
  }

  const selectItemCompany = (event) => {
    setCompany(companies.filter((x) => x.id == event))
    setValue('company', companies.filter((x) => x.id == event)[0])
    setValue('companyid', event)
    if (getValues('roleId')?.length > 0 && getValues('companyid')?.length > 0) {
      getCompanyManager(getValues('companyid'), getValues('roleName'))
    }
  }

  const selectItemManager = (event) => {
    setManager(managerList.filter((x) => x.id == event))
    setValue('manager', managerList.filter((x) => x.id == event)[0])
    setValue('managerId', event)
  }

  const selectItemRole = (event) => {
    setRoleFunc(event.value.map((id) => ({ id: id, name: roleList.filter((item) => id === item.id)[0].name })))
    setValue('roleId', event.value)
    if (getValues('roleId')?.length > 0 && getValues('companyid')?.length > 0) {
      getCompanyManager(getValues('companyid'), getValues('roleName'))
    } else {
      setManagerList([])
    }
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={dataList}
            dataKey="id"
            paginator
            rows={100}
            rowsPerPageOptions={[5, 10, 25, 100, 200]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
            globalFilter={globalFilter}
            emptyMessage="No records found."
            header={header}
            responsiveLayout="scroll"
            selectionMode="single"
            loading={loading}
            onRowClick={(e) => {
              editModalOpen(e.data)
            }}>
            <Column
              field="company.name"
              header={t('adminPanelUsers.table.company')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="fullName"
              header={t('adminPanelUsers.table.fullName')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="roleName"
              header={t('adminPanelUsers.table.role')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="email"
              header={t('adminPanelUsers.table.email')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="phone"
              header={t('adminPanelUsers.table.phone')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="status"
              body={statusTemplate}
              header={t('adminPanelUsers.table.status')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="action"
              header={t('adminPanelUsers.table.action')}
              body={actionBodyTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          </DataTable>

          <Dialog
            header={getValues('id') ? t('adminPanelUsers.modal.title.edit') : t('adminPanelUsers.modal.title.new')}
            visible={showEditModal}
            style={{ width: '30vw' }}
            onHide={() => setShowEditModal(false)}>
            <div>
              <div className="">
                <form onSubmit={handleSubmit(onSubmit)} className="grid p-fluid">
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="companyid" className={classNames({ 'p-error': errors.companyid })}>
                        {t('adminPanelUsers.table.company')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="companyid"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              id={field.name}
                              {...field}
                              onHide={() => selectItemCompany(getValues('companyid'))}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                              options={companies}
                              optionLabel="name"
                              optionValue="id"
                              placeholder={t('partners.modal.partnerType.placeholder')}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('companyid')}
                    </div>
                  </div>

                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="prefix" className={classNames({ 'p-error': errors.prefix })}>
                        {t('partnerUsers.modal.field.prefix')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="prefix"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              id={field.name}
                              {...field}
                              autoFocus
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                              options={prefixList}
                              optionLabel="label"
                              placeholder={t('partnerUsers.modal.prefix.placeholder')}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('prefix')}
                    </div>
                  </div>

                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="username" className={classNames({ 'p-error': errors.username })}>
                        {t('adminPanelUsers.modal.field.name')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="username"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.name}
                              {...field}
                              autoFocus
                              placeholder={t('adminPanelUsers.modal.name.placeholder')}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('username')}
                    </div>
                  </div>

                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>
                        {t('adminPanelUsers.modal.field.email')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: t('general.required.field.msg'),
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: t('general.email.pattern.msg'),
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.name}
                              {...field}
                              autoFocus
                              placeholder={t('adminPanelUsers.modal.email.placeholder')}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('email')}
                    </div>
                  </div>

                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="phone" className={classNames({ 'p-error': errors.phone })}>
                        {t('adminPanelUsers.modal.field.phone')}
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="phone"
                          control={control}
                          rules={{
                            validate: (value) => {
                              const phoneRegex = /^(?:(\+\d{1,4}[\s-]?)?\d{10,15})?$/
                              return phoneRegex.test(value) || t('general.invalid.phone.msg')
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.name}
                              {...field}
                              autoFocus
                              placeholder={t('adminPanelUsers.modal.phone.placeholder')}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('phone')}
                    </div>
                  </div>

                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="calendlyUrl" className={classNames({ 'p-error': errors.calendlyUrl })}>
                        {t('adminPanelUsers.modal.field.url')}
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="calendlyUrl"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.name}
                              {...field}
                              autoFocus
                              placeholder={t('adminPanelUsers.modal.url.placeholder')}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('calendlyUrl')}
                    </div>
                  </div>

                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="userRole" className={classNames({ 'p-error': errors.roles })}>
                        {t('adminPanelUsers.modal.field.role')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="roles"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <MultiSelect
                              showSelectAll={false}
                              selectionLimit={1}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                              options={roleList}
                              onChange={selectItemRole}
                              value={role.map((itm) => itm.id)}
                              optionLabel="displayName"
                              optionValue="id"
                              placeholder={t('adminPanelUsers.modal.select.placeholder')}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('roles')}
                    </div>
                  </div>

                  {managerList.length > 0 && (
                    <div className="col-12 md:col-6">
                      <div className="field">
                        <label htmlFor="managerId" className={classNames({ 'p-error': errors.managerId })}>
                          {t('adminPanelUsers.modal.field.manager')} *
                        </label>
                        <span className="p-float-label">
                          <Controller
                            name="managerId"
                            control={control}
                            rules={{ required: t('general.required.field.msg') }}
                            render={({ field, fieldState }) => (
                              <Dropdown
                                id={field.name}
                                {...field}
                                onHide={() => selectItemManager(getValues('managerId'))}
                                className={classNames({ 'p-invalid': fieldState.invalid })}
                                options={managerList}
                                optionLabel="name"
                                optionValue="id"
                                placeholder={t('adminPanelUsers.modal.select.placeholder')}
                              />
                            )}
                          />
                        </span>
                        {getFormErrorMessage('managerId')}
                      </div>
                    </div>
                  )}

                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.status })}>
                        {t('adminPanelUsers.modal.field.status')}
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="status"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputSwitch
                              trueValue={'ACTIVE'}
                              falseValue={'PASSIVE'}
                              checked={getValues('status')}
                              onChange={(e) => setValue('status', e.value)}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('status')}
                    </div>
                  </div>

                  <div className="col-12">
                    <Button type="submit" label="Save" className="mt-2" />
                  </div>
                </form>
              </div>
            </div>
          </Dialog>

          <Dialog
            visible={deleteUserDialog}
            style={{ width: '450px' }}
            header="Confirm"
            modal
            onHide={hideDeleteUserDialog}>
            <div className="flex align-items-center justify-content-center">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
              {
                <span>
                  Are you sure you want to delete <b>{getValues('name')}</b>?{' '}
                </span>
              }
            </div>
            <footer style={{ textAlign: 'right' }}>
              <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
              <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => deleteUser(getValues('id'))}
              />
            </footer>
          </Dialog>
        </div>
      </div>
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(AdminPanelUsers, comparisonFn)
