import React, { useEffect, useMemo, useRef, useCallback, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Toast } from 'primereact/toast'
import classNames from 'classnames'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { ProgressSpinner } from 'primereact/progressspinner'

import CompanyService from '../../../service/CompanyService'
import CommonService from '../../../service/CommonService'
import { useTranslation } from '../../../hooks/useTranslation'

const CompanyAdmin = ({ companyAdminInfo, onCompanyChange }) => {
  const languageKeys = useMemo(
    () => ({
      toastSuccessSummary: 'general.toast.success.summary',
      toastCompanyUpdatedMsg: 'companyManagement.toast.success.updatedMsg',
      toastErrorSummary: 'general.toast.error.summary',
      subtitle: 'companyManagement.details.admin.subtitle',
      adminName: 'companyManagement.form.adminName.label',
      adminNamePlaceholder: 'companyManagement.form.adminName.placeholder',
      adminTitle: 'companyManagement.form.adminTitle.label',
      adminEmail: 'companyManagement.form.adminEmail.label',
      adminEmailPlaceholder: 'companyManagement.form.adminEmail.placeholder',
      adminPhone: 'companyManagement.form.adminPhone.label',
      adminPhonePlaceholder: 'companyManagement.form.adminPhone.placeholder',
      requiredField: 'general.required.field.msg',
      dropdownPlaceholder: 'general.dropdown.placeholder',
      invalidPhone: 'general.invalid.phone.msg',
      invalidEmail: 'general.invalid.email.msg',
      save: 'general.save',
    }),
    []
  )

  const { t, lang } = useTranslation(Object.values(languageKeys))

  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [titles, setTitles] = useState([])

  const mounted = useRef(false)
  const toast = useRef(null)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const getActiveStaticContents = useCallback(
    (staticContents, group) => {
      const result = staticContents[group]
        .filter((item) => JSON.parse(item.value)?.active !== false)
        .map((item) => ({
          label: JSON.parse(item.value)[lang],
          value: item.label,
        }))
      return result
    },
    [lang]
  )

  const getStaticContent = useCallback(async () => {
    try {
      if (mounted.current === true) setLoading(true)
      const response = await CommonService.staticContent(['contact.titles'])
      if (mounted.current === true) {
        setTitles(getActiveStaticContents(response.data.result, 'contact.titles'))
      }
    } catch (error) {
    } finally {
      if (mounted.current === true) setLoading(false)
    }
  }, [getActiveStaticContents])

  useEffect(() => {
    if (titles.length === 0) getStaticContent()
  }, [getStaticContent, titles.length])

  const updateCompanyAdminInfo = useCallback(
    async (data) => {
      try {
        if (mounted.current === true) setSaving(true)
        const response = await CompanyService.updateCompanyAdminInfo(data)
        onCompanyChange(response.data)
        // onConvertFromTo([{ from: data.id, to: data.name }])
        toast.current.show({
          severity: 'success',
          summary: t(languageKeys.toastSuccessSummary),
          detail: t(languageKeys.toastCompanyUpdatedMsg),
          life: 3000,
        })
      } catch (err) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: err.response.data.message,
          life: 3000,
        })
      } finally {
        if (mounted.current === true) setSaving(false)
      }
    },
    [onCompanyChange, t, languageKeys]
  )

  const defaultValues = {
    id: null,
    name: '',
    companyAddress: '',
    state: '',
    city: '',
    zipcode: '',
    companyShortCode: '',
    companyPhone: '',
    companyEmail: '',
    companyTaxnbr: '',
    qbId: '',
    adminName: '',
    adminTitle: '',
    adminEmail: '',
    adminPhone: '',
    status: true,
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues, shouldFocusError: false })

  useEffect(() => {
    if (companyAdminInfo !== null) {
      reset(companyAdminInfo)
    }
  }, [companyAdminInfo, reset])

  const getFormErrorMessage = (fieldName) => {
    return errors[fieldName] && <small className="p-error">{errors[fieldName].message}</small>
  }
  return (
    <>
      <Toast ref={toast} />
      <div style={{ maxHeight: '100%' }}>
        <div style={{ marginTop: '1.0rem', paddingBottom: '1.5rem' }}>
          <form onSubmit={handleSubmit(updateCompanyAdminInfo)} className="grid">
            <div className="col-12 md:col-12">
              <h4 style={{ fontSize: '1.1rem', fontWeight: 700, margin: '1.0rem 0 0.5rem 0' }}>
                {t(languageKeys.subtitle)}
              </h4>
            </div>

            <div className="col-12 md:col-6 p-fluid">
              <div className="field">
                <label htmlFor="adminName" className={classNames({ 'p-error': errors.adminName })}>
                  {t(languageKeys.adminName) + ' *'}
                </label>
                <span className="p-float-label">
                  <Controller
                    name="adminName"
                    control={control}
                    rules={{
                      required: t(languageKeys.requiredField),
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder={t(languageKeys.adminNamePlaceholder)}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                </span>
                {getFormErrorMessage('adminName')}
              </div>
            </div>

            <div className="col-12 md:col-6 p-fluid">
              <div className="field">
                <label htmlFor="adminTitle" className={classNames({ 'p-error': errors.adminTitle })}>
                  {t(languageKeys.adminTitle) + ' *'}
                </label>
                {loading === true ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                    <ProgressSpinner style={{ width: '40px', height: '40px' }} />
                  </div>
                ) : (
                  <>
                    <span className="p-float-label">
                      <Controller
                        name="adminTitle"
                        control={control}
                        rules={{ required: t(languageKeys.requiredField) }}
                        render={({ field, fieldState }) => (
                          <Dropdown
                            id={field.name}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e.value)
                            }}
                            className={classNames({ 'p-invalid': fieldState.invalid })}
                            options={titles}
                            optionLabel="label"
                            optionValue="value"
                            placeholder={t(languageKeys.dropdownPlaceholder)}
                          />
                        )}
                      />
                    </span>
                    {getFormErrorMessage('adminTitle')}
                  </>
                )}
              </div>
            </div>

            <div className="col-12 md:col-6 p-fluid">
              <div className="field">
                <label htmlFor="adminEmail" className={classNames({ 'p-error': errors.adminEmail })}>
                  {t(languageKeys.adminEmail) + ' *'}
                </label>
                <span className="p-float-label">
                  <Controller
                    name="adminEmail"
                    control={control}
                    rules={{
                      required: t(languageKeys.requiredField),
                      validate: (value) => {
                        const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'gm')
                        return emailRegex.test(value) || t(languageKeys.invalidEmail)
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder={t(languageKeys.adminEmailPlaceholder)}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                </span>
                {getFormErrorMessage('adminEmail')}
              </div>
            </div>

            <div className="col-12 md:col-6 p-fluid">
              <div className="field">
                <label htmlFor="adminPhone" className={classNames({ 'p-error': errors.adminPhone })}>
                  {t(languageKeys.adminPhone) + ' *'}
                </label>
                <span className="p-float-label">
                  <Controller
                    name="adminPhone"
                    control={control}
                    rules={{
                      required: t(languageKeys.requiredField),
                      validate: (value) => {
                        const phoneRegex = /^(?:(\+\d{1,4}[\s-]?)?\d{10,15})?$/
                        return phoneRegex.test(value) || t(languageKeys.invalidPhone)
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder={t(languageKeys.adminPhonePlaceholder)}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                </span>
                {getFormErrorMessage('adminPhone')}
              </div>
            </div>

            <div
              className="col-12 md:p-fluid md:col-12"
              style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button
                type="submit"
                label={t(languageKeys.save)}
                loading={saving}
                loadingIcon="pi pi-spin pi-spinner"
                className="mt-2"
                style={{ display: 'block' }}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default CompanyAdmin
