import React, {useEffect, useRef, useState} from 'react'
import {Button} from 'primereact/button'
import DealersService from '../../service/DealersService'
import {Dropdown} from 'primereact/dropdown'
import {Toast} from "primereact/toast";
import {useHistory} from 'react-router-dom'

const Approval = (props) => {
    const {dealerId, dealerStatus, assignedUserId} = props
    const [disabled, setDisabled] = useState(dealerStatus !== 'APPROVAL')
    const [selectedStatus, setSelectedStatus] = useState(null)
    let history = useHistory()


    const toast = useRef(null);
    const approvalStatus = [
        {label: 'Not Eligible', value: 'NOT_ELIGIBLE'},
        {label: 'Approved', value: 'CONVERTED'},
    ]


    const setApprove = () => {
        let request = {
            id: dealerId,
            status: selectedStatus,
            assignedUser : {
                id: assignedUserId
           }
        }
        DealersService.updateDealers(dealerId, request).then((response) => {
            toast.current.show({severity:'success', summary: 'Success Approve', detail:'Success Assign', life: 3000});
            history.push('/applications/approval')
        })
        // DealersService.approve(dealerId, selectedStatus).then((response) => {
        //     toast.current.show({severity:'success', summary: 'Success Approve', detail:'Success Assign', life: 3000});
        // })
    }

    return (
        <div className="grid crud-demo">
            <Toast ref={toast} />
            <div className="col-12 p-0">
                <div className="card">
                    <div className="grid">
                        <div className="col-8">
                            <Dropdown
                                className="min-w-full"
                                value={selectedStatus}
                                options={approvalStatus}
                                onChange={(e) => setSelectedStatus(e.value)}
                                optionLabel="label"
                                optionValue="value"
                                placeholder="Select a Status"
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-4">
                            <Button label="Save" disabled={disabled} onClick={setApprove}className="p-button-success min-w-full"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Approval
