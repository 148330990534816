import React from 'react'
import { Dialog } from 'primereact/dialog'
import classNames from 'classnames'
import { Controller } from 'react-hook-form'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { useTranslation } from '../../../hooks/useTranslation'

const AddOrEditSuggestionDialog = ({ visible, onHide, onSubmit, errors, control, loading, getValues }) => {
  const languageKeys = {
    titleAddNew: 'suggestions.dialog.title.addNew',
    titleUpdate: 'suggestions.dialog.title.update',
    labelSuggestion: 'suggestions.dialog.label.suggestion',
    requiredSuggestion: 'suggestions.dialog.required.suggestion',
    buttonAddNew: 'suggestions.dialog.button.addNew',
    buttonUpdate: 'suggestions.dialog.button.update',
  }

  const { t } = useTranslation(Object.values(languageKeys))

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  return (
    <Dialog
      header={getValues('id') === null ? t(languageKeys.titleAddNew) : t(languageKeys.titleUpdate)}
      visible={visible}
      style={{ width: '50vw' }}
      onHide={onHide}>
      <div>
        <div>
          <form onSubmit={onSubmit} className="grip">
            <div className="col-12 md:col-12">
              <div className="suggestion">
                <label htmlFor="text" className={classNames({ 'p-error': errors.text })}>
                  {t(languageKeys.labelSuggestion)}
                </label>
                <span className="p-float-label">
                  <Controller
                    name="text"
                    control={control}
                    rules={{ required: t(languageKeys.requiredSuggestion) }}
                    render={({ field, fieldState }) => (
                      <InputTextarea
                        id={field.name}
                        {...field}
                        rows={5}
                        cols={70}
                        autoFocus
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                </span>
                {getFormErrorMessage('text')}
              </div>
            </div>
            <div className="flex justify-content-end">
              <Button
                type="submit"
                label={getValues('id') === null ? t(languageKeys.buttonAddNew) : t(languageKeys.buttonUpdate)}
                loading={loading}
                loadingIcon="pi pi-spin pi-spinner"
                className="mt-2 p-button-secondary"
                style={{ display: 'block' }}
              />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  )
}

export default AddOrEditSuggestionDialog
