import { FileUpload } from 'primereact/fileupload'
import React, { useState } from 'react'
import { Tag } from 'primereact/tag'
import { Button } from 'primereact/button'
import { AdminFileUploadType } from '../../helpers/file-upload.type'

const AdminFileupload = ({uploader, bomCalcFunc, uploadType, isBomCalc, isSamePrice, bomCalcData, isButtonLoading}) => {
    const [file, setFile] = useState(null);
    const [isChooseFile, setIsChooseFile] = useState(false);

    const onSubmitSave = (useBomPrice) => {
        if(uploadType === AdminFileUploadType.BOM) {
            if(isBomCalc) {
                uploader(file, useBomPrice);
            } else {
                bomCalcFunc(file);
            }
        } else {
            uploader(file);
        }
    }

    const onTemplateSelect = (e) => {
        setFile(e.files[0]);
        setIsChooseFile(true);
    }

    const onTemplateClear = (callback) => {
        setFile(null);
        setIsChooseFile(false);
        if(callback !== null && typeof callback !== 'undefined') {
            callback();
        }
    }

    const itemTemplate = (file, props) => {
        return (
                <div className="flex align-items-center flex-wrap">
                    <div className="flex align-items-center" style={{width: '40%'}}>
                        <i className={(uploadType === AdminFileUploadType.BOM) ? "pi pi-file-excel" : ( uploadType === AdminFileUploadType.PART_LIST ? "pi pi-file" : "pi pi-file-pdf")} style={{'fontSize': '3em', color: 'var(--surface-d)'}}/>
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button disabled={isButtonLoading} type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateClear(props.onRemove)} />
            </div>
        )
    }

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-download mt-3 p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
            </div>
        )
    }

    const headerTemplate = (options) => {
        const { chooseButton, className } = options;
        return (
            <div className={className}>
                {chooseButton}
            </div>
        );
    }

    const uploadButtons = () => {
        if(uploadType === AdminFileUploadType.BOM) {
            if(isBomCalc) {
                if(!isSamePrice) {
                    return (
                        <div className="flex flex-row justify-content-around mt-5">
                            <Button loading={isButtonLoading} label="Update MSRP & Save" type="button" className="p-button-success" onClick={() => onSubmitSave(true)} />
                            <Button loading={isButtonLoading} label="Not Update MSRP & Save" type="button" className="p-button-danger" onClick={() => onSubmitSave(false)} />
                        </div>
                    );
                } else {
                    return <Button loading={isButtonLoading} label="Upload" type="button" className="p-button-primary mt-5" onClick={() => onSubmitSave(true)} />;
                }
            } else {
                return <Button loading={isButtonLoading} label="Calculate" type="button" className="p-button-primary mt-5" onClick={onSubmitSave} />;
            }
        } else {
            return <Button loading={isButtonLoading} label="Upload" type="button" className="p-button-primary mt-5" onClick={onSubmitSave} />;
        }
    }

    const bomCalcPrice = () => {
        if(isBomCalc) {
            return(
                <div className="flex flex-column mt-5">
                    <div className="flex flex-row justify-content-start">
                        <div className="col-3">
                            <span>BOM File MSRP: </span>
                        </div>
                        <div className="col-3">
                            <span>{bomCalcData.bomFileMsrp}</span>
                        </div>
                    </div>
                    <div className="flex flex-row justify-content-start">
                        <div className="col-3">
                            <span>Partner MSRP: </span>
                        </div>
                        <div className="col-3">
                            <span>{bomCalcData.partnerMsrp}</span>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return(
            <>
            <FileUpload accept={(uploadType === AdminFileUploadType.BOM) ? ".csv, .xlsx, .xls" : (uploadType === AdminFileUploadType.PART_LIST ? ".txt" : ".pdf")}
                        headerTemplate={headerTemplate} headerClassName={isChooseFile ? 'hidden': ''}
                        onSelect={onTemplateSelect} onError={onTemplateClear}
                        itemTemplate={itemTemplate} emptyTemplate={emptyTemplate} />
            {bomCalcPrice()}
            {uploadButtons()}
        </>
    );
};

export default AdminFileupload;
