import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import OrderService from '../../service/OrderService'
import { useHistory } from 'react-router-dom'
var ReactBarcode = require('react-barcode')

const PreparePacks = () => {
  const [deletePackingDialog, setDeletePackingDialog] = useState(false)
  const [dataList, setDataList] = useState(null)
  const [additionalPartList, setAdditionalPartList] = useState(null)
  const [chapterdataList, setChapterDataList] = useState(null)
  const [packingdataList, setPackingDataList] = useState(null)
  const [packingpartList, setPackingPartList] = useState(null)
  const [globalFilter, setGlobalFilter] = useState(null)
  const [loading, setLoading] = useState(true)
  const [partBarcode, setPartBarcode] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showEditRoomModal, setShowEditRoomModal] = useState(false)
  const [showPackingModal, setShowPackingModal] = useState(false)
  const [showAddPartBarcodeModal, setShowAddPartBarcodeModal] = useState(false)
  const [showAdditionalPackingModal, setShowAdditionalPackingModal] = useState(false)
  const [showBarcodeModal, setShowBarcodeModal] = useState(false)
  const [showPrintModal, setShowPrintModal] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const packcontentref = useRef(null)
  const dt = useRef(null)
  const toast = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    getData()
  }, [])

  const defaultValues = {
    id: null,
    name: null,
    partBarcode: '',
    PartId: '',
    orderid: '',
    ordername: '',
  }
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    resetField,
    setValue,
    getValues,
  } = useForm({ defaultValues: defaultValues })

  const selectItem = (id) => {}

  const exportCSV = () => {
    dt.current.exportCSV()
  }

  const history = useHistory()

  const partListClick = (id) => {
    history.push(`/logistic-management/order-part-list/` + id)
  }

  const editItem = async (data) => {
    OrderService.updateOrder(data.id, data)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Success Successfully updated',
          life: 3000,
        })
        setShowEditModal(false)
        getData()
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  const onSubmit = async (data) => {
    if (data.id) {
      editItem(data)
    } else {
      // addNew(data);
    }
  }
  const handleUserInput = (e) => {
    setInputValue(e.target.value)
  }

  const onSubmitPacking = async (data) => {
    var control = getValues('isClose')
    if (control) {
      setValue('isClose', false)
      var a = getValues('orderid')
      OrderService.createPacking(a)
        .then((response) => {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully Create New Package',
            life: 3000,
          })

          var partid = response.data.id
          OrderService.readBarcodePacking(partid, data)
            .then((response1) => {
              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully Added Barcode',
                life: 3000,
              })
              setShowEditModal(false)
              var orderid = data.orderid
              getPackingDataNoAdd(orderid)
              getPackingPartData(orderid, partid)
              getData()

              resetField('partBarcode')
            })
            .catch((err) => {
              toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: err.response1.data.message,
                life: 3000,
              })

              resetField('partBarcode')
            })
        })
        .catch((err) => {
          toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
        })
    } else {
      var partid = data.PartId
      OrderService.readBarcodePacking(partid, data)
        .then((response) => {
          setValue('notPackPartCount', response.data.notPackPartCount)
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully Added', life: 3000 })
          setShowEditModal(false)
          var orderid = data.orderid
          getPackingDataNoAdd(orderid)
          getPackingPartData(orderid, partid)
          getData()
          resetField('partBarcode')
        })
        .catch((err) => {
          toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
          resetField('partBarcode')
        })
    }
    var isClose = data.partBarcode.toLowerCase().trim()
    if (isClose == 'close') {
      setValue('isClose', true)
      print()
    }
  }

  const onSubmitAdditionalPacking = async (data) => {
    var orderid = getValues('orderid')
    const sampleJSON = {
      parts: [{ name: data.name }],
    }
    OrderService.createAdditionalPacking(orderid, sampleJSON)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Successfully Added Additional Part',
          life: 3000,
        })
        packcontentref.current.value = ''
        getAdditionalPartData(orderid)
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
    packcontentref.current.value = ''
  }
  const packingActionBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => deletePacking(rowData)}
        />
      </>
    )
  }
  const additionalPackingActionBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => deleteAdditionalPacking(rowData)}
        />
      </>
    )
  }
  const printBodyTemplate = (rowData) => {
    return (
      <Button type="submit" className="p-button-success mr-2 mb-2" onClick={() => printModalOpen(rowData)}>
        Print Box Label
      </Button>
    )
  }
  const printModalOpen = (rowData) => {
    setValue('barcode', rowData.barcode)
    setValue('partName', rowData.parts)

    if (rowData.parts.length > 0) {
      var partList = ''
      for (var i = 0; i < rowData.parts.length; i++) {
        partList +=
          rowData.barcode.split('-')[1] + '-' + rowData.barcode.split('-')[2] + ' ' + rowData.parts[i].name + '|'
      }
      setValue('partName', partList)
      setShowBarcodeModal(true)
    }
  }
  const BarcodeFieldTemplate = (rowData) => {
    return (
      <>
        <span>{rowData.parts.length > 0 ? rowData.parts[0].name : ''}</span>
      </>
    )
  }
  const RoomnameFieldTemplate = (rowData) => {
    return (
      <>
        <span>{rowData.parts.length > 0 ? rowData.parts[0].chapterOfOrder.name : ''}</span>
      </>
    )
  }
  const packingActionPartDeleteTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => deleteParts(rowData.id)}
        />
      </>
    )
  }
  const AddPartBarcodeActionBodyTemplate = (rowData) => {
    return (
      <>
        <Button type="submit" className="p-button-success mr-2 mb-2" onClick={() => partbarcodeModalOpen(rowData.id)}>
          Add Part Barcode
        </Button>
      </>
    )
  }
  const PrintActionBodyTemplate = (rowData) => {
    return (
      <>
        <Button type="submit" className="p-button-success mr-2 mb-2" onClick={() => print()}>
          Print
        </Button>
      </>
    )
  }
  const CloseBarcode = (data) => {
    if (data == 0) {
      getData()
      setShowBarcodeModal(false)
      setShowAddPartBarcodeModal(false)
      setShowPackingModal(false)
      alert('Tüm Partlar Paketlendi')
    } else {
      setShowBarcodeModal(false)
    }
  }
  const Print = () => {
    let scrollPosition = document.documentElement.scrollTop
    window.scrollTo(0, 0)
    window.print()
    window.scrollTo(0, scrollPosition)
  }

  const editRoomModalOpen = (rowData) => {
    setShowEditRoomModal(true)
    getChapterData(rowData.id)
    if (rowData) {
      setValue('name', rowData.name)
      setValue('code', rowData.roomcode)
      setValue('id', rowData.id)
    } else {
      reset()
    }
  }
  const packingModalOpen = (rowData) => {
    setShowPackingModal(true)
    getPackingData(rowData.id)
    if (rowData) {
      setValue('ordername', rowData.name)
      setValue('orderid', rowData.id)
      setValue('PartId', rowData.PartId)
    } else {
      reset()
    }
  }
  const additionalPackingModalOpen = (rowData) => {
    setShowAdditionalPackingModal(true)
    getAdditionalPartData(rowData.id)
    if (rowData) {
      setValue('ordername', rowData.name)
      setValue('orderid', rowData.id)
    } else {
      reset()
    }
  }

  const getAdditionalPartData = (orderid) => {
    setValue('orderid', orderid)
    OrderService.getAdditionalPacking(orderid).then((response) => {
      setLoading(false)
      setAdditionalPartList(response.data)
      packcontentref.current.value = ''
    })
  }
  const partbarcodeModalOpen = (rowData) => {
    setShowAddPartBarcodeModal(true)
    var orderId = getValues('orderid')
    setValue('PartId', rowData)
    setValue('orderid', orderId)
    getPackingPartData(orderId, rowData)
  }
  const print = () => {
    var oid = getValues('orderid')
    var pid = getValues('paketid')

    OrderService.getPacking(oid)
      .then((response) => {
        var a = response.data.filter((x) => x.id == pid)
        if (a.length > 0) {
          setValue('barcode', a[0].barcode)
          setValue('roomName', a[0].parts[0].chapterOfOrder.name)
          var partList = ''
          for (var i = 0; i < a[0].parts.length; i++) {
            partList +=
              a[0].parts[i].barcode.split('-')[2] +
              '-' +
              a[0].parts[i].barcode.split('-')[3] +
              ' ' +
              a[0].parts[i].name +
              '|'
          }
          setValue('partName', partList)
          setShowBarcodeModal(true)
        }
      })
      .catch((err) => {})
  }

  const deletePacking = async (rowData) => {
    OrderService.deletePacking(rowData.id)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Success Successfully deleted',
          life: 3000,
        })
        getPackingDataNoAdd(getValues('orderid'))
        getData()
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  const deleteAdditionalPacking = async (rowData) => {
    OrderService.deleteParts(rowData.parts[0].id)
      .then((response) => {
        OrderService.deletePacking(rowData.id).then((response) => {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Success Successfully deleted',
            life: 3000,
          })
          getAdditionalPartData(getValues('orderid'))
        })
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  const deleteParts = async (rowData) => {
    OrderService.deleteParts(rowData)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Part Successfully deleted', life: 3000 })
        var orderId = getValues('orderid')
        getPackingPartData(orderId, rowData)
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }
  const createPackage = async () => {
    var a = getValues('orderid')
    OrderService.createPacking(a)
      .then((response) => {
        setValue('paketid', response.data.id)
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Successfully Create Package',
          life: 3000,
        })
        getPackingData(getValues('orderid'))
        getData()
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }
  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  const getData = () => {
    OrderService.getPreparePacksOrders().then((response) => {
      setLoading(false)
      setDataList(response.data)
    })
  }

  const getChapterData = (rowData) => {
    OrderService.getChapter(rowData).then((response) => {
      setLoading(false)
      setChapterDataList(response.data)
    })
  }

  const getPackingData = (rowData) => {
    OrderService.getPacking(rowData).then((response) => {
      setLoading(false)

      if (response.data.length == 0) {
        createPackage()
        partbarcodeModalOpen(rowData)
      } else if (response.data.length > 0) {
        var a = response.data.filter((x) => x.status == 'OPEN')
        setPackingDataList(a)

        if (a.length == 0) {
          createPackage()
          partbarcodeModalOpen(rowData)
        } else {
          setValue('PartId', a[0].id)
        }
      }
    })
  }
  const getPackingDataNoAdd = (rowData) => {
    OrderService.getPacking(rowData).then((response) => {
      setLoading(false)
      if (response.data.length > 0) {
        var a = response.data.filter((x) => x.status == 'OPEN')
        setPackingDataList(a)
      }
    })
  }
  const getPackingPartData = (orderid, rowData) => {
    setValue('PartId', rowData)
    setValue('paketid', rowData)
    setValue('orderid', orderid)
    OrderService.getPacking(orderid).then((response) => {
      setLoading(false)
      if (response.data.length > 0) {
        var a = response.data.filter((x) => x.status == 'OPEN')
        if (a.length > 0) {
          a = a[0].parts
        }
        setPackingPartList(a)
        resetField('partBarcode')
      }
    })
  }
  const actionBodyTemplate = (rowData) => {
    if (rowData.notPackPartCount == 0)
      return (
        <Button
          type="submit"
          className="p-button-primary mr-2 mb-2"
          onClick={() => additionalPackingModalOpen(rowData)}>
          Add Packing
        </Button>
      )
    else if (rowData.partCount == rowData.notPackPartCount)
      return (
        <Button type="submit" className="p-button-primary mr-2 mb-2" onClick={() => packingModalOpen(rowData)}>
          Start Packing
        </Button>
      )
    else
      return (
        <Button type="submit" className="p-button-primary mr-2 mb-2" onClick={() => packingModalOpen(rowData)}>
          Continue Packing ({rowData.partCount - rowData.notPackPartCount})
        </Button>
      )
  }
  const NextStatus = (data) => {
    const sampleJSON = {
      orderStatus: 'PACKING',
    }
    OrderService.changeStatus(data, sampleJSON)
      .then((response) => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Changed Status', life: 3000 })
        setShowPackingModal(false)
        setShowAdditionalPackingModal(false)
        getData()
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }
  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t('order.title')}</h5>
      <div className="flex">
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help"
          style={{ marginRight: '5px' }}
          onClick={exportCSV}
        />

        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
        </span>
      </div>
    </div>
  )

  const hideDeletePackingDialog = () => {
    setDeletePackingDialog(false)
  }

  const roomBodyTemplate = (rowData) => {
    return (
      <Button type="submit" className="p-button-success mr-2 mb-2" onClick={() => editRoomModalOpen(rowData)}>
        Rooms({rowData.chapterCount})
      </Button>
    )
  }
  const partBodyTemplate = (rowData) => {
    return (
      <Button type="submit" className="p-button-warning mr-2 mb-2" onClick={() => partListClick(rowData.id)}>
        View({rowData.partCount})
      </Button>
    )
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={dataList}
            dataKey="id"
            paginator
            rows={100}
            rowsPerPageOptions={[5, 10, 25, 100, 200]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders"
            globalFilter={globalFilter}
            emptyMessage="No orders found."
            header={header}
            responsiveLayout="scroll"
            selectionMode="single"
            loading={loading}
            selection={selectItem}>
            <Column
              field="name"
              header={t('order.table.name')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="code"
              header={t('order.table.code')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

            <Column
              field="orderRooms"
              header={t('order.table.rooms')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}
              body={roomBodyTemplate}
            />
            <Column
              field="orderParts"
              header={t('order.table.parts')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}
              body={partBodyTemplate}></Column>
            <Column
              field="orderStatus"
              header={t('order.table.action')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}
              body={actionBodyTemplate}></Column>
          </DataTable>
          <Dialog
            header={getValues('name') + ' ' + t('general.modal.title.room')}
            visible={showEditModal}
            style={{ width: '50vw' }}
            onHide={() => setShowEditModal(false)}>
            <div>
              <div className="">
                <form onSubmit={handleSubmit(onSubmit)} className="grid p-fluid">
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.name })}></label>
                      <span className="p-float-label">
                        <Controller
                          name="name"
                          control={control}
                          rules={{ required: 'Name is required.' }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.name}
                              {...field}
                              placeholder="Name"
                              autoFocus
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('name')}
                    </div>
                  </div>

                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="code" className={classNames({ 'p-error': errors.code })}></label>
                      <span className="p-float-label">
                        <Controller
                          name="code"
                          control={control}
                          rules={{ required: 'Code is required.' }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.code}
                              {...field}
                              placeholder="Code"
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('code')}
                    </div>
                  </div>

                  <Button type="submit" label="Save" className="mt-2" />
                </form>
              </div>
            </div>
          </Dialog>
          <Dialog
            visible={deletePackingDialog}
            style={{ width: '450px' }}
            header="Confirm"
            modal
            onHide={hideDeletePackingDialog}>
            <div className="flex align-items-center justify-content-center">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
              {
                <span>
                  Are you sure you want to delete <b>{getValues('name')}</b>?{' '}
                </span>
              }
            </div>
            <footer>
              <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePackingDialog} />
              <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => deletePacking(getValues('id'))}
              />
            </footer>
          </Dialog>
          <Dialog
            header={getValues('name') + ' - ' + t('general.modal.title.Rooms')}
            visible={showEditRoomModal}
            style={{ width: '50vw' }}
            onHide={() => setShowEditRoomModal(false)}>
            <div>
              <div className="">
                <div className="col-12">
                  <div className="card">
                    <DataTable
                      ref={dt}
                      value={chapterdataList}
                      dataKey="id"
                      paginator
                      rows={100}
                      rowsPerPageOptions={[5, 10, 25, 100, 200]}
                      className="datatable-responsive"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} rooms"
                      globalFilter={globalFilter}
                      emptyMessage="No room found."
                      responsiveLayout="scroll"
                      selectionMode="single"
                      loading={loading}
                      selection={selectItem}>
                      <Column
                        field="name"
                        header={t('Room')}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                      <Column
                        field="partsCount"
                        header={t('# of Parts')}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}
                      />
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            header={getValues('ordername') + ' - ' + t('general.modal.title.AddPartBarcode')}
            visible={showAddPartBarcodeModal}
            style={{ width: '50vw' }}
            onHide={() => setShowAddPartBarcodeModal(false)}>
            <div>
              <div className="">
                <form onSubmit={handleSubmit(onSubmitPacking)} className="grid p-fluid">
                  <div className="col-12 md:col-12">
                    <div className="field">
                      <span className="p-float-label">
                        <Controller
                          name="partBarcode"
                          control={control}
                          rules={{ required: 'barcode is required.' }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.name}
                              {...field}
                              autoFocus
                              placeholder="Part Barcode"
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('barcode')}
                    </div>
                  </div>
                </form>
                <div className="col-12">
                  <div className="card">
                    <DataTable
                      ref={dt}
                      value={packingpartList}
                      dataKey="id"
                      paginator
                      rows={100}
                      rowsPerPageOptions={[5, 10, 25, 100, 200]}
                      className="datatable-responsive"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} part"
                      globalFilter={globalFilter}
                      emptyMessage="No part found."
                      responsiveLayout="scroll"
                      selectionMode="single"
                      loading={loading}
                      selection={selectItem}>
                      <Column
                        className="col-md-10"
                        field="barcode"
                        header={t('Barcode')}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                      <Column
                        className="col-md-2"
                        field="action"
                        body={packingActionPartDeleteTemplate}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            header={getValues('ordername') + ' - ' + t('general.modal.title.packing')}
            visible={showPackingModal}
            style={{ width: '50vw' }}
            onHide={() => setShowPackingModal(false)}>
            <div>
              <div className="">
                <div className="col-12">
                  <div className="card">
                    <DataTable
                      ref={dt}
                      value={packingdataList}
                      dataKey="id"
                      paginator
                      rows={100}
                      rowsPerPageOptions={[5, 10, 25, 100, 200]}
                      className="datatable-responsive"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pack"
                      globalFilter={globalFilter}
                      emptyMessage="No barcode found."
                      responsiveLayout="scroll"
                      selectionMode="single"
                      loading={loading}
                      selection={selectItem}>
                      <Column
                        field="barcode"
                        header={t('Barcode')}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                      <Column
                        className="col-md-10"
                        body={RoomnameFieldTemplate}
                        header={t('RoomName')}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                      <Column
                        field="action"
                        body={packingActionBodyTemplate}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                      <Column
                        field="action"
                        body={AddPartBarcodeActionBodyTemplate}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Button
                    type="submit"
                    label="Add Package"
                    className="mt-2 inline-block mr-2"
                    onClick={() => createPackage()}
                  />

                  <Button
                    type="submit"
                    label="Ready For Palletizing"
                    onClick={() => NextStatus(getValues('orderid'))}
                    className="mt-2 inline-block"
                  />
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            header={getValues('ordername') + ' - ' + t('general.modal.title.AddAdditionalPacking')}
            visible={showAdditionalPackingModal}
            style={{ width: '50vw' }}
            onHide={() => setShowAdditionalPackingModal(false)}>
            <div>
              <div className="">
                <form onSubmit={handleSubmit(onSubmitAdditionalPacking)} className="grid p-fluid">
                  <div className="col-12 md:col-12">
                    <div className="field">
                      <span className="p-float-label">
                        <Controller
                          name="name"
                          control={control}
                          rules={{ required: 'Name is required.' }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.name}
                              {...field}
                              autoFocus
                              placeholder="Pack Content"
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                              ref={packcontentref}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('name')}
                    </div>
                  </div>

                  <div className="col-9 md:col-9">
                    <div className="field"></div>
                  </div>
                  <div className="col-3 md:col-3">
                    <div className="field">
                      <Button type="submit" label="Add" className="mt-2" />
                    </div>
                  </div>
                </form>
                <div className="col-12">
                  <div className="card">
                    <DataTable
                      ref={dt}
                      value={additionalPartList}
                      dataKey="id"
                      paginator
                      rows={100}
                      rowsPerPageOptions={[5, 10, 25, 100, 200]}
                      className="datatable-responsive"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} part"
                      globalFilter={globalFilter}
                      emptyMessage="No part found."
                      responsiveLayout="scroll"
                      selectionMode="single"
                      loading={loading}
                      selection={selectItem}>
                      <Column
                        className="col-md-10"
                        body={BarcodeFieldTemplate}
                        header={t('PackContent')}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                      <Column
                        field="print"
                        header={t('partlist.table.print')}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}
                        body={printBodyTemplate}
                      />

                      <Column
                        className="col-md-2"
                        field="action"
                        body={additionalPackingActionBodyTemplate}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    </DataTable>
                    <Button
                      type="submit"
                      label="Ready For Palletizing"
                      onClick={() => NextStatus(getValues('orderid'))}
                      className="mt-2 inline-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            header={getValues('ordername') + ' - ' + t('general.modal.title.Barcode')}
            visible={showBarcodeModal}
            style={{}}
            onHide={() => {
              setShowBarcodeModal(false)
              resetField('partBarcode')
            }}>
            <div id="divcontents" className="">
              <div
                className="ifmcontentstoprint relative"
                style={{
                  width: '10cm',
                  height: '8cm',
                  maxWidth: '10cm !important',
                  maxHeight: '8cm !important',
                  overflow: 'hidden',
                  pageBreakAfter: 'always',
                }}>
                <div className="grid p-fluid">
                  <div className="col-12 md:col-12">
                    <div className="field">
                      <div className="p-float-label">
                        <div className="mb-1">
                          <b>Order Name:</b> {getValues('ordername')}
                        </div>
                        {getValues('roomName') != null ? (
                          <div className="mb-1">
                            <b>Room: </b> {getValues('roomName')}
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="mb-1">
                          <b>Parts:</b>
                          {getValues('partName')
                            ?.split('|')
                            .map((x) => {
                              return <div>{x}</div>
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    margin: 'auto;',
                    zIndex: '9999',
                    width: '10cm',
                    textAlign: 'center',
                  }}>
                  <div>
                    <ReactBarcode value={getValues('barcode')} displayValue={true} renderer={'svg'} height={75} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 md:col-6"></div>
            <div className="col-6 md:col-6">
              <Button type="submit" className="p-button-primary mr-2 mb-2" onClick={() => Print()}>
                Print
              </Button>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(PreparePacks, comparisonFn)
