import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { InputText } from 'primereact/inputtext'
import { Image } from 'primereact/image'
import loginImage from '../../assets/images/login-onlight.png'
import logo from '../../assets/images/bienal-logo.png'
import { Button } from 'primereact/button'
import { useHistory, useLocation } from 'react-router-dom'
import './SignIn.scss'
import AuthContext from '../../context/AuthContext'
import Dealer from '../dealer/Dealer'
import { classNames } from 'primereact/utils'
import AuthService from '../../service/AuthService'
import { useTranslation } from 'react-i18next'

export const SignIn = () => {
  const [dealerDisplayModal, setDealerDisplayModal] = useState(false)
  const { signIn, setUserData } = useContext(AuthContext)
  const { t } = useTranslation()

  const defaultValues = {
    email: '',
    password: '',
  }
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues })

  const onSubmit = (data) => {
    SignIn(data)
    reset()
  }

  const SignIn = (request) => {
    AuthService.signIn(request).then((response) => {
      setUserData({ ...response.data, roles: response.data.roleList })
      signIn(response.data.accessToken)
      history.replace(from)
    })
  }

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error ">{errors[name].message}</small>
  }

  const forgetPassword = () => {
    history.push({
      pathname: `/forgetPassword`,
    })
  }

  let history = useHistory()
  let location = useLocation()

  let { from } = location.state || { from: { pathname: '/' } }

  return (
    <div>
      <div className="login-body">
        <div className="login-image">
          <Image src={loginImage}></Image>
        </div>
        <div className="login-panel p-fluid">
          <div className="flex flex-column">
            <div className="flex align-items-center mb-6 logo-container">
              <img className="login-appname" src={logo} alt={'icon'}></img>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-container">
                <div className="field text-left">
                  <label htmlFor="name" className={classNames({ 'p-error': errors.email })}>
                    {t('login.email')}
                  </label>
                  <Controller
                    id="email"
                    name="email"
                    control={control}
                    rules={{ required: t('general.required.field.msg') }}
                    render={({ field, fieldState }) => (
                      <span className="p-input-icon-left mb-3">
                        <i className="pi pi-envelope"></i>
                        <InputText
                          id={field.email}
                          {...field}
                          autoFocus
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      </span>
                    )}
                  />
                  {getFormErrorMessage('email')}
                </div>
                <div className="field text-left">
                  <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>
                    {t('login.pass')}
                  </label>
                  <Controller
                    id="password"
                    name="password"
                    control={control}
                    rules={{ required: t('general.required.field.msg') }}
                    render={({ field, fieldState }) => (
                      <span className="p-input-icon-left mb-3">
                        <i className="pi pi-key"></i>
                        <InputText
                          type="password"
                          id={field.password}
                          {...field}
                          autoFocus
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      </span>
                    )}
                  />
                  {getFormErrorMessage('password')}
                </div>
                <div className="button-container ">
                  <Button type="submit" label={t('login.loginButton')} className="mt-2" />
                </div>
              </div>
            </form>
            <button className="flex p-link mb-3 " onClick={forgetPassword}>
              {t('login.forgot.pass')}
            </button>
            <button className="flex p-link mb-3" onClick={() => setDealerDisplayModal(true)}>
              {t('login.sign.up')}
            </button>
          </div>
        </div>
      </div>
      <Dealer display={dealerDisplayModal} onHide={() => setDealerDisplayModal(false)}></Dealer>
    </div>
  )
}
