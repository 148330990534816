import http from '../ServiceConfig'

class CompanyService {
  getCompanies() {
    return http.get('/companies')
  }

  getCompany(id) {
    return http.get('/companies/' + id)
  }

  createCompany(request) {
    return http.post('/companies', request)
  }

  updateCompanyInfo(request) {
    return http.put('/companies/update-company', request)
  }

  updateCompanyAdminInfo(request) {
    return http.put('/companies/update-company-admin', request)
  }
}

export default new CompanyService()
