import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import OrderService from '../../../../service/OrderService'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { dateFormatter } from '../../../../helpers/convertor'

const UploadedDocumentsDialog = ({ visible, onHide, orderId }) => {
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)

  const mounted = useRef(false)
  const toast = useRef(null)
  const dt = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const getUploadedDocuments = useCallback(async () => {
    try {
      if (mounted.current === true) setLoading(true)
      const response = await OrderService.getUploadedDocuments(orderId)
      if (mounted.current === true) setFiles(response.data)
    } catch (err) {
      console.error(err)
      toast.current.show({
        severity: 'error',
        summary: t('general.toast.error.summary'),
        detail: err.response?.data?.message ?? t('general.toast.error.detail'),
        life: 3000,
      })
    } finally {
      if (mounted.current === true) setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  useEffect(() => {
    if (visible === true) getUploadedDocuments()
  }, [getUploadedDocuments, visible])

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={t('project.order.dialog.header.uploadedDocuments')}
        visible={visible}
        style={{ width: '70vw' }}
        onHide={onHide}>
        <div className="card">
          <DataTable
            ref={dt}
            value={files}
            dataKey="id"
            rows={5}
            className="files-table datatable-responsive"
            emptyMessage={t('project.order.table.documents.emptyMessage')}
            responsiveLayout="scroll"
            showGridlines={false}
            showHeaders={false}
            loading={loading}>
            <Column
              field="file"
              body={(rowData) => {
                const { fileName, fileLink, createDate } = rowData
                return (
                  <div style={{ margin: '5px 0' }}>
                    <a key={fileLink} style={{ display: 'block' }} href={fileLink}>
                      {fileName}
                    </a>
                    <i>{dateFormatter(createDate)}</i>
                  </div>
                )
              }}></Column>
          </DataTable>
        </div>
      </Dialog>
    </>
  )
}

export default UploadedDocumentsDialog
