import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { confirmPopup } from 'primereact/confirmpopup'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import LanguageService from '../service/LanguageService'
import BreadcrumbContext from '../components/breadcrumb/Breadcrumb'

const LanguageKeys = () => {
  const { id } = useParams()
  const [pages, setPages] = useState(null)
  const [dataList, setDataList] = useState(null)
  const [globalFilter, setGlobalFilter] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showEditModal, setShowEditModal] = useState(false)
  const dt = useRef(null)
  const toast = useRef(null)
  const { onChangeLastLabel } = useContext(BreadcrumbContext)
  useEffect(() => {
    getAllPages()
    getData()
  }, [])

  useEffect(() => {
    if (pages) {
      pages.map((item) => {
        if (item.id === id) {
          onChangeLastLabel(item.pageName)
        }
      })
    }
  }, [pages])

  const defaultValues = {
    id: null,
    key: '',
    tr: '',
    en: '',
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues: defaultValues })

  const selectItem = (id) => {}
  const addNew = async (data) => {
    let page = await pages.filter((item) => item.id == id)[0]
    if (data.key.startsWith(page.pageName)) {
      toast.current.show({
        severity: 'error',
        summary: '',
        detail: 'Please delete key pagename in the beginning : ' + page.pageName,
        life: 3000,
      })
      return
    }

    LanguageService.addKey(id, data)
      .then((response) => {
        setShowEditModal(false)
        getData()
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully added', life: 3000 })
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  const editItem = async (data) => {
    let page = await pages.filter((item) => item.id == id)[0]
    if (data.key.startsWith(page.pageName)) {
      toast.current.show({
        severity: 'error',
        summary: '',
        detail: 'Please delete key pagename in the beginning : ' + page.pageName,
        life: 3000,
      })
      return
    }

    LanguageService.updateKey(data.id, data)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Success Successfully deleted',
          life: 3000,
        })
        setShowEditModal(false)
        getData()
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  const onSubmit = async (data) => {
    if (data.id) {
      editItem(data)
    } else {
      addNew(data)
    }
  }

  const deleteItem = (rowData) => {
    LanguageService.deleteKey(rowData.id).then((response) => {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Success Successfully deleted',
        life: 3000,
      })
      let arr = dataList.filter((item) => item.id !== rowData.id)
      setDataList(arr)
      getData()
    })
  }

  const getAllPages = async (status) => {
    LanguageService.getAllPages().then((response) => {
      setPages(response.data)
    })
  }

  const editModalOpen = (rowData) => {
    setShowEditModal(true)
    if (rowData) {
      setValue('key', rowData.key.substring(rowData.key.indexOf('.') + 1))
      setValue('tr', rowData.tr)
      setValue('en', rowData.en)
      setValue('id', rowData.id)
    } else {
      reset()
    }
  }
  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  const getData = async (status) => {
    LanguageService.getKeys(id).then((response) => {
      setLoading(false)
      setDataList(response.data)
    })
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Content Management – Key - Value Listeleme</h5>
      <div className="flex">
        <Button label="New" icon="pi pi-plus" onClick={() => editModalOpen(null)} className="p-button-success " />
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    </div>
  )

  const deleteConfirm = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteItem(event),
    })
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-secondary"
          onClick={() => editModalOpen(rowData)}
          aria-label="Edit"
        />
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-danger"
          onClick={() => deleteConfirm(rowData)}
          aria-label="Delete"
        />
      </>
    )
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={dataList}
            dataKey="id"
            paginator
            rows={100}
            rowsPerPageOptions={[5, 10, 25, 100, 200]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            globalFilter={globalFilter}
            emptyMessage="No products found."
            header={header}
            responsiveLayout="scroll"
            selectionMode="single"
            loading={loading}
            selection={selectItem}>
            <Column field="key" header="Key" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column field="tr" header="Tr Value" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column field="en" header="En Value" headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="action"
              header="Action"
              body={actionBodyTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          </DataTable>

          <Dialog
            header={'Edit or Save'}
            visible={showEditModal}
            style={{ width: '30vw' }}
            onHide={() => setShowEditModal(false)}>
            <div>
              <div className="">
                <form onSubmit={handleSubmit(onSubmit)} className="grid p-fluid">
                  <div className="col-12">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>
                        Key*
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="key"
                          control={control}
                          rules={{ required: 'Key is required.' }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.key}
                              {...field}
                              autoFocus
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('key')}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>
                        Tr*
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="tr"
                          control={control}
                          rules={{ required: 'Tr is required.' }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.tr}
                              {...field}
                              autoFocus
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('tr')}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>
                        En*
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="en"
                          control={control}
                          rules={{ required: 'En is required.' }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.en}
                              {...field}
                              autoFocus
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('en')}
                    </div>
                  </div>
                  <Button type="submit" label="Save" className="mt-2" />
                </form>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(LanguageKeys, comparisonFn)
