import React, { useMemo } from 'react'
import { TabMenu } from 'primereact/tabmenu'
import { Badge } from 'primereact/badge'

// import BreadcrumbContext from '../../components/breadcrumb/Breadcrumb'
import Modules from './components/Modules'
import MainMenus from './components/MainMenus'
import Submenus from './components/Submenus'
import { useModuleMenuManagement } from './useModuleMenuManagement'

const ModuleMenuManagement = () => {
  const { languageKeys, currentTabIndex, setCurrentTabIndex, t } = useModuleMenuManagement()

  const tabMenuTemplate = (options) => {
    return (
      <div onClick={() => setCurrentTabIndex(options.index)} className="p-menuitem-link relative" role="presentation">
        <div className="p-menuitem-text cursor-pointer">{options.label}</div>
        {options.badge === true ? (
          <div style={{ position: 'absolute', right: '0px', top: '5px' }}>
            <Badge value={options.badgeValue} severity="danger"></Badge>
          </div>
        ) : null}
      </div>
    )
  }

  const buildItems = useMemo(() => {
    return [
      {
        index: 0,
        label: t(languageKeys.tabModule),
        key: 'MODULE',
        template: tabMenuTemplate,
        render: () => <Modules />,
      },
      {
        index: 1,
        label: t(languageKeys.tabMainMenu),
        key: 'MAIN_MENU',
        template: tabMenuTemplate,
        render: () => <MainMenus />,
      },
      {
        index: 2,
        label: t(languageKeys.tabSubmenu),
        key: 'SUBMENU',
        template: tabMenuTemplate,
        render: () => <Submenus />,
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageKeys, t])

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <TabMenu model={buildItems} activeIndex={currentTabIndex} onTabChange={(e) => setCurrentTabIndex(e.index)} />
          {buildItems[currentTabIndex].render()}
        </div>
      </div>
    </div>
  )
}

export default ModuleMenuManagement
