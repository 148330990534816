import http from '../ServiceConfig'

class SubmenuService {
  getSubmenus() {
    return http.get('/submenu')
  }

  createSubmenu(request) {
    return http.post('/submenu', request)
  }

  updateSubmenu(request) {
    return http.put('/submenu', request)
  }

  deleteSubmenu(request, submenuId) {
    return http.put('/submenu/' + submenuId, request)
  }
}

export default new SubmenuService()
