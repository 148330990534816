import i18n from 'i18next'
import backend from 'i18next-http-backend'
import {initReactI18next} from 'react-i18next'
import LanguageService from '../src/service/LanguageService'

const loadResources = async (locale: string) => {
    return await LanguageService.getLanguages(locale.toUpperCase())
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.log(error)
        })
}


const backendOptions = {
    loadPath: '{{lng}}|{{ns}}',
    request: (options: any, url: any, payload: any, callback: any) => {
        try {
            const [lng] = url.split('|');
            loadResources(lng).then((response) => {
                callback(null, {
                    data: response,
                    status: 200,
                });
            });
        } catch (e) {
            console.error(e);
            callback(null, {
                status: 500,
            });
        }
    },
};

i18n
    .use(backend)
    .use(initReactI18next)
    .init({
        backend: backendOptions,
        debug: true,
        fallbackLng: ['en', 'tr'],
        keySeparator: false,
        react: {
            wait: false,
            useSuspense: true,
            bindStore: false,
            bindI18n: 'languageChanged',
        },
        interpolation: {
            escapeValue: false,
        },

    });

export default i18n;
