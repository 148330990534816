import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { useTranslation } from '../../../hooks/useTranslation'
import MainMenuService from '../../../service/MainMenuService'
import NewMainMenuDialog from './NewMainMenuDialog'
import EditMainMenuDialog from './EditMainMenuDialog'

const MainMenus = () => {
  const languageKeys = useMemo(
    () => ({
      toastSuccessSummary: 'general.toast.success.summary',
      toastErrorSummary: 'general.toast.error.summary',
      mainMenusTableTitle: 'moduleMenuManagement.mainMenu.table.title',
      newMainMenuButton: 'moduleMenuManagement.button.newMainMenu',
      search: 'general.search',
      noRecordsMsg: 'general.table.noRecords',
      tableMainMenuNameEN: 'moduleMenuManagement.mainMenu.table.mainMenuNameEN',
      tableMainMenuNameTR: 'moduleMenuManagement.mainMenu.table.mainMenuNameTR',
      tableKey: 'moduleMenuManagement.mainMenu.table.key',
      toastErrorDetailForTranslations: 'moduleMenuManagement.toast.error.detailForTranslationsMainMenu',
    }),
    []
  )

  const [loading, setLoading] = useState(false)
  const [mainMenus, setMainMenus] = useState([])
  const [mainMenusFilter, setMainMenusFilter] = useState('')
  const [isNewMainMenuDialogVisible, setIsNewMainMenuDialogVisible] = useState(false)
  const [isEditMainMenuDialogVisible, setIsEditMainMenuDialogVisible] = useState(false)
  const [selectedMainMenu, setSelectedMainMenu] = useState(null)

  const mounted = useRef(false)
  const toast = useRef(null)
  const dt = useRef(null)

  const { t, getAllTranslationsOfGivenList } = useTranslation(Object.values(languageKeys))

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const convertMainMenusToDataTable = useCallback(
    async (mainMenuList) => {
      if (mainMenuList.length === 0) return []

      try {
        const keyList = mainMenuList.map((mainMenu) => 'mainMenus.' + mainMenu.key)
        const translations = await getAllTranslationsOfGivenList(keyList)
        const converted = mainMenuList.map((mainMenu) => {
          return {
            ...mainMenu,
            nameEN: translations?.en['mainMenus.' + mainMenu.key],
            nameTR: translations?.tr['mainMenus.' + mainMenu.key],
          }
        })

        return converted
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: t(languageKeys.toastErrorDetailForTranslations),
          life: 3000,
        })

        return []
      }
    },
    [getAllTranslationsOfGivenList, t, languageKeys]
  )

  const getMainMenus = useCallback(async () => {
    try {
      if (mounted.current === true) setLoading(true)
      const response = await MainMenuService.getMainMenus()
      const converted = await convertMainMenusToDataTable(response.data)
      if (mounted.current === true) setMainMenus(converted)
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: t(languageKeys.toastErrorSummary),
        detail: error.response.data.message,
        life: 3000,
      })
    } finally {
      if (mounted.current === true) setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageKeys])

  useEffect(() => {
    getMainMenus()
  }, [getMainMenus])

  const onNewMainMenuClick = () => {
    setIsNewMainMenuDialogVisible(true)
  }

  const onMainMenuRowClick = (row) => {
    setSelectedMainMenu(row.data)
    setIsEditMainMenuDialogVisible(true)
  }

  const onHideNewMainMenuDialog = () => {
    setIsNewMainMenuDialogVisible(false)
  }

  const onHideEditMainMenuDialog = () => {
    setSelectedMainMenu(null)
    setIsEditMainMenuDialogVisible(false)
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">
        {t(languageKeys.mainMenusTableTitle, <ProgressSpinner style={{ width: '40px', height: '40px' }} />)}
      </h5>
      <div className="flex">
        <Button
          label={t(languageKeys.newMainMenuButton)}
          icon="pi pi-plus"
          onClick={onNewMainMenuClick}
          className="p-button-success "
        />
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setMainMenusFilter(e.target.value)}
            placeholder={t(languageKeys.search)}
          />
        </span>
      </div>
    </div>
  )

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <Toast ref={toast} />
        <DataTable
          ref={dt}
          value={mainMenus}
          dataKey="id"
          paginator
          rows={100}
          rowsPerPageOptions={[5, 10, 25, 100, 200]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          globalFilter={mainMenusFilter}
          emptyMessage={t(languageKeys.noRecordsMsg)}
          header={header}
          responsiveLayout="scroll"
          selectionMode="single"
          loading={loading}
          // selection={selectItem}
          onRowClick={onMainMenuRowClick}
          // sortField="lastActivityTime"
          // sortOrder={SortOrder.DESCENDING}
        >
          <Column
            field="nameEN"
            header={t(languageKeys.tableMainMenuNameEN)}
            headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
          <Column
            field="nameTR"
            header={t(languageKeys.tableMainMenuNameTR)}
            headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
          <Column
            field="key"
            header={t(languageKeys.tableKey)}
            headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
        </DataTable>
      </div>
      <NewMainMenuDialog
        visible={isNewMainMenuDialogVisible}
        onHide={onHideNewMainMenuDialog}
        fetchMainMenus={getMainMenus}
      />
      <EditMainMenuDialog
        visible={isEditMainMenuDialogVisible}
        onHide={onHideEditMainMenuDialog}
        fetchMainMenus={getMainMenus}
        selectedMainMenu={selectedMainMenu}
      />
    </div>
  )
}

export default MainMenus
