import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { MultiSelect } from 'primereact/multiselect'
import { InputSwitch } from 'primereact/inputswitch'
import { classNames } from 'primereact/utils'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, set, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import PartnerService from '../service/PartnerService'
import { FileUpload } from 'primereact/fileupload'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { encodeBase64 } from '../helpers/convertor'
import { Tag } from 'primereact/tag'

const Partners = () => {
  const { id } = useParams()
  const [dataList, setDataList] = useState(null)
  const [globalFilter, setGlobalFilter] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showEditModal, setShowEditModal] = useState(false)
  const [partnerType, setPartnerType] = useState('')
  const [parentPartner, setParentPartner] = useState('')
  const [parentPartners, setParentPartners] = useState([])
  const [crmUser, setCrmUser] = useState(null)
  const [productTypeMultiplier, setProductTypeMultiplier] = useState([])
  const [crmUsers, setCrmUsers] = useState([])
  const [partnerProductTypeMultipliers, setPartnerProductTypeMultipliers] = useState([])
  const dt = useRef(null)
  const toast = useRef(null)
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = useState(0)
  const [partnerFiles, setPartnerFiles] = useState([])
  const [uploadPartnerFiles, setUploadPartnerFiles] = useState([])
  const [deletePartnerFileDialogData, setDeletePartnerFileDialogData] = useState(null)
  const [deleteSavedPartnerFileDialog, setDeleteSavedPartnerFileDialog] = useState(false)
  useEffect(() => {
    getData()
    getCrmUsers()
    getProductTypes()
  }, [])

  const defaultValues = {
    id: null,
    name: '',
    contactName: '',
    parentPartner: '',
    parentPartnerCommission: 0,
    multiplier: 0,
    partnerType: '',
    status: 'PASSIVE',
    address: '',
    zipCode: '',
    email: '',
    state: '',
    phoneNumber: '',
    crmUser: '',
    partnerProductTypeMultipliers: [],
    quickBooksId: '',
  }

  const yesNo = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ]

  const partnerTypes = [
    { label: 'Showroom', value: 'SHOWROOM' },
    { label: 'Dealer', value: 'DEALER' },
    { label: 'Sub-dealer', value: 'SUB_DEALER' },
    { label: 'Contractor', value: 'CONTRACTOR' },
  ]

  const headerTemplate = (options) => {
    const { chooseButton, className } = options
    return <div className={className}>{chooseButton}</div>
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({ defaultValues: defaultValues })

  const selectItem = (id) => {}
  const addNew = async (data) => {
    PartnerService.addPartner(data)
      .then((response) => {
        setShowEditModal(false)
        getData()
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully added', life: 3000 })
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  const editItem = async (data) => {
    PartnerService.updatePartner(data.id, data)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Success Successfully updated',
          life: 3000,
        })
        setShowEditModal(false)
        getData()
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  const onSubmit = async (data) => {
    data.partnerProductTypeMultipliers = productTypeMultiplier
    data.partnerType = partnerType
    data.crmUser = { id: crmUser }
    data.partnerFiles = await setDataUploadPartnerFiles()
    if (parentPartner) {
      data.parentPartner = { id: parentPartner }
    } else {
      data.parentPartner = null
    }

    if (data.id) {
      editItem(data)
    } else {
      addNew(data)
    }
  }

  const setDataUploadPartnerFiles = async () => {
    const promises = uploadPartnerFiles.map(async (file) => {
      const encodedFile = await encodeBase64(file)
      const rawEncodedFile = encodedFile.replace('data:' + file.type + ';base64,', '')
      return {
        data: rawEncodedFile,
        extension: file.name.split('.')[1],
        name: file.name,
      }
    })

    const partnerFilesObj = await Promise.all(promises)
    return partnerFilesObj
  }

  const editModalOpen = (rowData) => {
    setShowEditModal(true)
    if (rowData) {
      setValue('name', rowData.name)
      setValue('contactName', rowData.contactName)
      setValue('parentPartner', rowData?.parentPartner?.id)
      setValue('parentPartnerCommission', rowData?.parentPartnerCommission)
      setValue('multiplier', rowData.multiplier)
      setValue('partnerType', rowData.partnerType)
      setValue('status', rowData.status)
      setValue('address', rowData.address)
      setValue('zipCode', rowData.zipCode)
      setValue('taxNumber', rowData.taxNumber)
      setValue('salesTaxExempt', rowData.salesTaxExempt)
      setValue('email', rowData.email)
      setValue('state', rowData.state)
      setValue('phoneNumber', rowData.phoneNumber)
      setValue('crmUser', rowData.crmUser.id)
      setValue('quickBooksId', rowData.quickBooksId)
      setValue('partnerProductTypeMultipliers', rowData.partnerProductTypeMultipliers)
      setValue('en', rowData.en)
      setValue('en', rowData.en)
      setValue('id', rowData.id)
      setProductTypeMultiplier(rowData.partnerProductTypeMultipliers)
      setPartnerType(rowData.partnerType)
      setCrmUser(rowData.crmUser.id)
      setParentPartner(rowData?.parentPartner?.id)
      setPartnerFiles(rowData.partnerFiles)
      setUploadPartnerFiles([])
    } else {
      reset()
      setProductTypeMultiplier([])
      setPartnerType('')
      setCrmUser('')
      setParentPartner('')
      setActiveIndex(0)
      setPartnerFiles([])
      setUploadPartnerFiles([])
    }
  }
  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  const getData = (status) => {
    PartnerService.getPartners().then((response) => {
      setLoading(false)
      if (response) {
        setParentPartners(response.data.map((item) => ({ label: item.name, value: item.id })))
        setDataList(response.data)
        setPartnerFiles(response.data.partnerFiles)
      }
    })
  }

  const getCrmUsers = (status) => {
    PartnerService.getCrmUsers().then((response) => {
      if (response) {
        setCrmUsers(response.data.map((item) => ({ label: item.username, value: item.id })))
      }
    })
  }

  const getProductTypes = (status) => {
    PartnerService.getProductTypes().then((response) => {
      if (response) {
        setPartnerProductTypeMultipliers(
          response.data.map((item) => ({
            label: item.name,
            value: { productType: { name: item.name, id: item.id }, multiplier: 0 },
          }))
        )
      }
    })
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t('partners.title')}</h5>
      <div className="flex">
        <Button
          label={t('general.new')}
          icon="pi pi-plus"
          onClick={() => {
            editModalOpen(null)
          }}
          className="p-button-success "
        />
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
        </span>
      </div>
    </div>
  )

  const statusTemplate = (rowData) => {
    return (
      <>
        <span className={'customer-badge status-' + rowData.status}>{rowData.status}</span>
      </>
    )
  }

  const partnerTypeChange = (e) => {
    setPartnerType(e)
  }

  const parentPartnerChange = (e) => {
    setParentPartner(e)
    setValue('parentPartner', { id: e })
  }

  const crmUserChange = (e) => {
    setCrmUser(e)
  }

  const setValProductTypeMultiplier = (index, data) => {
    let arr = []
    arr = arr.concat(productTypeMultiplier)
    arr[index].multiplier = data.target.value
    setProductTypeMultiplier(arr)
  }

  const deleteProductMultiplier = (id) => {
    let newArr = productTypeMultiplier.filter((item) => item.id != id)
    setProductTypeMultiplier(newArr)
  }

  const partnerFileActionBodyTemplate = (rowData) => {
    return (
      <Button
        type="button"
        icon="pi pi-times"
        onClick={
          () => {
            setDeletePartnerFileDialogData(rowData)
            setDeleteSavedPartnerFileDialog(true)
          } /*onDeleteDesignFiles(rowData)*/
        }
        className="p-button-rounded p-button-danger p-button-outlined"
      />
    )
  }

  const hideDeleteSavedPartnerFileDialog = () => {
    setDeleteSavedPartnerFileDialog(false)
  }

  const onDeletePartnerFiles = (fileId) => {
    PartnerService.deletePartnerFile(fileId)
      .then((res) => {
        setPartnerFiles(partnerFiles.filter((item) => item.id !== fileId))
        const updatedDataList = dataList.map((item) => {
          item.partnerFiles = item.partnerFiles.filter((file) => file.id !== fileId)
          return item
        })
        setDataList(updatedDataList)
        // getData()
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Success Successfully deleted',
          life: 3000,
        })
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  const onTemplateSelect = (e) => {
    const files = [...uploadPartnerFiles, ...e.files]
    setUploadPartnerFiles(files)
  }

  const onTemplateClear = (callback) => {
    setUploadPartnerFiles([])
    if (callback !== null && typeof callback !== 'undefined') {
      callback()
    }
  }

  const onTemplateRemove = async (e) => {
    const updatedFiles = uploadPartnerFiles.filter((file) => file !== e.file)
    setUploadPartnerFiles(updatedFiles)
  }

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-download mt-3 p-5"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}></i>
      </div>
    )
  }

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img alt={''} role="presentation" src={file.objectURL} width={100} />
          <span className="flex flex-column text-left mx-5 word-break">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          style={{ width: '3rem' }}
          onClick={() => onTemplateClear(props.onRemove)}
        />
      </div>
    )
  }

  const setProductTypeMultiplierFunc = (ids) => {
    if (ids.length === 0) {
      setProductTypeMultiplier([])
      setValue('partnerProductTypeMultipliers', [])
      return
    }
    let newArr = productTypeMultiplier.filter((item) => ids.indexOf(item.productType.id) === -1)
    newArr = newArr.concat(
      partnerProductTypeMultipliers
        .filter((item) => ids.indexOf(item.value.productType.id) > -1)
        .filter((item) => !productTypeMultiplier.find((x) => x.id === item.value.productType.id))
        .map((item) => item.value)
    )
    setProductTypeMultiplier(newArr)
    setValue('partnerProductTypeMultipliers', newArr)
  }

  const getPartnerProductMultipliersTemplate = () => {
    if (!productTypeMultiplier) return

    return productTypeMultiplier.map((item, index) => {
      return (
        <div className="p-inputgroup" key={'pm' + index} style={{ marginTop: '20px' }}>
          <span className="p-inputgroup-addon">{item?.productType?.name}</span>
          <InputText
            type="number"
            required={true}
            value={item?.multiplier}
            min={0.01}
            max={0.99}
            onChange={(event) => setValProductTypeMultiplier(index, event)}
            step="0.01"
          />

          <Button
            type="button"
            icon="pi pi-times"
            onClick={() => deleteProductMultiplier(item?.id)}
            className="p-button-danger"
          />
        </div>
      )
    })
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={dataList}
            dataKey="id"
            paginator
            rows={100}
            rowsPerPageOptions={[5, 10, 25, 100, 200]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            globalFilter={globalFilter}
            emptyMessage="No products found."
            header={header}
            responsiveLayout="scroll"
            selectionMode="single"
            loading={loading}
            selection={selectItem}
            onRowClick={e=>{
              editModalOpen(e.data)
            }}>
            <Column
              field="name"
              header={t('partners.table.name')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="partnerType"
              header={t('partners.table.type')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="contactName"
              header={t('partners.table.contact.name')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="email"
              header={t('partners.table.email')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="phoneNumber"
              header={t('partners.table.phone')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="status"
              body={statusTemplate}
              header={t('partners.table.status')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          </DataTable>

          <Dialog
            header={getValues('id') ? t('general.modal.title.edit') : t('general.modal.title.new')}
            visible={showEditModal}
            style={{ width: '50vw' }}
            onHide={() => {
              setShowEditModal(false)
            }}>
            <div>
              <div className="">
                <form onSubmit={handleSubmit(onSubmit)} className="grid p-fluid">
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>
                        {t('partners.modal.name')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="name"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.name}
                              {...field}
                              autoFocus
                              placeholder={t('partners.modal.name.placeHolder')}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('name')}
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.partnerType })}>
                        {t('partners.modal.partnerType')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="partnerType"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              id={field.partnerType}
                              {...field}
                              onHide={() => partnerTypeChange(getValues('partnerType'))}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                              options={partnerTypes}
                              optionLabel="label"
                              placeholder={t('partners.modal.partnerType.placeholder')}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('partnerType')}
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.contactName })}>
                        {t('partners.modal.contactName')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="contactName"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.contactName}
                              {...field}
                              placeholder={t('partners.modal.contactName.placeholder')}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('contactName')}
                    </div>
                  </div>
                  {partnerType && partnerType === 'SUB_DEALER' && (
                    <div className="col-12 md:col-6">
                      <div className="field">
                        <label htmlFor="name" className={classNames({ 'p-error': errors.parentPartner })}>
                          {t('partners.modal.dealer')} *
                        </label>
                        <span className="p-float-label">
                          <Controller
                            name="parentPartner"
                            control={control}
                            rules={{ required: t('general.required.field.msg') }}
                            render={({ field, fieldState }) => (
                              <Dropdown
                                id={field.parentPartner}
                                {...field}
                                value={parentPartner}
                                onChange={(event) => parentPartnerChange(event.target.value)}
                                className={classNames({ 'p-invalid': fieldState.invalid })}
                                options={parentPartners}
                                optionLabel="label"
                                optionValue="value"
                                placeholder={t('partners.modal.dealer.placeholder')}
                              />
                            )}
                          />
                        </span>
                        {getFormErrorMessage('parentPartner')}
                      </div>

                      <div className="field">
                        <label htmlFor="name" className={classNames({ 'p-error': errors.parentPartnerCommission })}>
                          {t('partners.modal.dealer.comission')} *
                        </label>
                        <span className="p-float-label">
                          <Controller
                            name="parentPartnerCommission"
                            control={control}
                            rules={{ required: t('general.required.field.msg') }}
                            render={({ field, fieldState }) => (
                              <InputText
                                id={field.parentPartnerCommission}
                                {...field}
                                type="number"
                                min={0.01}
                                max={0.99}
                                step="0.01"
                                placeholder={t('partners.modal.dealer.comission.placeholder')}
                                className={classNames({ 'p-invalid': fieldState.invalid })}
                              />
                            )}
                          />
                        </span>
                        {getFormErrorMessage('parentPartnerCommission')}
                      </div>
                    </div>
                  )}
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.email })}>
                        {t('partners.modal.email')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: t('general.required.field.msg'),
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: t('general.email.pattern.msg'),
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.email}
                              {...field}
                              placeholder={t('partners.modal.email.placeholder')}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('email')}
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.multiplier })}>
                        {t('partners.modal.multiplier')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="multiplier"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.multiplier}
                              {...field}
                              type="number"
                              min={0.01}
                              max={0.99}
                              step="0.01"
                              placeholder={t('partners.modal.multiplier.placeholder')}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('multiplier')}
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.phoneNumber })}>
                        {t('partners.modal.phone')}*
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="phoneNumber"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.phoneNumber}
                              {...field}
                              placeholder={t('partners.modal.phone.placeholder')}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('phoneNumber')}
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.crmUser })}>
                        {t('partners.modal.crm.representation')}*
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="crmUser"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              id={field.crmUser}
                              {...field}
                              value={getValues('crmUser')}
                              onHide={() => crmUserChange(getValues('crmUser'))}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                              options={crmUsers}
                              optionLabel="label"
                              optionValue="value"
                              placeholder={t('partners.modal.crm.representation.placeholder')}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('crmUser')}
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.address })}>
                        {t('partners.modal.address')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="address"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.address}
                              {...field}
                              placeholder={t('partners.modal.address.placeholder')}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('address')}
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.zipCode })}>
                        {t('partners.modal.zipCode')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="zipCode"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.zipCode}
                              {...field}
                              placeholder={t('partners.modal.zipCode.placeholder')}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('zipCode')}
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.taxNumber })}>
                        {t('partners.modal.taxNumber')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="taxNumber"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.taxNumber}
                              {...field}
                              placeholder={t('partners.modal.taxNumber.placeholder')}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('taxNumber')}
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.salesTaxExempt })}>
                        {t('partners.modal.salexTaxExempt')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="salesTaxExempt"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              id={field.salesTaxExempt}
                              {...field}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                              options={yesNo}
                              optionLabel="label"
                              optionValue="value"
                              placeholder={t('partners.modal.salexTaxExempt.placeholder')}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('salesTaxExempt')}
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.quickBooksId })}>
                        {t('partners.modal.quickBooksId')} *
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="quickBooksId"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.quickBooksId}
                              {...field}
                              type="number"
                              min={0}
                              placeholder={t('partners.modal.quickBooksId.placeholder')}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('multiplier')}
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.partnerProductTypeMultipliers })}>
                        {t('partners.modal.products')}*
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="partnerProductTypeMultipliers"
                          control={control}
                          rules={{ required: t('general.required.field.msg') }}
                          render={({ field, fieldState }) => (
                            <MultiSelect
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                              options={partnerProductTypeMultipliers}
                              onChange={(e) => setProductTypeMultiplierFunc(e.value)}
                              value={productTypeMultiplier.map((item) => item.productType.id)}
                              optionLabel="label"
                              optionValue="value.productType.id"
                              placeholder={t('partners.modal.products.placeholder')}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('Products')}
                      {getPartnerProductMultipliersTemplate()}
                    </div>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.status })}>
                        {t('partners.modal.status')}
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name="status"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputSwitch
                              trueValue={'ACTIVE'}
                              falseValue={'PASSIVE'}
                              checked={getValues('status')}
                              onChange={(e) => setValue('status', e.value)}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('status')}
                    </div>
                  </div>
                  <Dialog
                    visible={deleteSavedPartnerFileDialog}
                    style={{ width: '450px' }}
                    header="Confirm"
                    modal
                    onHide={hideDeleteSavedPartnerFileDialog}>
                    <div className="flex align-items-center justify-content-center">
                      <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                      {
                        <span>
                          {t('general.modal.areyousure')}
                          <b>{getValues('FileName')}</b>?{' '}
                        </span>
                      }
                    </div>
                    <footer style={{ textAlign: 'right' }}>
                      <Button
                        label="No"
                        icon="pi pi-times"
                        className="p-button-text"
                        onClick={hideDeleteSavedPartnerFileDialog}
                      />
                      <Button
                        label="Yes"
                        icon="pi pi-check"
                        className="p-button-text"
                        onClick={() => {
                          setDeleteSavedPartnerFileDialog(false)
                          onDeletePartnerFiles(deletePartnerFileDialogData.id)
                        }}
                      />
                    </footer>
                  </Dialog>
                  <div className="col-12 md:col-12">
                    {
                      <Accordion
                        activeIndex={activeIndex}
                        onTabChange={(e) => {
                          setActiveIndex(e.index)
                          setUploadPartnerFiles([])
                        }}>
                        <AccordionTab header={t('general.modal.title.fileName')}>
                          <DataTable
                            ref={dt}
                            value={partnerFiles}
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25, 100, 200]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} parts"
                            emptyMessage="No file found."
                            responsiveLayout="scroll"
                            selectionMode="single"
                            loading={loading}>
                            <Column
                              field="fileName"
                              body={(partnerFiles) => (
                                <p>
                                  <a href={partnerFiles.link}>{partnerFiles.name}</a>
                                </p>
                              )}
                              header={t('project.order.table.fileName')}
                              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            <Column
                              field="action"
                              header={t('order.table.action')}
                              body={partnerFileActionBodyTemplate}
                              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                          </DataTable>
                        </AccordionTab>
                        <AccordionTab header={t('order.general.upload')}>
                          <FileUpload
                            accept={'.png, .jpeg, .jpg, .doc, .docx, .xlsx, .csv, .txt, .pdf'}
                            headerTemplate={headerTemplate}
                            multiple={true}
                            maxFileSize={50000000}
                            onSelect={onTemplateSelect}
                            onError={onTemplateClear}
                            onRemove={onTemplateRemove}
                            itemTemplate={itemTemplate}
                            emptyTemplate={emptyTemplate}
                          />
                        </AccordionTab>
                      </Accordion>
                    }
                  </div>{' '}
                  *
                  <Button type="submit" label="Save" className="mt-2" />
                </form>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(Partners, comparisonFn)
