/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useTranslation } from '../../hooks/useTranslation'
import { useForm } from 'react-hook-form'

import ConfigurationManagerService from '../../service/ConfigurationManagerService'

export const useConfigurationManager = () => {
  const languageKeys = useMemo(
    () => ({
      toastTitleSuccess: 'configurationManager.toast.title.success',
      toastTitleError: 'configurationManager.toast.title.error',
      toastTitleAddMsg: 'configurationManager.toast.message.success.add',
      toastTitleUpdateMsg: 'configurationManager.toast.message.success.update',
      toastTitleDeleteMsg: 'configurationManager.toast.message.success.delete',
      tableTitle: 'configurationManager.table.title',
      newButton: 'general.new',
      searchPlaceholder: 'general.search',
      activeStatus: 'configurationManager.status.active',
      passiveStatus: 'configurationManager.status.passive',
      yes: 'general.Yes',
      no: 'general.No',
      tableEmptyMessage: 'configurationManager.table.errorMessage',
      methodNameColumn: 'configurationManager.table.methodName',
      allowedRolesColumn: 'configurationManager.table.allowedRoles',
      controllerNameColumn: 'configurationManager.table.controllerName',
      checkLoginColumn: 'configurationManager.table.checkLogin',
      statusColumn: 'configurationManager.table.status',
      updateDateColumn: 'configurationManager.table.updateDate',
      actionColumn: 'configurationManager.table.action',
    }),
    []
  )

  const [serviceConfigurationList, setServiceConfigurationList] = useState([])
  const [allRoles, setAllRoles] = useState([])
  const [filter, setFilter] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingAddNewOrEditServiceConfigurationDialog, setLoadingAddNewOrEditServiceConfigurationDialog] =
    useState(false)
  const [isAddNewOrEditServiceConfigurationDialogVisible, setIsAddNewOrEditServiceConfigurationDialogVisible] =
    useState(false)
  const [
    isDeleteServiceConfigurationConfirmationDialogVisible,
    setIsDeleteServiceConfigurationConfirmationDialogVisible,
  ] = useState(false)

  const toast = useRef(null)
  const dt = useRef(null)
  const { t } = useTranslation(Object.values(languageKeys))
  const mountedRef = useRef(false)

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getServiceConfigurationList = useCallback(() => {
    if (mountedRef.current === true) setLoading(true)
    ConfigurationManagerService.getServiceConfigurationList()
      .then((response) => {
        if (response.data && response.data.length > 0 && mountedRef.current === true) {
          setServiceConfigurationList(response.data)
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        if (mountedRef.current === true) setLoading(false)
      })
  }, [setLoading, setServiceConfigurationList])

  const getAllRoles = useCallback(() => {
    ConfigurationManagerService.getAllRoles()
      .then((response) => {
        if (response.data && response.data.length > 0 && mountedRef.current === true) {
          setAllRoles(response.data)
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {})
  }, [setAllRoles])

  useEffect(() => {
    if (mountedRef.current === true) {
      getServiceConfigurationList()
      getAllRoles()
    }
  }, [getServiceConfigurationList, getAllRoles])

  const defaultValues = {
    id: null,
    methodName: '',
    allowedRoles: [],
    controllerName: '',
    isActive: false,
    checkLogin: false,
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({ defaultValues: defaultValues, shouldFocusError: false })

  const onAddOrEditServiceConfiguration = (rowData) => {
    reset(defaultValues)
    if (rowData !== null && rowData !== undefined) {
      setValue('id', rowData.id)
      setValue('methodName', rowData.methodName)
      setValue('allowedRoles', rowData.allowedRoles)
      setValue('controllerName', rowData.controllerName)
      setValue('isActive', rowData.isActive)
      setValue('checkLogin', rowData.checkLogin)
    }
    setIsAddNewOrEditServiceConfigurationDialogVisible(true)
  }

  const onHideAddNewOrEditServiceConfigurationDialog = () => {
    setIsAddNewOrEditServiceConfigurationDialogVisible(false)
  }
  const onHideDeleteServiceConfigurationConfirmationDialog = () => {
    setIsDeleteServiceConfigurationConfirmationDialogVisible(false)
  }

  const onSubmitServiceConfiguration = handleSubmit((data) => {
    setLoadingAddNewOrEditServiceConfigurationDialog(true)
    if (data.id === null || data.id === undefined) {
      ConfigurationManagerService.addServiceConfiguration(data)
        .then((response) => {
          if (response.data && mountedRef.current === true) {
            getServiceConfigurationList()
            reset(defaultValues)
            onHideAddNewOrEditServiceConfigurationDialog()
            toast.current.show({
              severity: 'success',
              summary: t(languageKeys.toastTitleSuccess),
              detail: t(languageKeys.toastTitleAddMsg),
              life: 3000,
            })
          }
        })
        .catch((error) => {
          toast.current.show({
            severity: 'error',
            summary: t(languageKeys.toastTitleError),
            detail: error.response.data.message,
            life: 3000,
          })
        })
        .finally(() => {
          setLoadingAddNewOrEditServiceConfigurationDialog(false)
        })
    } else {
      ConfigurationManagerService.updateServiceConfiguration(data)
        .then((response) => {
          if (response.data && mountedRef.current === true) {
            getServiceConfigurationList()
            reset(defaultValues)
            onHideAddNewOrEditServiceConfigurationDialog()
            toast.current.show({
              severity: 'success',
              summary: t(languageKeys.toastTitleSuccess),
              detail: t(languageKeys.toastTitleUpdateMsg),
              life: 3000,
            })
          }
        })
        .catch((error) => {
          toast.current.show({
            severity: 'error',
            summary: t(languageKeys.toastTitleError),
            detail: error.response.data.message,
            life: 3000,
          })
        })
        .finally(() => {
          setLoadingAddNewOrEditServiceConfigurationDialog(false)
        })
    }
  })

  const onDeleteServiceConfiguration = (rowData) => {
    setValue('id', rowData.id)
    setIsDeleteServiceConfigurationConfirmationDialogVisible(true)
  }

  const onConfirmDeleteServiceConfiguration = () => {
    ConfigurationManagerService.deleteServiceConfiguration(getValues('id'))
      .then((response) => {
        if (mountedRef.current === true) {
          getServiceConfigurationList()
          reset(defaultValues)
          onHideDeleteServiceConfigurationConfirmationDialog()
          toast.current.show({
            severity: 'success',
            summary: t(languageKeys.toastTitleSuccess),
            detail: t(languageKeys.toastTitleDeleteMsg),
            life: 3000,
          })
        }
      })
      .catch((error) => {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastTitleError),
          detail: error.response.data.message,
          life: 3000,
        })
      })
      .finally(() => {})
  }

  const dateFormatterForSorting = (date) => {
    let dt = new Date(date)
    return `${dt.getFullYear().toString().padStart(4, '0')}${(dt.getMonth() + 1).toString().padStart(2, '0')}${dt
      .getDate()
      .toString()
      .padStart(2, '0')}${dt.getHours().toString().padStart(2, '0')}${dt.getMinutes().toString().padStart(2, '0')}`
  }

  const dateSorter = (event) => {
    let sortedServiceConfigurationList = [...serviceConfigurationList]

    sortedServiceConfigurationList.sort((sc1, sc2) => {
      const date1 = dateFormatterForSorting(sc1[event.field])
      const date2 = dateFormatterForSorting(sc2[event.field])

      let result = null

      if (date1 == null && date2 != null) result = -1
      else if (date1 != null && date2 == null) result = 1
      else if (date1 == null && date2 == null) result = 0
      else if (typeof date1 === 'string' && typeof date2 === 'string')
        result = date1.localeCompare(date2, undefined, { numeric: true })
      else result = date1 < date2 ? -1 : date1 > date2 ? 1 : 0

      return event.order * result
    })

    return sortedServiceConfigurationList
  }

  const getStringOfRoleList = (roles, seperator) => {
    if (!roles || roles.length === 0) return ''

    return roles
      .map((role) => {
        const roleObject = allRoles?.find((r) => r.name === role)
        if (roleObject !== null && roleObject !== undefined) {
          return roleObject.displayName + seperator + ' '
        } else {
          return ''
        }
      })
      .join('')
  }

  return useMemo(
    () => ({
      t,
      dt,
      toast,
      serviceConfigurationList,
      filter,
      setFilter,
      loading,
      onAddOrEditServiceConfiguration,
      isAddNewOrEditServiceConfigurationDialogVisible,
      onHideAddNewOrEditServiceConfigurationDialog,
      control,
      errors,
      onSubmitServiceConfiguration,
      setValue,
      getValues,
      allRoles,
      loadingAddNewOrEditServiceConfigurationDialog,
      dateSorter,
      isDeleteServiceConfigurationConfirmationDialogVisible,
      onHideDeleteServiceConfigurationConfirmationDialog,
      onConfirmDeleteServiceConfiguration,
      onDeleteServiceConfiguration,
      getStringOfRoleList,
      languageKeys,
    }),
    [
      t,
      serviceConfigurationList,
      filter,
      loading,
      toast,
      dt,
      isAddNewOrEditServiceConfigurationDialogVisible,
      control,
      errors,
      allRoles,
      loadingAddNewOrEditServiceConfigurationDialog,
      isDeleteServiceConfigurationConfirmationDialogVisible,
      languageKeys,
    ]
  )
}
