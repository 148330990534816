export const Role = {
  SuperAdmin: 'ROLE_SUPER_ADMIN',
  Admin: 'ROLE_ADMIN',
  User: 'ROLE_USER',
  DesignManager: 'ROLE_DESIGN_MANAGER',
  Designer: 'ROLE_DESIGNER',
  CamManager: 'ROLE_CAM_MANAGER',
  CamDesigner: 'ROLE_CAM_DESIGNER',
  CrmRepresentative: 'ROLE_CRM_REPRESENTATIVE',
  CrmManager: 'ROLE_CRM_MANAGER',
  GeneralManager: 'ROLE_GENERAL_MANAGER',
  CountrySalesManager: 'ROLE_COUNTRY_SALES_MANAGER',
  RegionSalesManager: 'ROLE_REGION_SALES_MANAGER',
  ProductionPlanningManager: 'ROLE_PRODUCTION_PLANNING_MANAGER',
  ProductionManager: 'ROLE_PRODUCTION_MANAGER',
  FactoryProductionPlanningManager: 'ROLE_FACTORY_PRODUCTION_PLANNING_MANAGER',
  FactoryProductionManager: 'ROLE_FACTORY_PRODUCTION_MANAGER',
  LogisticsPlanningManager: 'ROLE_LOGISTICS_PLANNING_MANAGER',
  LogisticWorker: 'ROLE_LOGISTIC_WORKER',
}
