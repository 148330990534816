import http from '../ServiceConfig'

class PartnerService {
  getPartners(status) {
    return http.get('/partners')
  }

  addPartner(request) {
    return http.post('/partners', request)
  }

  updatePartner(partnerId, request) {
    return http.put('/partners/' + partnerId, request)
  }

  getCrmUsers() {
    return http.get('/partners/crm-users')
  }

  getProductTypes() {
    return http.get('/partners/product-types')
  }

  deletePartnerFile(fileId) {
    return http.delete('/partners/delete-file/' + fileId)
  }
}

export default new PartnerService()
