import { useParams } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../context/AuthContext'
import { Role } from '../../helpers/role'
import AdminProjectService from '../../service/AdminProjectService'
import { Dropdown } from 'primereact/dropdown'
import { OrderStatus, statusController } from '../../helpers/order-status'
import { Button } from 'primereact/button'

const AssignUser = ({ loading, setLoading, toast, orderStatus, assignedUser }) => {
  const { id } = useParams()
  const [assignee, setAssignee] = useState(null)
  const [assignableUsers, setAssignableUsers] = useState([])
  const { roleController, user } = useContext(AuthContext)
  const [authorizedDesignRoles] = useState([
    Role.Admin,
    Role.CamManager,
    Role.GeneralManager,
    Role.CountrySalesManager,
    Role.RegionSalesManager,
    Role.CrmManager,
  ])
  const [authorizedProductionRoles] = useState([Role.Admin, Role.ProductionPlanningManager, Role.ProductionManager])
  const [disabledStatues] = useState([OrderStatus.ProductionDesignPlanning, OrderStatus.WaitingForProductionPlanning])

  useEffect(() => {
    if (loading) getAssignableUsers()
  }, [loading])

  const onChangeAssignableUser = (val) => {
    setAssignee(val)
  }

  const getAssignableUsers = () => {
    AdminProjectService.getAssignableUsers(id).then((res) => {
      setAssignableUsers(res.data)
    })
  }

  const onSubmitAssignUser = () => {
    const req = {
      assignUser: { id: assignee.id },
      orderStatusReq: { orderStatus },
    }
    AdminProjectService.assignUser(id, req)
      .then((res) => {
        setLoading(true)
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully assigned', life: 3000 })
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }
  if (
    ((roleController(authorizedDesignRoles) && orderStatus === OrderStatus.ProductionDesignPlanning) ||
      (roleController(authorizedProductionRoles) && orderStatus === OrderStatus.WaitingForProductionPlanning)) &&
    user?.id !== assignedUser?.id
  ) {
    return (
      <div className="flex align-items-center justify-content-end">
        <Dropdown
          optionLabel="username"
          disabled={!statusController(disabledStatues, orderStatus)}
          value={assignee}
          options={assignableUsers}
          onChange={(e) => onChangeAssignableUser(e.value)}
          placeholder="Assignable Users"
          className="ml-2 mb-2"
        />
        <Button
          label="Assign"
          disabled={!statusController(disabledStatues, orderStatus)}
          onClick={onSubmitAssignUser}
          className="p-button-success ml-2 mb-2"
        />
      </div>
    )
  } else {
    return null
  }
}

export default AssignUser
