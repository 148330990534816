import React, { useState, useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { Controller } from 'react-hook-form'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import PalleteService from '../../../../service/PalleteService'
import { t } from 'i18next'

const ExcludeBoxesModal = ({
  getValues,
  handleSubmit,
  control,
  errors,
  resetField,
  show,
  onHide,
  palletes,
  setPalletes,
  setCurrentPalleteBoxList,
}) => {
  const [loading, setLoading] = useState(false)

  const toast = useRef(null)

  const header = (
    <div style={{ color: '#EF4444' }}>{t('palletes.boxes.excludeBoxes.title') + getValues('palleteName')}</div>
  )

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  const actionsBodyTemplate = (rowData) => (
    <Button
      type="button"
      icon="pi pi-trash"
      className="p-button-rounded p-button-warning"
      onClick={() => {
        setLoading(true)
        PalleteService.excludeBoxFromPallete({ packageBarcode: rowData.boxBarcode, palletId: getValues('palleteId') })
          .then((response) => {
            setPalletes([...palletes.filter((pallete) => pallete.id !== response.data.id), response.data])
            setCurrentPalleteBoxList(response.data)
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: t('palletes.box.exclude.successMsg'),
              life: 3000,
            })
          })
          .catch((err) => {
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: err.response?.data?.message,
              life: 3000,
            })
          })
          .finally(() => {
            setLoading(false)
          })
      }}
    />
  )

  const onSubmitExcludeBox = async (data) => {
    setLoading(true)
    PalleteService.excludeBoxFromPallete({ packageBarcode: data.boxBarcode, palletId: getValues('palleteId') })
      .then((response) => {
        setPalletes([...palletes.filter((pallete) => pallete.id !== response.data.id), response.data])
        setCurrentPalleteBoxList(response.data)
        resetField('boxBarcode')
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: data.boxBarcode === 'CLOSE' ? t('palletes.closed.successMsg') : t('palletes.box.exclude.successMsg'),
          life: 3000,
        })

        if (data.boxBarcode === 'CLOSE') {
          onHide()
        }
      })
      .catch((err) => {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: err.response?.data?.message,
          life: 3000,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onPalleteClose = async (_e) => {
    setLoading(true)
    PalleteService.addBoxToPallete({ packageBarcode: 'CLOSE', palletId: getValues('palleteId') })
      .then((response) => {
        setPalletes([...palletes.filter((pallete) => pallete.id !== response.data.id), response.data])
        setCurrentPalleteBoxList(response.data)
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: t('palletes.closed.successMsg'),
          life: 3000,
        })
        onHide()
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response?.data?.message, life: 3000 })
      })
      .finally(() => {
        resetField('boxBarcode')
        setLoading(false)
      })
  }

  return (
    <>
      <Toast ref={toast} />

      <Dialog header={header} visible={show} style={{ width: '70vw' }} onHide={onHide}>
        <div>
          <div className="">
            <form onSubmit={handleSubmit(onSubmitExcludeBox)} className="grid p-fluid">
              <div className="col-12">
                <div className="field">
                  <label htmlFor="boxBarcode" className={classNames({ 'p-error': errors.boxBarcode })}></label>
                  <span className="p-float-label">
                    <Controller
                      name="boxBarcode"
                      control={control}
                      rules={{ required: 'Box barcode is required.' }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.boxBarcode}
                          {...field}
                          placeholder={t('palletes.boxes.input.barcode')}
                          autoFocus
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('boxBarcode')}
                </div>
              </div>

              <div className="col-12">
                <div className="card">
                  <DataTable
                    value={getValues('palleteBoxList')}
                    dataKey="id"
                    paginator
                    rows={100}
                    rowsPerPageOptions={[5, 10, 25, 100, 200]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} boxes"
                    emptyMessage={t('palletes.boxes.exclude.table.emptyMsg')}
                    responsiveLayout="scroll"
                    selectionMode="single"
                    loading={loading}>
                    <Column
                      field="orderName"
                      header={t('palletes.boxes.table.orderName')}
                      headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    <Column
                      field="roomName"
                      header={t('palletes.boxes.table.roomName')}
                      headerStyle={{ width: '14%', minWidth: '10rem' }}
                    />
                    <Column
                      field="boxBarcode"
                      header={t('palletes.boxes.table.boxBarcode')}
                      headerStyle={{ width: '14%', minWidth: '10rem' }}
                    />
                    <Column
                      field="action"
                      header={t('palletes.button.actions')}
                      headerStyle={{ width: '14%', minWidth: '10rem' }}
                      body={actionsBodyTemplate}
                    />
                  </DataTable>
                </div>
              </div>
              <div className="col-12">
                <div className="flex align-items-center justify-content-end my-3">
                  <Button type="submit" style={{ display: 'none' }} />
                  <Button
                    type="button"
                    onClick={onPalleteClose}
                    className="p-button-primary"
                    style={{ fontWeight: 'bold', width: 'auto' }}>
                    {t('palletes.button.closePallete')}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default ExcludeBoxesModal
