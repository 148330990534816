import React from 'react'
import { useCompanyManagement } from './useCompanyManagement'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Column } from 'primereact/column'
import { ProgressSpinner } from 'primereact/progressspinner'
import NewCompanyDialog from './components/NewCompanyDialog'

const CompanyManagement = () => {
  const {
    loading,
    companies,
    companiesFilter,
    setCompaniesFilter,
    dt,
    t,
    toast,
    onNewCompanyClick,
    onCompanyRowClick,
    languageKeys,
    isNewCompanyDialogVisible,
    onHideNewCompanyDialog,
    errors,
    onNewCompanySubmit,
    control,
    savingNewCompany,
  } = useCompanyManagement()

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">
        {t(languageKeys.companiesTableTitle, <ProgressSpinner style={{ width: '40px', height: '40px' }} />)}
      </h5>
      <div className="flex">
        <Button
          label={t(languageKeys.newCompanyButton)}
          icon="pi pi-plus"
          onClick={onNewCompanyClick}
          className="p-button-success "
        />
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setCompaniesFilter(e.target.value)}
            placeholder={t(languageKeys.search)}
          />
        </span>
      </div>
    </div>
  )

  const statusCellTemplate = (rowData) => (
    <>
      <span className={'customer-badge status-' + (rowData.status ? 'ACTIVE' : 'PASSIVE')}>
        {rowData.status ? t(languageKeys.activeStatus) : t(languageKeys.passiveStatus)}
      </span>
    </>
  )

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={companies}
            dataKey="id"
            paginator
            rows={100}
            rowsPerPageOptions={[5, 10, 25, 100, 200]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
            globalFilter={companiesFilter}
            emptyMessage={t(languageKeys.noRecordsMsg)}
            header={header}
            responsiveLayout="scroll"
            selectionMode="single"
            loading={loading}
            // selection={selectItem}
            onRowClick={onCompanyRowClick}
            // sortField="lastActivityTime"
            // sortOrder={SortOrder.DESCENDING}
          >
            <Column
              field="name"
              header={t(languageKeys.tableCompanyName)}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="state"
              header={t(languageKeys.tableCompanyState)}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="companyPhone"
              header={t(languageKeys.tableCompanyPhone)}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="companyEmail"
              header={t(languageKeys.tableCompanyEmail)}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="status"
              body={statusCellTemplate}
              header={t(languageKeys.tableStatus)}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          </DataTable>
        </div>
      </div>

      <NewCompanyDialog
        visible={isNewCompanyDialogVisible}
        onHide={onHideNewCompanyDialog}
        errors={errors}
        onSubmit={onNewCompanySubmit}
        control={control}
        saving={savingNewCompany}
      />
    </div>
  )
}

export default CompanyManagement
