import React, { useContext, useEffect, useRef, useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import AuthContext from './context/AuthContext'
import { SelectButton } from 'primereact/selectbutton'
import i18n from 'i18next'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Toast } from 'primereact/toast'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Controller, useForm } from 'react-hook-form'
import { Button } from 'primereact/button'
import AuthService from './service/AuthService'
import { t } from 'i18next'

export const AppTopbar = (props) => {
  const { signOut, isLoggedIn, setUserData: setAuthContextUser } = useContext(AuthContext)

  const [language, setLanguage] = useState('EN')
  const [showPassModal, setShowPassModal] = useState(false)
  const [showProfileModal, setShowProfileModal] = useState(false)
  const [username, setUsername] = useState([])
  const [userData, setUserData] = useState({})
  const [isReset, setIsReset] = useState(false)

  const mounted = useRef(false)
  const toast = useRef(null)
  const op = useRef(null)
  const pro = useRef(null)
  const history = useHistory()

  const options = ['TR', 'EN']

  const defaultValues = {
    existingPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const getProfile = useCallback(async () => {
    try {
      if (isLoggedIn) {
        const response = await AuthService.getProfile()
        if (mounted.current === true) {
          setUserData(response.data)
          if (language !== response.data.preferredLanguage) {
            setLanguage(response.data.preferredLanguage)
          }
        }
      }
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: t('general.toast.error.summary'),
        detail: err.response.data.message,
        life: 3000,
      })
    } finally {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  useEffect(() => {
    getProfile()
  }, [getProfile])

  const setPreferredLanguage = useCallback(
    async (lng) => {
      try {
        const result = await AuthService.setPreferredLanguage(lng)
        setAuthContextUser(result.data)
      } catch (err) {
        toast.current.show({
          severity: 'error',
          summary: t('general.toast.error.summary'),
          detail: err.response.data.message,
          life: 3000,
        })
      } finally {
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm({ defaultValues: defaultValues })

  const changeLanguage = useCallback((lng) => {
    i18n.changeLanguage(lng.toLowerCase())
  }, [])

  useEffect(() => {
    language !== null && changeLanguage(language)
  }, [language, changeLanguage])

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  const onSubmit = async (data) => {
    if (data.newPassword === data.existingPassword) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: t('change_password.error_same_password') ?? 'error: Your Password is not changed',
        life: 5000,
      })
    } else {
      AuthService.changePassword(data).then((response) => {
        if (response.status === 200) {
          if (response.data.status === true) {
            setIsReset(true)
            const successMessage = t(response.data.response.key) ?? 'Success : Your Password is changed'
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: successMessage,
              life: 5000,
            })
            const timer = setTimeout(() => {
              signOut()
            }, 5000)
            return () => clearTimeout(timer)
          } else {
            setIsReset(false)
            const errorMessage = t(response.data.response.key) ?? 'error: Your Password is not changed'
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: errorMessage,
              life: 5000,
            })
          }
        } else {
          setIsReset(false)
          const errorMessage = 'error: Your Password is not changed'
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: errorMessage,
            life: 5000,
          })
        }
      })
    }
  }

  const resetPasswordSuccess = () => {
    return (
      <small
        className="text-left"
        style={{ fontSize: '13px', color: 'green', marginTop: '10px', display: 'inline-block' }}>
        {t('reset_password.SuccessChangePasswordMessage')}
      </small>
    )
  }

  const passModalOpen = (rowData) => {
    setShowPassModal(true)
    reset()
  }

  // useEffect(() => {
  //   if (isLoggedIn)
  //     AuthService.getProfile().then((response) => {
  //       setUserData(response.data)
  //     })
  // }, [])

  const getprofile = (e) => {
    //const userData = JSON.parse(sessionStorage.getItem('user'))
    setUsername(userData.username)
    pro.current.toggle(e)
  }

  return (
    <div className="layout-topbar">
      <Toast ref={toast} />
      <Link to="/" className="layout-topbar-logo">
        {/*<img src={logo} alt="logo"/>*/}
        <span>BIENAL</span>
      </Link>

      <button
        type="button"
        className="p-link  layout-menu-button layout-topbar-button"
        onClick={props.onToggleMenuClick}>
        <i className="pi pi-bars" />
      </button>

      <button
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={props.onMobileTopbarMenuClick}>
        <i className="pi pi-ellipsis-v" />
      </button>

      <ul
        className={classNames('layout-topbar-menu lg:flex origin-top', {
          'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive,
        })}>
        <div>
          <div className="flex">
            <div>
              <span id="name" style={{ fontSize: '15px', paddingRight: '3px', paddingBottom: '3px' }}>
                {userData.username}
              </span>
            </div>
            <span style={{ fontSize: '15px', paddingRight: '3px', paddingBottom: '3px' }}>/</span>
            <div>
              <span id="company" style={{ fontSize: '15px', paddingLeft: '3px', paddingBottom: '3px' }}>
                {userData.company?.name}
              </span>
            </div>
          </div>

          <div>
            {userData.roles?.map((role, index) => (
              <span key={role.id} id={`role-${role.id}`}>
                {role.displayName}
                {userData.roles?.length - 1 === index ? '' : ', '}
              </span>
            ))}
          </div>
        </div>

        <li>
          <button
            className="p-link layout-topbar-button"
            style={{ fontSize: '20px' }}
            onClick={(e) => {
              getprofile(e)
            }}>
            <i className="pi pi-user" />
            <span>Profile</span>
          </button>
        </li>
        <li>
          <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
            <i className="pi pi-calendar" />
            <span>Events</span>
          </button>
        </li>
        <li>
          <button
            className="p-link layout-topbar-button"
            style={{ fontSize: '20px' }}
            onClick={(e) => op.current.toggle(e)}>
            <i className="pi pi-globe" />
            <span>Language</span>
          </button>
        </li>
      </ul>

      <OverlayPanel ref={op} id="overlay_panel" className="overlaypanel-demo">
        <SelectButton
          value={language}
          options={options}
          onChange={(e) => {
            setLanguage(e.value)
            setPreferredLanguage(e.value)
          }}
        />
      </OverlayPanel>

      <OverlayPanel ref={pro} id="overlay_panel" className="overlaypanel-demo">
        <div>
          <span style={{ paddingLeft: '10px' }}>{username}</span>
        </div>

        <hr></hr>
        <div>
          <button
            className="p-link layout-topbar-button"
            style={{ fontSize: '15px', paddingTop: '3px', paddingBottom: '3px' }}
            onClick={() => {
              pro.current.hide()
              history.push({ pathname: '/my-suggestions' })
            }}>
            <i className="pi pi-plus" style={{ paddingRight: '6px' }} />
            <span>{t('topbarItems.suggestion')}</span>
          </button>
        </div>
        <div>
          <button
            className="p-link layout-topbar-button"
            style={{ fontSize: '15px', paddingTop: '3px', paddingBottom: '3px' }}
            onClick={() => passModalOpen(null)}>
            <i className="pi pi-key" style={{ paddingRight: '6px' }} />
            <span>Change Password</span>
          </button>
        </div>
        <div>
          <button
            className="p-link layout-topbar-button"
            style={{ fontSize: '15px', paddingTop: '3px', paddingBottom: '3px' }}
            onClick={signOut}>
            <i className="pi pi-sign-out" style={{ paddingRight: '6px' }} />
            <span>Sign Out</span>
          </button>
        </div>
      </OverlayPanel>

      <Dialog
        header="Change Password"
        visible={showPassModal}
        style={{ width: '50vw' }}
        onHide={() => setShowPassModal(false)}>
        <div>
          <div className="">
            <form onSubmit={handleSubmit(onSubmit)} className="grid p-fluid">
              <div className="col-12 md:col-12">
                <div className="field">
                  <label htmlFor="existingPassword" className={classNames({ 'p-error': errors.existingPassword })}>
                    Existing Password
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="existingPassword"
                      control={control}
                      rules={{ required: 'Existing Password is required.' }}
                      render={({ field, fieldState }) => (
                        <InputText
                          type="password"
                          id={field.existingPassword}
                          {...field}
                          placeholder="Existing Password"
                          autoFocus
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('name')}
                </div>
              </div>

              <div className="col-12 md:col-12">
                <div className="field">
                  <label htmlFor="newPassword" className={classNames({ 'p-error': errors.newPassword })}>
                    New Password
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="newPassword"
                      control={control}
                      rules={{ required: 'New Password is required.' }}
                      render={({ field, fieldState }) => (
                        <InputText
                          type="password"
                          id={field.newPassword}
                          {...field}
                          placeholder="New Password"
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('newPassword')}
                </div>
              </div>
              <div className="col-12 md:col-12">
                <div className="field">
                  <label htmlFor="confirmPassword" className={classNames({ 'p-error': errors.confirmPassword })}>
                    Confirm Password
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="confirmPassword"
                      control={control}
                      rules={{
                        required: 'Confirm Password is required.',
                        validate: {
                          matchesNewPassword: (value) =>
                            value === getValues('newPassword') || 'New Password fields are not the same.',
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          type="password"
                          id={field.confirmPassword}
                          {...field}
                          placeholder="Confirm Password"
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('confirmPassword')}
                </div>
              </div>

              <Button type="submit" label="Change Password" className="mt-2" />
            </form>
            {isReset && resetPasswordSuccess()}
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Profile Info"
        visible={showProfileModal}
        style={{ width: '18vw', textAlign: 'center' }}
        onHide={() => setShowProfileModal(false)}>
        <div className="card">
          <div></div>
          <h3> {getValues('prefix') + ' ' + getValues('username')}</h3>
          <p>
            {getValues('email')} <br /> {getValues('company')}
          </p>
          <p>{getValues('manager')}</p>
        </div>
      </Dialog>
    </div>
  )
}
