import React, { createContext, useEffect, useState, useMemo, useCallback } from 'react'
import { BreadCrumb } from 'primereact/breadcrumb'
import { useLocation } from 'react-router-dom'

const BreadcrumbContext = createContext({})

export const BreadcrumbProvider = (props) => {
  const location = useLocation()
  const [items, setItems] = useState([])
  const applicationsType = useMemo(() => ['new', 'in_progress', 'approval', 'converted', 'not_eligible', 'all'], [])

  useEffect(() => {
    let _items = location.pathname.split('/').map((item, i, { length }) => {
      return {
        label:
          location.pathname.includes('applications') && length === 4 && i === 3
            ? 'Application Detail'
            : item !== ''
            ? item
                .replaceAll('_', ' ')
                .replaceAll('-', ' ')
                .split(' ')
                .map((word) => word[0].toUpperCase() + word.substring(1))
                .join(' ')
            : '',
        url:
          i + 1 === length
            ? location.pathname
            : i === 0
            ? '/'
            : item === 'applications'
            ? '/applications/new'
            : applicationsType.includes(item)
            ? '/applications/' + item
            : '#',
      }
    })
    _items.shift()
    setItems([..._items])
  }, [location, applicationsType])

  const home = { icon: 'pi pi-home', url: '/' }

  const onChangeLastLabel = (label) => {
    if (label) {
      let _items = items
      if (_items.length !== 0) {
        _items.at(_items.length - 1).label = label
        setItems([..._items])
      }
    }
  }

  const onConvertFromTo = useCallback(
    (conversions) => {
      if (conversions.length > 0) {
        const _items = location.pathname.split('/').map((item, i, { length }) => {
          return {
            label: item,
            url:
              i === length - 1
                ? location.pathname
                : location.pathname
                    .split('/')
                    .splice(0, i + 1)
                    .join('/'),
          }
        })

        if (_items.length > 0) {
          _items.shift()
        }

        conversions.forEach(({ from, to }) => {
          if (from !== null && to !== null) {
            if (_items.length > 0) {
              const index = _items.findIndex((item) => {
                return item.label === from
              })
              if (index !== -1) {
                _items[index].label = to
              }
            }
          }
        })

        const upperCasedList = _items.map((item) => {
          const newLabel = item.label
            .replaceAll('_', ' ')
            .replaceAll('-', ' ')
            .split(' ')
            .map((word) => word[0].toUpperCase() + word.substring(1))
            .join(' ')
          // const newLabel =
          //   item.label[0].toUpperCase() + item.label.substring(1).replaceAll('_', ' ').replaceAll('-', ' ')
          return {
            ...item,
            label: newLabel,
          }
        })

        setItems([...upperCasedList])
      }
    },
    [location.pathname]
  )

  const value = {
    onChangeLastLabel,
    onConvertFromTo,
  }

  return (
    <BreadcrumbContext.Provider value={value}>
      <div className="breadcrumb-card">
        <BreadCrumb model={items} home={home} />
      </div>
      {props.children}
    </BreadcrumbContext.Provider>
  )
}

export default BreadcrumbContext
