import { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import CompanyService from '../../service/CompanyService'
import { useTranslation } from '../../hooks/useTranslation'
import { useForm } from 'react-hook-form'

export const useCompanyManagement = () => {
  const languageKeys = useMemo(
    () => ({
      toastSuccessSummary: 'general.toast.success.summary',
      toastErrorSummary: 'general.toast.error.summary',
      newCompanyButton: 'companyManagement.button.newCompany',
      search: 'general.search',
      companiesTableTitle: 'companyManagement.table.title',
      noRecordsMsg: 'general.table.noRecords',
      tableCompanyName: 'companyManagement.table.companyName',
      tableCompanyState: 'companyManagement.table.companyState',
      tableCompanyPhone: 'companyManagement.table.companyPhone',
      tableCompanyEmail: 'companyManagement.table.companyEmail',
      tableStatus: 'companyManagement.table.status',
      activeStatus: 'companyManagement.status.active',
      passiveStatus: 'companyManagement.status.passive',
      toastCompanyCreatedMsg: 'companyManagement.toast.success.createdMsg',
    }),
    []
  )
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [companiesFilter, setCompaniesFilter] = useState('')
  const [isNewCompanyDialogVisible, setIsNewCompanyDialogVisible] = useState(false)
  const [savingNewCompany, setSavingNewCompany] = useState(false)

  const dt = useRef(null)
  const toast = useRef(null)
  const mounted = useRef(false)

  const { t } = useTranslation(Object.values(languageKeys))
  const history = useHistory()

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const getCompanies = useCallback(async () => {
    try {
      if (mounted.current === true) setLoading(true)
      const response = await CompanyService.getCompanies()
      if (mounted.current === true) setCompanies(response.data)
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: t(languageKeys.toastErrorSummary),
        detail: error.response.data.message,
        life: 3000,
      })
    } finally {
      if (mounted.current === true) setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getCompanies()
  }, [getCompanies])

  const addNewCompany = useCallback(
    async (data) => {
      try {
        setSavingNewCompany(true)
        await CompanyService.createCompany(data)
        setIsNewCompanyDialogVisible(false)
        toast.current.show({
          severity: 'success',
          summary: t(languageKeys.toastSuccessSummary),
          detail: t(languageKeys.toastCompanyCreatedMsg),
          life: 3000,
        })

        getCompanies()
      } catch (err) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: err.response.data.message,
          life: 3000,
        })
      } finally {
        setSavingNewCompany(false)
      }
    },
    [getCompanies, t, languageKeys]
  )

  const defaultValues = {
    id: null,
    name: '',
    companyAddress: '',
    state: '',
    city: '',
    zipcode: '',
    shortCode: '',
    companyPhone: '',
    companyEmail: '',
    companyTaxnbr: '',
    qbId: '',
    adminName: '',
    adminTitle: '',
    adminEmail: '',
    adminPhone: '',
    status: true,
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues, shouldFocusError: false })

  const onNewCompanyClick = () => {
    reset(defaultValues)
    setIsNewCompanyDialogVisible(true)
  }

  const onCompanyRowClick = (e) => {
    history.push({
      pathname: '/system-management/company-management/' + e.data.id,
    })
  }

  const onHideNewCompanyDialog = () => {
    setIsNewCompanyDialogVisible(false)
  }

  const onNewCompanySubmit = handleSubmit(addNewCompany)

  return useMemo(
    () => ({
      loading,
      companies,
      companiesFilter,
      setCompaniesFilter,
      dt,
      t,
      toast,
      onNewCompanyClick,
      onCompanyRowClick,
      languageKeys,
      isNewCompanyDialogVisible,
      onHideNewCompanyDialog,
      errors,
      onNewCompanySubmit,
      control,
      savingNewCompany,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      loading,
      companies,
      companiesFilter,
      dt,
      t,
      toast,
      languageKeys,
      isNewCompanyDialogVisible,
      errors,
      control,
      savingNewCompany,
    ]
  )
}
