import { t } from 'i18next'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import PartnerService from '../service/PartnerService'
import PartnerUserService from '../service/PartnerUserService'

const PartnerUsers = () => {
  const {id} = useParams()
  const [dataList, setDataList] = useState(null)
  const [globalFilter, setGlobalFilter] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showEditModal, setShowEditModal] = useState(false)
  const dt = useRef(null)
  const toast = useRef(null)
  const [role, setRole] = useState([])
  const [salesRepresentative, setSalesRepresentative] = useState('ROLE_PARTNER_SALESPERSON')
  const [salesExecutive, setSalesExecutive] = useState('ROLE_PARTNER_EXECUTIVE')
  const [roleList, setRoleList] = useState([])
  const [managerList, setManagerList] = useState([])
  const [partnerList, setPartnerList] = useState([])
  const [partnerSelected, setSelectedPartner] = useState(null)

  useEffect(() => {
    getData();
    getRoleList();
    getPartnerList();
  }, [])


  const defaultValues = {
    id : null,
    prefix: '',
    username: '',
    email : '',
    phone : '',
    roles: [],
    partner: "",
    status: 'PASSIVE',
    managerId: null
  }


  const {
    control,
    formState: {errors},
    handleSubmit,
    reset,
    setValue,
    getValues
    } = useForm({defaultValues: defaultValues})


    const prefixList = [
        {label: 'MR', value: 'mr'},
        {label: 'MRS', value: 'MRS'},
        {label: 'MISS', value: 'miss'},
    ]

  const selectItem = (event) => {
    let selectPrtnr = partnerList.filter(item => item.value === event.target.value).map(item => ({id : item.value}))[0];
    setSelectedPartner(selectPrtnr);
    setValue("partner", selectPrtnr);
    getManagerUser(event.target.value);
  }
  const addNew = async (data) => {

    PartnerUserService.addPartnerUser(data).then((response) => {
        setShowEditModal(false);
        getData();
        toast.current.show({severity:'success', summary: 'Success', detail:'Successfully added', life: 3000});
    }).catch(err => {
        toast.current.show({severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000})
    })

  }

  const editItem = async (data) => {

    PartnerUserService.updatePartnerUser(data.id,data).then((response) => {
        toast.current.show({severity:'success', summary: 'Success', detail:'Success Successfully deleted', life: 3000});
        setShowEditModal(false);
        getData();
    }).catch(err => {
        toast.current.show({severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000})
    })

  }


    const onSubmit = async (data) => {
        if(data.id){
            editItem(data);
        }else{
            addNew(data);
        }
    }

  const editModalOpen = (rowData) => {
    setShowEditModal(true);
    if(rowData){
        setValue("prefix", rowData.prefix );
        setValue("username", rowData.username);
        setValue("phone", rowData.phone);
        setValue("email", rowData.email);
        setValue("roles", rowData.roles);
        setValue("partner", rowData.partner);
        setValue("status", rowData.status);
        setValue("managerId", rowData.managerId);
        setValue("id",rowData.id)
        setRole(rowData.roles);
        setSelectedPartner(rowData.partner);
    }else{
        reset();
        setRole([]);
        setSelectedPartner(null);
    }
  }
  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
   }

  const getData = () => {
    PartnerUserService.getPartnerUsers().then((response) => {
        setLoading(false)
        setDataList(response.data)
    })
  }

  const getRoleList = () => {
    PartnerUserService.getRoleList().then((response) => {
        setRoleList(response.data.map(item => ({label : item.name, value : item.id, displayName : item.displayName})))
    })
  }

  const getManagerUser = (partnerId) => {
    PartnerUserService.getManagerUser(partnerId).then((response) => {
        setManagerList(response.data.map(item => ({label : item.username, value : item.id})))
    })
  }

  const getPartnerList = () => {
    PartnerService.getPartners().then((response) => {
        setLoading(false)
        if(response){
            setPartnerList(response.data.map(item => ({name : item.name, value : item.id})))
        }
    })
  }

  const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("partnerUsers.title")}</h5>
            <div className="flex">
                <Button label={t("general.new")} icon="pi pi-plus" onClick={() => editModalOpen(null)} className="p-button-success "/>
                <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
        <i className="pi pi-search"/>
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t("general.search")}/>
        </span>
            </div>
        </div>
    )

    const mergePrefixAndFullName = (rowData) => {
        return (
            <>
                <span>{rowData.prefix} {rowData.username}</span>
            </>
        )
    }

    const getRole = (rowData) => {
        return (
            <>
                <span>{rowData.roles ? roleList.find(r => rowData.roles[0].id === r.value )?.displayName  : ''}</span>
            </>
        )
    }

    const statusTemplate = (rowData) => {
        return (
            <>
                <span className={'customer-badge status-' + rowData.status}>{rowData.status}</span>
            </>
        )
    }

    const setRoleFunc = (rList) => {
        setRole(rList);
        setValue("roles",rList);
    }



  return (
    <div className="grid crud-demo">
    <div className="col-12">
        <div className="card">
            <Toast ref={toast}/>
                    <DataTable
                        ref={dt}
                        value={dataList}
                        dataKey="id"
                        paginator
                        rows={100}
                        rowsPerPageOptions={[5, 10, 25,100,200]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        globalFilter={globalFilter}
                        emptyMessage="No records found."
                        header={header}
                        responsiveLayout="scroll"
                        selectionMode="single"
                        loading={loading}
                        selection={selectItem}
                        onRowClick={e=>{
                            editModalOpen(e.data)
                          }}
                        >

                        <Column field="partner.name" header={t("partnerUsers.table.partnerName")} headerStyle={{width: '14%', minWidth: '10rem'}}></Column>
                        <Column field="fullName" body={mergePrefixAndFullName} header={t("partnerUsers.table.fullName")} headerStyle={{width: '14%', minWidth: '10rem'}} ></Column>
                        <Column field="role" body={getRole} header={t("partnerUsers.table.role")} headerStyle={{width: '14%', minWidth: '10rem'}} ></Column>
                        <Column field="email" header={t("partnerUsers.table.email")} headerStyle={{width: '14%', minWidth: '10rem'}} ></Column>
                        <Column field="phone" header={t("partnerUsers.table.phone")} headerStyle={{width: '14%', minWidth: '10rem'}} ></Column>
                        <Column field="status" body={statusTemplate} header={t("partnerUsers.table.status")} headerStyle={{width: '14%', minWidth: '10rem'}} ></Column>
                    </DataTable>

                    <Dialog header={getValues("id") ? t("partnerUsers.modal.title.edit") :  t("partnerUsers.modal.title.new")} visible={showEditModal} style={{ width: '30vw' }} onHide={() => setShowEditModal(false)}>
                        <div>
                            <div className="">
                                <form onSubmit={handleSubmit(onSubmit)} className="grid p-fluid">
                                    <div className="col-12">
                                        <div className="field">
                                            <label htmlFor="prefix" className={classNames({'p-error': errors.prefix})}>
                                                {t("partnerUsers.modal.field.prefix")} *
                                            </label>
                                            <span className="p-float-label">
                                            <Controller
                                                name="prefix"
                                                control={control}
                                                rules={{required: t("general.required.field.msg")}}
                                                render={({field, fieldState}) => (
                                                    <Dropdown
                                                            id={field.prefix}
                                                            {...field}
                                                            autoFocus
                                                            className={classNames({'p-invalid': fieldState.invalid})}
                                                            options={prefixList}
                                                            optionLabel="label"
                                                            placeholder={t("partnerUsers.modal.prefix.placeholder")}
                                                        />
                                                        )}
                                                    />
                                        </span>
                                            {getFormErrorMessage('prefix')}
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="field">
                                            <label htmlFor="name" className={classNames({'p-error': errors.name})}>
                                            {t("partnerUsers.modal.field.name")} *
                                            </label>
                                            <span className="p-float-label">
                                            <Controller
                                                name="username"
                                                control={control}
                                                rules={{required: t("general.required.field.msg")}}
                                                render={({field, fieldState}) => (
                                                    <InputText
                                                        id={field.username}
                                                        {...field}
                                                        autoFocus
                                                        placeholder={t("partnerUsers.modal.name.placeholder")}
                                                        className={classNames({'p-invalid': fieldState.invalid})}
                                                    />
                                                        )}
                                                    />
                                        </span>
                                            {getFormErrorMessage('name')}
                                        </div>
                                    </div>


                                    <div className="col-12">
                                        <div className="field">
                                            <label htmlFor="email" className={classNames({'p-error': errors.email})}>
                                            {t("partnerUsers.modal.field.email")} *
                                            </label>
                                            <span className="p-float-label">
                                            <Controller
                                                name="email"
                                                control={control}
                                                rules={
                                                    {
                                                        required: t("general.required.field.msg"),
                                                        pattern: {
                                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                    message: t("general.email.pattern.msg")}
                                                        }}
                                                render={({field, fieldState}) => (
                                                    <InputText
                                                        id={field.email}
                                                        {...field}
                                                        autoFocus
                                                        placeholder={t("partnerUsers.modal.email.placeholder")}
                                                        className={classNames({'p-invalid': fieldState.invalid})}
                                                    />
                                                        )}
                                                    />
                                        </span>
                                            {getFormErrorMessage('email')}
                                        </div>
                                    </div>




                                    <div className="col-12">
                                        <div className="field">
                                            <label htmlFor="phone" className={classNames({'p-error': errors.phone})}>
                                            {t("partnerUsers.modal.field.phone")} *
                                            </label>
                                            <span className="p-float-label">
                                            <Controller
                                                name="phone"
                                                control={control}
                                                rules={{required: t("general.required.field.msg")}}
                                                render={({field, fieldState}) => (
                                                    <InputText
                                                        id={field.phone}
                                                        {...field}
                                                        autoFocus
                                                        placeholder={t("partnerUsers.modal.phone.placeholder")}
                                                        className={classNames({'p-invalid': fieldState.invalid})}
                                                    />
                                                        )}
                                                    />
                                        </span>
                                            {getFormErrorMessage('phone')}
                                        </div>
                                    </div>


                                    <div className="col-12">
                                        <div className="field">
                                            <label htmlFor="partners" className={classNames({'p-error': errors.partners})}>
                                            {t("partnerUsers.modal.field.partners")} *
                                            </label>
                                            <span className="p-float-label">
                                            <Controller
                                                name="partner"
                                                control={control}
                                                rules={{required: t("general.required.field.msg")}}
                                                render={({field, fieldState}) => (
                                                    <Dropdown
                                                            id={field.partner}
                                                            {...field}
                                                            disabled = {getValues("id")}
                                                            value = {partnerSelected?.id ? partnerSelected.id : partnerSelected}
                                                            onChange = { selectItem}
                                                            className={classNames({'p-invalid': fieldState.invalid})}
                                                            options={partnerList}
                                                            optionLabel="name"
                                                            optionValue="value"
                                                            placeholder={t("partnerUsers.modal.partners.placeholder")}
                                                        />
                                                        )}
                                                    />
                                        </span>
                                            {getFormErrorMessage('partner')}
                                        </div>
                                    </div>


                                    <div className="col-12">
                                        <div className="field">
                                            <label htmlFor="userRole" className={classNames({'p-error': errors.roles})}>
                                            {t("partnerUsers.modal.field.role")} *
                                            </label>
                                            <span className="p-float-label">
                                            <Controller
                                                name="roles"
                                                control={control}
                                                rules={{required: t("general.required.field.msg")}}
                                                render={({field, fieldState}) => (
                                                    <MultiSelect
                                                            showSelectAll= {false}
                                                            selectionLimit={1}
                                                            className={classNames({'p-invalid': fieldState.invalid})}
                                                            options={roleList}
                                                            onChange={(e) => setRoleFunc(e.value.map(id => ({id:id,name : roleList.filter(item => id === item.value)[0].label})))}
                                                            value={role.map(itm=> itm.id)}
                                                            optionLabel="displayName"
                                                            optionValue="value"
                                                            placeholder={t("partnerUsers.modal.role.placeholder")}
                                                        />
                                                        )}
                                                    />
                                        </span>
                                            {getFormErrorMessage('roles')}
                                        </div>
                                    </div>




                                    { role.filter(item => item.name === salesRepresentative).length > 0 && role.filter(item => item.name === salesExecutive).length === 0 && <div className="col-12">
                                        <div className="field">
                                            <label htmlFor="managerId" className={classNames({'p-error': errors.managerId})}>
                                                {t("partnerUsers.modal.field.manager")} *
                                            </label>
                                            <span className="p-float-label">
                                            <Controller
                                                name="managerId"
                                                control={control}
                                                rules={{required: t("general.required.field.msg")}}
                                                render={({field, fieldState}) => (
                                                    <Dropdown
                                                            id={field.managerId}
                                                            {...field}
                                                            autoFocus
                                                            className={classNames({'p-invalid': fieldState.invalid})}
                                                            options={managerList}
                                                            optionLabel="label"
                                                            placeholder={t("partnerUsers.modal.manager.placeholder")}
                                                        />
                                                        )}
                                                    />
                                        </span>
                                            {getFormErrorMessage('managerId')}
                                        </div>
                                    </div>}


                                    <div className="col-12">
                                        <div className="field">
                                            <label htmlFor="name" className={classNames({'p-error': errors.status})}>
                                            {t("partnerUsers.modal.field.status")}
                                            </label>
                                            <span className="p-float-label">
                                            <Controller
                                                name="status"
                                                control={control}
                                                render={({field, fieldState}) => (
                                                    <InputSwitch  trueValue={"ACTIVE"} falseValue={'SUSPENDED'} checked={getValues("status")} onChange={(e) => setValue('status',e.value)} />
                                                        )}
                                                    />
                                        </span>
                                            {getFormErrorMessage('status')}
                                        </div>
                                    </div>





                                    <Button type="submit" label="Save" className="mt-2"/>
                                    </form>
                                </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(PartnerUsers, comparisonFn)
