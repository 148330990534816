import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from '../../../hooks/useTranslation'

const DeleteSuggestionConfirmationDialog = ({ isVisible, onHide, onConfirm }) => {
  const languageKeys = {
    title: 'suggestions.dialog.title.delete',
    confirmText: 'suggestions.dialog.confirmText.delete',
    buttonCancel: 'suggestions.dialog.button.cancel',
    buttonYes: 'suggestions.dialog.button.yes',
  }

  const { t } = useTranslation(Object.values(languageKeys))

  return (
    <Dialog visible={isVisible} style={{ width: '450px' }} header={t(languageKeys.title)} modal onHide={onHide}>
      <div className="flex align-items-center flex-start mb-3">
        <span>{t(languageKeys.confirmText)}</span>
      </div>
      <footer style={{ textAlign: 'right' }}>
        <Button
          type="button"
          label={t(languageKeys.buttonCancel)}
          icon="pi pi-times"
          className="p-button-text"
          onClick={onHide}
        />
        <Button
          type="button"
          label={t(languageKeys.buttonYes)}
          icon="pi pi-check"
          className="p-button-text"
          onClick={onConfirm}
        />
      </footer>
    </Dialog>
  )
}

export default DeleteSuggestionConfirmationDialog
