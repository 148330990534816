/* eslint-disable react-hooks/exhaustive-deps */

import { useMemo, useRef, useState, useEffect, useCallback } from 'react'
// import { useTranslation } from 'react-i18next'
import SuggestionService from '../../service/SuggestionService'
import { useForm } from 'react-hook-form'
import { useTranslation } from '../../hooks/useTranslation'

export const useMySuggestions = () => {
  const languageKeys = {
    tableHeader: 'suggestions.mySuggestions.table.header',
    addNewButton: 'general.button.addNew',
    noRecordsMsg: 'general.table.noRecords',
    suggestionColumn: 'suggestions.table.column.suggestion',
    dateColumn: 'suggestions.table.column.date',
    actionColumn: 'suggestions.table.column.action',
    toastErrorSummary: 'general.toast.error.summary',
    toastSuccessSummary: 'general.toast.success.summary',
    toastSuccessCreatedMsg: 'suggestions.toast.success.createdMsg',
    toastSuccessUpdatedMsg: 'suggestions.toast.success.updatedMsg',
    toastSuccessDeletedMsg: 'suggestions.toast.success.deletedMsg',
  }

  const mounted = useRef(false)
  const toast = useRef(null)
  const dt = useRef(null)
  const { t } = useTranslation(Object.values(languageKeys))

  const [loading, setLoading] = useState(false)
  const [loadingAddOrEditSuggestionDialog, setLoadingAddOrEditSuggestionDialog] = useState(false)
  const [isAddOrEditSuggestionDialogVisible, setIsAddOrEditSuggestionDialogVisible] = useState(false)
  const [isDeleteSuggestionConfirmationDialogVisible, setIsDeleteSuggestionConfirmationDialogVisible] = useState(false)
  const [suggestions, setSuggestions] = useState([])

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const getMySuggestions = useCallback(async () => {
    try {
      if (mounted.current === true) setLoading(true)
      const response = await SuggestionService.getMySuggestions()
      if (mounted.current === true) setSuggestions(response.data)
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: t(languageKeys.toastErrorSummary),
        detail: err.response.data.message,
        life: 3000,
      })
    } finally {
      if (mounted.current === true) setLoading(false)
    }
  }, [])

  const addSuggestion = useCallback(
    async (data) => {
      try {
        setLoadingAddOrEditSuggestionDialog(true)
        await SuggestionService.addSuggestion(data)
        toast.current.show({
          severity: 'success',
          summary: t(languageKeys.toastSuccessSummary),
          detail: t(languageKeys.toastSuccessCreatedMsg),
          life: 3000,
        })
        setIsAddOrEditSuggestionDialogVisible(false)
        getMySuggestions()
      } catch (err) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: err.response.data.message,
          life: 3000,
        })
      } finally {
        setLoadingAddOrEditSuggestionDialog(false)
      }
    },
    [getMySuggestions, t, languageKeys]
  )

  const updateSuggestion = useCallback(
    async (data) => {
      try {
        setLoadingAddOrEditSuggestionDialog(true)
        await SuggestionService.updateSuggestion(data)
        setIsAddOrEditSuggestionDialogVisible(false)

        toast.current.show({
          severity: 'success',
          summary: t(languageKeys.toastSuccessSummary),
          detail: t(languageKeys.toastSuccessUpdatedMsg),
          life: 3000,
        })

        getMySuggestions()
      } catch (err) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: err.response.data.message,
          life: 3000,
        })
      } finally {
        setLoadingAddOrEditSuggestionDialog(false)
      }
    },
    [getMySuggestions, t, languageKeys]
  )

  useEffect(() => {
    getMySuggestions()
  }, [getMySuggestions])

  const defaultValues = {
    id: null,
    text: '',
  }

  const {
    control,
    reset,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues })

  const deleteSuggestion = (id) => {
    setValue('id', id)
    setIsDeleteSuggestionConfirmationDialogVisible(true)
  }

  const onOpenAddOrEditSuggestionDialog = (data) => {
    if (data === null || data === undefined) {
      reset(defaultValues)
    } else {
      reset(data)
    }
    setIsAddOrEditSuggestionDialogVisible(true)
  }

  const dateFormatterForSorting = (date) => {
    let dt = new Date(date)
    return `${dt.getFullYear().toString().padStart(4, '0')}${(dt.getMonth() + 1).toString().padStart(2, '0')}${dt
      .getDate()
      .toString()
      .padStart(2, '0')}${dt.getHours().toString().padStart(2, '0')}${dt.getMinutes().toString().padStart(2, '0')}`
  }

  const dateSorter = (event) => {
    let sortedSuggestionList = [...suggestions]

    sortedSuggestionList.sort((sc1, sc2) => {
      const date1 = dateFormatterForSorting(sc1[event.field])
      const date2 = dateFormatterForSorting(sc2[event.field])

      let result = null

      if (date1 == null && date2 != null) result = -1
      else if (date1 != null && date2 == null) result = 1
      else if (date1 == null && date2 == null) result = 0
      else if (typeof date1 === 'string' && typeof date2 === 'string')
        result = date1.localeCompare(date2, undefined, { numeric: true })
      else result = date1 < date2 ? -1 : date1 > date2 ? 1 : 0

      return event.order * result
    })

    return sortedSuggestionList
  }

  const onHideDeleteSuggestionConfirmationDialog = () => {
    setIsDeleteSuggestionConfirmationDialogVisible(false)
  }

  const onConfirmDeleteMySuggestion = useCallback(async () => {
    try {
      await SuggestionService.deleteMySuggestion(getValues('id'))
      toast.current.show({
        severity: 'success',
        summary: t(languageKeys.toastSuccessSummary),
        detail: t(languageKeys.toastSuccessDeletedMsg),
        life: 3000,
      })

      reset(defaultValues)
      onHideDeleteSuggestionConfirmationDialog()
      getMySuggestions()
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: t(languageKeys.toastErrorSummary),
        detail: err.response.data.message,
        life: 3000,
      })
    }
  }, [getMySuggestions, getValues, reset, defaultValues, t, languageKeys])

  const onHideAddOrEditSuggestionDialog = () => {
    setIsAddOrEditSuggestionDialogVisible(false)
  }

  const onAddOrEditSuggestionSubmit = handleSubmit((data) => {
    if (data.id === null || data.id === undefined) {
      addSuggestion(data)
    } else {
      updateSuggestion(data)
    }
  })

  return useMemo(
    () => ({
      t,
      onOpenAddOrEditSuggestionDialog,
      deleteSuggestion,
      toast,
      dt,
      suggestions,
      loading,
      dateSorter,
      isAddOrEditSuggestionDialogVisible,
      onHideAddOrEditSuggestionDialog,
      errors,
      control,
      getValues,
      loadingAddOrEditSuggestionDialog,
      onAddOrEditSuggestionSubmit,
      isDeleteSuggestionConfirmationDialogVisible,
      onHideDeleteSuggestionConfirmationDialog,
      onConfirmDeleteMySuggestion,
      languageKeys,
    }),
    [
      t,
      toast,
      dt,
      suggestions,
      loading,
      isAddOrEditSuggestionDialogVisible,
      errors,
      control,
      loadingAddOrEditSuggestionDialog,
      isDeleteSuggestionConfirmationDialogVisible,
      languageKeys,
    ]
  )
}
