import http from '../ServiceConfig'

class AuthService {
  signIn(request) {
    return http.post('/auth/signin', request)
  }

  signUp(request) {
    return http.post('/auth/signup', request)
  }

  resetPassword(request) {
    return http.post('/auth/resetPassword', request)
  }

  applyResetPassword(request) {
    return http.post('/auth/applyResetPassword', request)
  }

  changePassword(request) {
    return http.post('/users/change-password', request)
  }

  getProfile() {
    return http.get('/users/profile')
  }

  getSpecificUser(id) {
    return http.get('/users/' + id)
  }

  setPreferredLanguage(language) {
    return http.get('/users/set-preferred-language/' + language)
  }
}

export default new AuthService()
