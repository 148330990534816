import React, { useContext, useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import DealersService from '../service/DealersService'
import { useHistory, useParams } from 'react-router-dom'
import { TabMenu } from 'primereact/tabmenu'
import { Badge } from 'primereact/badge'
import { FilterMatchMode } from 'primereact/api'
import { Dropdown } from 'primereact/dropdown'
import AuthContext from '../context/AuthContext'
import { Role } from '../helpers/role'
import { Pagination } from '../helpers/Pagination'

function getQueryVariable(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}

const Dealers = () => {
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [appls, setAppls] = useState(null)
  const [applsDetail, setApplsDetail] = useState(null)
  const [globalFilter, setGlobalFilter] = useState(null)
  const [status, setStatus] = useState(params.type.toUpperCase())
  const [approvalDealerCount, setApprovalDealerCount] = useState(0)
  const toast = useRef(null)
  const dt = useRef(null)
  let history = useHistory()

  const [activePage, setActivePage] = useState(getQueryVariable('page') ? parseInt(getQueryVariable('page')) : 0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [assignableUsers, setAssignableUsers] = useState([])

  const { roleController } = useContext(AuthContext)

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    company: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    marketAreaServed: { value: null, matchMode: FilterMatchMode.CONTAINS },
    updateDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    assignedUserName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  useEffect(() => {
    getDealers(status, activePage)
    getApprovalDealerCount()
    status == 'NEW' && getAssignableUsers()
    setActiveIndex(items.findIndex((item) => item.key == status))
  }, [status, activePage])

  const getAssignableUsers = () => {
    DealersService.assignableUsers().then((response) => {
      setAssignableUsers(response.data)
    })
  }

  const getApprovalDealerCount = () => {
    DealersService.getDealers('APPROVAL').then((response) => {
      setApprovalDealerCount(response.data.totalElements)
    })
  }

  const getDealers = (status, activePage) => {
    setLoading(true)
    DealersService.getDealers(status, activePage).then((response) => {
      setAppls(response.data.content)
      setApplsDetail(response.data)
      setLoading(false)
    })
  }

  const getDealerDetails = (e) => {
    history.push('/partner-management/applications/' + e.status.toLowerCase() + '/' + e.id)
  }

  const addNewDealer = (e) => {
    history.push('/partner-management/applications/new/0')
  }

  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status</span>
        <span className={`product-badge status-${rowData.status.toLowerCase()}`}>{rowData.status}</span>
      </>
    )
  }

  const assignedUserNameTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Assigne User</span>
        {status == 'NEW' ? (
          <span>
            <Dropdown
              filter
              className="min-w-full"
              options={assignableUsers}
              onChange={(e) => changeAssignableUsers(e.value, rowData)}
              optionLabel="username"
              optionValue="id"
              placeholder="Select a Assignable User"
            />
          </span>
        ) : (
          <span>{rowData.assignedUser?.username}</span>
        )}
      </>
    )
  }

  const changeAssignableUsers = (value, rowData) => {
    setAssign(rowData.id, rowData.status, value)
  }

  const setAssign = (dealerId, status, selectedAssignableUser) => {
    let newStatus = status == 'NEW' ? 'IN_PROGRESS' : status
    DealersService.assign(dealerId, newStatus, selectedAssignableUser).then((response) => {
      toast.current.show({ severity: 'success', summary: 'Success Assign', detail: 'Success Assign', life: 3000 })
      getDealers(status)
    })
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Dealers</h5>
      <div className="flex">
        {status == 'NEW' && roleController([Role.Admin]) && (
          <Button label="New" icon="pi pi-plus" onClick={addNewDealer} className="p-button-success " />
        )}
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    </div>
  )

  const selectTab = (index, key) => {
    setActiveIndex(index)
    var t = status
    setStatus(key)
    var tt = status
    setActivePage(3)
    history.push('/partner-management/applications/' + key.toLowerCase())
  }

  const selectPage = (index) => {
    setActivePage(index)
    history.push(`/partner-management/applications/${status.toLowerCase()}?page=${index}`)
  }

  const tabMenuTemplate = (options) => {
    return (
      <div
        onClick={() => selectTab(options.index, options.key)}
        className="p-menuitem-link relative"
        role="presentation">
        <div className="p-menuitem-text cursor-pointer">{options.label}</div>
        {approvalDealerCount > 0 ? (
          <div
            style={{ position: 'absolute', right: '0px', top: '5px' }}
            className={options.key == 'APPROVAL' ? '' : 'hidden'}>
            <Badge value={approvalDealerCount} severity="danger"></Badge>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    )
  }

  const dateFormatter = (date) => {
    let dt = new Date(date)
    return `${(dt.getMonth() + 1).toString().padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}-${dt
      .getFullYear()
      .toString()
      .padStart(4, '0')}
              ${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`
  }

  const createDateTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Create Date</span>
        <span>{dateFormatter(rowData.createDate)}</span>
      </>
    )
  }

  const updateDateTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Update Date</span>
        <span>{dateFormatter(rowData.updateDate)}</span>
      </>
    )
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-search"
        className="p-button-rounded p-button-secondary"
        onClick={() => getDealerDetails(rowData)}
      />
    )
  }

  const items = [
    { index: 0, label: 'New', key: 'NEW', template: tabMenuTemplate },
    { index: 1, label: 'In Progress', key: 'IN_PROGRESS', template: tabMenuTemplate },
    { index: 2, label: 'Approval', key: 'APPROVAL', template: tabMenuTemplate },
    { index: 3, label: 'Converted', key: 'CONVERTED', template: tabMenuTemplate },
    { index: 4, label: 'Not Eligible', key: 'NOT_ELIGIBLE', template: tabMenuTemplate },
    { index: 5, label: 'All', key: 'ALL', template: tabMenuTemplate },
  ]
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => selectTab(e)} />
          <DataTable
            ref={dt}
            value={appls}
            dataKey="id"
            rows={1000}
            //rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            //paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            //currentPageReportTemplate="Showing {first} to {last} of {totalRecords} applications"
            emptyMessage="No application found."
            header={header}
            responsiveLayout="scroll"
            selectionMode="single"
            loading={loading}
            //filterDisplay="row"
          >
            <Column
              field="company"
              header="Company"
              headerStyle={{ width: '34%', minWidth: '10rem' }}
              /*filter
                                filterPlaceholder=""*/
            ></Column>
            <Column
              field="name"
              header="Name"
              headerStyle={{ width: '14%', minWidth: '10rem' }}
              /*filter
                                filterPlaceholder=""*/
            ></Column>
            <Column
              field="marketAreaServed"
              header="Market Area"
              headerStyle={{ width: '14%', minWidth: '10rem' }}
              /*filter
                                filterPlaceholder=""*/
            ></Column>
            <Column
              field="status"
              header="Status"
              body={statusBodyTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}
              /*filter
                                filterPlaceholder=""*/
            ></Column>
            <Column
              field="createDate"
              header="Create Date"
              headerStyle={{ width: '14%', minWidth: '10rem' }}
              /*filter
                                filterPlaceholder=""*/
            ></Column>
            <Column
              field="assignedUserName"
              body={assignedUserNameTemplate}
              header="Assigned User"
              headerStyle={{ width: '14%', minWidth: '10rem' }}
              /*filter
                                filterPlaceholder=""*/
            ></Column>
            <Column field="action" body={actionBodyTemplate}></Column>
          </DataTable>
          <div>
            <Pagination
              paginatedData={applsDetail}
              selectPage={(p) => {
                selectPage(p)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(Dealers, comparisonFn)
