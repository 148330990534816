import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { Dialog } from 'primereact/dialog'
import classNames from 'classnames'
import { Controller } from 'react-hook-form'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { ProgressSpinner } from 'primereact/progressspinner'
import { useTranslation } from '../../../hooks/useTranslation'
import CommonService from '../../../service/CommonService'
import StateCityService from '../../../service/StateCityService'
import { Toast } from 'primereact/toast'
import { InputSwitch } from 'primereact/inputswitch'

const NewCompanyDialog = ({ visible, onHide, errors, onSubmit, control, saving }) => {
  const languageKeys = useMemo(
    () => ({
      toastErrorSummary: 'general.toast.error.summary',
      dialogTitle: 'companyManagement.dialog.newCompany.title',
      requiredField: 'general.required.field.msg',
      invalidPhone: 'general.invalid.phone.msg',
      invalidEmail: 'general.invalid.email.msg',
      dropdownPlaceholder: 'general.dropdown.placeholder',
      name: 'companyManagement.form.name.label',
      namePlaceholder: 'companyManagement.form.name.placeholder',
      companyAddress: 'companyManagement.form.companyAddress.label',
      companyAddressPlaceholder: 'companyManagement.form.companyAddress.placeholder',
      state: 'companyManagement.form.state.label',
      city: 'companyManagement.form.city.label',
      zipcode: 'companyManagement.form.zipcode.label',
      zipcodePlaceholder: 'companyManagement.form.zipcode.placeholder',
      companyShortCode: 'companyManagement.form.companyShortCode.label',
      companyShortCodePlaceholder: 'companyManagement.form.companyShortCode.placeholder',
      companyPhone: 'companyManagement.form.companyPhone.label',
      companyPhonePlaceholder: 'companyManagement.form.companyPhone.placeholder',
      companyEmail: 'companyManagement.form.companyEmail.label',
      companyEmailPlaceholder: 'companyManagement.form.companyEmail.placeholder',
      companyTaxnbr: 'companyManagement.form.companyTaxnbr.label',
      companyTaxnbrPlaceholder: 'companyManagement.form.companyTaxnbr.placeholder',
      qbId: 'companyManagement.form.qbId.label',
      qbIdPlaceholder: 'companyManagement.form.qbId.placeholder',
      adminName: 'companyManagement.form.adminName.label',
      adminNamePlaceholder: 'companyManagement.form.adminName.placeholder',
      adminTitle: 'companyManagement.form.adminTitle.label',
      adminEmail: 'companyManagement.form.adminEmail.label',
      adminEmailPlaceholder: 'companyManagement.form.adminEmail.placeholder',
      adminPhone: 'companyManagement.form.adminPhone.label',
      adminPhonePlaceholder: 'companyManagement.form.adminPhone.placeholder',
      status: 'companyManagement.form.status.label',
      save: 'general.save',
    }),
    []
  )

  const { t, lang } = useTranslation(Object.values(languageKeys))

  const [loading, setLoading] = useState(false)
  const [loadingStates, setLoadingStates] = useState(false)
  const [loadingCities, setLoadingCities] = useState(false)
  const [titles, setTitles] = useState([])

  const [states, setStates] = useState([])
  const [selectedState, setSelectedState] = useState(null)
  const [cities, setCities] = useState([])

  const mounted = useRef(false)
  const toast = useRef(null)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const getActiveStaticContents = useCallback(
    (staticContents, group) => {
      const result = staticContents[group]
        .filter((item) => JSON.parse(item.value)?.active !== false)
        .map((item) => ({
          label: JSON.parse(item.value)[lang],
          value: item.label,
        }))
      return result
    },
    [lang]
  )

  const getStaticContent = useCallback(async () => {
    try {
      if (mounted.current === true) setLoading(true)
      const response = await CommonService.staticContent(['contact.titles'])
      if (mounted.current === true) {
        setTitles(getActiveStaticContents(response.data.result, 'contact.titles'))
      }
    } catch (error) {
    } finally {
      if (mounted.current === true) setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActiveStaticContents])

  const getStates = useCallback(async () => {
    if (states.length === 0) {
      try {
        if (mounted.current === true) setLoadingStates(true)
        const response = await StateCityService.getStates()
        if (mounted.current === true)
          setStates(
            [...(response.data ?? [])]
              .sort((a, b) => (a.stateName > b.stateName ? 1 : a.stateName < b.stateName ? -1 : 0))
              .map((item) => ({ label: item.stateName, value: item.stateName })) ?? []
          )
      } catch (err) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: err.response.data.message,
          life: 3000,
        })
      } finally {
        if (mounted.current === true) setLoadingStates(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states.length])

  const getCities = useCallback(async () => {
    if (selectedState !== null) {
      try {
        if (mounted.current === true) setLoadingCities(true)
        const response = await StateCityService.getCities(selectedState)

        if (mounted.current === true)
          setCities(
            [...(response.data ?? [])]
              .sort((a, b) => (a.cityName > b.cityName ? 1 : a.cityName < b.cityName ? -1 : 0))
              .map((item) => ({ label: item.cityName, value: item.cityName })) ?? []
          )
      } catch (err) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: err.response.data.message,
          life: 3000,
        })
      } finally {
        if (mounted.current === true) setLoadingCities(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState])

  useEffect(() => {
    if (visible === true && titles.length === 0) getStaticContent()
  }, [getStaticContent, visible, titles.length])

  useEffect(() => {
    if (visible === true) getStates()
  }, [getStates, visible])

  useEffect(() => {
    if (visible === true) getCities()
  }, [getCities, visible])

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog header={t(languageKeys.dialogTitle)} visible={visible} style={{ width: '70vw' }} onHide={onHide}>
        <div>
          <div style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}>
            <form onSubmit={onSubmit} className="grid">
              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>
                    {t(languageKeys.name) + ' *'}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: t(languageKeys.requiredField) }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder={t(languageKeys.namePlaceholder)}
                          autoFocus
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('name')}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="companyAddress" className={classNames({ 'p-error': errors.companyAddress })}>
                    {t(languageKeys.companyAddress) + ' *'}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="companyAddress"
                      control={control}
                      rules={{ required: t(languageKeys.requiredField) }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder={t(languageKeys.companyAddressPlaceholder)}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('companyAddress')}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="state" className={classNames({ 'p-error': errors.state })}>
                    {t(languageKeys.state) + ' *'}
                  </label>
                  {loadingStates === true ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                      <ProgressSpinner style={{ width: '40px', height: '40px' }} />
                    </div>
                  ) : (
                    <>
                      <span className="p-float-label">
                        <Controller
                          name="state"
                          control={control}
                          rules={{ required: t(languageKeys.requiredField) }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              id={field.state}
                              {...field}
                              value={selectedState}
                              onChange={(e) => {
                                setSelectedState(e.value)
                                field.onChange(e.value)
                              }}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                              options={states}
                              optionLabel="label"
                              optionValue="value"
                              placeholder={t(languageKeys.dropdownPlaceholder)}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('state')}
                    </>
                  )}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="city" className={classNames({ 'p-error': errors.city })}>
                    {t(languageKeys.city) + ' *'}
                  </label>
                  {loadingCities === true ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                      <ProgressSpinner style={{ width: '40px', height: '40px' }} />
                    </div>
                  ) : (
                    <>
                      <span className="p-float-label">
                        <Controller
                          name="city"
                          control={control}
                          rules={{ required: t(languageKeys.requiredField) }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              id={field.city}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e.value)
                              }}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                              options={cities}
                              optionLabel="label"
                              optionValue="value"
                              placeholder={t(languageKeys.dropdownPlaceholder)}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('city')}
                    </>
                  )}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="zipcode" className={classNames({ 'p-error': errors.zipcode })}>
                    {t(languageKeys.zipcode) + ' *'}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="zipcode"
                      control={control}
                      rules={{ required: t(languageKeys.requiredField) }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder={t(languageKeys.zipcodePlaceholder)}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('zipcode')}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="shortCode" className={classNames({ 'p-error': errors.shortCode })}>
                    {t(languageKeys.companyShortCode)}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="shortCode"
                      control={control}
                      // rules={{ required: t(languageKeys.requiredField) }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder={t(languageKeys.companyShortCodePlaceholder)}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('shortCode')}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="companyPhone" className={classNames({ 'p-error': errors.companyPhone })}>
                    {t(languageKeys.companyPhone) + ' *'}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="companyPhone"
                      control={control}
                      rules={{
                        required: t(languageKeys.requiredField),
                        validate: (value) => {
                          const phoneRegex = /^(?:(\+\d{1,4}[\s-]?)?\d{10,15})?$/
                          return phoneRegex.test(value) || t(languageKeys.invalidPhone)
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder={t(languageKeys.companyPhonePlaceholder)}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('companyPhone')}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="companyEmail" className={classNames({ 'p-error': errors.companyEmail })}>
                    {t(languageKeys.companyEmail) + ' *'}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="companyEmail"
                      control={control}
                      rules={{
                        required: t(languageKeys.requiredField),
                        validate: (value) => {
                          const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'gm')
                          return emailRegex.test(value) || t(languageKeys.invalidEmail)
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder={t(languageKeys.companyEmailPlaceholder)}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('companyEmail')}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="companyTaxnbr" className={classNames({ 'p-error': errors.companyTaxnbr })}>
                    {t(languageKeys.companyTaxnbr) + ' *'}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="companyTaxnbr"
                      control={control}
                      rules={{
                        required: t(languageKeys.requiredField),
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder={t(languageKeys.companyTaxnbrPlaceholder)}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('companyTaxnbr')}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="qbId" className={classNames({ 'p-error': errors.qbId })}>
                    {t(languageKeys.qbId) + ' *'}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="qbId"
                      control={control}
                      rules={{
                        required: t(languageKeys.requiredField),
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder={t(languageKeys.qbIdPlaceholder)}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('qbId')}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="adminName" className={classNames({ 'p-error': errors.adminName })}>
                    {t(languageKeys.adminName) + ' *'}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="adminName"
                      control={control}
                      rules={{
                        required: t(languageKeys.requiredField),
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder={t(languageKeys.adminNamePlaceholder)}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('adminName')}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="adminTitle" className={classNames({ 'p-error': errors.adminTitle })}>
                    {t(languageKeys.adminTitle) + ' *'}
                  </label>
                  {loading === true ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                      <ProgressSpinner style={{ width: '40px', height: '40px' }} />
                    </div>
                  ) : (
                    <>
                      <span className="p-float-label">
                        <Controller
                          name="adminTitle"
                          control={control}
                          rules={{ required: t(languageKeys.requiredField) }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              id={field.name}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e.value)
                              }}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                              options={titles}
                              optionLabel="label"
                              optionValue="value"
                              placeholder={t(languageKeys.dropdownPlaceholder)}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('adminTitle')}
                    </>
                  )}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="adminEmail" className={classNames({ 'p-error': errors.adminEmail })}>
                    {t(languageKeys.adminEmail) + ' *'}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="adminEmail"
                      control={control}
                      rules={{
                        required: t(languageKeys.requiredField),
                        validate: (value) => {
                          const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'gm')
                          return emailRegex.test(value) || t(languageKeys.invalidEmail)
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder={t(languageKeys.adminEmailPlaceholder)}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('adminEmail')}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="adminPhone" className={classNames({ 'p-error': errors.adminPhone })}>
                    {t(languageKeys.adminPhone) + ' *'}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="adminPhone"
                      control={control}
                      rules={{
                        required: t(languageKeys.requiredField),
                        validate: (value) => {
                          const phoneRegex = /^(?:(\+\d{1,4}[\s-]?)?\d{10,15})?$/
                          return phoneRegex.test(value) || t(languageKeys.invalidPhone)
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder={t(languageKeys.adminPhonePlaceholder)}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('adminPhone')}
                </div>
              </div>

              <div className="col-12 md:col-6 flex align-items-end">
                <div className="field mb-0">
                  <label htmlFor="status" className={classNames({ 'p-error': errors.status })}>
                    {t(languageKeys.status)}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field, fieldState }) => (
                        <InputSwitch
                          inputId={field.name}
                          checked={field.value}
                          inputRef={field.ref}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.value)}
                        />
                      )}
                    />
                  </span>
                </div>
              </div>

              <div className="col-12 md:col-12" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ position: 'absolute', right: '1.5em', bottom: '1.5em' }}>
                  <Button
                    type="submit"
                    label={t(languageKeys.save)}
                    loading={saving}
                    loadingIcon="pi pi-spin pi-spinner"
                    className="mt-2"
                    style={{ display: 'block' }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default NewCompanyDialog
