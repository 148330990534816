import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { ProgressSpinner } from 'primereact/progressspinner'
import { useTranslation } from '../../../hooks/useTranslation'
import SubmenuService from '../../../service/SubmenuService'
import { ListBox } from 'primereact/listbox'
import RoleService from '../../../service/RoleService'
import './EditRoleSubmenusDialog.scss'

const EditRoleSubmenusDialog = ({ visible, onHide, fetchRoles, selectedRole }) => {
  const languageKeys = useMemo(
    () => ({
      toastSuccessSummary: 'general.toast.success.summary',
      toastErrorSummary: 'general.toast.error.summary',
      toastSuccessDetail: 'roleSubmenuManagement.editRoleSubmenus.toast.success.detail',
      save: 'general.save',
    }),
    []
  )

  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [mainMenus, setMainMenus] = useState(null)
  const [roleSubmenus, setRoleSubmenus] = useState([])

  const mounted = useRef(false)
  const toast = useRef(null)

  const { t, getAllTranslationsOfGivenList, lang } = useTranslation(Object.values(languageKeys))

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const getSubmenus = useCallback(async () => {
    if (visible === true) {
      try {
        if (mounted.current === true) setLoading(true)
        const response = await SubmenuService.getSubmenus()
        // console.log(response.data)
        const submenuList = response.data

        const keyList = submenuList.reduce((acc, submenu) => {
          return [
            ...acc,
            'submenus.' + submenu.key,
            ...submenu.modules.map((module) => 'modules.' + module.key),
            'mainMenus.' + submenu.mainMenu.key,
          ]
        }, [])

        const translations = await getAllTranslationsOfGivenList(keyList)
        // roleSubmenus burada set edilmeli.
        if (mounted.current === true)
          setRoleSubmenus(
            selectedRole.submenus.map((submenu) => ({
              id: submenu.id,
              key: submenu.key,
              name: translations[lang]['submenus.' + submenu.key],
            }))
          )
        const groupedSubmenusByMainMenu = submenuList.reduce((acc, submenu) => {
          const key = submenu.mainMenu.key
          if (!acc[key]) {
            acc[key] = {}
          }
          acc[key] = { name: translations[lang]['mainMenus.' + key], submenus: acc[key].submenus ?? [] }
          acc[key].submenus.push({
            id: submenu.id,
            key: submenu.key,
            name: translations[lang]['submenus.' + submenu.key],
          })
          return acc
        }, {})

        if (mounted.current === true) setMainMenus(groupedSubmenusByMainMenu)
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: error.response.data.message,
          life: 3000,
        })
      } finally {
        if (mounted.current === true) setLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  useEffect(() => {
    if (visible === false && mounted.current === true) {
      setRoleSubmenus([])
      setMainMenus(null)
    }
  }, [visible])

  useEffect(() => {
    getSubmenus()
  }, [getSubmenus])

  const assignSubmenusToRole = useCallback(async () => {
    try {
      if (mounted.current === true) setSaving(true)

      console.log('selectedRole: ', selectedRole)
      console.log('roleSubmenus: ', roleSubmenus)

      if (selectedRole !== null) {
        await RoleService.assignSubmenus({
          roleId: selectedRole.id,
          subMenuIds: roleSubmenus.map((submenu) => submenu.id),
        })
        onHide()
        toast.current.show({
          severity: 'success',
          summary: t(languageKeys.toastSuccessSummary),
          detail: t(languageKeys.toastSuccessDetail),
          life: 3000,
        })
        fetchRoles()
      } else {
        console.error('Selected role can not be null')
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: t(languageKeys.toastErrorSummary),
        detail: error.response?.data?.message,
        life: 3000,
      })
    } finally {
      if (mounted.current === true) setSaving(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleSubmenus, selectedRole])

  return (
    <>
      <Toast ref={toast} />
      <Dialog header={selectedRole?.displayName ?? ''} visible={visible} style={{ width: '70vw' }} onHide={onHide}>
        {loading === true ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <ProgressSpinner style={{ width: '50px', height: '50px' }} />
          </div>
        ) : (
          <div>
            <div style={{ display: 'flex', width: '100%' }}>
              {mainMenus !== null
                ? Object.keys(mainMenus).map((mainMenuKey) => (
                    <div key={mainMenuKey} className="custom-listbox-container flex justify-content-center">
                      <ListBox
                        multiple
                        value={roleSubmenus}
                        onChange={(e) => {
                          setRoleSubmenus(e.value)
                        }}
                        options={[mainMenus[mainMenuKey]]}
                        optionLabel="name"
                        optionGroupLabel="name"
                        optionGroupChildren="submenus"
                        // optionGroupTemplate={groupTemplate}
                        className="w-full md:w-14rem"
                        listStyle={{ maxHeight: '250px' }}
                      />
                    </div>
                  ))
                : null}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1.5rem' }}>
              <Button
                type="button"
                label={t(languageKeys.save)}
                loading={saving}
                loadingIcon="pi pi-spin pi-spinner"
                onClick={(e) => {
                  e.preventDefault()
                  assignSubmenusToRole()
                }}
              />
            </div>
          </div>
        )}
      </Dialog>
    </>
  )
}

export default EditRoleSubmenusDialog
