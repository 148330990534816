import React, {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Button} from 'primereact/button'
import DealersService from '../../service/DealersService'
import {Dropdown} from 'primereact/dropdown'
import {Toast} from 'primereact/toast'
import {InputText} from 'primereact/inputtext'
import {MultiSelect} from 'primereact/multiselect'
import {Controller} from 'react-hook-form'

const ConvertToCustomer = (props) => {
    const {dealer, dealerId, dealerStatus, application} = props
    const [disabled, setDisabled] = useState(dealerStatus !== 'IN_PROGRESS' && dealerStatus !== 'APPROVAL')
    const [selectedPartnerType, setSelectedPartnerType] = useState(application.partnerType)
    const [partnerProductTypes, setPartnerProductTypes] = useState(null)
    const [selectedPartnerProduct, setSelectedPartnerProduct] = useState(application.partnerProductTypeMultiplierDTOList)
    const [crmUsers, setCrmUsers] = useState(null)
    const [selectedCrmUser, setSelectedCrmUser] = useState(null)
    const [selectedPartnerMultiplier, setSelectedPartnerMultiplier] = useState(0)
    let history = useHistory()

    useEffect(async () => {
        await getPartnerProductTypes()
        await getCrmUsers()
        if(dealerStatus === "APPROVAL")
            await getPartner()
    }, [])

    const toast = useRef(null)

    //SUB_DEALER, DEALER, CONTRACTOR, SHOWROOM
    const partnerTypes = [
        {label: 'Showroom', value: 'SHOWROOM'},
        {label: 'Dealer', value: 'DEALER'},
        {label: 'Sub Dealer', value: 'SUB_DEALER'},
        {label: 'Contracter', value: 'CONTRACTOR'},
    ]
    
    const getCrmUsers = async() => {
        await DealersService.users().then((response) => {
            response.data = response.data.filter(x=> x.roles.filter(y=> y.name === 'ROLE_CRM_MANAGER' || y.name === 'ROLE_CRM_REPRESENTATIVE').length > 0)
            setCrmUsers(response.data)
        })
    }

    const setConvertToCustomer = () => {
        let request = {
            id: dealerId,
            assignedUser: {id:application.assignedUser.id},
            applicationProductTypeMultipliers: selectedPartnerProduct.map(x=> ({
                multiplier: x.multiplier.replace(',','.'),
                productTypeId: x.productTypeId,
                id: x.id
            })),
            partnerType: selectedPartnerType,
            status:"APPROVAL",
            crmUser:{id:selectedCrmUser},
            multiplier: selectedPartnerMultiplier
        }
        DealersService.dealerApplicationPUT(dealerId, request).then((response) => {
            if(dealerStatus !== "APPROVAL"){
                toast.current.show({
                    severity: 'success',
                    summary: 'Success Convert To Customer',
                    detail: 'Success Convert To Customer',
                    life: 3000,
                })
                history.push('/applications/in_progress')
            }
            else {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success Update',
                    detail: 'Success Update',
                    life: 3000,
                })
            }
        })
    }

    const getPartner = async () => {
        if(dealer){
            setSelectedPartnerMultiplier(dealer.multiplier)
            setSelectedCrmUser(dealer.crmUser?.id)
            setSelectedPartnerProduct(dealer.applicationProductTypeMultipliers.map(x=> ({ 
                id: x.id,
                productTypeId: x.productTypeId, 
                multiplier: x.multiplier
            })))
            setSelectedPartnerType(dealer.partnerType)
        }
    }

    const getPartnerProductTypes = async () => {
        await DealersService.partnerProductTypes().then((response) => {
            let items = []
            response.data.forEach((item) =>
                items.push({
                    productTypeName: item.name,
                    multiplier: '',
                    productTypeId: item.id
                })
            )
            setPartnerProductTypes(items)
        })
    }

    const setValProductTypeMultiplier = (index, data) => {
        let arr = []
        arr = arr.concat(selectedPartnerProduct)
        arr[index].multiplier = data.target.value
        setSelectedPartnerProduct(arr)
    }

    const getPartnerProductMultipliersTemplate = () => {
        if (!selectedPartnerProduct) return
        return selectedPartnerProduct.map((item, index) => {
            return (
                <div className="p-inputgroup" key={'pm' + index} style={{marginTop: '20px'}}>
                    {/* <span className="p-inputgroup-addon">{item?.productTypeName}</span> */}
                    <span className="p-inputgroup-addon">{partnerProductTypes.filter(y=> y.productTypeId === item?.productTypeId)[0].productTypeName}</span>
                    <InputText
                        disabled={disabled}
                        type="number"
                        value={selectedPartnerProduct[index].multiplier}
                        min={0}
                        onChange={(event) => setValProductTypeMultiplier(index, event)}
                        step="0.01"
                    />

                    <Button
                        type="button"
                        icon="pi pi-times"
                        disabled={disabled}
                        onClick={() => deleteProductMultiplier(item?.productTypeId)}
                        className="p-button-danger"
                    />
                </div>
            )
        })
    }

    const deleteProductMultiplier = (productTypeId) => {
        let newArr = selectedPartnerProduct.filter((item) => item.productTypeId !== productTypeId)
        setSelectedPartnerProduct(newArr)
    }

    return (
        <div className="grid crud-demo">
            <Toast ref={toast}/>
            <div className="col-12 p-0">
                <div className="card">
                    <div className="grid">
                        <div className="col-12">
                            <Dropdown
                                className="min-w-full"
                                value={selectedPartnerType}
                                options={partnerTypes}
                                onChange={(e) => setSelectedPartnerType(e.value)}
                                optionLabel="label"
                                optionValue="value"
                                placeholder="Select a Partner Type"
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-12">
                        <div className="p-inputgroup"><span className="p-inputgroup-addon">Partner Multiplier</span>
                            <InputText
                                placeholder="Partner Multiplier"
                                type="number"
                                min="1"
                                value={selectedPartnerMultiplier}
                                onChange={(event) => setSelectedPartnerMultiplier(parseFloat(event.target.value))}
                                step="0.01"
                            />
                        </div></div>
                        <div className="col-12">
                            <Dropdown
                                    filter
                                    showClear
                                    className="min-w-full"
                                    value={selectedCrmUser}
                                    options={crmUsers}
                                    onChange={(e) => setSelectedCrmUser(e.value)}
                                    optionLabel="username"
                                    optionValue="id"
                                    placeholder="Select a CRM Representative"
                                    disabled={disabled}
                                />
                        </div>
                        <div className="col-12">
                            <MultiSelect
                                className="min-w-full"
                                options={partnerProductTypes}
                                value={selectedPartnerProduct}
                                onChange={(e) => setSelectedPartnerProduct(e.value)}
                                optionLabel="productTypeName"
                                dataKey="productTypeId"
                                placeholder="Select Product"
                                showSelectAll={true}
                                selectAll={true}
                                filterPlaceholder="Product Name"
                                filter={true}
                                filterBy="productTypeName"
                                showClear={true}
                                fixedPlaceholder={true}
                                disabled={disabled}
                            />
                            {getPartnerProductMultipliersTemplate()}
                        </div>
                        <div className="col-12">
                            <Button
                                label={dealerStatus === "IN_PROGRESS" ? "Convert To Customer" : "Save"}
                                disabled={disabled}
                                onClick={setConvertToCustomer}
                                className="p-button-success min-w-full"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConvertToCustomer
