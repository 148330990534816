import React, { useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import DealersService from '../../service/DealersService'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dialog } from 'primereact/dialog'

const Notes = (props) => {
  const { dealerId } = props
  const [loading, setLoading] = useState(true)
  const [notes, setNotes] = useState(null)
  const [globalFilter, setGlobalFilter] = useState(null)
  const toast = useRef(null)
  const dt = useRef(null)
  const [selectedNote, setSelectedNote] = useState(null)

  const [display, setDisplay] = useState(false)
  const [noteText, setNoteText] = useState('')
  const [note, setNote] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [isSave, setIsSave] = useState(false)

  useEffect(() => {
    getNotes(dealerId)
  }, [])

  const getNotes = (dealerId) => {
    setLoading(true)
    DealersService.getNotes(dealerId).then((response) => {
      setNotes(response.data)
      setLoading(false)
    })
  }

  const noteEdit = (e) => {
    setIsEdit(false)
    setIsSave(false)
    setDisplay(true)
    setNoteText(e.data.note)
    setNote(e.data)
  }

  const noteNew = (e) => {
    setIsEdit(false)
    setIsSave(true)
    setNoteText('')
    setDisplay(true)
  }

  const saveNote = () => {
    isEdit ? updateNote() : addNote()
  }

  const addNote = () => {
    let request = {
      dealerId: dealerId,
      note: noteText,
    }
    DealersService.AddNote(dealerId, request).then((response) => {
      getNotes(dealerId)
      setDisplay(false)
    })
  }

  const updateNote = () => {
    let request = {
      ...note,
      note: noteText,
    }

    DealersService.updateNote(dealerId, note.id, request).then((response) => {
      getNotes(dealerId)
      setDisplay(false)
    })
  }

  const dateFormatter = (date) => {
    let dt = new Date(date)
    return `${(dt.getMonth() + 1).toString().padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}-${dt
      .getFullYear()
      .toString()
      .padStart(4, '0')}
              ${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`
  }

  const renderFooter = () => {
    return (
      <div>
        <Button label="Cancel" icon="pi pi-times" onClick={() => setDisplay(false)} className="p-button-text" />
        {isEdit && (
          <Button label="Delete" icon="pi pi-trash" onClick={() => saveNote()} autoFocus className="p-button-danger" />
        )}
        {isSave && <Button label="Save" icon="pi pi-check" onClick={() => saveNote()} autoFocus />}
      </div>
    )
  }

  const dateTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Date</span>
        <span>{dateFormatter(rowData.createDate)}</span>
      </>
    )
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Notes</h5>
      <div className="flex">
        <span className="block mt-2 md:mt-0 p-input-icon-left mr-2">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
        <Button label="New" icon="pi pi-plus" className="p-button-success " onClick={noteNew} />
      </div>
    </div>
  )

  return (
    <div className="grid crud-demo">
      <div className="col-12 p-0">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={notes}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            globalFilter={globalFilter}
            emptyMessage="No products found."
            header={header}
            responsiveLayout="scroll"
            selectionMode="single"
            loading={loading}
            selection={selectedNote}
            onSelectionChange={(e) => setSelectedNote(e.value)}
            onRowDoubleClick={(e) => noteEdit(e)}>
            <Column field="note" header="Note"></Column>
            <Column field="creatorUserName" header="User"></Column>
            <Column field="createDate" body={dateTemplate} header="Date"></Column>
          </DataTable>
        </div>
      </div>
      <Dialog
        header="Note"
        visible={display}
        style={{ width: '50vw' }}
        footer={renderFooter('displayBasic')}
        onHide={() => setDisplay(false)}>
        <InputTextarea value={noteText} onChange={(e) => setNoteText(e.target.value)} rows={25} />
      </Dialog>
    </div>
  )
}

export default Notes
