import 'react-app-polyfill/ie11'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import AppWrapper from './AppWrapper'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext'
import './i18n'
import { ProgressSpinner } from 'primereact/progressspinner'

const Loader = () => (
  <div className="loader">
    <ProgressSpinner />
  </div>
)

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <Suspense fallback={<Loader />}>
        <AppWrapper />
      </Suspense>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
