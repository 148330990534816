import http from '../ServiceConfig'


class CommonService {
    staticContent(groups) {
        return http.post('/common/get/static-content', {groups: groups})
    }
}


export default new CommonService()
