import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

const RemoveOrderModal = ({ show, onHide, onAccept }) => {
  const { t } = useTranslation()

  return (
    <Dialog visible={show} style={{ width: '450px' }} header={t('palletes.confirm.header')} modal onHide={onHide}>
      <div className="flex align-items-center flex-start mb-3">
        <span>{t('palletes.confirm.remove.text')}</span>
      </div>
      <footer style={{ textAlign: 'right' }}>
        <Button
          type="button"
          label={t('palletes.confirm.button.cancel')}
          icon="pi pi-times"
          className="p-button-text"
          onClick={onHide}
        />
        <Button
          type="button"
          label={t('palletes.confirm.button.confirm')}
          icon="pi pi-check"
          className="p-button-text"
          onClick={onAccept}
        />
      </footer>
    </Dialog>
  )
}

export default RemoveOrderModal
