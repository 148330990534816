import http from '../ServiceConfig'

class AdminProjectService {
  getMyOrders() {
    return http.get('/orders/my-orders')
  }

  getNotes(orderId) {
    return http.get('/order-notes/' + orderId)
  }

  getChapterOfOrderByOrderId(orderId) {
    return http.get('/chapter-of-order/' + orderId)
  }

  getChapterOfOrderWithPartsByOrderId(orderId) {
    return http.get('/chapter-of-order/with-parts/' + orderId)
  }

  getBomDetailByOrderRoomId(roomId) {
    return http.get('/chapter-of-order/bom/' + roomId)
  }

  addNote(orderId, request) {
    return http.post('/order-notes/' + orderId, request)
  }

  updateNote(orderId, request) {
    return http.put('/order-notes/' + orderId, request)
  }

  deleteNote(orderId) {
    return http.delete('/order-notes/' + orderId)
  }

  getOrderById(orderId) {
    return http.get('/orders/' + orderId)
  }

  getAssignableUsers(orderId) {
    return http.get('/order-assign/get/available-user/' + orderId)
  }

  assignUser(orderId, req) {
    return http.post('/order-assign/next-status/assign/user/' + orderId, req)
  }

  orderNextStatus(orderId, req) {
    return http.post('/order-status/order/next-status/' + orderId, req)
  }

  calculateBomPrice(roomId, req) {
    return http.post('/inventories/calculate-price/' + roomId, req, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  saveBom(roomId, req) {
    return http.post('/inventories/bom/' + roomId, req, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  saveDesignFile(roomId, req) {
    return http.post('/chapter-of-order/add-design-file/' + roomId, req)
  }

  savePartFile(roomId, req) {
    return http.post('/part-of-chapter/add-part/from-file/' + roomId, req)
  }

  saveDiscount(roomId, req) {
    return http.post('/order-price/chapter/discount/' + roomId, req)
  }
}

export default new AdminProjectService()
