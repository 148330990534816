import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import { Tag } from 'primereact/tag'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Dialog } from 'primereact/dialog'
import { FileUpload } from 'primereact/fileupload'
import { classNames } from 'primereact/utils'
import { Controller, useForm } from 'react-hook-form'
import './MyOrderDetail.scss'
import AdminProjectService from '../../../service/AdminProjectService'
import { Steps } from 'primereact/steps'
import AdminFileupload from '../../../components/admin/admin-fileupload'
import AssignUser from '../../../components/admin/admin-assign-user'
import CommonCamButton from '../../../components/admin/admin-common-button'
import OrderDetailSummary from '../../../components/admin/admin-order-detail-summary'
import AdminDatatable from '../../../components/admin/admin-datatable'
import { AdminFileUploadType } from '../../../helpers/file-upload.type'
import { dateFormatWithTime, encodeBase64 } from '../../../helpers/convertor'
import AuthContext from '../../../context/AuthContext'
import { Role } from '../../../helpers/role'
import { OrderStatus, statusController } from '../../../helpers/order-status'
import { InputNumber } from 'primereact/inputnumber'
import { SplitButton } from 'primereact/splitbutton'
import CommonService from '../../../service/CommonService'
import { downloader } from '../../../helpers/downloader-util'
import { ToggleButton } from 'primereact/togglebutton'
import AdminPartList from '../../../components/admin/admin-part-list'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { TabView, TabPanel } from 'primereact/tabview'
import i18next from 'i18next'
import AuthService from '../../../service/AuthService'
import UploadedDocumentsDialog from './components/UploadedDocumentsDialog'

const MyOrderDetail = () => {
  const { id } = useParams()
  const [dataList, setDataList] = useState(null)
  const [noteDataList, setNoteDataList] = useState(null)
  const [globalFilter, setGlobalFilter] = useState(null)
  const [logFilter, setLogFilter] = useState(null)
  const [globalFilterForRoomDesignFiles, setGlobalFilterForRoomDesignFiles] = useState(null)
  const [globalFilterForDesignForm, setGlobalFilterForDesignForm] = useState(null)
  const [globalFilterForNote, setGlobalFilterForNote] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showAddNoteModal, setShowAddNoteModal] = useState(false)
  const [showAddProductionDesign, setShowAddProductionDesign] = useState(false)
  const [stepperActiveIndex, setStepperActiveIndex] = useState(0)
  const [orderDetail, setOrderDetail] = useState(null)
  const [isBomCalc, setIsBomCalc] = useState(false)
  const [isSamePrice, setIsSamePrice] = useState(false)
  const [bomCalcData, setBomCalcData] = useState(null)
  const [isDialogButtonLoading, setIsDialogButtonLoading] = useState(false)
  const [showDiscountModal, setShowDiscountModal] = useState(false)
  const [showDesignFormModal, setShowDesignFormModal] = useState(false)
  const [showBomDetailModal, setShowBomDetailModal] = useState(false)
  const [showProductionDesignModal, setShowProductionDesignModal] = useState(false)
  const [showPartListModal, setShowPartListModal] = useState(false)
  const [designFormContent, setDesignFormContent] = useState(null)
  const [designFileDataList, setDesignFileDataList] = useState(null)
  const [logdataList, setLogDataList] = useState(null)
  const [bomDetailDataList, setBomDetailDataList] = useState({ bomDetailList: [], totalPrice: 0 })
  const [designFormFilesList, setDesignFormFilesList] = useState([{ extension: '', link: '', name: '', version: '' }])
  const [showDesignFilesModal, setShowDesignFilesModal] = useState(false)
  const [roomDesignFiles, setRoomDesignFiles] = useState([])
  const [assignedUser, setAssignedUser] = useState(null)
  const [showEditNoteModal, setShowEditNoteModal] = useState(false)
  const [deleteNoteDialog, setDeleteNoteDialog] = useState(false)
  const [showDesignFilesModalIfFilesUploadedFirstRoomModal, setShowDesignFilesModalIfFilesUploadedFirstRoomModal] =
    useState(false)
  const [uploadedNoteFiles, setUploadedNoteFiles] = useState([])
  const [isUploadedDocumentsDialogVisible, setIsUploadedDocumentsDialogVisible] = useState(false)

  const toast = useRef(null)
  const fileUploadRef = useRef(null)
  const { t } = useTranslation()
  const { roleController, user } = useContext(AuthContext)

  useEffect(() => {
    if (loading) {
      getOrderDetails()
      getRooms()
      getNotes()
      getStaticContents()
      getLogs()
    }
  }, [loading])

  useEffect(() => {
    if (orderDetail?.assignedUser?.id) {
      getAssignedUser(orderDetail?.assignedUser?.id)
    }
  }, [orderDetail?.assignedUser?.id])

  useEffect(() => {
    if (!showAddNoteModal) setUploadedNoteFiles([])
  }, [showAddNoteModal])

  const defaultValues = {
    id: null,
    name: '',
    note: '',
  }

  const acceptedFileTypes = ['png', 'jpeg', 'jpg', 'doc', 'docx', 'xlsx', 'csv', 'txt', 'pdf']

  const stepperItems = [{ label: 'BOM File Upload' }, { label: 'Production Design Upload' }]

  const staticContentGroups = [
    'add.backer',
    'backer.color',
    'base.molding.option',
    'belt.rack',
    'body.color',
    'crown.molding.option',
    'door.color.ACHILLES',
    'door.color.ACHILLES_WITH_GLASS',
    'door.color.ALUMINUM',
    'door.color.CIRCE',
    'door.color.CIRCE_WITH_GLASS',
    'door.color.FLUTED',
    'door.color.J_GROOVE',
    'door.color.ODYSSEUS',
    'door.color.RATTAN',
    'door.color.SHAKER',
    'door.color.SHAKER_WITH_GLASS',
    'door.color.SLAB',
    'door.style',
    'double.coat.hook',
    'drawer.box',
    'drawer.face.color',
    'drawer.face.color.ACHILLES',
    'drawer.face.color.ACHILLES_WITH_GLASS',
    'drawer.face.color.CIRCE',
    'drawer.face.color.CIRCE_WITH_GLASS',
    'drawer.face.color.FLUTED',
    'drawer.face.color.J_GROOVE',
    'drawer.face.color.ODYSSEUS',
    'drawer.face.color.RATTAN',
    'drawer.face.color.SHAKER',
    'drawer.face.color.SHAKER_WITH_GLASS',
    'drawer.face.color.SLAB',
    'drawer.type',
    'edgeband.color',
    'glass.color',
    'glass.shelves',
    'led.option',
    'puck.option',
    'pull.option',
    'rod.type.color',
    'scarf.rack',
    'shoe.fence.option',
    'single.coat.hook',
    'tag.divided.drawer',
    'tag.divided.shelf',
    'tag.folding.station',
    'tag.jewelry',
    'tag.laundry.organizer',
    'tag.lingerie.drawer',
    'tag.pant.rack',
    'tag.shoe.organizer',
    'tie.rack',
    'valet.rod',
    'velvet.jewelry.tray',
    'wire.basket.option',
  ]

  const initDesignFormContent = async (roomData) => {
    const doorStyleFilter = roomData?.filter((x) => x.key === 'DoorStyle')
    const doorStyleControl =
      doorStyleFilter.length === 1 &&
      doorStyleFilter[0].value !== undefined &&
      doorStyleFilter[0].value !== null &&
      doorStyleFilter[0].value.length
    let designFormTemp = {
      PullOption: { isColor: true, group: 'pull.option' },
      LedOption: { isColor: true, group: 'led.option' },
      PuckOption: { isColor: true, group: 'puck.option' },
      DoorStyle: { group: 'door.style', value: '-' },
      DoorColor: { isColor: true },
      DoorGlassColor: { isColor: true, colorGroup: 'glass.color', value: '-' },
      BodyColor: { isColor: true, colorGroup: 'body.color' },
      EdgebandColor: { isColor: true, group: 'edgeband.color' },
      DrawerFaceColor: { isColor: true },
      DrawerBox: { group: 'drawer.box', value: '-' },
      Backer: { value: 'CONSTANT_YESNO_NO', colorGroup: 'backer.color' },

      RodTypeColor: { isColor: true, group: 'rod.type.color' },
      WireBasketOption: { group: 'wire.basket.option' },
      ShoeFenceOption: { group: 'shoe.fence.option' },
      CrownMoldingOption: { group: 'crown.molding.option' },
      BaseMoldingOption: { group: 'base.molding.option' },
      VelvetJewelryTray: { group: 'velvet.jewelry.tray' },
      DrawerType: { group: 'drawer.type' },
      SingleCoatHook: { group: 'single.coat.hook' },

      DoubleCoatHook: { group: 'double.coat.hook' },
      BeltRack: { group: 'belt.rack' },
      ValetRod: { group: 'valet.rod' },
      TieRack: { group: 'tie.rack' },
      ScarfRack: { group: 'scarf.rack' },
      GlassShelves: { group: 'glass.shelves' },
      TAGDividedDrawer: { group: 'tag.divided.drawer' },
      TAGDividedShelf: { group: 'tag.divided.shelf' },

      TAGFoldingStation: { group: 'tag.folding.station' },
      TAGJewelry: { group: 'tag.jewelry' },
      TAGLaundryOrganizer: { group: 'tag.laundry.organizer' },
      TAGLingerieDrawer: { group: 'tag.lingerie.drawer' },
      TAGPantRack: { group: 'tag.pant.rack' },
      TAGShoeOrganizer: { group: 'tag.shoe.organizer' },
    }
    if (doorStyleControl) {
      designFormTemp.DoorColor.colorGroup = 'door.color.' + doorStyleFilter[0].value
      designFormTemp.DrawerFaceColor.colorGroup = 'drawer.face.color.' + doorStyleFilter[0].value
    }
    if (roomData != null) {
      for (const [key, value] of Object.entries(designFormTemp)) {
        let _data = roomData.filter((x) => x.key === key)
        if (_data.length === 1 && _data[0].value !== undefined && _data[0].value !== null && _data[0].value.length) {
          designFormTemp[key].value = _data[0].value
          if (_data[0].quantity !== null) designFormTemp[key].quantity = _data[0].quantity
          if (_data[0].other !== null) designFormTemp[key].other = _data[0].other
        }
      }
    }
    return designFormTemp
  }

  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues, shouldFocusError: false })

  const [designerRole] = useState([Role.CamDesigner, Role.CamManager])
  const [discountAuthorizedRoles] = useState([
    Role.Admin,
    Role.GeneralManager,
    Role.CountrySalesManager,
    Role.RegionSalesManager,
  ])
  const [discountVisibilityStatus] = useState([
    OrderStatus.ProductionDesignInProgress,
    OrderStatus.ProductionDesignApproval,
    OrderStatus.ProductionDesignRevisionRequested,
  ])
  // const [designsAuthorizedRoles] = useState([
  //   Role.GeneralManager,
  //   Role.CountrySalesManager,
  //   Role.RegionSalesManager,
  //   Role.CrmManager,
  //   Role.CrmRepresentative,
  //   Role.CamManager,
  //   Role.CamDesigner,
  //   Role.Admin,
  //   Role.ProductionPlanningManager,
  //   Role.ProductionManager,
  // ])
  const [designsVisibilityStatues] = useState([
    OrderStatus.ProductionDesignInProgress,
    OrderStatus.ProductionDesignApproval,
    OrderStatus.ProductionDesignRevisionRequested,
    OrderStatus.WaitingForPrepayment,
    OrderStatus.WaitingForProductionPlanning,
    OrderStatus.ProductionPlanned,
    OrderStatus.ProductionStarted,
    OrderStatus.ProductionFinished,
    OrderStatus.ReadyForPacking,
    OrderStatus.Packing,
    OrderStatus.BeingPalletized,
    OrderStatus.ReadyForPalletizing,
    OrderStatus.ReadyToLoad,
    OrderStatus.Loaded,
    OrderStatus.Done,
  ])
  const [partAuthorizedRoles] = useState([Role.ProductionPlanningManager, Role.ProductionManager, Role.Admin])
  const [noteAuthorizedRoles] = useState([
    Role.Admin,
    Role.GeneralManager,
    Role.CountrySalesManager,
    Role.RegionSalesManager,
    Role.CrmManager,
    Role.CrmRepresentative,
    Role.DesignManager,
    Role.Designer,
    Role.CamManager,
    Role.CamDesigner,
    Role.ProductionPlanningManager,
    Role.ProductionManager,
    Role.FactoryProductionPlanningManager,
    Role.FactoryProductionManager,
    Role.LogisticsPlanningManager,
    Role.LogisticWorker,
  ])

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  const getStaticContents = () => {
    CommonService.staticContent(staticContentGroups).then((response) => {
      let contentList = []
      for (let g in staticContentGroups) {
        let content = {
          group: staticContentGroups[g],
          values: [],
        }
        if (staticContentGroups[g].includes('drawer.face.color')) content.values.push({ label: 'No Drawer', id: '-' })
        if (staticContentGroups[g].includes('door.color')) content.values.push({ label: 'Not Selected', id: '-' })
        for (let i = 0; i < response.data.result[staticContentGroups[g]].length; i++) {
          let parsedValue = JSON.parse(response.data.result[staticContentGroups[g]][i].value)
          parsedValue.label = response.data.result[staticContentGroups[g]][i].label
          content.values.push(parsedValue)
        }
        contentList.push(content)
      }
      contentList.filter((x) => x.group === 'door.style')[0].values.splice(0, 0, { label: 'No Doors', id: '-' })
      contentList.filter((x) => x.group === 'glass.color')[0].values.splice(0, 0, { label: 'No Glass', id: '-' })
      setDesignFormContent(contentList)
    })
  }

  const getAssignedUser = (id) => {
    AuthService.getSpecificUser(id).then((response) => {
      setAssignedUser(response.data)
    })
  }

  const getOrderDetails = () => {
    AdminProjectService.getOrderById(id).then((response) => {
      setOrderDetail(response.data)
      setValue('orderId', response.data?.id)
      setLoading(false)
    })
  }

  const getRooms = () => {
    AdminProjectService.getChapterOfOrderWithPartsByOrderId(id).then((response) => {
      setLoading(false)
      setDataList(response.data)
    })
  }

  const getNotes = () => {
    AdminProjectService.getNotes(id).then((response) => {
      setLoading(false)
      if (!roleController(noteAuthorizedRoles)) {
        const noteList = response.data.filter((note) => note.hidden === false)
        setNoteDataList(noteList)
      } else {
        const noteList = response.data.map((note) => {
          if (note.hidden) {
            note.note = '(Private) ' + note.note
          }
          return note
        })
        setNoteDataList(noteList)
      }
    })
  }

  const headerTemplate = (options) => {
    const { chooseButton, cancelButton, className } = options
    return (
      <div className={className}>
        {chooseButton} {cancelButton}
      </div>
    )
  }

  const onTemplateSelect = (e) => {
    const newFiles = [...e.files]

    const files = [...uploadedNoteFiles, ...newFiles].reduce((acc, current) => {
      const x = acc.find((item) => item.name === current.name && item.size === current.size)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])

    setUploadedNoteFiles(files)
  }

  const onTemplateClear = (callback) => {
    setUploadedNoteFiles([])
    if (callback !== null && typeof callback !== 'undefined') {
      callback()
    }
  }

  const onTemplateRemove = async (e) => {
    const updatedFiles = uploadedNoteFiles.filter((file) => file !== e.file)
    setUploadedNoteFiles(updatedFiles)
  }

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img alt={''} role="presentation" src={file.objectURL} width={100} />
          <span className="flex flex-column text-left mx-5 word-break">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          style={{ width: '3rem' }}
          onClick={() => onTemplateClear(props.onRemove)}
        />
      </div>
    )
  }

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-download mt-3 p-5"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}></i>
      </div>
    )
  }

  const getLogs = () => {
    AdminProjectService.getOrderById(id).then((response) => {
      const tempList = response.data?.orderLogs?.map((log) => {
        log.text = getLogText(log)
        return log
      })
      setLogDataList(tempList)
    })
  }
  const addNoteModalOpen = (rowData) => {
    setShowAddNoteModal(true)
    if (rowData) {
      setValue('name', rowData.name)
      setValue('id', rowData.id)
    } else {
      reset()
    }
  }

  const discountModalOpen = (rowData) => {
    setShowDiscountModal(true)
    if (rowData) {
      setValue('discount', rowData.msrp[0].discount)
      setValue('id', rowData.id)
      setValue('bienalPrice', rowData.msrp[0].bienalPrice)
    } else {
      reset()
    }
  }
  const prepareNoteFiles = async (fileList) => {
    const promises = fileList.map(async (file) => {
      const encodedFile = await encodeBase64(file)
      const rawEncodedFile = encodedFile.replace('data:' + file.type + ';base64,', '')
      return {
        data: rawEncodedFile,
        extension: [...file.name.split('.')].pop(),
        fileName: file.name,
      }
    })

    const preparedNoteFiles = await Promise.all(promises)
    return preparedNoteFiles
  }

  const onSubmitNote = async (data) => {
    // Check if all files are accepted
    const isAllFilesAccepted = uploadedNoteFiles.every((file) => {
      return acceptedFileTypes.includes(file.type) || acceptedFileTypes.includes(file.name.split('.').pop())
    })

    if (!isAllFilesAccepted) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Only ' + acceptedFileTypes.join(', ') + ' files are accepted',
        life: 3000,
      })
      fileUploadRef.current.clear()
      setUploadedNoteFiles([])
      return
    }

    // Check if there are more than 5 files
    if (uploadedNoteFiles.length > 5) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'You can upload maximum 5 files',
        life: 3000,
      })
      return
    }

    data.files = await prepareNoteFiles(uploadedNoteFiles)
    if (data.id) {
      editNote(data)
    } else {
      AdminProjectService.addNote(id, data)
        .then((response) => {
          setShowAddNoteModal(false)
          setLoading(true)
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully added', life: 3000 })
        })
        .catch((err) => {
          toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
        })
    }
  }

  const onSubmitDiscount = async (data) => {
    const req = { discount: data.discount }
    AdminProjectService.saveDiscount(data.id, req)
      .then((res) => {
        setShowDiscountModal(false)
        setLoading(true)
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully added', life: 3000 })
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  const onSubmitUploadBom = async (file, useBomPrice) => {
    const roomRowData = getValues('roomRowData')
    const form = new FormData()
    form.append('file', file)
    form.append('useBomPrice', useBomPrice)
    setIsDialogButtonLoading(true)
    AdminProjectService.saveBom(roomRowData.id, form, useBomPrice)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Successfully saved BOM file',
          life: 3000,
        })
        setIsDialogButtonLoading(false)
        setStepperActiveIndex(1)
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
        setIsDialogButtonLoading(!isDialogButtonLoading)
        console.log(err)
      })
  }

  const onSubmitCalculateBom = async (file) => {
    const roomRowData = getValues('roomRowData')
    const form = new FormData()
    form.append('file', file)
    setIsDialogButtonLoading(true)
    AdminProjectService.calculateBomPrice(roomRowData.id, form)
      .then((response) => {
        setBomCalcData(response?.data)
        setIsDialogButtonLoading(false)
        const bomFileMsrp = parseFloat(response?.data?.bomFileMsrp)
        const partnerMsrp = parseFloat(response?.data?.partnerMsrp)
        setIsSamePrice(bomFileMsrp === partnerMsrp)
        setIsBomCalc(true)
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
        setIsDialogButtonLoading(!isDialogButtonLoading)
      })
  }

  const onSubmitUploadProductionDesign = async (file) => {
    const encodedFile = await encodeBase64(file)
    const rawEncodedFile = encodedFile.replace('data:application/pdf;base64,', '')
    const roomRowData = getValues('roomRowData')
    const req = { data: rawEncodedFile, extension: 'pdf', fileName: file?.name }
    setIsDialogButtonLoading(true)
    AdminProjectService.saveDesignFile(roomRowData.id, req)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Successfully saved DESIGN file',
          life: 3000,
        })
        setIsDialogButtonLoading(false)
        setShowAddProductionDesign(false)
        setBomCalcData(null)
        setIsSamePrice(false)
        setIsBomCalc(false)
        setLoading(true)
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
        setIsDialogButtonLoading(!isDialogButtonLoading)
        console.log(err)
      })
  }

  const onClickShowProductionDesign = (rowData) => {
    setStepperActiveIndex(0)
    setValue('roomRowData', rowData)
    setShowAddProductionDesign(true)
  }

  const onClickShowPartList = (rowData) => {
    setValue('roomRowData', rowData)
    setShowPartListModal(true)
  }

  const onClickShowDesignFormModal = async (rowData) => {
    setValue('roomRowData', rowData)
    let designForm = []
    if (rowData?.designForms !== null) {
      designForm = await initDesignFormContent(rowData?.designForms)
    }
    if (rowData?.designForms === null) {
      designForm = await initDesignFormContent([])
    }

    let list = []
    for (const [key, value] of Object.entries(designForm)) {
      let valueText = '-'
      let colorText = ''

      if (designForm[key].value === 'CONSTANT_YESNO_YES') valueText = t('general.' + 'Yes')
      else if (designForm[key].value === 'CONSTANT_YESNO_NO') valueText = t('general.' + 'No')
      else if (
        designForm[key].value &&
        designForm[key].value !== '-' &&
        designForm[key].group &&
        designFormContent
          .filter((x) => x.group === designForm[key].group)[0]
          .values.filter((x) => x.id === designForm[key].value).length
      )
        valueText = designFormContent
          .filter((x) => x.group === designForm[key].group)[0]
          .values.filter((x) => x.id === designForm[key].value)[0].label

      if (
        designForm[key].other &&
        designForm[key].other !== '-' &&
        designForm[key].colorGroup &&
        designFormContent
          .filter((x) => x.group === designForm[key].colorGroup)[0]
          .values.filter((x) => x.id === designForm[key].other).length
      )
        colorText = designFormContent
          .filter((x) => x.group === designForm[key].colorGroup)[0]
          .values.filter((x) => x.id === designForm[key].other)[0].label
      else if (designForm[key].isColor && valueText !== '-' && !designForm[key].other) {
        colorText = valueText
        valueText = t('general.' + 'colorSelected')
      } else if (designForm[key].isColor && valueText !== '-' && designForm[key].other) {
        colorText = designForm[key].other
      }

      list.push({
        key: key,
        quantity: designForm[key].quantity,
        value: designForm[key].value,
        valueText: valueText,
        other: designForm[key].other,
        color: designForm[key].other,
        colorText: colorText,
      })
    }
    setDesignFileDataList(list)
    setShowDesignFormModal(true)
  }

  const onClickShowBomDetailModal = (rowIndex) => {
    const roomData = getValues('roomRowData')
    AdminProjectService.getBomDetailByOrderRoomId(roomData.id).then((response) => {
      const bomDetailData =
        response.data && response.data.length > 0 ? response.data.filter((el) => el.version === rowIndex)[0] : null
      setBomDetailDataList(bomDetailData)
      setShowBomDetailModal(true)
    })
  }

  const onShowDesignFilesModal = (rowData) => {
    setValue('Roomname', rowData?.name)
    setShowDesignFilesModal(true)
    setRoomDesignFiles(rowData?.designFiles)
  }

  const onShowDesignFilesModalIfFilesUploadedFirstRoom = (rowData) => {
    setValue('Roomname', rowData?.name)
    setShowDesignFilesModalIfFilesUploadedFirstRoomModal(true)
  }
  const onClickShowProductionDesigns = (rowData) => {
    setValue('roomRowData', rowData)
    setDesignFormFilesList(rowData.designFormFiles)
    setShowProductionDesignModal(true)
  }

  const onHideUploadModal = () => {
    setShowAddProductionDesign(false)
    setBomCalcData(null)
    setIsSamePrice(false)
    setIsBomCalc(false)
  }

  const onUploadedDocumentsClick = () => setIsUploadedDocumentsDialogVisible(true)
  const onHideUploadedDocumentsDialog = () => setIsUploadedDocumentsDialogVisible(false)
  const orderStatusListUploadedDocuments = [
    'WAITING_FOR_PREPAYMENT',
    'WAITING_FOR_PRODUCTION_PLANNING',
    'PRODUCTION_PLANNED',
    'PRODUCTION_STARTED',
    'PRODUCTION_FINISHED',
    'READY_FOR_PACKING',
    'PACKING',
    'READY_FOR_PALLETIZING',
    'BEING_PALLETIZED',
    'READY_TO_LOAD',
    'LOADED',
    'UNLOADED',
    'DONE',
  ]

  const headerLog = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t('project.order.title.Log')}</h5>
      <div className="flex">
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setLogFilter(e.target.value)} placeholder={t('general.search')} />
        </span>
      </div>
    </div>
  )

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t('project.order.title.Room')}</h5>
      <div className="flex">
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
        </span>
      </div>
    </div>
  )

  const headerDesignForm = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t('general.review')}</h5>
      <div className="flex">
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilterForDesignForm(e.target.value)}
            placeholder={t('general.search')}
          />
        </span>
      </div>
    </div>
  )

  const designFilesHeader = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t('project.order.title.design-files')}</h5>
      <div className="flex">
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilterForRoomDesignFiles(e.target.value)}
            placeholder={t('general.search')}
          />
        </span>
      </div>
    </div>
  )

  const headerNote = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t('project.order.title.Note')}</h5>
      <div className="flex">
        <Button
          label={t('general.newNote')}
          icon="pi pi-plus"
          onClick={() => addNoteModalOpen(null)}
          className="p-button-success "
        />
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilterForNote(e.target.value)}
            placeholder={t('general.search')}
          />
        </span>
      </div>
    </div>
  )

  const getLogText = (rowData) => {
    var translate = t(rowData.keyMap.langKey)
    let changetranslate = i18next.t(translate, { rowData })

    return changetranslate
  }
  const actionConditionalButtons = (rowData, items) => {
    let isUploaded =
      rowData?.msrp[0]?.cvMsrp !== null &&
      rowData?.designFormFiles.length !== 0 &&
      !rowData?.designFormFiles[rowData?.designFormFiles.lenght - 1]?.sendApproval
    if (roleController(designerRole) && orderDetail?.orderStatus === OrderStatus.ProductionDesignInProgress) {
      if (isUploaded) {
        items.push({
          label: 'Edit Production Design',
          icon: 'pi pi-upload',
          command: () => onClickShowProductionDesign(rowData),
        })
      } else {
        items.push({
          label: 'Add Production Design',
          icon: 'pi pi-upload',
          command: () => onClickShowProductionDesign(rowData),
        })
      }
    } else if (
      roleController(discountAuthorizedRoles) &&
      statusController(discountVisibilityStatus, orderDetail?.orderStatus)
    ) {
      if (isUploaded) {
        items.push({
          label: 'Discount',
          icon: 'pi pi-percentage',
          command: () => discountModalOpen(rowData),
        })
      }
    }
    if (orderDetail?.orderStatus !== OrderStatus.OrderCreated) {
      items.push({
        label: 'View Design Form',
        icon: 'pi pi-eye',
        command: () => onClickShowDesignFormModal(rowData),
      })
    }

    if (
      // roleController(designsAuthorizedRoles) &&
      statusController(designsVisibilityStatues, orderDetail?.orderStatus)
    ) {
      items.push({
        label: 'View Production Designs',
        icon: 'pi pi-eye',
        command: () => onClickShowProductionDesigns(rowData),
      })
    }

    if (
      roleController(designerRole) &&
      statusController([OrderStatus.ProductionDesignRevisionRequested], orderDetail?.orderStatus)
    ) {
      items.push({
        label: 'New Production Design',
        icon: 'pi pi-upload',
        command: () => onClickShowProductionDesign(rowData),
      })
    }

    if (
      roleController(partAuthorizedRoles) &&
      statusController([OrderStatus.ProductionFinished], orderDetail?.orderStatus) &&
      (orderDetail?.assignedUser?.id === user?.id ||
        assignedUser?.manager?.id === user?.id ||
        roleController([Role.Admin]))
    ) {
      items.push({
        label: 'Part List',
        icon: 'pi pi-upload',
        command: () => onClickShowPartList(rowData),
      })
    }

    return items
  }

  const viewProductionDesignActionBodyTemplate = (rowData) => {
    let items = []
    const fileName = rowData?.name.split('.')[0]
    items.push(
      {
        label: 'View Bom Detail',
        icon: 'pi pi-eye',
        command: () => onClickShowBomDetailModal(rowData?.version),
      },
      {
        label: 'Production Design',
        icon: 'pi pi-download',
        command: () => downloader(rowData?.link, fileName),
      }
    )
    return (
      <div className="col-12 flex flex-row justify-content-end">
        <SplitButton label={'Actions'} model={items} />
      </div>
    )
  }

  const actionBodyTemplate = (rowData, index) => {
    let items = []

    if ((rowData?.designFiles && rowData?.designFiles.length !== 0) || rowData?.allFileUploadedToFirstRoom) {
      items.push({
        label: t('general.design-files'),
        icon: 'pi pi-eye',
        command: () =>
          rowData?.designFiles && rowData?.designFiles.length !== 0
            ? onShowDesignFilesModal(rowData)
            : onShowDesignFilesModalIfFilesUploadedFirstRoom(rowData),
      })
    }
    items = actionConditionalButtons(rowData, items)
    return <SplitButton label={'Actions'} model={items} />
  }

  // Date time helper from partner
  const dateFormatter = (date) => {
    let dt = new Date(date)
    return `${(dt.getMonth() + 1).toString().padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}-${dt
      .getFullYear()
      .toString()
      .padStart(4, '0')}
              ${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`
  }

  const noteBodyTemplate = (rowData) => {
    return (
      <span>
        {rowData.note}
        {rowData.files.length > 0 ? (
          <div style={{ margin: '5px 0' }}>
            <b style={{ display: 'block' }}>{t('order.note.fileList.title')}</b>
            {rowData.files.map((file) => (
              <a key={file.fileLink} style={{ display: 'block' }} href={file.fileLink}>
                {file.fileName}
              </a>
            ))}
          </div>
        ) : (
          ''
        )}
        {rowData.createDate !== rowData.updateDate ? (
          <i>
            {' '}
            ({t('project.order.note.update')} {dateFormatter(rowData.updateDate)})
          </i>
        ) : (
          ''
        )}
      </span>
    )
  }

  const actionBodyTemplateNote = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-secondary"
          onClick={() => editNoteModalOpen(rowData)}
          style={{ marginRight: '5px' }}
          aria-label={t('general.edit')}
          disabled={!rowData.allowUpdate}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => confirmDeleteNote(rowData)}
          disabled={!rowData.allowUpdate}
        />
      </>
    )
  }

  // edit Note
  const editNoteModalOpen = (rowData) => {
    setShowEditNoteModal(true)
    if (rowData) {
      setValue('id', rowData.id)
      setValue('note', rowData.note)
    } else {
      reset()
    }
  }

  /* DELETE Note*/
  const confirmDeleteNote = (rowData) => {
    setValue('NoteId', rowData.id)
    setDeleteNoteDialog(true)
  }

  const hideDeleteNoteDialog = () => {
    setDeleteNoteDialog(false)
  }

  const editNote = async (data) => {
    AdminProjectService.updateNote(data.id, data)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Success Successfully updated',
          life: 3000,
        })
        setShowEditNoteModal(false)
        getNotes()
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  const deleteNote = async (rowData) => {
    AdminProjectService.deleteNote(rowData)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Success Successfully deleted',
          life: 3000,
        })
        setDeleteNoteDialog(false)
        getNotes()
        getOrderDetails()
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  return (
    <div className="grid crud-demo">
      <Toast ref={toast} />
      <div className="lg:col-4 md:col-6 sm:col-12 col-12">
        <OrderDetailSummary orderDetail={orderDetail} dateFormatBodyTemplate={dateFormatWithTime} />
      </div>

      <div className=" lg:col-8 md:col-6 sm:col-12 col-12">
        <div className="card">
          <AdminDatatable
            dataList={dataList}
            globalFilter={globalFilter}
            header={header}
            rows={10}
            pages={[10, 20, 30, 40, 50]}
            loading={loading}>
            <Column
              field="code"
              header={t('project.order.table.roomcode')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="name"
              header={t('partlist.table.roomname')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              body={(rowData) => rowData?.msrp[0]?.msrp}
              header={t('project.order.table.msrp')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              body={(rowData) => rowData?.msrp[0]?.bienalPrice}
              header={t('project.order.table.bienalPrice')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              body={(rowData, { rowIndex }) => actionBodyTemplate(rowData, rowIndex)}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          </AdminDatatable>
        </div>
      </div>

      <div className="col-12 flex align-items-center flex-wrap justify-content-end">
        {orderStatusListUploadedDocuments.includes(orderDetail?.orderStatus) === true &&
        orderDetail?.hasOrderFile === true ? (
          <Button
            type="button"
            label={t('project.order.button.uploadedDocuments')}
            onClick={onUploadedDocumentsClick}
            // onClick={() => console.log('clicked')}
            className="p-button-primary ml-2 mb-2"
          />
        ) : null}
        <AssignUser
          loading={loading}
          setLoading={setLoading}
          toast={toast}
          orderStatus={orderDetail?.orderStatus}
          assignedUser={orderDetail?.assignedUser}
        />
        <CommonCamButton
          orderStatus={orderDetail?.orderStatus}
          toast={toast}
          setLoading={setLoading}
          roomList={dataList}
          assignedUser={orderDetail?.assignedUser}
          assignedUserManagerId={assignedUser?.manager?.id}
        />
      </div>

      <div className="col-12">
        <TabView>
          <TabPanel header={t('project.order.table.Note')}>
            <div className="card">
              <AdminDatatable
                dataList={noteDataList}
                globalFilter={globalFilterForNote}
                header={headerNote}
                rows={10}
                pages={[10, 20, 30, 40, 50]}
                loading={loading}>
                <Column
                  field="note"
                  header={t('project.order.table.Note')}
                  body={noteBodyTemplate}
                  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                <Column
                  field="creatorUser"
                  header={t('project.order.table.creatorUser')}
                  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                <Column
                  field="createDate"
                  header={t('project.order.table.createDate')}
                  body={(rowData) => dateFormatter(rowData?.createDate)}
                  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                <Column
                  field="action"
                  header={t('order.table.action')}
                  body={actionBodyTemplateNote}
                  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
              </AdminDatatable>
            </div>
          </TabPanel>
          <TabPanel header={t('project.order.table.History')}>
            {/* LOGS */}
            <div className="card">
              <Toast ref={toast} />
              <AdminDatatable
                dataList={logdataList}
                rows={10}
                pages={[5, 10, 25, 100, 200]}
                header={headerLog}
                globalFilter={logFilter}
                loading={loading}>
                <Column
                  field="text"
                  header={t('project.order.table.Activity')}
                  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                <Column
                  field="actionUser.username"
                  header={t('project.order.table.creatorUser')}
                  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                <Column
                  field="createDate"
                  header={t('project.order.table.createDate')}
                  body={(rowData) => dateFormatWithTime(rowData, 'createDate')}
                  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
              </AdminDatatable>
            </div>
          </TabPanel>
        </TabView>
      </div>
      <Dialog
        header={t('general.modal.title.new')}
        visible={showAddNoteModal}
        style={{ width: '50vw' }}
        onHide={() => setShowAddNoteModal(false)}>
        <form onSubmit={handleSubmit(onSubmitNote)} className="grid p-fluid">
          <div className="col-12 md:col-12">
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name="note"
                  control={control}
                  rules={{ required: 'Note is required.' }}
                  render={({ field, fieldState }) => (
                    <InputTextarea
                      id={field.name}
                      {...field}
                      placeholder="Note"
                      rows={5}
                      cols={70}
                      autoFocus
                      className={classNames({ 'p-invalid': fieldState.invalid })}
                    />
                  )}
                />
              </span>
              {getFormErrorMessage('note')}
            </div>
          </div>
          <div className="col-12 md:col-12">
            <div className="field">
              <div className="card">
                <Controller
                  name="files"
                  control={control}
                  render={({ field, fieldState, formState }) => {
                    return (
                      <FileUpload
                        id={field.name}
                        {...field}
                        ref={fileUploadRef}
                        accept={'.' + acceptedFileTypes.join(', .')}
                        headerTemplate={headerTemplate}
                        disabled={uploadedNoteFiles.length >= 5}
                        multiple={true}
                        maxFileSize={50000000}
                        onSelect={onTemplateSelect}
                        onError={onTemplateClear}
                        onRemove={onTemplateRemove}
                        itemTemplate={itemTemplate}
                        emptyTemplate={emptyTemplate}
                      />
                    )
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-6 mt-2 md:col-6">
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name="hidden"
                  control={control}
                  render={({ field, fieldState }) => (
                    <ToggleButton
                      onLabel="Private"
                      offLabel="Public"
                      onIcon="pi pi-check"
                      offIcon="pi pi-times"
                      checked={getValues('hidden')}
                      onChange={(e) => setValue('hidden', e.value)}
                      className={classNames({ 'p-invalid': fieldState.invalid })}
                    />
                  )}
                />
              </span>
            </div>
          </div>
          <div className="col-6 md:col-6">
            <Button type="submit" label="Save" className="mt-2" />
          </div>
        </form>
      </Dialog>
      <Dialog
        header={t('general.modal.title.new')}
        visible={showAddProductionDesign}
        style={{ width: '50vw' }}
        onHide={onHideUploadModal}>
        <div className="flex flex-column">
          <Steps
            className="mb-4"
            model={stepperItems}
            activeIndex={stepperActiveIndex}
            onSelect={(e) => setStepperActiveIndex(e.index)}
          />
          {stepperActiveIndex === 0 ? (
            <AdminFileupload
              uploader={onSubmitUploadBom}
              uploadType={AdminFileUploadType.BOM}
              bomCalcFunc={onSubmitCalculateBom}
              isBomCalc={isBomCalc}
              isSamePrice={isSamePrice}
              bomCalcData={bomCalcData}
              isButtonLoading={isDialogButtonLoading}
            />
          ) : null}
          {stepperActiveIndex === 1 ? (
            <AdminFileupload
              uploader={onSubmitUploadProductionDesign}
              uploadType={AdminFileUploadType.PRODUCTION_DESIGN}
              isButtonLoading={isDialogButtonLoading}
            />
          ) : null}
        </div>
      </Dialog>
      <Dialog
        header={t('general.modal.title.new')}
        visible={showDiscountModal}
        style={{ width: '50vw' }}
        onHide={() => setShowDiscountModal(false)}>
        <form onSubmit={handleSubmit(onSubmitDiscount)} className="grid p-fluid">
          <div className="col-12 md:col-12">
            <div className="field">
              <label htmlFor="name" className={classNames({ 'p-error': errors.name })}></label>
              <span className="p-float-label">
                <Controller
                  name="discount"
                  control={control}
                  rules={{ required: 'Discount is required.' }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      id={field.name}
                      {...field}
                      onChange={(e) => {
                        if (e.value === null) {
                          field.onChange('')
                        } else {
                          field.onChange(parseFloat(e.value))
                        }
                      }}
                      placeholder="Discount"
                      autoFocus
                      mode="decimal"
                      maxFractionDigits={2}
                      min={0}
                      max={getValues('bienalPrice')}
                      className={classNames({ 'p-invalid': fieldState.invalid })}
                    />
                  )}
                />
              </span>
              {getFormErrorMessage('discount')}
            </div>
          </div>
          <Button type="submit" label="Save" className="mt-2" />
        </form>
      </Dialog>
      <Dialog
        header={getValues('roomRowData')?.name + ' - ' + t('general.modal.title.design')}
        visible={showDesignFormModal}
        style={{ width: '50vw' }}
        onHide={() => setShowDesignFormModal(false)}>
        <Accordion activeIndex={0}>
          <AccordionTab header="Partner Note">
            <p>{getValues('roomRowData')?.partnerNote}</p>
          </AccordionTab>
          <AccordionTab
            header={t('general.modal.title.design')}
            disabled={getValues('roomRowData')?.productType?.name === 'CUSTOM CABINET'}>
            <AdminDatatable
              dataList={designFileDataList}
              rows={10}
              pages={[10, 20, 30, 40, 50]}
              globalFilter={globalFilterForDesignForm}
              header={headerDesignForm}>
              <Column
                field={'key'}
                body={(rowData) => t('general.modal.title.design.' + rowData.key)}
                header={t('project.order.table.orderDescription')}
                headerStyle={{ width: '14%', minWidth: '10rem' }}
              />
              <Column
                field="valueText"
                header={t('project.order.table.value')}
                headerStyle={{ width: '14%', minWidth: '10rem' }}
              />
              <Column
                field="colorText"
                header={t('project.order.table.color')}
                headerStyle={{ width: '14%', minWidth: '10rem' }}
              />
              <Column
                field={'quantity'}
                header={t('project.order.table.quantity')}
                headerStyle={{ width: '14%', minWidth: '10rem' }}
              />
            </AdminDatatable>
          </AccordionTab>
        </Accordion>
      </Dialog>
      <Dialog
        header={getValues('roomRowData')?.name + ' - ' + 'Bom Detail'}
        visible={showBomDetailModal}
        style={{ width: '50vw' }}
        onHide={() => setShowBomDetailModal(false)}>
        <AdminDatatable
          dataList={bomDetailDataList.bomDetailList}
          rows={10}
          pages={[10, 20, 30, 40, 50]}
          globalFilter={globalFilterForDesignForm}
          header={headerDesignForm}>
          <Column field="cvId" header={'Item Code'} headerStyle={{ width: '14%', minWidth: '10rem' }} />
          <Column field="quantity" header={'Qty'} headerStyle={{ width: '14%', minWidth: '10rem' }} />
          <Column field="unit" header={'Unit'} headerStyle={{ width: '14%', minWidth: '10rem' }} />
          <Column field="itemName" header={'Item Name'} headerStyle={{ width: '14%', minWidth: '10rem' }} />
          <Column field="totalPrice" header={'Price'} headerStyle={{ width: '14%', minWidth: '10rem' }} />
        </AdminDatatable>
        <div className="col-12 flex flex-row justify-content-end">Total Price: {bomDetailDataList.totalPrice}</div>
      </Dialog>
      <Dialog
        header={'View Production Design'}
        visible={showProductionDesignModal}
        style={{ width: '50vw' }}
        onHide={() => setShowProductionDesignModal(false)}>
        <AdminDatatable
          dataList={designFormFilesList}
          rows={10}
          pages={[10, 20, 30, 40, 50]}
          globalFilter={globalFilterForDesignForm}
          header={headerDesignForm}>
          <Column field="version" header={'Version'} headerStyle={{ width: '14%', minWidth: '10rem' }} />
          <Column
            body={viewProductionDesignActionBodyTemplate}
            headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
        </AdminDatatable>
      </Dialog>
      <Dialog
        header={t('partlist.title')}
        visible={showPartListModal}
        style={{ width: '50vw' }}
        onHide={() => setShowPartListModal(false)}>
        <AdminPartList
          rowData={getValues('roomRowData')}
          setLoading={setLoading}
          toast={toast}
          setShowPartListModal={setShowPartListModal}
          showPartListModal={showPartListModal}
          orderName={orderDetail?.name}
        />
      </Dialog>
      <Dialog
        visible={showDesignFilesModal}
        style={{ width: '50vw' }}
        header={getValues('Roomname') + ' - ' + t('project.order.design-files')}
        modal
        onHide={() => setShowDesignFilesModal(false)}>
        <AdminDatatable
          dataList={roomDesignFiles}
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 100, 200]}
          globalFilter={globalFilterForRoomDesignFiles}
          header={designFilesHeader}
          loading={loading}>
          <Column
            field="fileName"
            body={(rowData) => (
              <p>
                <a href={rowData.fileLink}>{rowData.fileName}</a>
              </p>
            )}
            header={t('project.order.table.fileName')}
            headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
        </AdminDatatable>
      </Dialog>
      <Dialog
        visible={deleteNoteDialog}
        style={{ width: '450px' }}
        header="Confirm"
        modal
        onHide={hideDeleteNoteDialog}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {<span>Are you sure you want to delete note?</span>}
        </div>
        <footer style={{ textAlign: 'right' }}>
          <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteNoteDialog} />
          <Button
            label="Yes"
            icon="pi pi-check"
            className="p-button-text"
            onClick={() => deleteNote(getValues('NoteId'))}
          />
        </footer>
      </Dialog>

      <Dialog
        header={t('general.modal.title.note')}
        visible={showEditNoteModal}
        style={{ width: '50vw' }}
        onHide={() => setShowEditNoteModal(false)}>
        <div>
          <div className="">
            <form onSubmit={handleSubmit(onSubmitNote)} className="grid p-fluid">
              <div className="col-12 md:col-12">
                <div className="field">
                  <label htmlFor="note" className={classNames({ 'p-error': errors.name })}></label>
                  <span className="p-float-label">
                    <Controller
                      name="note"
                      control={control}
                      rules={{ required: 'Note is required.' }}
                      render={({ field, fieldState }) => {
                        return (
                          <InputTextarea
                            id={field.name}
                            {...field}
                            rows={5}
                            cols={70}
                            autoFocus
                            className={classNames({ 'p-invalid': fieldState.invalid })}
                          />
                        )
                      }}
                    />
                  </span>
                  {getFormErrorMessage('Note')}
                </div>
              </div>
              <Button type="submit" label="Save" className="mt-2" />
            </form>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={showDesignFilesModalIfFilesUploadedFirstRoomModal}
        style={{ width: '50vw' }}
        header={getValues('Roomname') + ' - ' + t('project.order.design-files')}
        modal
        onHide={() => setShowDesignFilesModalIfFilesUploadedFirstRoomModal(false)}>
        <div>
          <p>{t('project.order.design-files-uploaded-first-room')}</p>
        </div>
      </Dialog>
      <UploadedDocumentsDialog
        visible={isUploadedDocumentsDialogVisible}
        onHide={onHideUploadedDocumentsDialog}
        orderId={orderDetail?.id}
      />
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(MyOrderDetail, comparisonFn)
