import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { SplitButton } from 'primereact/splitbutton'
import { Checkbox } from 'primereact/checkbox'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import OrderService from '../../../service/OrderService'
import PalleteService from '../../../service/PalleteService'
import NewEditPalleteModal from './components/NewEditPalleteModal'
import AddOrderModal from './components/AddOrderModal'
import RemoveOrderModal from './components/RemoveOrderModal'
import AddBoxesModal from './components/AddBoxesModal'
import ExcludeBoxesModal from './components/ExcludeBoxesModal'
import PalleteBoxesModal from './components/PalleteBoxesModal'
import OrdersModal from './components/OrdersModal'
import PalleteBarcodeModal from './components/PalleteBarcodeModal'
import ConfirmationToChangeStateModal from './components/ConfirmationToChangeStateModal'
import { SortOrder } from '../../../helpers/sort-type'

const PreparePalletes = () => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [showAddOrderModal, setShowAddOrderModal] = useState(false)
  const [removeOrderModal, setRemoveOrderModal] = useState(false)
  const [showAddBoxesModal, setShowAddBoxesModal] = useState(false)
  const [showExcludeBoxesModal, setShowExcludeBoxesModal] = useState(false)
  const [showBarcodeModal, setShowBarcodeModal] = useState(false)
  const [showPalleteBoxesModal, setShowPalleteBoxesModal] = useState(false)
  const [shippedPalletesIncluded, setShippedPalletesIncluded] = useState(false)
  const [showReadyForPalletizingOrdersModal, setShowReadyForPalletizingOrdersModal] = useState(false)
  const [showConfirmationToChangeStateModal, setShowConfirmationToChangeStateModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [availableOrders, setAvailableOrders] = useState([])
  const [selectedOrders, setSelectedOrders] = useState([])
  const [palletes, setPalletes] = useState([])
  const [palletesFilter, setPalletesFilter] = useState('')

  const mountedRef = useRef(false)
  const dt = useRef(null)
  const toast = useRef(null)
  const { t } = useTranslation()

  const getPalletes = useCallback(() => {
    if (shippedPalletesIncluded) {
      if (mountedRef.current === true) setLoading(true)
      PalleteService.getPalletesWithShipped()
        .then((response) => {
          if (mountedRef.current === true) {
            setPalletes(response.data)
          }
        })
        .catch((err) => {
          toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
        })
        .finally(() => {
          if (mountedRef.current === true) setLoading(false)
        })
    } else {
      if (mountedRef.current === true) setLoading(true)
      PalleteService.getPalletesWithoutShipped()
        .then((response) => {
          if (mountedRef.current === true) {
            setPalletes(response.data)
          }
        })
        .catch((err) => {
          toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
        })
        .finally(() => {
          if (mountedRef.current === true) setLoading(false)
        })
    }
  }, [shippedPalletesIncluded])

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (mountedRef.current === true) {
      getPalletes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippedPalletesIncluded])

  useEffect(() => {
    if (showAddOrderModal) {
      setLoading(true)
      OrderService.getOrdersAvailableForPalletizing()
        .then((response) => {
          setAvailableOrders(
            response.data?.filter((order) => !selectedOrders.map((so) => so.code).includes(order.code))
          )
        })
        .catch((err) => {
          toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setAvailableOrders([])
    }
  }, [showAddOrderModal, selectedOrders])

  const defaultValues = {
    palleteId: null,
    palleteName: '',
    palleteBarcode: '',
    boxBarcode: '',
  }
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    resetField,
    setValue,
    getValues,
  } = useForm({ defaultValues: defaultValues })

  const dateFormatter = (date) => {
    let dt = new Date(date)
    return `${(dt.getMonth() + 1).toString().padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}-${dt
      .getFullYear()
      .toString()
      .padStart(4, '0')}
              ${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`
  }

  const dateFormatterForSorting = (date) => {
    let dt = new Date(date)
    return `${dt.getFullYear().toString().padStart(4, '0')}${(dt.getMonth() + 1).toString().padStart(2, '0')}${dt
      .getDate()
      .toString()
      .padStart(2, '0')}${dt.getHours().toString().padStart(2, '0')}${dt.getMinutes().toString().padStart(2, '0')}`
  }

  const dateSorter = (event) => {
    let sortedPalleteList = [...palletes]

    sortedPalleteList.sort((pallete1, pallete2) => {
      const date1 = dateFormatterForSorting(pallete1[event.field])
      const date2 = dateFormatterForSorting(pallete2[event.field])

      let result = null

      if (date1 == null && date2 != null) result = -1
      else if (date1 != null && date2 == null) result = 1
      else if (date1 == null && date2 == null) result = 0
      else if (typeof date1 === 'string' && typeof date2 === 'string')
        result = date1.localeCompare(date2, undefined, { numeric: true })
      else result = date1 < date2 ? -1 : date1 > date2 ? 1 : 0

      return event.order * result
    })

    return sortedPalleteList
  }

  const selectItem = (_id) => {}

  const editModalOpen = (rowData) => {
    setShowEditModal(true)
    if (rowData) {
      setValue('palleteId', rowData.id)
      setValue('palleteName', rowData.paletteName)
      setValue('palleteBarcode', rowData.barcode)
      setSelectedOrders(rowData.orders)
    } else {
      reset()
      setSelectedOrders([])
    }
  }

  const exportCSV = (ref) => {
    ref.current.exportCSV()
  }

  const setCurrentPalleteBoxList = (pallete) => {
    const boxes = pallete.orders.reduce((acc, order) => {
      return [
        ...acc,
        ...order.orderPackings.map((orderPacking) => {
          return {
            orderName: order.name,
            roomName: orderPacking.chapterName,
            boxBarcode: orderPacking.barcode,
          }
        }),
      ]
    }, [])

    setValue('palleteBoxList', boxes)
  }

  const editPallete = async (data) => {
    setLoading(true)
    PalleteService.updatePallete(
      {
        orderCodes: selectedOrders.map((order) => order.code),
        paletteBarcode: data.palleteBarcode,
        paletteName: data.palleteName,
      },
      data.palleteId
    )
      .then((response) => {
        setShowEditModal(false)
        setPalletes([...palletes.filter((pallete) => pallete.id !== response.data.id), response.data])
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully updated', life: 3000 })
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response?.data?.message, life: 3000 })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const addNewPallete = async (data) => {
    setLoading(true)
    PalleteService.createPallete({
      orderCodes: selectedOrders.map((order) => order.code),
      paletteBarcode: data.palleteBarcode,
      paletteName: data.palleteName,
    })
      .then((response) => {
        setShowEditModal(false)
        setPalletes([...palletes, response.data])
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully added', life: 3000 })
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response?.data?.message, life: 3000 })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const confirmRemoveOrder = (rowData) => {
    if (rowData?.orderPackings?.length > 0) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: t('palletes.remove.order.hasBox.error'),
        life: 3000,
      })
    } else {
      setValue('orderCodeToRemove', rowData.code)
      setRemoveOrderModal(true)
    }
  }

  const hideRemoveOrderModal = () => {
    setRemoveOrderModal(false)
    setValue('orderCodeToRemove', '', { shouldValidate: false, shouldDirty: false, shouldTouch: false })
  }

  const removeOrder = async (removedOrderCode) => {
    setSelectedOrders((prev) => prev.filter((order) => order.code !== removedOrderCode))
    hideRemoveOrderModal()
  }

  const confirmReadyToLoad = async () => {
    try {
      const order = getValues('orderToNextStatus')
      if (order !== null) {
        setLoading(true)
        await OrderService.changeStatus(order.orderId, {
          orderStatus: order.orderStatus,
        })

        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Changed Status', life: 3000 })

        const getOrdersWithStatusResult = await OrderService.getOrdersStatusList([
          'READY_FOR_PALLETIZING',
          'BEING_PALLETIZED',
        ])

        setValue(
          'readyForPalletizingOrders',
          getOrdersWithStatusResult.data.map((ord) => {
            return {
              orderId: ord.id,
              orderCode: ord.code,
              orderName: ord.name,
              palletizedBoxCount: ord.palletizedPackageCount,
              boxCount: ord.packageCount,
              orderStatus: ord.orderStatus,
            }
          })
        )
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: error.response.data.message,
        life: 3000,
      })
    } finally {
      setLoading(false)
      setShowConfirmationToChangeStateModal(false)
    }
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0 mb-2">{t('palletes.title')}</h5>
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-start">
        <Button
          type="button"
          label={t('palletes.button.orders')}
          className="p-button-primary mr-2 ml-2 mb-2"
          onClick={() => {
            setShowReadyForPalletizingOrdersModal(true)
            setLoading(true)
            OrderService.getOrdersStatusList(['READY_FOR_PALLETIZING', 'BEING_PALLETIZED'])
              .then((response) => {
                setValue(
                  'readyForPalletizingOrders',
                  response.data.map((order) => {
                    return {
                      orderId: order.id,
                      orderCode: order.code,
                      orderName: order.name,
                      palletizedBoxCount: order.palletizedPackageCount,
                      boxCount: order.packageCount,
                      orderStatus: order.orderStatus,
                    }
                  })
                )
              })
              .catch((err) =>
                toast.current.show({
                  severity: 'error',
                  summary: 'Error',
                  detail: err.response?.data?.message,
                  life: 3000,
                })
              )
              .finally(() => setLoading(false))
          }}
        />

        <Button
          type="button"
          label={t('palletes.button.new')}
          icon="pi pi-plus"
          onClick={() => editModalOpen(null)}
          className="p-button-success mr-2 ml-2 mb-2"
        />
        <Button
          type="button"
          label={t('palletes.button.export')}
          icon="pi pi-upload"
          className="p-button-help mr-2 ml-2 mb-2"
          onClick={() => exportCSV(dt)}
        />
        <div>
          <span className="block mt-2 md:mt-0 mb-2 p-input-icon-left ml-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={palletesFilter}
              onInput={(e) => setPalletesFilter(e.target.value)}
              placeholder={t('general.search')}
            />
          </span>

          <div className="block mt-2 md:mt-0 p-input-icon-left ml-2 flex align-items-center">
            <Checkbox
              inputId="shippedCB"
              onChange={(e) => {
                setShippedPalletesIncluded(e.checked)
              }}
              checked={shippedPalletesIncluded}></Checkbox>
            <label htmlFor="shippedCB" className="p-checkbox-label ml-1">
              {t('palletes.checkbox.label')}
            </label>
          </div>
        </div>
      </div>
    </div>
  )

  const palleteTableNumberOfBoxesBodyTemplate = (rowData) => (
    <Button
      type="button"
      className="p-button-warning"
      onClick={() => {
        setShowPalleteBoxesModal(true)
        setValue('palleteName', rowData.paletteName)
        setCurrentPalleteBoxList(rowData)
      }}>
      {t('palletes.button.view')} ({rowData.orders.reduce((acc, order) => acc + order.orderPackings?.length ?? 0, 0)})
    </Button>
  )

  const palleteTableStatusBodyTemplate = (rowData) => <p>{t('palletes.state.' + rowData.status)}</p>

  const palleteTableActionsBodyTemplate = (rowData) => {
    const items = [
      {
        label: t('palletes.button.addBoxes'),
        icon: 'pi pi-plus-circle',
        command: () => {
          setShowAddBoxesModal(true)
          setValue('palleteName', rowData.paletteName)
          setValue('palleteId', rowData.id)
          setCurrentPalleteBoxList(rowData)
        },
        statusList: ['CREATED', 'PREPARING'],
      },
      {
        label: t('palletes.button.excludeBoxes'),
        icon: 'pi pi-minus-circle',
        command: () => {
          setShowExcludeBoxesModal(true)
          setValue('palleteName', rowData.paletteName)
          setValue('palleteId', rowData.id)
          setCurrentPalleteBoxList(rowData)
        },
        statusList: ['PREPARING'],
      },
      {
        label: t('palletes.button.openPallete'),
        icon: 'pi pi-arrow-circle-up',
        command: async () => {
          try {
            setLoading(true)
            const openedPalleteResponse = await PalleteService.addBoxToPallete({
              packageBarcode: 'OPEN',
              palletId: rowData.id,
            })
            const openedPallete = openedPalleteResponse.data

            setPalletes([...palletes.filter((pallete) => pallete.id !== openedPallete.id), openedPallete])
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully opened',
              life: 3000,
            })
          } catch (error) {
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: error.response?.data?.message,
              life: 3000,
            })
          } finally {
            setLoading(false)
          }
        },
        statusList: ['CLOSED'],
      },
      {
        label: t('palletes.button.editPallete'),
        icon: 'pi pi-pencil',
        command: () => {
          editModalOpen(rowData)
        },
        statusList: ['CREATED', 'PREPARING'],
      },
      {
        label: t('palletes.button.barcode'),
        icon: 'pi pi-print',
        command: () => {
          setShowBarcodeModal(true)
          setValue('currentPallete', rowData)
        },
        statusList: ['CREATED', 'PREPARING', 'CLOSED', 'SHIPPED'],
      },
      {
        label: t('palletes.button.deletePallete'),
        icon: 'pi pi-trash',
        command: () => {
          setLoading(true)
          PalleteService.deletePalleteById(rowData.id)
            .then((_response) => {
              setPalletes(palletes.filter((pallete) => pallete.id !== rowData.id))
              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted',
                life: 3000,
              })
            })
            .catch((err) => {
              toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: err.response?.data?.message,
                life: 3000,
              })
            })
            .finally(() => {
              setLoading(false)
            })
        },
        statusList: ['CREATED'],
      },
    ]
    return (
      <SplitButton
        label={t('palletes.button.actions')}
        model={items.filter((item) => item.statusList.includes(rowData.status))}
      />
    )
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={palletes}
            dataKey="id"
            paginator
            rows={100}
            rowsPerPageOptions={[5, 10, 25, 100, 200]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} palletes"
            globalFilter={palletesFilter}
            emptyMessage="No palletes found."
            header={header}
            responsiveLayout="scroll"
            selectionMode="single"
            loading={loading}
            selection={selectItem}
            sortField={'createDate'}
            sortOrder={SortOrder.DESCENDING}>
            <Column
              field="paletteName"
              sortable
              header={t('palletes.table.name')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="barcode"
              sortable
              header={t('palletes.table.barcode')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

            <Column
              field="palleteNumberOfBoxes"
              header={t('palletes.table.numberOfBoxes')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}
              body={palleteTableNumberOfBoxesBodyTemplate}
            />
            <Column
              field="createDate"
              header={t('palletes.table.createDate')}
              sortable
              headerStyle={{ width: '14%', minWidth: '10rem' }}
              body={(rowData) => dateFormatter(rowData.createDate)}
              sortFunction={dateSorter}></Column>
            <Column
              field="updateDate"
              header={t('palletes.table.updateDate')}
              sortable
              headerStyle={{ width: '14%', minWidth: '10rem' }}
              body={(rowData) => dateFormatter(rowData.updateDate)}
              sortFunction={dateSorter}></Column>
            <Column
              field="status"
              header={t('palletes.table.status')}
              sortable
              headerStyle={{ width: '14%', minWidth: '10rem' }}
              body={palleteTableStatusBodyTemplate}></Column>
            <Column
              field="action"
              header={t('palletes.table.actions')}
              body={palleteTableActionsBodyTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          </DataTable>

          <NewEditPalleteModal
            getValues={getValues}
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            handleSubmit={handleSubmit}
            errors={errors}
            control={control}
            selectedOrders={selectedOrders}
            editPallete={editPallete}
            addNewPallete={addNewPallete}
            onAddOrderButtonClick={() => setShowAddOrderModal(true)}
            onRemoveOrderButtonClick={confirmRemoveOrder}
            onPalletizedButtonClick={(closedPallete) => {
              setPalletes([...palletes.filter((pallete) => pallete.id !== closedPallete.id), closedPallete])
              // setCurrentPalleteBoxList(closedPallete)
            }}
          />

          <AddOrderModal
            show={showAddOrderModal}
            onHide={() => setShowAddOrderModal(false)}
            availableOrders={availableOrders}
            loading={loading}
            onAddButtonClick={(row) => {
              setShowAddOrderModal(false)
              setSelectedOrders([...selectedOrders, row])
            }}
          />

          <RemoveOrderModal
            show={removeOrderModal}
            onHide={hideRemoveOrderModal}
            onAccept={() => removeOrder(getValues('orderCodeToRemove'))}
          />

          <AddBoxesModal
            show={showAddBoxesModal}
            onHide={() => setShowAddBoxesModal(false)}
            getValues={getValues}
            errors={errors}
            resetField={resetField}
            control={control}
            handleSubmit={handleSubmit}
            palletes={palletes}
            setPalletes={setPalletes}
            setCurrentPalleteBoxList={setCurrentPalleteBoxList}
          />

          <ExcludeBoxesModal
            getValues={getValues}
            handleSubmit={handleSubmit}
            control={control}
            errors={errors}
            resetField={resetField}
            show={showExcludeBoxesModal}
            onHide={() => setShowExcludeBoxesModal(false)}
            palletes={palletes}
            setPalletes={setPalletes}
            setCurrentPalleteBoxList={setCurrentPalleteBoxList}
          />

          <PalleteBoxesModal
            show={showPalleteBoxesModal}
            onHide={() => setShowPalleteBoxesModal(false)}
            getValues={getValues}
          />

          <OrdersModal
            show={showReadyForPalletizingOrdersModal}
            onHide={() => setShowReadyForPalletizingOrdersModal(false)}
            getValues={getValues}
            onReadyToLoadButtonClick={(order) => {
              setValue('orderToNextStatus', order)
              setShowConfirmationToChangeStateModal(true)
            }}
          />

          <PalleteBarcodeModal
            show={showBarcodeModal}
            getValues={getValues}
            onHide={() => {
              setShowBarcodeModal(false)
              resetField('palleteBarcode')
            }}
          />

          <ConfirmationToChangeStateModal
            show={showConfirmationToChangeStateModal}
            onHide={() => {
              setValue('orderToNextStatus', null)
              setShowConfirmationToChangeStateModal(false)
            }}
            onAccept={confirmReadyToLoad}
            allBoxesPalletized={
              getValues('orderToNextStatus')?.boxCount === getValues('orderToNextStatus')?.palletizedBoxCount
            }
          />
        </div>
      </div>
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(PreparePalletes, comparisonFn)
