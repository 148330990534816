import http from '../ServiceConfig'

class SuggestionService {
  addSuggestion(request) {
    return http.post('/suggestion', request)
  }

  getMySuggestions() {
    return http.get('/suggestion/my-suggestions')
  }

  getSuggestions() {
    return http.get('/suggestion')
  }

  deleteMySuggestion(id) {
    return http.delete(`/suggestion/my-suggestion/${id}`)
  }

  deleteSuggestion(id) {
    return http.delete(`/suggestion/${id}`)
  }

  updateSuggestion(request) {
    const { id, text } = request
    return http.put('/suggestion', { id, text })
  }
}

export default new SuggestionService()
