import { useTranslation } from 'react-i18next'
import BreadcrumbContext from '../breadcrumb/Breadcrumb'
import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState, useContext } from 'react'

const OrderDetailSummary = ({ orderDetail, dateFormatBodyTemplate }) => {
  const { t } = useTranslation()
  const { onChangeLastLabel } = useContext(BreadcrumbContext)
  const [showDialog, setShowDialog] = useState(false)
  const [customerDetail, setCustomerDetail] = useState(null)

  useEffect(() => {
    const orderName = orderDetail?.name
    if (orderName) {
      onChangeLastLabel(orderName)
    }
  }, [orderDetail?.name])

  const hideCustomerInformation = () => {
    setCustomerDetail(null)
    setShowDialog(false)
  }
  const handleCustomerClick = (customer) => {
    setCustomerDetail(customer)
    setShowDialog(true)
  }

  /*Styles*/
  const tableStyle = {
    textAlign: 'left',
    border: '1px solid #dee2e6',
    borderWidth: '0 0 1px 0',
    padding: '1rem 1rem',
    outlineColor: '#C7D2FE',
  }

  return (
    <div className="card">
      <h5 className="card-header">{t('project.order.title.OrderDetails')}</h5>
      <div className="flex flex-row card-row">
        <div className="col-6">
          <b>{t('project.order.table.code')} :</b>
        </div>
        <div className="col-6">{orderDetail?.code}</div>
      </div>
      <div className="flex flex-row card-row">
        <div className="col-6">
          <b>{t('project.order.table.partner')} :</b>
        </div>
        <div className="col-6">{orderDetail?.partner?.name}</div>
      </div>
      <div className="flex flex-row card-row">
        <div className="col-6">
          <b>{t('project.order.table.name')} :</b>
        </div>
        <div className="col-6">{orderDetail?.name}</div>
      </div>
      <div className="flex flex-row card-row">
        <div className="col-6">
          <b>{t('project.order.table.createdDate')} :</b>
        </div>
        <div className="col-6">{dateFormatBodyTemplate(orderDetail, 'createDate')}</div>
      </div>
      <div className="flex flex-row card-row">
        <div className="col-6">
          <b>{t('project.order.table.createdBy')} :</b>
        </div>
        <div className="col-6">{orderDetail?.createBy}</div>
      </div>
      <div className="flex flex-row card-row">
        <div className="col-6">
          <b>{t('project.order.table.status')} :</b>
        </div>
        <div className="col-6">{orderDetail?.orderDisplayStatus}</div>
      </div>
      <div className="flex flex-row card-row">
        <div className="col-6">
          <b>{t('project.order.table.msrp')} :</b>
        </div>
        <div className="col-6">{orderDetail?.msrp}</div>
      </div>
      <div className="flex flex-row card-row">
        <div className="col-6">
          <b>{t('project.order.table.bienalPrice')} :</b>
        </div>
        <div className="col-6">{orderDetail?.bienalPrice}</div>
      </div>
      <div className="flex flex-row card-row">
        <div className="col-6">
          <b>{t('project.order.table.discount')} :</b>
        </div>
        <div className="col-6">{orderDetail?.discount}</div>
      </div>
      <div className="flex flex-row card-row">
        <div className="col-6">
          <b>{t('project.order.table.finalPrice')} :</b>
        </div>
        <div className="col-6">{orderDetail?.finalPrice}</div>
      </div>
      <div className="flex flex-row card-row">
        <div className="col-6">
          <b>{t('order.table.salesTax')} :</b>
        </div>
        <div className="col-6">
          {orderDetail?.invoiceOfOrder?.salesTax?.toString() || t('order.table.salesTaxText')}
        </div>
      </div>
      <div className="flex flex-row card-row">
        <div className="col-6">
          <b>{t('project.order.table.assigned')} :</b>
        </div>
        <div className="col-6">{orderDetail?.assignedUser?.username}</div>
      </div>
      <div className="flex flex-row card-row">
        <div className="col-6">
          <b>{t('project.customer.table.name')} :</b>
        </div>
        <div
          className="col-6 p-text-bold"
          style={{ color: 'blue', cursor: 'pointer' }}
          onClick={() => handleCustomerClick(orderDetail?.customer?.customerName)}>
          {orderDetail?.customer?.customerName}
        </div>
      </div>
      <Dialog
        visible={showDialog}
        style={{ width: '50vw' }}
        header={<h4 style={{ margin: 0 }}>Customer Information</h4>}
        onHide={hideCustomerInformation}>
        <div className="col-6 md:col-12">
          <div className="col-6 md:col-12">
            <h4
              style={{
                background: 'rgb(248, 249, 250)',
                //color: 'rgb(52, 58, 64)',
                borderWidth: '1px 0px',
                borderStyle: 'solid',
                borderColor: 'rgb(222, 226, 230)',
                borderImage: 'initial',
                padding: '0.5rem',
                fontWeight: '700',
              }}>
              {orderDetail?.customer?.customerName}
            </h4>
          </div>
          <div className="col-12 md:col-12">
            <strong>Address:</strong> {orderDetail?.customer?.address}
            <hr />
          </div>
          <div className="col-12 md:col-12">
            <strong>State:</strong> {orderDetail?.customer?.state}
            <hr />
          </div>
          <div className="col-12 md:col-12">
            <strong>City:</strong> {orderDetail?.customer?.city}
            <hr />
          </div>
          <div className="col-12 md:col-12">
            <strong>Zip Code:</strong> {orderDetail?.customer?.zipCode}
            <hr />
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default OrderDetailSummary
