import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import OrderService from '../../service/OrderService'
import { useHistory } from 'react-router-dom'

const Orders = () => {
  const [deleteOrderDialog, setDeleteOrderDialog] = useState(false)
  const [deleteChapterDialog, setDeleteChapterDialog] = useState(false)
  const [dataList, setDataList] = useState(null)
  const [chapterdataList, setChapterDataList] = useState(null)
  const [globalFilter, setGlobalFilter] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showEditRoomModal, setShowEditRoomModal] = useState(false)
  const [showRoomModal, setShowRoomModal] = useState(false)
  const [csvfilename, setcsvfilename] = useState(null)
  const [csv, setcsv] = useState(null)
  const dt = useRef(null)
  const toast = useRef(null)
  const mountedRef = useRef(false)
  const { t } = useTranslation()

  const uploadButtonStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    width: '20vw',
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'green',
    borderRadius: 5,
    color: 'green',
  }

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getData = useCallback(async () => {
    try {
      if (mountedRef.current === true) setLoading(true)
      const response = await OrderService.getOrders()
      if (mountedRef.current === true) {
        if (mountedRef.current === true) {
          setDataList(
            response.data.map((x) => {
              x.orderStatus = t(`order.status.${x.orderStatus}`)
              return x
            })
          )
        }
      }
    } catch (err) {
      if (toast.current) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      }
    } finally {
      if (mountedRef.current === true) setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (mountedRef.current === true) {
      getData()
    }
  }, [getData])

  const defaultValues = {
    id: null,
    name: '',
  }
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({ defaultValues: defaultValues })

  const getChapterData = useCallback(
    async (rowData) => {
      try {
        setValue('id', rowData)
        setValue('orderid', rowData)
        setLoading(true)
        const response = await OrderService.getChapter(rowData)
        setChapterDataList(response.data)
      } catch (err) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      } finally {
        setLoading(false)
      }
    },
    [setValue]
  )

  const selectItem = (id) => {}

  const exportCSV = () => {
    dt.current.exportCSV()
  }

  const addNew = async (data) => {
    try {
      setShowEditModal(false)
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Successfully added', life: 3000 })
      await getData()
    } catch (err) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
    }
  }

  const history = useHistory()

  const partListClick = (id) => {
    history.push(`/logistic-management/order-part-list/` + id)
  }

  const editItem = async (data) => {
    try {
      const updateJSON = {
        name: data.name,
        code: data.code,
      }
      await OrderService.updateOrder(data.id, updateJSON)
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Success Successfully updated',
        life: 3000,
      })
      setShowEditModal(false)
      await getData()
    } catch (err) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
    }
  }

  const deleteOrder = async (rowData) => {
    try {
      await OrderService.deleteOrder(rowData)
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Success Successfully deleted',
        life: 3000,
      })
      setDeleteOrderDialog(false)
      await getData()
    } catch (err) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
    }
  }

  const onSubmit = async (data) => {
    if (data.id) {
      editItem(data)
    } else {
      addNew(data)
    }
  }
  const onSubmitRoom = async (data) => {
    data.csvData = csv
    handleClick(data)
  }

  const editModalOpen = (rowData) => {
    setShowEditModal(true)
    if (rowData) {
      setValue('name', rowData.name)
      setValue('code', rowData.code)
      setValue('orderStatus', rowData.orderStatus)
      setValue('chapters', rowData.chapters)
      setValue('chapterCount', rowData.chapterCount)
      setValue('partCount', rowData.partCount)
      setValue('id', rowData.id)
    } else {
      reset()
    }
  }

  const editRoomModalOpen = (rowData) => {
    try {
      setcsvfilename('')
      setShowEditRoomModal(true)
      getChapterData(rowData.id)
      if (rowData) {
        setValue('name', rowData.roomname)
        setValue('code', rowData.roomcode)
        setValue('csvfile', rowData.roomcsvfile)
        setValue('id', rowData.id)
      } else {
        reset()
      }
    } catch (err) {
      console.error(err)
    }
  }

  const showRoomModalOpen = (rowData) => {
    try {
      setShowRoomModal(true)
      getChapterData(rowData.id)
      if (rowData) {
        setValue('name', rowData.name)
        setValue('id', rowData.id)
      } else {
        reset()
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleClick = async (data) => {
    try {
      await OrderService.createChapter(data.id, data)
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Room Successfully Added', life: 3000 })

      await getChapterData(data.id)
      await getData()
    } catch (err) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
    }
  }

  const NextStatus = async (data) => {
    try {
      const sampleJSON = {
        orderStatus: 'PART_LIST_CREATION',
      }
      await OrderService.changeStatus(data, sampleJSON)
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Changed Status', life: 3000 })
      await getData()
    } catch (err) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
    }
  }

  const deleteChapter = async (rowData) => {
    try {
      await OrderService.deleteChapter(rowData)
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Success Successfully deleted',
        life: 3000,
      })
      setDeleteChapterDialog(false)
      await getChapterData(getValues('orderid'))
      await getData()
    } catch (err) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
    }
  }

  const onChange = (e) => {
    const files = e.target.files
    const file = files[0]
    getBase64(file)
    setcsvfilename(file.name)
  }

  const onLoad = (fileString) => {
    var dd = fileString
    var a = dd.substring(dd.indexOf('base64,') + 7)
    setcsv(a)
  }

  const getBase64 = (file) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      onLoad(reader.result)
    }
  }

  const confirmDeleteOrder = (rowData) => {
    setValue('id', rowData.id)
    setValue('Ordname', rowData.name)
    setDeleteOrderDialog(true)
  }

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t('order.title')}</h5>
      <div className="flex">
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help"
          style={{ marginRight: '5px' }}
          onClick={exportCSV}
        />
        <Button
          label={t('general.new')}
          icon="pi pi-plus"
          onClick={() => editModalOpen(null)}
          className="p-button-success "
        />
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
        </span>
      </div>
    </div>
  )

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => confirmDeleteOrder(rowData)}
        />
      </>
    )
  }

  const roomActionBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => deleteChapter(rowData.id)}
        />
      </>
    )
  }

  const hideDeleteOrderDialog = () => {
    setDeleteOrderDialog(false)
  }

  const hideDeleteChapterDialog = () => {
    setDeleteChapterDialog(false)
  }

  const roomBodyTemplate = (rowData) => {
    if (rowData.chapterCount === 0)
      return (
        <Button type="submit" className="p-button-success mr-2 mb-2" onClick={() => editRoomModalOpen(rowData)}>
          {t('order.general.addRoom')}
        </Button>
      )
    else if (rowData.orderStatus !== 'Part List Creation')
      return (
        <Button type="submit" className="p-button-success mr-2 mb-2" onClick={() => showRoomModalOpen(rowData)}>
          {t('order.general.editRoom')}({rowData.chapterCount})
        </Button>
      )
    else
      return (
        <Button type="submit" className="p-button-success mr-2 mb-2" onClick={() => editRoomModalOpen(rowData)}>
          {t('order.general.editRoom')}({rowData.chapterCount})
        </Button>
      )
  }

  const partBodyTemplate = (rowData) => {
    if (rowData.partCount === 0)
      return (
        <Button type="submit" className="p-button-secondary mr-2 mb-2">
          View({rowData.partCount})
        </Button>
      )
    else
      return (
        <Button type="submit" className="p-button-warning mr-2 mb-2" onClick={() => partListClick(rowData.id)}>
          {t('order.general.view')}({rowData.partCount})
        </Button>
      )
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={dataList}
            dataKey="id"
            paginator
            rows={100}
            rowsPerPageOptions={[5, 10, 25, 100, 200]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders"
            globalFilter={globalFilter}
            emptyMessage="No orders found."
            header={header}
            responsiveLayout="scroll"
            selectionMode="single"
            loading={loading}
            selection={selectItem}
            onRowClick={(e) => {
              editModalOpen(e.data)
            }}>
            <Column
              field="name"
              header={t('order.table.name')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="code"
              header={t('order.table.code')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

            <Column
              field="orderRooms"
              header={t('order.table.rooms')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}
              body={roomBodyTemplate}
            />
            <Column
              field="orderParts"
              header={t('order.table.parts')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}
              body={partBodyTemplate}></Column>
            <Column
              field="orderStatus"
              header={t('order.table.orderStatus')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="action"
              header={t('order.table.action')}
              body={actionBodyTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          </DataTable>

          <Dialog
            header={getValues('id') ? t('general.modal.title.edit') : t('general.modal.title.new')}
            visible={showEditModal}
            style={{ width: '50vw' }}
            onHide={() => setShowEditModal(false)}>
            <div>
              <div className="">
                <form onSubmit={handleSubmit(onSubmit)} className="grid p-fluid">
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="name" className={classNames({ 'p-error': errors.name })}></label>
                      <span className="p-float-label">
                        <Controller
                          name="name"
                          control={control}
                          rules={{ required: 'Name is required.' }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.name}
                              {...field}
                              placeholder="Name"
                              autoFocus
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('name')}
                    </div>
                  </div>

                  <div className="col-12 md:col-6">
                    <div className="field">
                      <label htmlFor="code" className={classNames({ 'p-error': errors.code })}></label>
                      <span className="p-float-label">
                        <Controller
                          name="code"
                          control={control}
                          rules={{ required: 'Code is required.' }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.code}
                              {...field}
                              placeholder="Code"
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('code')}
                    </div>
                  </div>

                  <Button type="submit" label="Save" className="mt-2" />
                </form>
              </div>
            </div>
          </Dialog>
          <Dialog
            visible={deleteOrderDialog}
            style={{ width: '450px' }}
            header="Confirm"
            modal
            onHide={hideDeleteOrderDialog}>
            <div className="flex align-items-center justify-content-center">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
              {
                <span>
                  {t('order.question.delete')} <b>{getValues('Ordname')}</b>?{' '}
                </span>
              }
            </div>
            <footer style={{ textAlign: 'right' }}>
              <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteOrderDialog} />
              <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => deleteOrder(getValues('id'))}
              />
            </footer>
          </Dialog>
          <Dialog
            visible={deleteChapterDialog}
            style={{ width: '450px' }}
            header="Confirm"
            modal
            onHide={hideDeleteChapterDialog}>
            <div className="flex align-items-center justify-content-center">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
              {
                <span>
                  {t('order.question.delete')} <b>{getValues('name')}</b>?{' '}
                </span>
              }
            </div>
            <footer>
              <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteChapterDialog} />
              <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => deleteChapter(getValues('id'))}
              />
            </footer>
          </Dialog>
          <Dialog
            header={getValues('id') ? t('general.modal.title.editRoom') : t('general.modal.title.new')}
            visible={showEditRoomModal}
            style={{ width: '50vw' }}
            onHide={() => setShowEditRoomModal(false)}>
            <div>
              <div className="">
                <form onSubmit={handleSubmit(onSubmitRoom)} className="grid p-fluid">
                  <div className="col-12 md:col-6">
                    <div className="field">
                      <span className="p-float-label">
                        <Controller
                          name="name"
                          control={control}
                          rules={{ required: 'Name is required.' }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.name}
                              {...field}
                              autoFocus
                              placeholder="Room Name"
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('name')}
                    </div>
                  </div>

                  <div className="col-12 md:col-6">
                    <div className="field">
                      <span className="p-float-label">
                        <Controller
                          name="code"
                          control={control}
                          rules={{ required: 'Code is required.' }}
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.name}
                              {...field}
                              placeholder="Room Code"
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('code')}
                    </div>
                  </div>
                  <div className="col-12 md:col-12">
                    <div className="col-6 md:col-3">
                      <label htmlFor={'upload-button'}>
                        <div style={uploadButtonStyle}>
                          {/* <SomeIconElement style={{marginRight: 10}}/>  */}
                          <i className="pi pi-upload mr-1"></i>
                          {t('order.general.upload')}
                          {csvfilename ? <span className="ml-2 font-italic">({csvfilename})</span> : ''}
                        </div>
                      </label>
                      <input
                        name="csvfile"
                        type="file"
                        id="upload-button"
                        style={{ display: 'none' }}
                        onChange={onChange}
                      />{' '}
                    </div>
                    <div className="col-6 md:col-9"></div>
                  </div>
                  <div className="col-12 md:col-6"> </div>
                  <div className="col-12 md:col-6">
                    <Button type="submit" label="Add" className="mt-2" />
                  </div>
                </form>
                <div className="col-12">
                  <div className="card">
                    <DataTable
                      ref={dt}
                      value={chapterdataList}
                      dataKey="id"
                      paginator
                      rows={100}
                      rowsPerPageOptions={[5, 10, 25, 100, 200]}
                      className="datatable-responsive"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} rooms"
                      globalFilter={globalFilter}
                      emptyMessage="No room found."
                      responsiveLayout="scroll"
                      selectionMode="single"
                      loading={loading}
                      selection={selectItem}>
                      <Column
                        field="name"
                        header={t('Room')}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                      <Column
                        field="partsCount"
                        header={t('# of Parts')}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}
                      />
                      <Column
                        field="action"
                        body={roomActionBodyTemplate}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    </DataTable>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <Button
                    type="submit"
                    label="Ready for Packing"
                    onClick={() => NextStatus(getValues('orderid'))}
                    className="mt-2"
                  />
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog
            header={getValues('name') + ' - ' + t('general.modal.title.Rooms')}
            visible={showRoomModal}
            style={{ width: '50vw' }}
            onHide={() => setShowRoomModal(false)}>
            <div>
              <div className="">
                <div className="col-12">
                  <div className="card">
                    <DataTable
                      ref={dt}
                      value={chapterdataList}
                      dataKey="id"
                      paginator
                      rows={100}
                      rowsPerPageOptions={[5, 10, 25, 100, 200]}
                      className="datatable-responsive"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} rooms"
                      globalFilter={globalFilter}
                      emptyMessage="No room found."
                      responsiveLayout="scroll"
                      selectionMode="single"
                      loading={loading}
                      selection={selectItem}>
                      <Column
                        field="name"
                        header={t('Room')}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                      <Column
                        field="partsCount"
                        header={t('# of Parts')}
                        headerStyle={{ width: '14%', minWidth: '10rem' }}
                      />
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(Orders, comparisonFn)
