import React, { useMemo } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useTranslation } from '../../../hooks/useTranslation'

const DeleteServiceConfigurationConfirmationDialog = ({ isVisible, onHide, onConfirm }) => {
  const languageKeys = useMemo(
    () => ({
      title: 'configurationManager.dialog.title.delete',
      text: 'configurationManager.dialog.confirmation.delete',
      cancelButton: 'configurationManager.dialog.delete.cancel',
      yesButton: 'configurationManager.dialog.delete.yes',
    }),
    []
  )

  const { t } = useTranslation(Object.values(languageKeys))
  return (
    <Dialog visible={isVisible} style={{ width: '450px' }} header={t(languageKeys.title)} modal onHide={onHide}>
      <div className="flex align-items-center flex-start mb-3">
        <span>{t(languageKeys.text)}</span>
      </div>
      <footer style={{ textAlign: 'right' }}>
        <Button
          type="button"
          label={t(languageKeys.cancelButton)}
          icon="pi pi-times"
          className="p-button-text"
          onClick={onHide}
        />
        <Button
          type="button"
          label={t(languageKeys.yesButton)}
          icon="pi pi-check"
          className="p-button-text"
          onClick={onConfirm}
        />
      </footer>
    </Dialog>
  )
}

export default DeleteServiceConfigurationConfirmationDialog
