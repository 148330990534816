import { useEffect, useState } from 'react'

export const useIsUploadedProductionDesignFiles = (roomList) => {
    const [isAllUploaded, setIsAllUploaded] = useState(false);

    useEffect(() => {
        let isUploadedDesignFiles = true;
        
        if(roomList !== null) {
            roomList.map((room) => {
                isUploadedDesignFiles = isUploadedDesignFiles && (room?.msrp[0]?.cvMsrp !== null && room?.designFormFiles.length !== 0 && !room?.designFormFiles[room?.designFormFiles.lenght -1 ]?.sendApproval);
            });
            setIsAllUploaded(isUploadedDesignFiles);
        }
    });

    return isAllUploaded;
}

export const useIsUploadedPartFiles = (roomList) => {
    const [isAllUploaded, setIsAllUploaded] = useState(false);

    useEffect(() => {
        let isUploadedPartFiles = true;
        if(roomList !== null) {
            roomList.map((room) => {
                isUploadedPartFiles = isUploadedPartFiles && room.partsCount > 0;
            });
            setIsAllUploaded(isUploadedPartFiles);
        }
    });

    return isAllUploaded;
}
