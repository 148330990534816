import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'

const AddOrderModal = ({ show, onHide, availableOrders, loading, onAddButtonClick }) => {
  const [filter, setFilter] = useState('')

  const { t } = useTranslation()

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t('palletes.orders.title')}</h5>
      <div className="flex">
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText
            value={filter}
            type="search"
            onInput={(e) => setFilter(e.target.value)}
            placeholder={t('general.search')}
          />
        </span>
      </div>
    </div>
  )

  const palletedBoxesBodyTemplate = (rowData) => (
    <Button
      type="button"
      className="p-button-warning"
      style={{ width: 'auto' }}
      onClick={() => {
        window.open(window.location.origin + '/logistic-management/order-part-list/' + rowData.id, '_blank')
      }}>
      {t('palletes.orders.button.view')} ({rowData?.palletizedPackageCount ?? 0}/{rowData?.packageCount ?? 0})
    </Button>
  )

  const actionsBodyTemplate = (r) => (
    <Button type="button" className="p-button-primary" onClick={() => onAddButtonClick(r)}>
      {t('palletes.orders.button.add')}
    </Button>
  )

  return (
    <Dialog header={t('palletes.addOrder.title')} visible={show} style={{ width: '70vw' }} onHide={onHide}>
      <div>
        <div className="">
          <div className="col-12">
            <div className="card">
              <DataTable
                value={availableOrders}
                dataKey="id"
                paginator
                rows={100}
                header={header}
                rowsPerPageOptions={[5, 10, 25, 100, 200]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders"
                globalFilter={filter}
                emptyMessage={t('palletes.orders.emptyMsg')}
                responsiveLayout="scroll"
                selectionMode="single"
                loading={loading}>
                <Column
                  field="code"
                  header={t('palletes.orders.table.id')}
                  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                <Column
                  field="name"
                  header={t('palletes.orders.table.name')}
                  headerStyle={{ width: '14%', minWidth: '10rem' }}
                />
                <Column
                  field="palletedBoxes"
                  header={t('palletes.orders.table.palletedBoxes')}
                  headerStyle={{ width: '14%', minWidth: '10rem' }}
                  body={palletedBoxesBodyTemplate}
                />
                <Column
                  field="action"
                  header={t('palletes.orders.table.actions')}
                  headerStyle={{ width: '14%', minWidth: '10rem' }}
                  body={actionsBodyTemplate}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AddOrderModal
