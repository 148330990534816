import React, {useEffect, useRef, useState} from 'react'
import {Button} from 'primereact/button'
import DealersService from '../../service/DealersService'
import {Dropdown} from 'primereact/dropdown'
import {Toast} from "primereact/toast";
import {useHistory} from 'react-router-dom'

const Assing = (props) => {
    const {dealerId,assignedUserId,dealerStatus} = props

    const [assignableUsers, setAssignableUsers] = useState(false)
    const [selectedAssignableUsers, setSelectedAssignableUsers] = useState(assignedUserId)
    const [disabled, setDisabled] = useState(dealerStatus !=='NEW')
    let history = useHistory()

    const toast = useRef(null);


    useEffect(() => {
        getAssignableUsers()
    }, [])

    const getAssignableUsers = () => {
        DealersService.assignableUsers().then((response) => {
            setAssignableUsers(response.data)
        })
    }

    const setAssign = () => {
        // DealersService.assign(dealerId, selectedAssignableUsers).then((response) => {
        //     toast.current.show({severity:'success', summary: 'Success Assign', detail:'Success Assign', life: 3000});
        // })
        let newStatus = dealerStatus == "NEW" ? "IN_PROGRESS" : dealerStatus
        DealersService.assign(dealerId, newStatus, selectedAssignableUsers).then((response) => {
            toast.current.show({severity:'success', summary: 'Success Assign', detail:'Success Assign', life: 3000});
            history.push('/applications/new')
        })
    }

    return (disabled ? <div></div> :
        <div className="grid crud-demo">
            <Toast ref={toast} />
            <div className="col-12 p-0">
                <div className="card">
                    <div className="grid">
                        <div className="col-8">
                            <Dropdown
                                filter
                                showClear
                                className="min-w-full"
                                value={selectedAssignableUsers}
                                options={assignableUsers}
                                onChange={(e) => setSelectedAssignableUsers(e.value)}
                                optionLabel="username"
                                optionValue="id"
                                placeholder="Select a Assignable User"
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-4">
                            <Button label="Assign" disabled={disabled} onClick={setAssign}className="p-button-success min-w-full"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Assing
