import { useMemo, useState, useRef, useEffect, useCallback } from 'react'
import { useTranslation } from '../../hooks/useTranslation'
import RoleService from '../../service/RoleService'

export const useRoleSubmenuManagement = () => {
  const languageKeys = useMemo(
    () => ({
      tableTitle: 'roleSubmenuManagement.table.title',
      searchPlaceholder: 'general.search',
      roleColumn: 'roleSubmenuManagement.table.role',
      submenusColumn: 'roleSubmenuManagement.table.submenus',
      actionColumn: 'roleSubmenuManagement.table.action',
    }),
    []
  )

  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState([])
  const [filter, setFilter] = useState(null)
  const [isEditRoleDialogVisible, setIsEditRoleDialogVisible] = useState(false)
  const [selectedRole, setSelectedRole] = useState(null)

  const dt = useRef(null)
  const toast = useRef(null)
  const mounted = useRef(false)

  const { t, getAllTranslationsOfGivenList, lang } = useTranslation(Object.values(languageKeys))

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const getRoles = useCallback(async () => {
    try {
      if (mounted.current === true) setLoading(true)
      const response = await RoleService.getRoles()
      const keyList = response.data.reduce((acc, role) => {
        return [
          ...acc,
          ...(role.submenus?.map((submenu) => ['submenus.' + submenu.key, 'mainMenus.' + submenu.mainMenu.key]) ?? []),
        ]
      }, [])
      const flattenKeyList = keyList.reduce((acc, key) => [...acc, ...key], [])
      const translations = await getAllTranslationsOfGivenList(flattenKeyList)
      const convertedRoles = response.data.map((role) => ({
        ...role,
        submenusFlattenAndConverted:
          role.submenus
            ?.map(
              (submenu) =>
                translations[lang ?? 'en']['mainMenus.' + submenu.mainMenu.key] +
                '/' +
                translations[lang ?? 'en']['submenus.' + submenu.key]
            )
            ?.join('\n') ?? '',
      }))
      if (mounted.current === true) setRoles(convertedRoles)
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: t(languageKeys.toastErrorSummary),
        detail: error.response.data.message,
        life: 3000,
      })
    } finally {
      if (mounted.current === true) setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])

  useEffect(() => {
    getRoles()
  }, [getRoles])

  const onRoleEditClicked = (rowData) => {
    setSelectedRole(rowData)
    setIsEditRoleDialogVisible(true)
  }

  const onHideEditRoleDialog = () => {
    setIsEditRoleDialogVisible(false)
  }

  return useMemo(
    () => ({
      t,
      languageKeys,
      setFilter,
      filter,
      loading,
      roles,
      dt,
      toast,
      onRoleEditClicked,
      isEditRoleDialogVisible,
      onHideEditRoleDialog,
      getRoles,
      selectedRole,
    }),

    [t, languageKeys, filter, loading, roles, dt, toast, isEditRoleDialogVisible, getRoles, selectedRole]
  )
}
