export const encodeBase64 = (file) => {
  return new Promise(async (resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function () {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}

export const dateFormatWithTime = (rowData, field) => {
  const { date, calendar } = getDateWithStr(rowData, field)
  if (calendar === '-') {
    return calendar
  }
  const hours = date.getHours()
  const minutes = date.getMinutes()
  return calendar + ' ' + hours + ':' + minutes
}

export const dateFormat = (rowData, field) => {
  const { calendar } = getDateWithStr(rowData, field)
  return calendar
}

const getDateWithStr = (rowData, field) => {
  if (typeof rowData === 'undefined' || rowData === null) {
    return { calendar: '-' }
  }
  const data = rowData[field]
  if (typeof data === 'undefined' || data === null) return { calendar: '-' }
  const date = new Date(data)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const calendar = day + '.' + month + '.' + year
  return { date, calendar }
}

export const dateTimeDDMMYYYYWithPad2AndSeperatorFormat = (rowData, field, dateSeperator = '.') => {
  let date = null

  if (field === '' || field === null || field === undefined) {
    date = rowData
  } else {
    date = rowData[field]
  }

  if (date === null || date === undefined) return ''

  let dt = new Date(date)
  return `${dt.getDate().toString().padStart(2, '0')}${dateSeperator}${(dt.getMonth() + 1)
    .toString()
    .padStart(2, '0')}${dateSeperator}${dt.getFullYear().toString().padStart(4, '0')}
              ${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`
}

export const dateFormatter = (date) => {
  if (date === null || date === undefined) return ''

  let dt = new Date(date)
  return `${(dt.getMonth() + 1).toString().padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}-${dt
    .getFullYear()
    .toString()
    .padStart(4, '0')}
              ${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`
}
