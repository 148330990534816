import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { useForm, Controller } from 'react-hook-form'
import classNames from 'classnames'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { useTranslation } from '../../../hooks/useTranslation'
import ModuleService from '../../../service/ModuleService'

const EditModuleDialog = ({ visible, onHide, fetchModules, selectedModule }) => {
  const languageKeys = useMemo(
    () => ({
      toastSuccessSummary: 'general.toast.success.summary',
      toastErrorSummary: 'general.toast.error.summary',
      toastSuccessDetail: 'moduleMenuManagement.editModule.toast.success.detail',
      modalTitle: 'moduleMenuManagement.editModule.modal.title',
      keyLabel: 'moduleMenuManagement.form.key.label',
      keyPlaceholder: 'moduleMenuManagement.form.key.placeholder',
      requiredField: 'general.required.field.msg',
      save: 'general.save',
    }),
    []
  )

  const [saving, setSaving] = useState(false)
  const toast = useRef(null)
  const { t } = useTranslation(Object.values(languageKeys))

  const defaultValues = {
    id: null,
    key: '',
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues, shouldFocusError: false })

  useEffect(() => {
    reset(selectedModule)
  }, [selectedModule, reset])

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  const onSubmitModule = useCallback(
    async (data) => {
      try {
        setSaving(true)
        await ModuleService.updateModule({
          id: data.id,
          key: data.key,
        })
        onHide()
        toast.current.show({
          severity: 'success',
          summary: t(languageKeys.toastSuccessSummary),
          detail: t(languageKeys.toastSuccessDetail),
          life: 3000,
        })
        fetchModules()
      } catch (err) {
        console.log(err)
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: err.response?.data?.message,
          life: 3000,
        })
      } finally {
        setSaving(false)
      }
    },
    [fetchModules, onHide, t, languageKeys]
  )

  return (
    <>
      <Toast ref={toast} />
      <Dialog header={t(languageKeys.modalTitle)} visible={visible} style={{ width: '50vw' }} onHide={onHide}>
        <div>
          <div className="">
            <form onSubmit={handleSubmit(onSubmitModule)} className="grid">
              <div className="col-12 p-fluid">
                <div className="field">
                  <label htmlFor="key" className={classNames({ 'p-error': errors.key })}>
                    {t(languageKeys.keyLabel)} *
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="key"
                      control={control}
                      rules={{ required: t(languageKeys.requiredField) }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder={t(languageKeys.keyPlaceholder)}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('key')}
                </div>
              </div>

              <div className="col-12 flex flex-row-reverse">
                <Button
                  type="submit"
                  label={t(languageKeys.save)}
                  loading={saving}
                  loadingIcon="pi pi-spin pi-spinner"
                />
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default EditModuleDialog
