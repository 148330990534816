import React, {Suspense} from 'react'
import { SignIn } from '../components/signIn/SignIn'
import logo from "../assets/images/bienal-logo.png";

export const Login = () => {

  return(

        <SignIn></SignIn>

  )
}
