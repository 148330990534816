import http from '../ServiceConfig'

class RoleService {
  assignSubmenus(request) {
    return http.post('/role/assign-submenus', request)
  }

  getRoles() {
    return http.get('/role')
  }
}

export default new RoleService()
