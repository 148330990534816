import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Controller } from 'react-hook-form';

const AddInput = ({ label, name, rules, control, errors, placeholder }) => {
        const { t } = useTranslation();
      
        return (
          <div className="col-12 md:col-6">
            <div className="field">
              <label htmlFor={name} className={classNames({'p-error': errors[name]})}>
                {label} *
              </label>
              <span className="p-float-label">
                <Controller
                  name={name}
                  control={control}
                  rules={rules}
                  render={({field, fieldState}) => (
                    <InputText
                      id={name}
                      {...field}
                      autoFocus
                      placeholder={placeholder}
                      className={classNames({'p-invalid': fieldState.invalid})}
                    />
                  )}
                />
              </span>
              {errors[name] && <small className="p-error">{t(errors[name].message)}</small>}
            </div>
          </div>
        );
                  }
export default AddInput