import React from 'react'

import {useTranslation} from 'react-i18next'

const Dashboard = (props) => {
    const {t} = useTranslation()

    console.log(t('Welcome'))

    return <div className="grid"></div>
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Dashboard, comparisonFn);
