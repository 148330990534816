import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import OrderService from '../../service/OrderService';
import { useHistory  } from "react-router-dom";

const UnLoadPacks = () => {
  const [dataList, setDataList] = useState(null)
  const [chapterdataList, setChapterDataList] = useState(null)
  const [globalFilter, setGlobalFilter] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showEditRoomModal, setShowEditRoomModal] = useState(false)
  const [showPackingModal, setShowPackingModal] = useState(false)
  const dt = useRef(null)
  const toast = useRef(null)
  const { t } = useTranslation();


  useEffect(() => {
    getData();
  }, [])

  const defaultValues = {
    id : null,
    name: null,
      partBarcode: ''
  }
  const {
        control,
        formState: {errors},
        handleSubmit,
        resetField,
        reset,
        setValue,
        getValues,
    } = useForm({defaultValues: defaultValues})

  const selectItem = (id) => {

  }

  const exportCSV = () => {
    dt.current.exportCSV();
   }

const history = useHistory();
  
const partListClick = (id) =>{
    history.push(`/logistic-management/order-part-list/`+id);
}

    const onSubmitPacking = async (data) => {
        OrderService.unloadPackingStatus(data.partBarcode).then((response) => {
            toast.current.show({severity:'success', summary: 'Success', detail:'Success', life: 3000});
            setShowEditModal(false);
            setValue("total", response.data.total);
            setValue("unloadpack", response.data.unloadPack);
            getData();
        }).catch(err => {
            toast.current.show({severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000})
        })
        resetField('partBarcode');
    }

  const editRoomModalOpen = (rowData) => {
    setShowEditRoomModal(true);
    getChapterData(rowData.id);
    if(rowData){
        setValue("name", rowData.name);
        setValue("code", rowData.roomcode);
        setValue("id",rowData.id);
       
    }else{
        reset();
    }
  }
  const packingModalOpen = (rowData) => {
    packCount(rowData.id);
    if(rowData){
        setValue("ordername", rowData.name);
        setValue("id",rowData.id);
        setValue("orderid",rowData.id);
       
    }else{
        reset();
    }
  }

  const packCount = (data) => {
    OrderService.getPacking(data).then((response) => {
      var a =response.data.filter(x => x.status =="UNLOADED")
      OrderService.getPacking(data).then((response1) => {
        setValue("unloadedCount",a.length);
        setValue("tcCount",(response1.data.length));
        setShowPackingModal(true);
    }).catch(err => {

      console.log("Paket sayısı gelmiyor")
    })
     ;
  }).catch(err => {
    
  })

    
  };

  const NextStatus = (data) => {
    const sampleJSON = {
        "orderStatus": "LOADED"
      };
    OrderService.changeStatus(data,sampleJSON).then((response) => {
        toast.current.show({severity:'success', summary: 'Success', detail:'Changed Status', life: 3000});
        setShowPackingModal(false);
        getData();
    }).catch(err => {
        toast.current.show({severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000})
    })
    
  };
  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
   }

  const getData = (status) => {
    OrderService.getUnLoadPacksOrders().then((response) => {
        setLoading(false);
        setDataList(response.data);
         })
  }

  const getChapterData = (rowData) => {
    console.log(rowData);
    OrderService.getChapter(rowData).then((response) => {
        setLoading(false);
        setChapterDataList(response.data);
         })
  }

  const actionBodyTemplate = (rowData) => {
    return <Button type="submit" className="p-button-primary mr-2 mb-2" onClick={() => packingModalOpen(rowData)}>Start Unload</Button>
    }

  const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("order.title")}</h5>
            <div className="flex">
                <Button label="Export" icon="pi pi-upload" className="p-button-help" style={{marginRight: "5px"}} onClick={exportCSV} />
               
                <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
        <i className="pi pi-search"/>
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t("general.search")}/>
        </span>
            </div>
        </div>
    )

    const roomBodyTemplate = (rowData) => {
        return <Button type="submit" className="p-button-success mr-2 mb-2" onClick={() => editRoomModalOpen(rowData)}>Rooms({rowData.chapterCount})</Button>
         }
    const partBodyTemplate = (rowData) => {
        return <Button type="submit" className="p-button-warning mr-2 mb-2" onClick={() => partListClick(rowData.id)}>View({rowData.partCount})</Button>
        }
  return (
    <div className="grid crud-demo">
    <div className="col-12">
        <div className="card">
            <Toast ref={toast}/>
                    <DataTable
                        ref={dt}
                        value={dataList}
                        dataKey="id"
                        paginator
                        rows={100}
                        rowsPerPageOptions={[5, 10, 25,100,200]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders"
                        globalFilter={globalFilter}
                        emptyMessage="No orders found."
                        header={header}
                        responsiveLayout="scroll"
                        selectionMode="single"
                        loading={loading}
                        selection={selectItem}
                        >

                        <Column field="name" header={t("order.table.name")} headerStyle={{width: '14%', minWidth: '10rem'}}></Column>
                        <Column field="code" header={t("order.table.code")} headerStyle={{width: '14%', minWidth: '10rem'}} ></Column>
                        
                        <Column field="orderRooms" header={t("order.table.rooms")} headerStyle={{width: '14%', minWidth: '10rem'}} body={roomBodyTemplate} />
                        <Column field="orderParts" header={t("order.table.parts")} headerStyle={{width: '14%', minWidth: '10rem'}} body={partBodyTemplate}></Column>
                        <Column field="orderStatus" header={t("order.table.action")} headerStyle={{width: '14%', minWidth: '10rem'}} body={actionBodyTemplate} ></Column>
                        
                    </DataTable>
                    <Dialog header={getValues("name") + " - "+ t("general.modal.title.Rooms")} visible={showEditRoomModal} style={{ width: '50vw' }} onHide={() => setShowEditRoomModal(false)}>
                        <div>
                        <div className="">

                                    <div className="col-12">
                                    <div className="card">
                                    <DataTable
                        ref={dt}
                        value={chapterdataList}
                        dataKey="id"
                        paginator
                        rows={100}
                        rowsPerPageOptions={[5, 10, 25,100,200]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} rooms"
                        globalFilter={globalFilter}
                        emptyMessage="No room found."
                        responsiveLayout="scroll"
                        selectionMode="single"
                        loading={loading}
                        selection={selectItem}
                        >

                        <Column field="name" header={t("Room")} headerStyle={{width: '14%', minWidth: '10rem'}}></Column>
                        <Column field="partsCount" header={t("# of Parts")} headerStyle={{width: '14%', minWidth: '10rem'}}/>
                         
                                    </DataTable>
                                    </div>
                                    </div>
                                </div>
                        </div>
                    </Dialog>
                    <Dialog header={getValues("ordername") +" - "+ t("general.modal.title.unloadpacking")} visible={showPackingModal} style={{ width: '50vw' }} onHide={() => setShowPackingModal(false)}>
                        <div>
                        <div  className="field">
                            </div>
                            <div className="">
                                <form onSubmit={handleSubmit(onSubmitPacking)} className="grid p-fluid">
                                
                                    <div className="col-12 md:col-12">
                                        <div className="field">
                                            <span className="p-float-label">
                                            <Controller
                                                name="partBarcode"
                                                control={control}
                                                rules={{required: 'barcode is required.'}}
                                                render={({field, fieldState}) => (
                                                    <InputText
                                                        id={field.name}
                                                        value={field.value}
                                                        onChange={(e) => field.onChange(e.target.value)}
                                                        autoFocus
                                                        placeholder="Part Barcode"
                                                        className={classNames({'p-invalid': fieldState.invalid})}
                                                    />
                                                        )}
                                                    />
                                        </span>
                                            {getFormErrorMessage('partBarcode')}
                                        </div>
                                    </div>
                                    </form>
                                    <div className="col-12 md:col-12">
                                        <div className="field">
                                   <b> {getValues("unloadpack")!=undefined? + getValues("unloadpack") +" of " +getValues("total") +" packs were unloaded": getValues("unloadedCount")+" of " +getValues("tcCount") +" packs were unloaded"}  </b>
                                    </div>
                                    </div>
                                    <Button type="submit" label="Unload Finished" onClick={() => NextStatus(getValues("orderid"))} className="mt-2 inline-block"/>
                                </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(UnLoadPacks, comparisonFn)
