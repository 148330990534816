import http from '../ServiceConfig'

class ModuleService {
  getModules() {
    return http.get('/modules')
  }

  createModule(request) {
    return http.post('/modules', request)
  }

  updateModule(request) {
    return http.put('/modules', request)
  }

  deleteModule(request, moduleId) {
    return http.put('/modules/' + moduleId, request)
  }
}

export default new ModuleService()
