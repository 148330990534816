export const barcodeValidator = (str) => {
  if (str.length === 0) {
    return false
  }

  if (str.length > 20) {
    return false
  }

  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127) {
      return false
    }
  }
  return true
}
