import http from '../ServiceConfig'

class OrderService {
  // Orders
  getOrders() {
    return http.get('/orders')
  }

  /**
   * READY_FOR_PALLETIZING ve BEING_PALLETIZED durumundaki orderları getirir.
   * Order status active olmalı ve orderın içerisinde en az 1 adet paletlenmemiş package olmalıdır.
   */
  getOrdersAvailableForPalletizing() {
    return http.get('/orders/available-for-palletizing')
  }

  // ['READY_FOR_PALLETIZING', 'BEING_PALLETIZED']
  // order id
  // order code
  // order name
  // packageCount
  // palletizedPackageCount
  getOrdersStatusList(request) {
    return http.post('/orders/status-list', request)
  }

  addOrder(request) {
    return http.post('/orders', request)
  }

  updateOrder(orderId, request) {
    return http.put('/orders/order/' + orderId, request)
  }

  deleteOrder(orderId) {
    return http.delete('/orders/order/' + orderId)
  }

  orderPartList(orderId) {
    return http.get('/part-of-chapter/by-order-id/' + orderId)
  }
  deleteOrderPartList(PartId) {
    return http.delete('/part-of-chapter/' + PartId)
  }
  createChapter(orderId, request) {
    return http.post('/chapter-of-order/' + orderId, request)
  }

  getChapter(orderId) {
    return http.get('/chapter-of-order/' + orderId)
  }

  deleteChapter(orderId) {
    return http.delete('/chapter-of-order/' + orderId)
  }

  getPreparePacksOrders() {
    return http.get('/orders/status/PACKING')
  }

  getReadyForPalletizingOrders() {
    return http.get('/orders/status/READY_FOR_PALLETIZING')
  }

  //PACKING
  getPacking(orderId) {
    return http.get('/order-packing/' + orderId)
  }

  deletePacking(packId) {
    return http.delete('/order-packing/' + packId)
  }

  createPacking(orderId) {
    return http.get('/order-packing/create/packing/' + orderId)
  }

  readBarcodePacking(orderId, request) {
    return http.post('/order-packing/part/read-barcode/' + orderId, request)
  }

  deleteParts(orderId) {
    return http.delete('/order-packing/item/' + orderId)
  }

  createAdditionalPacking(orderId, request) {
    return http.post('/order-packing/create/additional-packing/' + orderId, request)
  }

  getAdditionalPacking(orderId) {
    return http.get('/order-packing/get-additional-pack/' + orderId)
  }

  //ChangeStatus
  changeStatus(orderId, request) {
    return http.post('/order-status/order/next-status/' + orderId, request)
  }

  // Load Packing
  getLoadPacksOrders() {
    return http.get('/orders/status/READY_TO_LOAD')
  }

  loadPackingStatus(packBarcode) {
    return http.get('/packing-status/load/' + packBarcode)
  }

  // UnLoad Packing
  getUnLoadPacksOrders() {
    return http.get('/orders/status/LOADED')
  }
  unloadPackingStatus(packBarcode) {
    return http.get('/packing-status/unload/' + packBarcode)
  }

  getUploadedDocuments(orderId) {
    return http.get('/order-file/' + orderId)
  }
}

export default new OrderService()
