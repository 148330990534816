import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import AddOrEditSuggestionDialog from './components/AddOrEditSuggestionDialog'
import { SortOrder } from '../../helpers/sort-type'
import { dateTimeDDMMYYYYWithPad2AndSeperatorFormat } from '../../helpers/convertor'
import DeleteSuggestionConfirmationDialog from './components/DeleteSuggestionConfirmationDialog'
import { useMySuggestions } from './useMySuggestions'

const MySuggestions = () => {
  const {
    t,
    onOpenAddOrEditSuggestionDialog,
    deleteSuggestion,
    toast,
    dt,
    suggestions,
    loading,
    dateSorter,
    isAddOrEditSuggestionDialogVisible,
    onHideAddOrEditSuggestionDialog,
    errors,
    control,
    getValues,
    loadingAddOrEditSuggestionDialog,
    onAddOrEditSuggestionSubmit,
    isDeleteSuggestionConfirmationDialogVisible,
    onHideDeleteSuggestionConfirmationDialog,
    onConfirmDeleteMySuggestion,
    languageKeys,
  } = useMySuggestions()

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t(languageKeys.tableHeader)}</h5>
      <div className="flex">
        <Button
          label={t(languageKeys.addNewButton)}
          icon="pi pi-plus"
          onClick={() => onOpenAddOrEditSuggestionDialog(null)}
          className="p-button-success "
        />
      </div>
    </div>
  )

  const dateCellTemplate = (rowData) => {
    return <p>{dateTimeDDMMYYYYWithPad2AndSeperatorFormat(rowData, 'updateDate', '.')}</p>
  }

  const actionCellTemplate = (rowData) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => deleteSuggestion(rowData.id)}
        />
      </div>
    )
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={suggestions}
            dataKey="id"
            paginator
            rows={100}
            rowsPerPageOptions={[5, 10, 25, 100, 200]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
            emptyMessage={t(languageKeys.noRecordsMsg)}
            header={header}
            responsiveLayout="scroll"
            selectionMode="single"
            loading={loading}
            onRowClick={(e) => {
              onOpenAddOrEditSuggestionDialog(e.data)
            }}
            sortField="updateDate"
            sortOrder={SortOrder.DESCENDING}>
            <Column
              field="text"
              header={t(languageKeys.suggestionColumn)}
              headerStyle={{ width: '70%', minWidth: '20rem' }}></Column>
            <Column
              field="updateDate"
              sortable
              sortFunction={dateSorter}
              header={t(languageKeys.dateColumn)}
              body={dateCellTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="action"
              header={t(languageKeys.actionColumn)}
              body={actionCellTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          </DataTable>
        </div>
      </div>
      <AddOrEditSuggestionDialog
        visible={isAddOrEditSuggestionDialogVisible}
        onHide={onHideAddOrEditSuggestionDialog}
        errors={errors}
        control={control}
        getValues={getValues}
        loading={loadingAddOrEditSuggestionDialog}
        onSubmit={onAddOrEditSuggestionSubmit}
      />

      <DeleteSuggestionConfirmationDialog
        isVisible={isDeleteSuggestionConfirmationDialogVisible}
        onHide={onHideDeleteSuggestionConfirmationDialog}
        onConfirm={onConfirmDeleteMySuggestion}
      />
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(MySuggestions, comparisonFn)
