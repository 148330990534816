import { useState, useRef, useCallback, useEffect, useContext, useMemo } from 'react'
import LanguageService from '../service/LanguageService'
import AuthContext from '../context/AuthContext'

export const useTranslation = (keyList) => {
  const mounted = useRef(false)

  const [translations, setTranslations] = useState(null)
  const [loading, setLoading] = useState(false)
  const { user } = useContext(AuthContext)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const getTranslations = useCallback(async () => {
    try {
      if (mounted.current === true) {
        setLoading(true)
      }
      const response = await LanguageService.getAllValuesByKeyList(keyList)
      if (mounted.current === true) {
        setTranslations(response.data)
      }
    } catch (err) {
      console.log(err)
    } finally {
      if (mounted.current === true) {
        setLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...keyList])

  useEffect(() => {
    if (translations === null) getTranslations()
  }, [getTranslations, translations])

  const preferredLanguage = (user?.preferredLanguage ?? 'en').toLowerCase()

  const getTranslation = useCallback(
    (key, loadingComponent) => {
      const lng = preferredLanguage
      if (loading === true) {
        if (loadingComponent !== null && loadingComponent !== undefined) return loadingComponent
        return lng === 'tr' ? 'Yükleniyor...' : 'Loading...'
      }

      if (
        translations !== null &&
        translations[lng] !== null &&
        translations[lng] !== undefined &&
        translations[lng][key]
      ) {
        return translations[lng][key]
      } else {
        return key
      }
    },
    [translations, preferredLanguage, loading]
  )

  const getAllTranslationsOfGivenList = useCallback(async (givenList) => {
    try {
      const response = await LanguageService.getAllValuesByKeyList(givenList)
      return response.data
    } catch (err) {
      console.log(err)
      return null
    }
  }, [])

  return useMemo(
    () => ({ loading, t: getTranslation, lang: preferredLanguage, getAllTranslationsOfGivenList }),
    [loading, getTranslation, preferredLanguage, getAllTranslationsOfGivenList]
  )
}
