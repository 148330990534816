import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { useTranslation } from '../../../hooks/useTranslation'
import ModuleService from '../../../service/ModuleService'
import NewModuleDialog from './NewModuleDialog'
import EditModuleDialog from './EditModuleDialog'

const Modules = () => {
  const languageKeys = useMemo(
    () => ({
      toastSuccessSummary: 'general.toast.success.summary',
      toastErrorSummary: 'general.toast.error.summary',
      modulesTableTitle: 'moduleMenuManagement.module.table.title',
      newModuleButton: 'moduleMenuManagement.button.newModule',
      search: 'general.search',
      noRecordsMsg: 'general.table.noRecords',
      tableModuleNameEN: 'moduleMenuManagement.module.table.moduleNameEN',
      tableModuleNameTR: 'moduleMenuManagement.module.table.moduleNameTR',
      tableKey: 'moduleMenuManagement.module.table.key',
      toastErrorDetailForTranslations: 'moduleMenuManagement.toast.error.detailForTranslationsModule',
    }),
    []
  )

  const [loading, setLoading] = useState(false)
  const [modules, setModules] = useState([])
  const [modulesFilter, setModulesFilter] = useState('')
  const [isNewModuleDialogVisible, setIsNewModuleDialogVisible] = useState(false)
  const [isEditModuleDialogVisible, setIsEditModuleDialogVisible] = useState(false)
  const [selectedModule, setSelectedModule] = useState(null)

  const mounted = useRef(false)
  const toast = useRef(null)
  const dt = useRef(null)

  const { t, getAllTranslationsOfGivenList } = useTranslation(Object.values(languageKeys))

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const convertModulesToDataTable = useCallback(
    async (moduleList) => {
      if (moduleList.length === 0) return []

      try {
        const keyList = moduleList.map((module) => 'modules.' + module.key)
        const translations = await getAllTranslationsOfGivenList(keyList)
        const converted = moduleList.map((module) => {
          return {
            ...module,
            nameEN: translations?.en['modules.' + module.key],
            nameTR: translations?.tr['modules.' + module.key],
          }
        })

        return converted
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: t(languageKeys.toastErrorDetailForTranslations),
          life: 3000,
        })

        return []
      }
    },
    [getAllTranslationsOfGivenList, t, languageKeys]
  )

  const getModules = useCallback(async () => {
    try {
      if (mounted.current === true) setLoading(true)
      const response = await ModuleService.getModules()
      const converted = await convertModulesToDataTable(response.data)
      if (mounted.current === true) setModules(converted)
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: t(languageKeys.toastErrorSummary),
        detail: error.response.data.message,
        life: 3000,
      })
    } finally {
      if (mounted.current === true) setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageKeys])

  useEffect(() => {
    getModules()
  }, [getModules])

  const onNewModuleClick = () => {
    setIsNewModuleDialogVisible(true)
  }

  const onModuleRowClick = (row) => {
    setSelectedModule(row.data)
    setIsEditModuleDialogVisible(true)
  }

  const onHideNewModuleDialog = () => {
    setIsNewModuleDialogVisible(false)
  }

  const onHideEditModuleDialog = () => {
    setSelectedModule(null)
    setIsEditModuleDialogVisible(false)
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">
        {t(languageKeys.modulesTableTitle, <ProgressSpinner style={{ width: '40px', height: '40px' }} />)}
      </h5>
      <div className="flex">
        <Button
          label={t(languageKeys.newModuleButton)}
          icon="pi pi-plus"
          onClick={onNewModuleClick}
          className="p-button-success "
        />
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setModulesFilter(e.target.value)}
            placeholder={t(languageKeys.search)}
          />
        </span>
      </div>
    </div>
  )

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <Toast ref={toast} />
        <DataTable
          ref={dt}
          value={modules}
          dataKey="id"
          paginator
          rows={100}
          rowsPerPageOptions={[5, 10, 25, 100, 200]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          globalFilter={modulesFilter}
          emptyMessage={t(languageKeys.noRecordsMsg)}
          header={header}
          responsiveLayout="scroll"
          selectionMode="single"
          loading={loading}
          // selection={selectItem}
          onRowClick={onModuleRowClick}
          // sortField="lastActivityTime"
          // sortOrder={SortOrder.DESCENDING}
        >
          <Column
            field="nameEN"
            header={t(languageKeys.tableModuleNameEN)}
            headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
          <Column
            field="nameTR"
            header={t(languageKeys.tableModuleNameTR)}
            headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
          <Column
            field="key"
            header={t(languageKeys.tableKey)}
            headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
        </DataTable>
      </div>
      <NewModuleDialog visible={isNewModuleDialogVisible} onHide={onHideNewModuleDialog} fetchModules={getModules} />
      <EditModuleDialog
        visible={isEditModuleDialogVisible}
        onHide={onHideEditModuleDialog}
        fetchModules={getModules}
        selectedModule={selectedModule}
      />
    </div>
  )
}

export default Modules
