import React, { useContext, useEffect, useRef, useState } from 'react'
import {useParams, useHistory} from 'react-router-dom'
import DealersService from '../service/DealersService'
import {classNames} from 'primereact/utils'
import {Controller, useForm} from 'react-hook-form'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {Dropdown} from 'primereact/dropdown'
import Notes from '../components/notes/Notes'
import Assing from '../components/assign/Assign'
import {Toast} from 'primereact/toast'
import Approval from '../components/approval/Approval'
import ConvertToCustomer from '../components/convertToCustomer/ConvertToCustomer'
import AssignCrmUser from '../components/assignCrmUser/assignCrmUser'
import BreadcrumbContext from '../components/breadcrumb/Breadcrumb'

const DealerDetails = () => {
    const {id} = useParams()
    const [howDidYouHear, setHowDidYouHear] = useState('null')
    const [assignedUserId, setAssignedUserId] = useState('null')
    const [isNewDealer, setIsNewDealer] = useState(id == '0' ? true : false)
    const [dealerStatus, setDealerStatus] = useState(null)
    const [loading, setLoading] = useState(false)
    const [assignCrmUser, setAssignCrmUser] = useState(false)
    const [dealer, setDealer] = useState(false)
    const [doingClosed, setDoingClosed] = useState('')
    const [application, setApplication] = useState(null)
    let history = useHistory()
    const {onChangeLastLabel} = useContext(BreadcrumbContext);

    const toast = useRef(null)

    const yesNo = [
        {label: 'Yes', value: 'Yes'},
        {label: 'No', value: 'No'},
    ]
    const defaultValues = {
        id: null,
        name: '',
        company: '',
        status: 'NEW',
        webSiteAddress: '',
        address: '',
        email: '',
        state: '',
        zipCode:'',
        phoneNumber: '',
        marketAreaServed: '',
        businessType: '',
        numberOfEmployees: 0,
        yearsInBusiness: 0,
        annualSales: '',
        doingClosed: '',
        whoAreUsing: '',
        taxNumber: '',
        haveInHouseTeam: '',
        howDidYouHear: '',
        howDidYouHearOther: '',
        salesTaxExempt: '',
        creatorUserId: 0,
        assignedUserId: 0,
        approvedUserId: 0,
    }

    const aboutUs = [
        {label: 'Google', value: 'Google'},
        {label: 'Bing', value: 'Bing'},
        {label: 'Yahoo', value: 'Yahoo'},
        {label: 'Facebook', value: 'Facebook'},
        {label: 'Youtube', value: 'Youtube'},
        {label: 'Woodworking Network', value: 'Woodworking Network'},
        {label: 'RedBook', value: 'RedBook'},
        {label: 'Closets Magazine', value: 'Closets Magazine'},
        {label: 'IBS Show', value: 'IBS Show'},
        {label: 'IWF Show', value: 'IWF Show'},
        {label: 'Cabinets and Closets Expo', value: 'Cabinets and Closets Expo'},
        {label: 'AWFS', value: 'AWFS'},
        {label: 'Other', value: 'Other'},
    ]
    const annualSales = [
        {label: '0-500K', value: '0-500K'},
        {label: '500K-1M', value: '500K-1M'},
        {label: '1M-2.5M', value: '1M-2.5M'},
        {label: '2.5M-5M', value: '2.5M-5M'},
        {label: '5M and more', value: '5M and more'},
    ]
    const businessTypeOptions = [
        {label: 'Closet Company', value: 'Closet Company'},
        {label: 'Kitchen and Bath Dealer', value: 'Kitchen and Bath Dealer'},
        {label: 'Cabinetry Company', value: 'Cabinetry Company'},
        {label: 'Home Design Firm', value: 'Home Design Firm'},
        {label: "Manufacturer's Rep", value: "Manufacturer's Rep"},
        {label: 'Blinds & Shutters', value: 'Blinds & Shutters'},
        {label: 'Retail Home Center', value: 'Retail Home Center'},
        {label: 'Showroom Dealer', value: 'Showroom Dealer'},
        {label: 'Contractor', value: 'Contractor'},
        {label: 'Window Treatment', value: 'Window Treatment'},
        {label: 'Design and Build', value: 'Design and Build'},
        {label: 'Builder', value: 'Builder'},
        {label: 'Distributor', value: 'Distributor'},
        {label: 'Architect', value: 'Architect'},
        {label: 'General Contracter', value: 'General Contracter'},
        {label: 'Other', value: 'Other'},
    ]

    const {
        control,
        formState: {errors},
        handleSubmit,
        reset,
        setValue,
        getValues,
    } = useForm({defaultValues: defaultValues})

    const howDidYouHearChange = (e) => {
        setHowDidYouHear(e)
    }
    const addDealers = (request) => {
        if(!assignedUserId)
            request.assignedUser = {
                    id: assignedUserId
                }
        request.id == null
            ? DealersService.addDealers(request).then((response) => {
                toast.current.show({severity: 'success', summary: 'Success Dealer', detail: 'Success Dealer', life: 3000})
                history.push('/applications/new')
            })
            .catch(function (e) {
                toast.current.show({severity: 'error', summary: 'Error', detail: e.response.data.message, life: 3000})
            })
            : DealersService.updateDealers(request.id, request).then((response) => {
                toast.current.show({severity: 'success', summary: 'Success Dealer', detail: 'Success Dealer', life: 3000})
            })
            .catch(function (e) {
                toast.current.show({severity: 'error', summary: 'Error', detail: e.response.data.message, life: 3000})
            })
    }
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    }
    const onSubmit = (data) => {
        addDealers(data)
    }

    const doingClosedChange = (e) => {
        setDoingClosed(e)
    }

    const onChangeCompany = (e, field) => {
        const company = e.target.value;
        onChangeLastLabel(company);
        field.onChange(company);
    }

    useEffect(() => {
        if (!isNewDealer) {
            setLoading(false)
            DealersService.getDealer(id).then((response) => {
                setDealer(response.data)
                setAssignCrmUser(response.data.crmUserId !== null)
                if(response.data.assignedUser)
                setAssignedUserId(response.data.assignedUser.id)
                setDealerStatus(response.data.status)
                setApplication(response.data)
                let dealer = response.data
                const fields = [
                    'id',
                    'name',
                    'company',
                    'status',
                    'webSiteAddress',
                    'address',
                    'zipCode',
                    'email',
                    'state',
                    'phoneNumber',
                    'marketAreaServed',
                    'businessType',
                    'numberOfEmployees',
                    'yearsInBusiness',
                    'annualSales',
                    'doingClosed',
                    'whoAreUsing',
                    'taxNumber',
                    'haveInHouseTeam',
                    'howDidYouHear',
                    'howDidYouHearOther',
                    'salesTaxExempt',
                    'creatorUserId',
                    'assignedUserId',
                    'approvedUserId',
                ]
                fields.forEach((field) => setValue(field, dealer[field]))
                setLoading(true)
            })
        }
    }, [])

    useEffect(() => {
        let company = getValues('company')
        if(company) {
            onChangeLastLabel(company);
        }
    }, [getValues('company')])

    return (
        <div>
            <Toast ref={toast}/>
            {loading && (dealerStatus == 'NEW' || dealerStatus == 'IN_PROGRESS' || dealerStatus == 'APPROVAL') && (
                <Assing dealer={dealer} dealerId={id} assignedUserId={assignedUserId} dealerStatus={dealerStatus}></Assing>
            )}
            {loading && dealerStatus == 'APPROVAL' && assignCrmUser && (
                <Approval dealer={dealer} dealerId={id} assignedUserId={assignedUserId} dealerStatus={dealerStatus}></Approval>
            )}
            {loading && dealerStatus == 'APPROVAL' && !assignCrmUser && (
                <AssignCrmUser dealer={dealer} dealerId={id} dealerStatus={dealerStatus}></AssignCrmUser>
            )}
            {loading && (dealerStatus == 'IN_PROGRESS' || dealerStatus == 'APPROVAL') && (
                <ConvertToCustomer dealer={dealer} dealerId={id} dealerStatus={dealerStatus} application={application}></ConvertToCustomer>
            )}
            <div className="grid card mb-3">
                <div>
                    <div className="">
                        <form onSubmit={handleSubmit(onSubmit)} className="grid p-fluid">
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="name" className={classNames({'p-error': errors.name})}>
                                        Full Name*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="name"
                        control={control}
                        rules={{required: 'Input Is Required.'}}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                autoFocus
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('name')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="company" className={classNames({'p-error': !!errors.company})}>
                                        Company*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="company"
                        control={control}
                        rules={{required: 'Input Is Required.'}}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                onChange={(e) => onChangeCompany(e, field)}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('company')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="email" className={classNames({'p-error': !!errors.webSiteAddress})}>
                                        Website*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="webSiteAddress"
                        control={control}
                        rules={{
                            required: 'Input Is Required.',
                        }}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('webSiteAddress')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="address" className={classNames({'p-error': !!errors.address})}>
                                        Adress*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="address"
                        control={control}
                        rules={{
                            required: 'Input Is Required.',
                        }}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('address')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="zipCode" className={classNames({'p-error': !!errors.zipCode})}>
                                        Zip Code
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="zipCode"
                        control={control}
                        rules={{
                            required: 'Input Is Required.',
                        }}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('zipCode')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="state" className={classNames({'p-error': !!errors.state})}>
                                        State/Terr/Prov*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="state"
                        control={control}
                        rules={{
                            required: 'Input Is Required.',
                        }}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('state')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="phoneNumber" className={classNames({'p-error': !!errors.phoneNumber})}>
                                        Phone Number*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{
                            required: 'Input Is Required.',
                        }}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('phoneNumber')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="email" className={classNames({'p-error': !!errors.email})}>
                                        Email*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: 'Input Is Required.',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: 'Invalid email address. E.g. example@email.com',
                            },
                        }}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('email')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="businessType" className={classNames({'p-error': errors.businessType})}>
                                        Business Type*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="businessType"
                        control={control}
                        rules={{required: 'Dropdown Is Required.'}}
                        render={({field, fieldState}) => (
                            <Dropdown
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                                options={businessTypeOptions}
                                optionLabel="label"
                                placeholder="Select a Business Type*"
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('businessType')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="email" className={classNames({'p-error': !!errors.marketAreaServed})}>
                                        Market Area Served*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="marketAreaServed"
                        control={control}
                        rules={{
                            required: 'Input Is Required.',
                        }}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('marketAreaServed')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="yearsInBusiness" className={classNames({'p-error': errors.yearsInBusiness})}>
                                        Years In Business*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="yearsInBusiness"
                        control={control}
                        rules={{required: 'Input Is Required.'}}
                        render={({field, fieldState}) => (
                            <InputText
                                type="number"
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                                min="1"
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('yearsInBusiness')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="numberOfEmployees" className={classNames({'p-error': errors.numberOfEmployees})}>
                                        Number Of Employees*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="numberOfEmployees"
                        control={control}
                        rules={{required: 'Input Is Required.'}}
                        render={({field, fieldState}) => (
                            <InputText
                                type="number"
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                                min="1"
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('numberOfEmployees')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="annualSales" className={classNames({'p-error': errors.annualSales})}>
                                        Annual Sales *
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="annualSales"
                        control={control}
                        rules={{required: 'Dropdown Is Required.'}}
                        render={({field, fieldState}) => (
                            <Dropdown
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                                options={annualSales}
                                optionLabel="label"
                                placeholder="Select a Annual Sales*"
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('annualSales')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="name" className={classNames({'p-error': errors.haveInHouseTeam})}>
                                        Do you have an in house sales/desing team? *
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="haveInHouseTeam"
                        control={control}
                        rules={{required: 'Dropdown Is Required.'}}
                        render={({field, fieldState}) => (
                            <Dropdown
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                                options={yesNo}
                                optionLabel="label"
                                placeholder="Do you have an in house sales/desing team?"
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('haveInHouseTeam')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="doingClosed" className={classNames({'p-error': errors.doingClosed})}>
                                        Are you doing closets now ?*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="doingClosed"
                        control={control}
                        rules={{required: 'Dropdown Is Required.'}}
                        render={({field, fieldState}) => (
                            <Dropdown
                                id={field.name}
                                {...field}
                                onHide={() => doingClosedChange(getValues('doingClosed'))}
                                className={classNames({'p-invalid': fieldState.invalid})}
                                options={yesNo}
                                optionLabel="label"
                                placeholder="Select a Annual Sales*"
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('doingClosed')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="whoAreUsing" className={classNames({'p-error': errors.whoAreUsing})}>
                                        If so, who are you using
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="whoAreUsing"
                        control={control}
                        rules={{required: {value: doingClosed === 'Yes', message: 'Input Is Required.'}}}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('whoAreUsing')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="salesTaxExempt" className={classNames({'p-error': errors.salesTaxExempt})}>
                                        Tax Number *
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="taxNumber"
                        control={control}
                        rules={{required: 'Input Is Required.'}}
                        render={({field, fieldState}) => (
                            <InputText
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('taxNumber')}
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="salesTaxExempt" className={classNames({'p-error': errors.salesTaxExempt})}>
                                        Sales Tax Exempt*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="salesTaxExempt"
                        control={control}
                        rules={{required: 'Dropdown Is Required.'}}
                        render={({field, fieldState}) => (
                            <Dropdown
                                id={field.name}
                                {...field}
                                className={classNames({'p-invalid': fieldState.invalid})}
                                options={yesNo}
                                optionLabel="label"
                                placeholder="Sales Tax Exempt"
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('salesTaxExempt')}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="howDidYouHear" className={classNames({'p-error': errors.howDidYouHear})}>
                                        How did you hear us ?*
                                    </label>
                                    <span className="p-float-label">
                    <Controller
                        name="howDidYouHear"
                        control={control}
                        rules={{required: 'Dropdown Is Required.'}}
                        render={({field, fieldState}) => (
                            <Dropdown
                                id={field.name}
                                {...field}
                                onHide={() => howDidYouHearChange(getValues('howDidYouHear'))}
                                className={classNames({'p-invalid': fieldState.invalid})}
                                options={aboutUs}
                                optionLabel="label"
                                placeholder="Select a How Did You Hear*"
                            />
                        )}
                    />
                  </span>
                                    {getFormErrorMessage('howDidYouHear')}
                                </div>
                            </div>
                            {howDidYouHear == 'Other' && (
                                <div className="col-12 md:col-6">
                                    <div className="field">
                                        <label
                                            htmlFor="howDidYouHearOther"
                                            className={classNames({'p-error': errors.howDidYouHearOther})}>
                                            How Did You Hear Other *
                                        </label>
                                        <span className="p-float-label">
                      <Controller
                          name="howDidYouHearOther"
                          control={control}
                          rules={{required: 'Input Is Required.'}}
                          render={({field, fieldState}) => (
                              <InputText
                                  id={field.name}
                                  {...field}
                                  className={classNames({'p-invalid': fieldState.invalid})}
                              />
                          )}
                      />
                    </span>
                                        {getFormErrorMessage('howDidYouHearOther')}
                                    </div>
                                </div>
                            )}



                            <Button type="submit" label="Save" className="mt-2"/>
                        </form>
                    </div>
                </div>
            </div>
            {loading && <Notes dealerId={id}></Notes>}
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(DealerDetails, comparisonFn)
