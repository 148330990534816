import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { InputText } from 'primereact/inputtext'
import { Image } from 'primereact/image'
import loginImage from '../../assets/images/login-onlight.png'
import logo from '../../assets/images/bienal-logo.png'
import { Button } from 'primereact/button'
import { useHistory, useLocation } from 'react-router-dom'
import './ForgetPassword.scss'
import Dealer from '../dealer/Dealer'
import { classNames } from 'primereact/utils'
import AuthService from '../../service/AuthService'
import { useTranslation } from 'react-i18next'

export const ForgetPassword = () => {
  const { t } = useTranslation()
  const [dealerDisplayModal, setDealerDisplayModal] = useState(false)
  const [isReset, setIsReset] = useState(false)

  const defaultValues = {
    email: '',
  }
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues })

  const onSubmit = (data) => {
    resetPassword(data)
    reset()
  }

  const resetPassword = (request) => {
    AuthService.resetPassword(request).then((response) => {
      if (response.status == 200) {
        setIsReset(true)
      }
    })
  }

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error ">{errors[name].message}</small>
  }

  const resetPasswordSuccess = () => {
    return <small className="my-3 p-success text-left">{t('forget_password.SuccessSendEmailMessage')}</small>
  }

  let history = useHistory()
  let location = useLocation()

  let { from } = location.state || { from: { pathname: '/login' } }

  const login = () => {
    history.push({
      pathname: `/login`,
    })
  }

  return (
    <div>
      <div className="login-body">
        <div className="login-image">
          <Image src={loginImage}></Image>
        </div>
        <div className="login-panel p-fluid">
          <div className="flex flex-column">
            <div className="flex align-items-center mb-6 logo-container">
              <img className="login-appname" src={logo} alt={'icon'}></img>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-container">
                <div className="field text-left">
                  <label htmlFor="name" className={classNames({ 'p-error': errors.email })}>
                    Email
                  </label>
                  <Controller
                    id="email"
                    name="email"
                    control={control}
                    rules={{ required: 'Email is required.' }}
                    render={({ field, fieldState }) => (
                      <span className="p-input-icon-left mb-3">
                        <i className="pi pi-envelope"></i>
                        <InputText
                          id={field.email}
                          {...field}
                          autoFocus
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      </span>
                    )}
                  />
                  {getFormErrorMessage('email')}
                </div>
                <div className="button-container ">
                  <Button type="submit" label="Submit" className="mt-2" />
                </div>
              </div>
            </form>
            {isReset && resetPasswordSuccess()}
            <button className="flex p-link mb-3 " onClick={login}>
              {t('forget_password.loginButton')}
            </button>
            <button className="flex p-link mb-3" onClick={() => setDealerDisplayModal(true)}>
              {t('forget_password.signUpButton')}
            </button>
          </div>
        </div>
      </div>
      <Dealer display={dealerDisplayModal} onHide={() => setDealerDisplayModal(false)}></Dealer>
    </div>
  )
}
