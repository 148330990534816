import React, { useState, useRef, useContext, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import BreadcrumbContext from '../../../components/breadcrumb/Breadcrumb'
// import CommonService from '../../../service/CommonService'
import StateCityService from '../../../service/StateCityService'
import CompanyService from '../../../service/CompanyService'
import { useForm, Controller } from 'react-hook-form'

import { Toast } from 'primereact/toast'
import classNames from 'classnames'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { ProgressSpinner } from 'primereact/progressspinner'
import { InputSwitch } from 'primereact/inputswitch'

const CompanyInfo = ({ companyInfo, onCompanyChange }) => {
  const languageKeys = useMemo(
    () => ({
      requiredField: 'general.required.field.msg',
      toastErrorSummary: 'general.toast.error.summary',
      toastSuccessSummary: 'general.toast.success.summary',
      toastCompanyUpdatedMsg: 'companyManagement.toast.success.updatedMsg',
      subtitle: 'companyManagement.details.info.subtitle',
      name: 'companyManagement.form.name.label',
      namePlaceholder: 'companyManagement.form.name.placeholder',
      companyAddress: 'companyManagement.form.companyAddress.label',
      companyAddressPlaceholder: 'companyManagement.form.companyAddress.placeholder',
      state: 'companyManagement.form.state.label',
      city: 'companyManagement.form.city.label',
      zipcode: 'companyManagement.form.zipcode.label',
      zipcodePlaceholder: 'companyManagement.form.zipcode.placeholder',
      companyShortCode: 'companyManagement.form.companyShortCode.label',
      companyShortCodePlaceholder: 'companyManagement.form.companyShortCode.placeholder',
      companyPhone: 'companyManagement.form.companyPhone.label',
      companyPhonePlaceholder: 'companyManagement.form.companyPhone.placeholder',
      companyEmail: 'companyManagement.form.companyEmail.label',
      companyEmailPlaceholder: 'companyManagement.form.companyEmail.placeholder',
      companyTaxnbr: 'companyManagement.form.companyTaxnbr.label',
      companyTaxnbrPlaceholder: 'companyManagement.form.companyTaxnbr.placeholder',
      qbId: 'companyManagement.form.qbId.label',
      qbIdPlaceholder: 'companyManagement.form.qbId.placeholder',
      status: 'companyManagement.form.status.label',
      save: 'general.save',
      dropdownPlaceholder: 'general.dropdown.placeholder',
      invalidPhone: 'general.invalid.phone.msg',
      invalidEmail: 'general.invalid.email.msg',
    }),
    []
  )
  const { t /* lang */ } = useTranslation(Object.values(languageKeys))

  const [saving, setSaving] = useState(false)
  // const [loading, setLoading] = useState(false)
  const [loadingStates, setLoadingStates] = useState(false)
  const [loadingCities, setLoadingCities] = useState(false)
  // const [titles, setTitles] = useState([])
  const [states, setStates] = useState([])
  const [selectedState, setSelectedState] = useState(null)
  const [cities, setCities] = useState([])

  const mounted = useRef(false)
  const toast = useRef(null)

  const { onConvertFromTo } = useContext(BreadcrumbContext)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // const getActiveStaticContents = useCallback(
  //   (staticContents, group) => {
  //     const result = staticContents[group]
  //       .filter((item) => JSON.parse(item.value)?.active !== false)
  //       .map((item) => ({
  //         label: JSON.parse(item.value)[lang],
  //         value: item.label,
  //       }))
  //     return result
  //   },
  //   [lang]
  // )

  // const getStaticContent = useCallback(async () => {
  //   try {
  //     if (mounted.current === true) setLoading(true)
  //     const response = await CommonService.staticContent(['contact.titles'])
  //     if (mounted.current === true) {
  //       setTitles(getActiveStaticContents(response.data.result, 'contact.titles'))
  //     }
  //   } catch (error) {
  //   } finally {
  //     if (mounted.current === true) setLoading(false)
  //   }
  // }, [getActiveStaticContents])

  const getStates = useCallback(async () => {
    if (states.length === 0) {
      try {
        if (mounted.current === true) setLoadingStates(true)
        const response = await StateCityService.getStates()
        if (mounted.current === true)
          setStates(
            [...(response.data ?? [])]
              .sort((a, b) => (a.stateName > b.stateName ? 1 : a.stateName < b.stateName ? -1 : 0))
              .map((item) => ({ label: item.stateName, value: item.stateName })) ?? []
          )
      } catch (err) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: err.response.data.message,
          life: 3000,
        })
      } finally {
        if (mounted.current === true) setLoadingStates(false)
      }
    }
  }, [states.length, languageKeys, t])

  const getCities = useCallback(async () => {
    if (selectedState !== null && selectedState !== undefined && selectedState !== '') {
      try {
        if (mounted.current === true) setLoadingCities(true)
        const response = await StateCityService.getCities(selectedState)

        if (mounted.current === true)
          setCities(
            [...(response.data ?? [])]
              .sort((a, b) => (a.cityName > b.cityName ? 1 : a.cityName < b.cityName ? -1 : 0))
              .map((item) => ({ label: item.cityName, value: item.cityName })) ?? []
          )
      } catch (err) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: err.response.data.message,
          life: 3000,
        })
      } finally {
        if (mounted.current === true) setLoadingCities(false)
      }
    }
  }, [selectedState, languageKeys, t])

  // useEffect(() => {
  //   if (titles.length === 0) getStaticContent()
  // }, [getStaticContent, titles.length])

  useEffect(() => {
    getStates()
  }, [getStates])

  useEffect(() => {
    getCities()
  }, [getCities])

  const updateCompanyInfo = useCallback(
    async (data) => {
      console.log('updateCompanyInfo', data)
      try {
        if (mounted.current === true) setSaving(true)
        const response = await CompanyService.updateCompanyInfo(data)
        onCompanyChange(response.data)
        onConvertFromTo([{ from: data.id, to: data.name }])
        toast.current.show({
          severity: 'success',
          summary: t(languageKeys.toastSuccessSummary),
          detail: t(languageKeys.toastCompanyUpdatedMsg),
          life: 3000,
        })
      } catch (err) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: err.response.data.message,
          life: 3000,
        })
      } finally {
        if (mounted.current === true) setSaving(false)
      }
    },
    [onCompanyChange, onConvertFromTo, t, languageKeys]
  )

  const defaultValues = {
    id: null,
    name: '',
    companyAddress: '',
    state: '',
    city: '',
    zipcode: '',
    shortCode: '',
    companyPhone: '',
    companyEmail: '',
    companyTaxnbr: '',
    qbId: '',
    adminName: '',
    adminTitle: '',
    adminEmail: '',
    adminPhone: '',
    status: true,
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues, shouldFocusError: false })

  useEffect(() => {
    if (companyInfo !== null) {
      reset(companyInfo)
      setSelectedState(companyInfo.state)
    }
  }, [companyInfo, reset])

  const getFormErrorMessage = (fieldName) => {
    return errors[fieldName] && <small className="p-error">{errors[fieldName].message}</small>
  }

  return (
    <>
      <Toast ref={toast} />
      <div style={{ maxHeight: '100%' }}>
        <div style={{ marginTop: '1.0rem', paddingBottom: '1.5rem' }}>
          <form onSubmit={handleSubmit(updateCompanyInfo)} className="grid">
            <div className="col-12 md:col-6">
              <h4 style={{ fontSize: '1.1rem', fontWeight: 700, margin: '1.0rem 0 0.5rem 0' }}>
                {t(languageKeys.subtitle)}
              </h4>
            </div>

            <div className="col-12 md:col-6 flex md:justify-content-end justify-content-start align-items-end">
              <div className="field mb-0">
                <label htmlFor="status" className={classNames({ 'p-error': errors.status })}>
                  {t(languageKeys.status)}
                </label>
                <span className="p-float-label">
                  <Controller
                    name="status"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <InputSwitch
                          inputId={field.name}
                          checked={field.value}
                          inputRef={field.ref}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.value)}
                        />
                      )
                    }}
                  />
                </span>
              </div>
            </div>

            <div className="col-12 md:col-6 p-fluid">
              <div className="field">
                <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>
                  {t(languageKeys.name) + ' *'}
                </label>
                <span className="p-float-label">
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: t(languageKeys.requiredField),
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder={t(languageKeys.namePlaceholder)}
                        autoFocus
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                </span>
                {getFormErrorMessage('name')}
              </div>
            </div>

            <div className="col-12 md:col-6 p-fluid">
              <div className="field">
                <label htmlFor="companyAddress" className={classNames({ 'p-error': errors.companyAddress })}>
                  {t(languageKeys.companyAddress) + ' *'}
                </label>
                <span className="p-float-label">
                  <Controller
                    name="companyAddress"
                    control={control}
                    rules={{ required: t(languageKeys.requiredField) }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder={t(languageKeys.companyAddressPlaceholder)}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                </span>
                {getFormErrorMessage('companyAddress')}
              </div>
            </div>

            <div className="col-12 md:col-6 p-fluid">
              <div className="field">
                <label htmlFor="state" className={classNames({ 'p-error': errors.state })}>
                  {t(languageKeys.state) + ' *'}
                </label>
                {loadingStates === true ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                    <ProgressSpinner style={{ width: '40px', height: '40px' }} />
                  </div>
                ) : (
                  <>
                    <span className="p-float-label">
                      <Controller
                        name="state"
                        control={control}
                        rules={{ required: t(languageKeys.requiredField) }}
                        render={({ field, fieldState }) => (
                          <Dropdown
                            id={field.state}
                            {...field}
                            value={selectedState}
                            onChange={(e) => {
                              setSelectedState(e.value)
                              field.onChange(e.value)
                            }}
                            className={classNames({ 'p-invalid': fieldState.invalid })}
                            options={states}
                            optionLabel="label"
                            optionValue="value"
                            placeholder={t(languageKeys.dropdownPlaceholder)}
                          />
                        )}
                      />
                    </span>
                    {getFormErrorMessage('state')}
                  </>
                )}
              </div>
            </div>

            <div className="col-12 md:col-6 p-fluid">
              <div className="field">
                <label htmlFor="city" className={classNames({ 'p-error': errors.city })}>
                  {t(languageKeys.city) + ' *'}
                </label>
                {loadingCities === true ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                    <ProgressSpinner style={{ width: '40px', height: '40px' }} />
                  </div>
                ) : (
                  <>
                    <span className="p-float-label">
                      <Controller
                        name="city"
                        control={control}
                        rules={{ required: t(languageKeys.requiredField) }}
                        render={({ field, fieldState }) => (
                          <Dropdown
                            id={field.city}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e.value)
                            }}
                            className={classNames({ 'p-invalid': fieldState.invalid })}
                            options={cities}
                            optionLabel="label"
                            optionValue="value"
                            placeholder={t(languageKeys.dropdownPlaceholder)}
                          />
                        )}
                      />
                    </span>
                    {getFormErrorMessage('city')}
                  </>
                )}
              </div>
            </div>

            <div className="col-12 md:col-6 p-fluid">
              <div className="field">
                <label htmlFor="zipcode" className={classNames({ 'p-error': errors.zipcode })}>
                  {t(languageKeys.zipcode) + ' *'}
                </label>
                <span className="p-float-label">
                  <Controller
                    name="zipcode"
                    control={control}
                    rules={{ required: t(languageKeys.requiredField) }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder={t(languageKeys.zipcodePlaceholder)}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                </span>
                {getFormErrorMessage('zipcode')}
              </div>
            </div>

            <div className="col-12 md:col-6 p-fluid">
              <div className="field">
                <label htmlFor="shortCode" className={classNames({ 'p-error': errors.shortCode })}>
                  {t(languageKeys.companyShortCode)}
                </label>
                <span className="p-float-label">
                  <Controller
                    name="shortCode"
                    control={control}
                    // rules={{ required: t(languageKeys.requiredField) }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder={t(languageKeys.companyShortCodePlaceholder)}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                </span>
                {getFormErrorMessage('shortCode')}
              </div>
            </div>

            <div className="col-12 md:col-6 p-fluid">
              <div className="field">
                <label htmlFor="companyPhone" className={classNames({ 'p-error': errors.companyPhone })}>
                  {t(languageKeys.companyPhone) + ' *'}
                </label>
                <span className="p-float-label">
                  <Controller
                    name="companyPhone"
                    control={control}
                    rules={{
                      required: t(languageKeys.requiredField),
                      validate: (value) => {
                        const phoneRegex = /^(?:(\+\d{1,4}[\s-]?)?\d{10,15})?$/
                        return phoneRegex.test(value) || t(languageKeys.invalidPhone)
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder={t(languageKeys.companyPhonePlaceholder)}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                </span>
                {getFormErrorMessage('companyPhone')}
              </div>
            </div>

            <div className="col-12 md:col-6 p-fluid">
              <div className="field">
                <label htmlFor="companyEmail" className={classNames({ 'p-error': errors.companyEmail })}>
                  {t(languageKeys.companyEmail) + ' *'}
                </label>
                <span className="p-float-label">
                  <Controller
                    name="companyEmail"
                    control={control}
                    rules={{
                      required: t(languageKeys.requiredField),
                      validate: (value) => {
                        const emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'gm')
                        return emailRegex.test(value) || t(languageKeys.invalidEmail)
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder={t(languageKeys.companyEmailPlaceholder)}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                </span>
                {getFormErrorMessage('companyEmail')}
              </div>
            </div>

            <div className="col-12 md:col-6 p-fluid">
              <div className="field">
                <label htmlFor="companyTaxnbr" className={classNames({ 'p-error': errors.companyTaxnbr })}>
                  {t(languageKeys.companyTaxnbr) + ' *'}
                </label>
                <span className="p-float-label">
                  <Controller
                    name="companyTaxnbr"
                    control={control}
                    rules={{
                      required: t(languageKeys.requiredField),
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder={t(languageKeys.companyTaxnbrPlaceholder)}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                </span>
                {getFormErrorMessage('companyTaxnbr')}
              </div>
            </div>

            <div className="col-12 md:col-6 p-fluid">
              <div className="field">
                <label htmlFor="qbId" className={classNames({ 'p-error': errors.qbId })}>
                  {t(languageKeys.qbId) + ' *'}
                </label>
                <span className="p-float-label">
                  <Controller
                    name="qbId"
                    control={control}
                    rules={{
                      required: t(languageKeys.requiredField),
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        placeholder={t(languageKeys.qbIdPlaceholder)}
                        className={classNames({ 'p-invalid': fieldState.invalid })}
                      />
                    )}
                  />
                </span>
                {getFormErrorMessage('qbId')}
              </div>
            </div>

            <div
              className="col-12 md:p-fluid md:col-12"
              style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button
                type="submit"
                label={t(languageKeys.save)}
                loading={saving}
                loadingIcon="pi pi-spin pi-spinner"
                className="mt-2"
                style={{ display: 'block' }}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default CompanyInfo
