import React, { useEffect } from 'react'
import { Route, useLocation, withRouter } from 'react-router-dom'
import App from './App'
import { Login } from './pages/Login'
import { ForgetPassword } from './components/forgetPassword/ForgetPassword'
import { ResetPassword } from './components/resetPassword/ResetPassword'
import { useTranslation } from 'react-i18next'

const AppWrapper = () => {
  let location = useLocation()
  const { i18n } = useTranslation()

  const setLanguage = () => {
    sessionStorage.setItem('language', i18n.language)
  }

  useEffect(() => {
    setLanguage()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  if (location.pathname.startsWith('/reset_password')) {
    return <Route path="/reset_password/:token" exact component={ResetPassword} />
  }
  switch (location.pathname) {
    case '/login':
      return <Route path="/login" component={Login} />
    case '/forgetPassword':
      return <Route path="/forgetPassword" exact component={ForgetPassword} />
    default:
      return <App />
  }
}

export default withRouter(AppWrapper)
