import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import React, { useContext, useEffect, useRef, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import OrderService from '../../service/OrderService'
import PalleteService from '../../service/PalleteService'
import { useParams } from 'react-router-dom'
import { Dialog } from 'primereact/dialog'
import BreadcrumbContext from '../../components/breadcrumb/Breadcrumb'
var ReactBarcode = require('react-barcode')

const OrderPartList = () => {
  const { id } = useParams()
  const [dataList, setDataList] = useState(null)
  const [globalFilter, setGlobalFilter] = useState(null)
  const [showPrintModal, setShowPrintModal] = useState(false)
  const [showPalleteLabelPrintModal, setShowPalleteLabelPrintModal] = useState(false)
  const [deleteOrderDialog, setDeleteOrderDialog] = useState(false)
  const [loading, setLoading] = useState(true)
  const dt = useRef(null)
  const toast = useRef(null)
  const mountedRef = useRef(true)
  const { t } = useTranslation()
  const { onChangeLastLabel } = useContext(BreadcrumbContext)

  const defaultValues = {
    id: null,
    name: '',
    barcode: '',
  }

  const {
    setValue,
    getValues,
    // formState: { errors },
  } = useForm({ defaultValues: defaultValues })

  // API Calls
  const getData = useCallback(async () => {
    try {
      const orderPartListResult = await OrderService.orderPartList(id)
      const additionalPackingResult = await OrderService.getAdditionalPacking(id)

      if (!mountedRef.current) return null

      setLoading(false)
      let list = []
      for (var i = 0; i < orderPartListResult.data.length; i++) {
        setValue('orderName', orderPartListResult.data[i].chapterOfOrder.order.name)
        list = list.concat({
          OrderName: orderPartListResult.data[i].chapterOfOrder.order.name,
          RoomName: orderPartListResult.data[i].chapterOfOrder.name,
          // CabNo: orderPartListResult.data[i].barcode?.split('-')[2] ?? '',
          PartBarcode: orderPartListResult.data[i].barcode,
          PartName: orderPartListResult.data[i].name,
          PartId: orderPartListResult.data[i].id,
          BoxName:
            orderPartListResult.data[i].orderPacking == null ? '' : orderPartListResult.data[i].orderPacking.barcode,
          BoxStatus:
            orderPartListResult.data[i].orderPacking == null ? '' : orderPartListResult.data[i].orderPacking.status,
          Barcode:
            orderPartListResult.data[i].orderPacking == null ? '' : orderPartListResult.data[i].orderPacking.barcode,
          OrderId: orderPartListResult.data[i].chapterOfOrder.order.id,
          PaketId: orderPartListResult.data[i].orderPacking == null ? '' : orderPartListResult.data[i].orderPacking.id,
          PalleteId: orderPartListResult.data[i].orderPacking?.palette?.id ?? '',
          PalleteName: orderPartListResult.data[i].orderPacking?.palette?.paletteName ?? '',
          PalleteStatus: orderPartListResult.data[i].orderPacking?.palette?.status ?? '',
          PalleteBarcode: orderPartListResult.data[i].orderPacking?.palette?.barcode ?? '',
        })
      }
      for (var j = 0; j < additionalPackingResult.data.length; j++) {
        list = list.concat({
          OrderName: getValues('orderName'),
          RoomName: 'General',
          // CabNo: '',
          PartBarcode: '',
          PartName: additionalPackingResult.data[j].parts == null ? '' : additionalPackingResult.data[j].parts[0].name,
          BoxName: additionalPackingResult.data[j].barcode,
          BoxStatus: additionalPackingResult.data[j].status,
          Barcode: additionalPackingResult.data[j].barcode,
          OrderId: additionalPackingResult.data[j].id,
          PaketId:
            additionalPackingResult.data[j].orderPacking == null ? '' : additionalPackingResult.data[j].orderPacking.id,
          PalleteId: additionalPackingResult.data[j].palette?.id ?? '',
          PalleteName: additionalPackingResult.data[j].palette?.paletteName ?? '',
          PalleteStatus: additionalPackingResult.data[j].palette?.status ?? '',
          PalleteBarcode: additionalPackingResult.data[j].palette?.barcode ?? '',
        })
      }
      setDataList(list)
    } catch (error) {}
  }, [getValues, id, setValue])

  useEffect(() => {
    getData()

    return () => {
      mountedRef.current = false
    }
  }, [getData])

  useEffect(() => {
    let orderName = getValues('orderName')
    if (orderName) {
      onChangeLastLabel(orderName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues('orderName')])

  const selectItem = (id) => {}

  const exportCSV = () => {
    dt.current.exportCSV()
  }

  const actionBodyTemplate = (rowData) => {
    if (rowData.BoxStatus !== 'CLOSED') {
      return (
        <>
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-warning mt-2"
            onClick={() => confirmDeleteOrder(rowData)}
          />
        </>
      )
    } else {
      return <></>
    }
  }
  const confirmDeleteOrder = (rowData) => {
    setValue('id', rowData.PartId)
    setValue('PrtName', rowData.PartName)
    setDeleteOrderDialog(true)
  }
  const hideDeleteOrderDialog = () => {
    setDeleteOrderDialog(false)
  }
  const deleteOrder = async (rowData) => {
    try {
      await OrderService.deleteOrderPartList(rowData)
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Success Successfully deleted',
        life: 3000,
      })
      setDeleteOrderDialog(false)
      getData()
    } catch (err) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
    }
  }

  const printBodyTemplate = (rowData) => {
    return (
      <Button type="submit" className="p-button-success mr-2 mb-2" onClick={() => printModalOpen(rowData)}>
        {t('partlist.printBoxLabel')}
      </Button>
    )
  }

  const printPalleteLabelBodyTemplate = (rowData) => {
    return rowData.PalleteBarcode !== null && rowData.PalleteBarcode !== '' ? (
      <Button type="button" className="p-button-success mr-2 mb-2" onClick={() => printPalleteLabelModalOpen(rowData)}>
        {t('partlist.table.button.printPallete')}
      </Button>
    ) : null
  }

  const printModalOpen = async (rowData) => {
    setValue('barcode', rowData.Barcode)
    setValue('orderName', rowData.OrderName)
    setValue('roomName', rowData.RoomName)
    setValue('cabNo', rowData.CabNo)
    setValue('partName', rowData.PartName)
    if (rowData.OrderId !== '' && rowData.PaketId !== '') {
      try {
        const packingResult = await OrderService.getPacking(rowData.OrderId)

        var a = packingResult.data.filter((x) => x.id === rowData.PaketId)
        if (a.length > 0) {
          var partList = ''
          for (var i = 0; i < a[0].parts.length; i++) {
            partList +=
              a[0].parts[i].barcode.split('-')[2] +
              '-' +
              a[0].parts[i].barcode.split('-')[3] +
              ' ' +
              a[0].parts[i].name +
              '|'
          }
          setValue('partName', partList)
          setShowPrintModal(true)
        }
      } catch (error) {}
    } else {
      setValue('partName', rowData.PartName + ' |')
      setShowPrintModal(true)
    }
  }

  const printPalleteLabelModalOpen = async (rowData) => {
    if (rowData.PalleteId !== '' && rowData.PalleteBarcode !== '') {
      try {
        setLoading(true)
        const palleteSummaryResult = await PalleteService.getPalleteSummary(rowData.PalleteId)
        const currentPallete = {
          paletteName: rowData.PalleteName,
          barcode: rowData.PalleteBarcode,
          orders: palleteSummaryResult.data,
        }

        setValue('currentPallete', currentPallete)
        setShowPalleteLabelPrintModal(true)
      } catch (error) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: error.response.data.message, life: 3000 })
      } finally {
        setLoading(false)
      }
    }
  }

  const Print = () => {
    window.scrollTo(0, 0)
    window.print()
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t('partlist.title')}</h5>
      <div className="flex">
        <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
        </span>
      </div>
    </div>
  )

  // const status = ['PART_LIST_CREATION', 'PACKING', 'READY_TO_LOAD', 'LOADED', 'UNLOADED']

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={dataList}
            dataKey="id"
            paginator
            rows={100}
            rowsPerPageOptions={[5, 10, 25, 100, 200]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} parts"
            globalFilter={globalFilter}
            emptyMessage="No part found."
            header={header}
            responsiveLayout="scroll"
            selectionMode="single"
            loading={loading}
            selection={selectItem}>
            <Column
              field="OrderName"
              header={t('partlist.table.ordername')}
              headerStyle={{ width: '12%', minWidth: '10rem' }}></Column>
            <Column
              field="RoomName"
              header={t('partlist.table.roomname')}
              headerStyle={{ width: '12%', minWidth: '10rem' }}></Column>
            <Column
              field="PartBarcode"
              header={t('partlist.table.barcode')}
              headerStyle={{ width: '12%', minWidth: '10rem' }}></Column>
            {/* <Column
              field="CabNo"
              header={t('partlist.table.cabno')}
              headerStyle={{ width: '12%', minWidth: '10rem' }}></Column> */}
            <Column
              field="PartName"
              header={t('partlist.table.partname')}
              headerStyle={{ width: '12%', minWidth: '10rem' }}></Column>
            <Column
              field="BoxName"
              header={t('partlist.table.boxname')}
              headerStyle={{ width: '12%', minWidth: '10rem' }}></Column>
            <Column
              field="print"
              header={t('partlist.table.print')}
              headerStyle={{ width: '12%', minWidth: '10rem' }}
              body={printBodyTemplate}
            />
            <Column
              field="BoxStatus"
              header={t('partlist.table.status')}
              headerStyle={{ width: '12%', minWidth: '10rem' }}></Column>
            <Column
              field="PalleteName"
              header={t('partlist.table.palleteName')}
              headerStyle={{ width: '12%', minWidth: '10rem' }}></Column>
            <Column
              field="PalleteStatus"
              header={t('partlist.table.palleteStatus')}
              headerStyle={{ width: '12%', minWidth: '10rem' }}></Column>
            <Column
              field="PalleteBarcode"
              header={t('partlist.table.palleteBarcode')}
              headerStyle={{ width: '12%', minWidth: '10rem' }}
              body={printPalleteLabelBodyTemplate}></Column>
            <Column
              field="action"
              header={t('order.table.action')}
              body={actionBodyTemplate}
              headerStyle={{ width: '12%', minWidth: '10rem' }}></Column>
          </DataTable>
          <Dialog
            header={getValues('orderName') + ' - ' + t('general.modal.title.print')}
            visible={showPrintModal}
            style={{}}
            onHide={() => setShowPrintModal(false)}>
            <div id="divcontents" className="">
              <div
                className="ifmcontentstoprint relative"
                style={{
                  width: '10cm',
                  height: '8cm',
                  maxWidth: '10cm !important',
                  maxHeight: '8cm !important',
                  overflow: 'hidden',
                  pageBreakAfter: 'always',
                }}>
                <div className="grid p-fluid">
                  <div className="col-12 md:col-12">
                    <div className="field">
                      <div className="p-float-label">
                        <div className="mb-1">
                          <b>{t('partlist.dialog.orderName')}:</b> {getValues('orderName')}
                        </div>
                        <div className="mb-1">
                          <b>{t('partlist.dialog.room')}:</b> {getValues('roomName')}
                        </div>
                        <div className="mb-1">
                          <b>{t('partlist.dialog.parts')}:</b>
                          {getValues('partName')
                            ?.split('|')
                            .map((x) => {
                              return <div>{x}</div>
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    margin: 'auto;',
                    zIndex: '9999',
                    width: '10cm',
                    textAlign: 'center',
                  }}>
                  <div>
                    <ReactBarcode value={getValues('barcode')} displayValue={true} renderer={'svg'} height={75} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 md:col-6"></div>
            <div className="col-6 md:col-6">
              <Button type="submit" className="p-button-primary mr-2 mb-2" onClick={() => Print()}>
                {t('partlist.dialog.print')}
              </Button>
            </div>
          </Dialog>
          <Dialog
            visible={deleteOrderDialog}
            style={{ width: '450px' }}
            header="Confirm"
            modal
            onHide={hideDeleteOrderDialog}>
            <div className="flex align-items-center justify-content-center">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
              {
                <span>
                  {t('order.question.delete')} <b>{getValues('PrtName')}</b>?{' '}
                </span>
              }
            </div>
            <footer style={{ textAlign: 'right' }}>
              <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteOrderDialog} />
              <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => deleteOrder(getValues('id'))}
              />
            </footer>
          </Dialog>

          <Dialog
            header={getValues('currentPallete')?.paletteName + ' - ' + t('general.modal.title.Barcode')}
            visible={showPalleteLabelPrintModal}
            style={{}}
            onHide={() => setShowPalleteLabelPrintModal(false)}>
            <div id="divcontents" className="">
              <div
                className="ifmcontentstoprint relative"
                style={{
                  width: '10cm',
                  height: '8cm',
                  maxWidth: '10cm !important',
                  maxHeight: '8cm !important',
                  overflow: 'hidden',
                  pageBreakAfter: 'always',
                }}>
                <div className="grid p-fluid">
                  <div className="col-12 md:col-12">
                    <div className="field">
                      <div className="p-float-label">
                        <div className="mb-1">
                          <b>Pallete Name:</b> {getValues('currentPallete')?.paletteName}
                        </div>
                        <div className="mb-1">
                          <b>Orders:</b>
                          {getValues('currentPallete')?.orders?.map((order) => {
                            return (
                              <div key={order.orderName}>
                                {order.orderName} ({order.numberOfBoxInPalette ?? 0} Boxes)
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    margin: 'auto',
                    zIndex: '9999',
                    width: '10cm',
                    textAlign: 'center',
                  }}>
                  <div>
                    <ReactBarcode
                      value={getValues('currentPallete')?.barcode ?? ''}
                      displayValue={true}
                      renderer={'svg'}
                      height={75}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 md:col-6"></div>
            <div className="col-6 md:col-6">
              <Button type="submit" className="p-button-primary mr-2 mb-2" onClick={() => Print()}>
                Print
              </Button>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(OrderPartList, comparisonFn)
