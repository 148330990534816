import http from '../ServiceConfig'


class DealersService {
    getDealers(status, activePage) {
        return http.get(`/applications?status=${status}&size=10&page=${activePage}`)
    }

    getDealer(id) {
        return http.get('/applications/'+id)
    }

    addDealers(request) {
        return http.post('/applications',request)
    }

    updateDealers(dealerId,request) {
        return http.put('/applications/'+dealerId,request)
    }

    getNotes(dealerId) {
        return http.get('/applications/'+dealerId+'/notes')
    }

    AddNote(dealerId,request) {
        return http.post('/applications/'+dealerId+'/notes',request)
    }

    updateNote(dealerId,dealerNoteId,request) {
        return http.put('/applications/'+dealerId+'/notes/'+dealerNoteId,request)
    }

    deleteNote(dealerId,dealerNoteId,request) {
        return http.delete('/applications'+dealerId+'/notes'+dealerNoteId)
    }

    convertToCustomer(dealerId) {
        return http.get('/applications/'+dealerId+'/convertToCustomer')
    }


    convertToPartner(dealerId, partnerType, applicationProductTypeMultipliers) {
        return http.put('/applications/'+dealerId,
        {
            id: dealerId,
            partnerType : partnerType,
            applicationProductTypeMultipliers: applicationProductTypeMultipliers
        })
    }
    
    dealerApplicationPUT(dealerId, request) {
        return http.put('/applications/'+dealerId, request)
    }


    assign(dealerId,status,userId) {
        return http.put('/applications/'+ dealerId, 
        {
            id: dealerId,
            status: status,
            assignedUser : {
                 id: userId
            }
        })
    }

    approve(dealerId,status) {
        return http.get('/dealers/'+dealerId+'/approve?status='+status)
    }

    assignableUsers() {
        return http.get('/applications/assignable-users')
    }

    crmUsers() {
        return http.get('/partners/crm-users')
    }

    users() {
        return http.get('/users')
    }


    assignCrmUser(dealerId,request) {
        return http.post('/dealers/'+dealerId+'/assign-crm-user',request)
    }


    partnerProductTypes() {
        return http.get('/partners/product-types')
    }


}


export default new DealersService()
