import http from '../ServiceConfig'


class CabinetVisionInventoryService {
   
    getPanelInventories(page = 0 , size = 20, searchTerm) {
        let url;
        if (searchTerm) {
          url = `/inventories?name=${searchTerm}&page=${page}&size=${size}`;
          
        } else {
          url = `/inventories?page=${page}&size=${size}`;
        }
        return http.get(url);
      }
    addPanelInventories(request) {
        return http.post('/inventories/',request)
    }

    updatePanelInventories(id, data) {
        return http.put(`/inventories/${id}`, data);
      }

    deleteElements(id) {
        return http.delete('/inventories/'+id)
    }

     getMaterials () {
        return  http.get('/material-types/?size=100')
    }

    getUoms(){
        return http.get('/uoms/?size=100')
    }

    getMaterialType() {
        return http.get('/inventories/material/')
    }

    getUomId(){
        return http.get('/inventories/uoms')
    }
}


export default new CabinetVisionInventoryService()
