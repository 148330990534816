import React from 'react'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
// import { getStringOfRoleList } from '../../helpers/role'
import { dateTimeDDMMYYYYWithPad2AndSeperatorFormat } from '../../helpers/convertor'
import { useConfigurationManager } from './useConfigurationManager'
import AddNewOrEditServiceConfigurationDialog from './components/AddNewOrEditServiceConfigurationDialog'
import { SortOrder } from '../../helpers/sort-type'
import DeleteServiceConfigurationConfirmationDialog from './components/DeleteServiceConfigurationConfirmationDialog'

const ConfigurationManager = () => {
  const {
    t,
    dt,
    toast,
    serviceConfigurationList,
    filter,
    setFilter,
    loading,
    onAddOrEditServiceConfiguration,
    isAddNewOrEditServiceConfigurationDialogVisible,
    onHideAddNewOrEditServiceConfigurationDialog,
    control,
    errors,
    onSubmitServiceConfiguration,
    setValue,
    getValues,
    allRoles,
    loadingAddNewOrEditServiceConfigurationDialog,
    dateSorter,
    isDeleteServiceConfigurationConfirmationDialogVisible,
    onHideDeleteServiceConfigurationConfirmationDialog,
    onConfirmDeleteServiceConfiguration,
    onDeleteServiceConfiguration,
    getStringOfRoleList,
    languageKeys,
  } = useConfigurationManager()

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t(languageKeys.tableTitle)}</h5>
      <div className="flex">
        <Button
          label={t(languageKeys.newButton)}
          icon="pi pi-plus"
          onClick={() => onAddOrEditServiceConfiguration(null)}
          className="p-button-success "
        />
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setFilter(e.target.value)}
            placeholder={t(languageKeys.searchPlaceholder)}
          />
        </span>
      </div>
    </div>
  )

  const statusCellTemplate = (rowData) => (
    <>
      <span className={'customer-badge status-' + (rowData.isActive ? 'ACTIVE' : 'PASSIVE')}>
        {rowData.isActive ? t(languageKeys.activeStatus) : t(languageKeys.passiveStatus)}
      </span>
    </>
  )

  const allowedRolesCellTemplate = (rowData) => {
    return <p>{rowData.allowedRoles?.length > 0 ? getStringOfRoleList(rowData.allowedRoles, '; ') : ''}</p>
  }

  const checkLoginCellTemplate = (rowData) => {
    return <p>{rowData.checkLogin === true ? t(languageKeys.yes) : t(languageKeys.no)}</p>
  }

  const dateCellTemplate = (rowData) => {
    return <p>{dateTimeDDMMYYYYWithPad2AndSeperatorFormat(rowData, 'updateDate', '.')}</p>
  }

  const actionCellTemplate = (rowData) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-secondary mt-2 mr-1"
          onClick={() => onAddOrEditServiceConfiguration(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => onDeleteServiceConfiguration(rowData)}
        />
      </div>
    )
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={serviceConfigurationList}
            dataKey="id"
            paginator
            rows={100}
            rowsPerPageOptions={[5, 10, 25, 100, 200]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
            globalFilter={filter}
            emptyMessage={t(languageKeys.tableEmptyMessage)}
            header={header}
            responsiveLayout="scroll"
            selectionMode="single"
            loading={loading}
            onRowClick={(e) => {
              onAddOrEditServiceConfiguration(e.data)
            }}
            sortField="updateDate"
            sortOrder={SortOrder.DESCENDING}>
            <Column
              field="methodName"
              sortable
              header={t(languageKeys.methodNameColumn)}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="roles"
              header={t(languageKeys.allowedRolesColumn)}
              body={allowedRolesCellTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="controllerName"
              sortable
              header={t(languageKeys.controllerNameColumn)}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="checkLogin"
              header={t(languageKeys.checkLoginColumn)}
              sortable
              body={checkLoginCellTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="status"
              body={statusCellTemplate}
              sortable
              header={t(languageKeys.statusColumn)}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="updateDate"
              header={t(languageKeys.updateDateColumn)}
              body={dateCellTemplate}
              sortable
              sortFunction={dateSorter}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="action"
              header={t(languageKeys.actionColumn)}
              body={actionCellTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          </DataTable>
        </div>
      </div>

      <AddNewOrEditServiceConfigurationDialog
        isVisible={isAddNewOrEditServiceConfigurationDialogVisible}
        onHide={onHideAddNewOrEditServiceConfigurationDialog}
        control={control}
        errors={errors}
        onSubmit={onSubmitServiceConfiguration}
        setValue={setValue}
        getValues={getValues}
        allRoles={allRoles}
        loading={loadingAddNewOrEditServiceConfigurationDialog}
      />

      <DeleteServiceConfigurationConfirmationDialog
        isVisible={isDeleteServiceConfigurationConfirmationDialogVisible}
        onHide={onHideDeleteServiceConfigurationConfirmationDialog}
        onConfirm={onConfirmDeleteServiceConfiguration}
      />
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(ConfigurationManager, comparisonFn)
