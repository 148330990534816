import React, { useState, createContext, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { isEqual } from '../helpers/is-equal'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  let history = useHistory()
  let token = sessionStorage.getItem('token')
  const userData = JSON.parse(sessionStorage.getItem('user'))
  const [isLoggedIn, setIsLoggedIn] = useState(token ? true : false)
  const [user, setUser] = useState()

  useEffect(() => {
    if (isLoggedIn && !isEqual(user, userData)) {
      setUser(userData)
    }
  }, [isLoggedIn, user, userData])

  const setUserData = useCallback((data) => {
    setUser(data)
    sessionStorage.setItem('user', JSON.stringify(data))
  }, [])

  const signIn = (token) => {
    setIsLoggedIn(true)
    sessionStorage.setItem('token', token)
  }

  const signOut = () => {
    setIsLoggedIn(false)
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('user')
    history.replace({ pathname: '/login' })
  }

  const roleController = (roles) => {
    if (roles?.length > 0) {
      return roles.some((element) => userData.roles.map((ur) => ur.name).includes(element))
    }
  }

  const value = {
    isLoggedIn,
    signIn,
    signOut,
    setUserData,
    roleController,
    user,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthContext
