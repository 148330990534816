import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button } from 'primereact/button'
import { Image } from 'primereact/image'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { useHistory, useLocation } from 'react-router-dom'
import logo from '../../assets/images/bienal-logo.png'
import loginImage from '../../assets/images/login-onlight.png'
import AuthService from '../../service/AuthService'
import './ResetPassword.scss'
import { useTranslation } from 'react-i18next'

export const ResetPassword = (props) => {
    const location = useLocation()
    const { t } = useTranslation()
    const [isReset, setIsReset] = useState(false)

    const defaultValues = {
        token: props.match.params.token,
        password: '',
    }

    const {
        control,
        formState: {errors},
        handleSubmit,
        reset,
    } = useForm({defaultValues})

    const onSubmit = (data) => {
        applyResetPassword(data)
        reset()
    }

    const applyResetPassword = (request) => {
        AuthService.applyResetPassword(request).then((response) => {
            if (response.status == 200) {
                setIsReset(true)
            }
        })
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error ">{errors[name].message}</small>
    }

    const resetPasswordSuccess = () => {
        return (
                <small className="my-3 p-success text-left">{t("reset_password.SuccessChangePasswordMessage")}</small>
        )
    }

    let history = useHistory()

    let {from} = location.state || {from: {pathname: '/login'}}

    const login = () => {
        history.push({
            pathname: `/login`,
        })
    }

    return (
        <div>
            <div className="login-body">
                <div className="login-image">
                    <Image src={loginImage}></Image>
                </div>
                <div className="login-panel p-fluid">
                    <div className="flex flex-column">
                        <div className="flex align-items-center mb-6 logo-container">
                            <img className="login-appname" src={logo} alt={'icon'}></img>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-container">
                                <div className="field text-left">
                                    <label htmlFor="name" className={classNames({'p-error': errors.password})}>
                                        {t("reset_password.new.pass")}
                                    </label>
                                    <Controller
                                        id="password"
                                        name="password"
                                        control={control}
                                        rules={{required: t("general.required.field.msg")}}
                                        render={({field, fieldState}) => (
                                            <span className="p-input-icon-left mb-3">
                        <i className="pi pi-key"></i>
                        <InputText
                            id={field.password}
                            {...field}
                            autoFocus
                            className={classNames({'p-invalid': fieldState.invalid})}
                        />
                      </span>
                                        )}
                                    />
                                    {getFormErrorMessage('password')}
                                </div>
                                <div className="button-container ">
                                    <Button type="submit" label={t("reset_password.submit")} className="mt-2"/>
                                </div>
                            </div>
                        </form>
                        {isReset && resetPasswordSuccess()}

                        <button className="flex p-link mb-3 " onClick={login}>
                        {t("reset_password.login.button")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
