import React, { useEffect, useState } from 'react'
import AdminFileupload from './admin-fileupload'
import { AdminFileUploadType } from '../../helpers/file-upload.type'
import { encodeBase64 } from '../../helpers/convertor'
import AdminProjectService from '../../service/AdminProjectService'
import { Message } from 'primereact/message'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import AdminDatatable from './admin-datatable'
import { Column } from 'primereact/column'
import { useTranslation } from 'react-i18next'

const AdminPartList = ({ rowData, setLoading, toast, setShowPartListModal, showPartListModal, orderName }) => {
  const { t } = useTranslation()

  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isReUpload, setIsReUpload] = useState(false)
  const [isView, setIsView] = useState(false)
  const [globalFilter, setGlobalFilter] = useState(null)

  useEffect(() => {
    if (!showPartListModal) {
      setIsReUpload(false)
      setIsView(false)
    }
  }, [showPartListModal])

  const onSubmitUploadPartList = async (file) => {
    const encodedFile = await encodeBase64(file)
    const rawEncodedFile = encodedFile
      .replace('data:text/csv;base64,', '')
      .replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,', '')
      .replace('data:text/plain;base64,', '')
    const req = { csvData: rawEncodedFile }
    setIsButtonLoading(true)
    AdminProjectService.savePartFile(rowData.id, req)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Successfully saved PART LIST file',
          life: 3000,
        })
        setIsReUpload(false)
        setShowPartListModal(false)
        setLoading(true)
      })
      .catch((err) => {
        let errorText = (
          <ul>
            <span>{err.response.data.message}</span>
            {err.response.data?.args?.length > 0 &&
              err.response.data?.args[0]?.map((e, i) => <li key={i}>{e.barcode}</li>)}
          </ul>
        )
        toast.current.show({ severity: 'error', summary: 'Error', detail: errorText, life: 7000 })
      })
      .finally(() => setIsButtonLoading(false))
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <div className="flex">
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('general.search')} />
        </span>
      </div>
    </div>
  )

  if (rowData.partsCount === 0 || isReUpload) {
    return (
      <div className={'flex flex-column'}>
        <div className={'flex row mb-4'}>
          <Message
            style={{
              border: 'solid #696cff',
              borderWidth: '0 0 0 6px',
              color: '#696cff',
            }}
            className="border-primary w-full justify-content-start"
            severity="info"
            text="Part list was not uploaded yet"
          />
        </div>
        <AdminFileupload
          uploader={onSubmitUploadPartList}
          uploadType={AdminFileUploadType.PART_LIST}
          isButtonLoading={isButtonLoading}
        />
      </div>
    )
  } else if (isView) {
    return (
      <AdminDatatable
        dataList={rowData.parts}
        rows={10}
        pages={[10, 20, 30, 40, 50]}
        globalFilter={globalFilter}
        header={header}>
        <Column
          header={t('partlist.dialog.orderName')}
          body={() => orderName}
          headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
        <Column
          header={t('partlist.table.roomname')}
          body={() => rowData?.name}
          headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
        <Column field="name" header={t('partlist.table.partname')} headerStyle={{ width: '14%', minWidth: '10rem' }} />
        <Column
          header={t('partlist.table.boxname')}
          body={(rData) => rData?.orderPacking?.barcode}
          headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
        <Column
          header={t('partlist.table.status')}
          body={(rData) => rData?.orderPacking?.status}
          headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
      </AdminDatatable>
    )
  } else {
    return (
      <div
        className={'flex row justify-content-between'}
        style={{
          border: '1px solid #dee2e6',
          borderRadius: '10px',
        }}>
        <div className={'col-4 flex justify-content-start text-xl align-items-center'}>
          {rowData.partsCount + ' part were uploaded'}
        </div>
        <div className={'col-8 flex row justify-content-end'}>
          <Button
            onClick={() => setIsView(true)}
            label="View"
            className="p-button-warning ml-2 p-button-lg"
            style={{ textAlign: 'center' }}
          />
          <Button
            onClick={() => setIsReUpload(true)}
            label="Re-upload"
            className="p-button-success ml-2 p-button-lg "
            style={{ textAlign: 'center' }}
          />
        </div>
      </div>
    )
  }
}

export default AdminPartList
