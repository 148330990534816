import React, { useRef, useState } from 'react'

import { Toast } from 'primereact/toast'
import { Dialog } from 'primereact/dialog'
import { Controller } from 'react-hook-form'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { useTranslation } from 'react-i18next'
import PalleteService from '../../../../service/PalleteService'
import { barcodeValidator } from '../../../../helpers/validator'

const NewEditPalleteModal = ({
  show,
  onHide,
  getValues,
  errors,
  control,
  handleSubmit,
  selectedOrders,
  editPallete,
  addNewPallete,
  onAddOrderButtonClick,
  onRemoveOrderButtonClick,
  onPalletizedButtonClick,
}) => {
  const toast = useRef(null)
  const { t } = useTranslation()

  const [filter, setFilter] = useState('')
  const [loading, setLoading] = useState(false)

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{t('order.title')}</h5>
      <div className="flex">
        <Button
          type="button"
          label={t('palletes.new.button.addOrder')}
          icon="pi pi-plus"
          onClick={onAddOrderButtonClick}
          className="p-button-success "
          style={{ marginRight: '5px' }}
        />
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={filter}
            onInput={(e) => setFilter(e.target.value)}
            placeholder={t('general.search')}
          />
        </span>
      </div>
    </div>
  )

  const palletedBoxesBodyTemplate = (rowData) => (
    <Button
      type="button"
      className="p-button-warning"
      style={{ width: 'auto' }}
      onClick={() => {
        window.open(window.location.origin + '/logistic-management/order-part-list/' + rowData.id, '_blank')
      }}>
      {t('palletes.orders.button.view')} ({rowData?.orderPackings?.length ?? 0}/{rowData?.packageCount ?? 0})
    </Button>
  )

  const actionsBodyTemplate = (rowData) => (
    <Button
      type="button"
      icon="pi pi-trash"
      className="p-button-rounded p-button-warning"
      onClick={() => onRemoveOrderButtonClick(rowData)}
    />
  )

  const onSubmitPallete = async (data) => {
    if (selectedOrders.length === 0) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: t('palletes.new.toast.error.noOrder'),
        life: 3000,
      })
      return
    }

    if (!barcodeValidator(data.palleteBarcode)) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: t('palletes.new.toast.error.invalidBarcode'),
        life: 3000,
      })
      return
    }

    if (data.palleteId) {
      editPallete(data)
    } else {
      addNewPallete(data)
    }
  }

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={getValues('palleteId') ? t('general.modal.title.edit') : t('general.modal.title.new')}
        visible={show}
        style={{ width: '70vw' }}
        onHide={onHide}>
        <div>
          <div className="">
            <form onSubmit={handleSubmit(onSubmitPallete)} className="grid p-fluid">
              <div className="col-12 md:col-6">
                <div className="field">
                  <label htmlFor="palleteName" className={classNames({ 'p-error': errors.palleteName })}></label>
                  <span className="p-float-label">
                    <Controller
                      name="palleteName"
                      control={control}
                      rules={{ required: t('palletes.new.name.required') }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.palleteName}
                          {...field}
                          placeholder={t('palletes.new.name')}
                          autoFocus
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('palleteName')}
                </div>
              </div>

              <div className="col-12 md:col-6">
                <div className="field">
                  <label htmlFor="palleteBarcode" className={classNames({ 'p-error': errors.palleteBarcode })}></label>
                  <span className="p-float-label">
                    <Controller
                      name="palleteBarcode"
                      control={control}
                      rules={{ required: t('palletes.new.barcode.required') }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.palleteBarcode}
                          {...field}
                          placeholder={t('palletes.new.barcode')}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('palleteBarcode')}
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <DataTable
                    value={selectedOrders}
                    dataKey="id"
                    paginator
                    rows={100}
                    header={header}
                    rowsPerPageOptions={[5, 10, 25, 100, 200]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders"
                    globalFilter={filter}
                    emptyMessage={t('palletes.new.orderRequiredMsg')}
                    responsiveLayout="scroll"
                    selectionMode="single"
                    loading={loading}>
                    <Column
                      field="code"
                      header={t('palletes.orders.table.id')}
                      headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    <Column
                      field="name"
                      header={t('palletes.orders.table.name')}
                      headerStyle={{ width: '14%', minWidth: '10rem' }}
                    />
                    <Column
                      field="palletedBoxes"
                      header={t('palletes.orders.table.palletedBoxes')}
                      headerStyle={{ width: '14%', minWidth: '10rem' }}
                      body={palletedBoxesBodyTemplate}
                    />
                    <Column
                      field="action"
                      header={t('palletes.orders.table.actions')}
                      headerStyle={{ width: '14%', minWidth: '10rem' }}
                      body={actionsBodyTemplate}
                    />
                  </DataTable>
                </div>
              </div>
              <div className="col-12">
                <div className="flex align-items-center justify-content-between my-3">
                  {getValues('palleteId') ? (
                    <Button
                      type="button"
                      className="p-button-primary"
                      style={{ fontWeight: 'bold', width: 'auto' }}
                      onClick={async () => {
                        try {
                          setLoading(true)
                          const closedPalleteResult = await PalleteService.addBoxToPallete({
                            packageBarcode: 'CLOSE',
                            palletId: getValues('palleteId'),
                          })
                          onPalletizedButtonClick(closedPalleteResult.data)
                          toast.current.show({
                            severity: 'success',
                            summary: 'Success',
                            detail: t('palleted.new.toast.success.palletized'),
                            life: 3000,
                          })
                        } catch (error) {
                          toast.current.show({
                            severity: 'error',
                            summary: 'Error',
                            detail: error.response?.data?.message,
                            life: 3000,
                          })
                        } finally {
                          setLoading(false)
                        }
                      }}>
                      {t('palletes.new.button.palletized')}
                    </Button>
                  ) : (
                    <div />
                  )}

                  <Button type="submit" className="p-button-success" style={{ fontWeight: 'bold', width: 'auto' }}>
                    {t('palletes.new.button.save')}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default NewEditPalleteModal
