import http from '../ServiceConfig'

class StateCityService {
  getStates() {
    return http.get('/state-city/states')
  }

  getCities(currentState) {
    return http.get('/state-city/cities/' + currentState)
  }
}

export default new StateCityService()
