import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button} from 'primereact/button'

export class Pagination extends React.Component {
    render() {
        let data = this.props?.paginatedData;
        if(data == null) return <div></div>

    let pageLink = (number) =><Button key={number} onClick={(e)=>{this.props.selectPage(number)}} className={"p-paginator-page p-paginator-element p-link p-paginator-page-start p-paginator-page-end" + (data.number == number ? " p-highlight" : "")}>{number + 1}<span className="p-ink"></span></Button>

    return(<div className="p-paginator p-component p-paginator-bottom">
    <Button key={"first"} onClick={(e)=>{this.props.selectPage(0)}} className={"p-paginator-first p-paginator-element p-link" + (data.first ? " p-disabled" : "")} disabled={data.first}>
        <span className="p-paginator-icon pi pi-angle-double-left"></span><span className="p-ink"></span>
    </Button>
    <Button key={"prev"} onClick={(e)=>{this.props.selectPage(data.number - 1)}} className={"p-paginator-first p-paginator-element p-link" + (data.number == 0 ? " p-disabled" : "")} disabled={data.number == 0}>
        <span className="p-paginator-icon pi pi-angle-left"></span><span className="p-ink"></span>
    </Button>
    <span className="p-paginator-pages">
         {[...Array(data.totalPages)].map((x, i) =>
             pageLink(i)
           )}
    </span>
    <Button key={"next"} onClick={(e)=>{this.props.selectPage(data.number + 1)}} className={"p-paginator-first p-paginator-element p-link" + (data.number >= (data.totalPages - 1) ? " p-disabled" : "")} disabled={data.number >= (data.totalPages - 1)}>
        <span className="p-paginator-icon pi pi-angle-right"></span><span className="p-ink"></span>
    </Button>
    <Button key={"last"} onClick={(e)=>{this.props.selectPage(data.totalPages - 1)}} className={"p-paginator-first p-paginator-element p-link" + (data.last ? " p-disabled" : "")} disabled={data.last}>
        <span className="p-paginator-icon pi pi-angle-double-right"></span><span className="p-ink"></span>
    </Button>
        {
        (data.number == 0) ? <span className="p-paginator-current">Showing first of {data.totalPages} pages</span> : 
        (data.number == (data.totalPages - 1)) ? <span className="p-paginator-current">Showing last of {data.totalPages} pages</span> : 
        <span className="p-paginator-current">Showing {data.number + 1}. of {data.totalPages} pages</span>
        }
    </div>)

    }
  }
