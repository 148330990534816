import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { useForm, Controller } from 'react-hook-form'
import classNames from 'classnames'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { ProgressSpinner } from 'primereact/progressspinner'
import { useTranslation } from '../../../hooks/useTranslation'
import SubmenuService from '../../../service/SubmenuService'
import MainMenuService from '../../../service/MainMenuService'
import ModuleService from '../../../service/ModuleService'

const EditSubmenuDialog = ({ visible, onHide, fetchSubmenus, selectedSubmenu }) => {
  const languageKeys = useMemo(
    () => ({
      toastSuccessSummary: 'general.toast.success.summary',
      toastErrorSummary: 'general.toast.error.summary',
      toastSuccessDetail: 'moduleMenuManagement.editSubmenu.toast.success.detail',
      modalTitle: 'moduleMenuManagement.editSubmenu.modal.title',
      keyLabel: 'moduleMenuManagement.form.key.label',
      keyPlaceholder: 'moduleMenuManagement.form.key.placeholder',
      submenuUrlLabel: 'moduleMenuManagement.form.submenuUrl.label',
      submenuUrlPlaceholder: 'moduleMenuManagement.form.submenuUrl.placeholder',
      mainMenuLabel: 'moduleMenuManagement.form.mainMenu.label',
      modulesLabel: 'moduleMenuManagement.form.modules.label',
      requiredField: 'general.required.field.msg',
      save: 'general.save',
      dropdownPlaceholder: 'general.dropdown.placeholder',
    }),
    []
  )

  const [saving, setSaving] = useState(false)
  const [loadingMainMenus, setLoadingMainMenus] = useState(false)
  const [loadingModules, setLoadingModules] = useState(false)
  const [mainMenus, setMainMenus] = useState([])
  const [modules, setModules] = useState([])

  const mounted = useRef(false)
  const toast = useRef(null)

  const { t, getAllTranslationsOfGivenList } = useTranslation(Object.values(languageKeys))

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const defaultValues = {
    id: null,
    key: '',
    submenuUrl: '',
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues, shouldFocusError: false })

  useEffect(() => {
    reset(selectedSubmenu)
  }, [selectedSubmenu, reset])

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  const convertMainMenusToDropdown = useCallback(
    async (mainMenuList) => {
      if (mainMenuList.length === 0) return []

      try {
        const keyList = mainMenuList.map((mainMenu) => 'mainMenus.' + mainMenu.key)
        const translations = await getAllTranslationsOfGivenList(keyList)
        const converted = mainMenuList.map((mainMenu) => {
          return {
            // ...mainMenu,
            label: translations?.en['mainMenus.' + mainMenu.key],
            value: mainMenu,
          }
        })

        return converted
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: t(languageKeys.toastErrorDetailForTranslations),
          life: 3000,
        })

        return []
      }
    },
    [getAllTranslationsOfGivenList, t, languageKeys]
  )

  const convertModulesToDropdown = useCallback(
    async (moduleList) => {
      if (moduleList.length === 0) return []

      try {
        const keyList = moduleList.map((module) => 'modules.' + module.key)
        const translations = await getAllTranslationsOfGivenList(keyList)
        const converted = moduleList.map((module) => {
          return {
            // ...module,
            label: translations?.en['modules.' + module.key],
            value: module,
          }
        })

        return converted
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: t(languageKeys.toastErrorDetailForTranslations),
          life: 3000,
        })

        return []
      }
    },
    [getAllTranslationsOfGivenList, t, languageKeys]
  )

  const getMainMenus = useCallback(async () => {
    try {
      if (mounted.current === true) setLoadingMainMenus(true)
      const response = await MainMenuService.getMainMenus()
      const converted = await convertMainMenusToDropdown(response.data)
      if (mounted.current === true) setMainMenus(converted)
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: t(languageKeys.toastErrorSummary),
        detail: err.response.data.message,
        life: 3000,
      })
    } finally {
      if (mounted.current === true) setLoadingMainMenus(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageKeys])

  useEffect(() => {
    getMainMenus()
  }, [getMainMenus])

  const getModules = useCallback(async () => {
    try {
      if (mounted.current === true) setLoadingModules(true)
      const response = await ModuleService.getModules()
      const converted = await convertModulesToDropdown(response.data)
      if (mounted.current === true) setModules(converted)
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: t(languageKeys.toastErrorSummary),
        detail: err.response.data.message,
        life: 3000,
      })
    } finally {
      if (mounted.current === true) setLoadingModules(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageKeys])

  useEffect(() => {
    getModules()
  }, [getModules])

  const onSubmitSubmenu = useCallback(
    async (data) => {
      try {
        setSaving(true)
        await SubmenuService.updateSubmenu(data)
        onHide()
        toast.current.show({
          severity: 'success',
          summary: t(languageKeys.toastSuccessSummary),
          detail: t(languageKeys.toastSuccessDetail),
          life: 3000,
        })
        fetchSubmenus()
      } catch (err) {
        console.log(err)
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: err.response?.data?.message,
          life: 3000,
        })
      } finally {
        setSaving(false)
      }
    },
    [fetchSubmenus, onHide, t, languageKeys]
  )

  return (
    <>
      <Toast ref={toast} />
      <Dialog header={t(languageKeys.modalTitle)} visible={visible} style={{ width: '70vw' }} onHide={onHide}>
        <div>
          <div className="">
            <form onSubmit={handleSubmit(onSubmitSubmenu)} className="grid">
              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="key" className={classNames({ 'p-error': errors.key })}>
                    {t(languageKeys.keyLabel)} *
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="key"
                      control={control}
                      rules={{ required: t(languageKeys.requiredField) }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder={t(languageKeys.keyPlaceholder)}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('key')}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="submenuUrl" className={classNames({ 'p-error': errors.submenuUrl })}>
                    {t(languageKeys.submenuUrlLabel)} *
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="submenuUrl"
                      control={control}
                      rules={{ required: t(languageKeys.requiredField) }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          placeholder={t(languageKeys.submenuUrlPlaceholder)}
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('submenuUrl')}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="mainMenu" className={classNames({ 'p-error': errors.mainMenu })}>
                    {t(languageKeys.mainMenuLabel) + ' *'}
                  </label>
                  {loadingMainMenus === true ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                      <ProgressSpinner style={{ width: '40px', height: '40px' }} />
                    </div>
                  ) : (
                    <>
                      <span className="p-float-label">
                        <Controller
                          name="mainMenu"
                          control={control}
                          rules={{ required: t(languageKeys.requiredField) }}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              id={field.mainMenu}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e.value)
                              }}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                              options={mainMenus}
                              optionLabel="label"
                              optionValue="value"
                              placeholder={t(languageKeys.dropdownPlaceholder)}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('mainMenu')}
                    </>
                  )}
                </div>
              </div>

              <div className="col-12 md:col-6 p-fluid">
                <div className="field">
                  <label htmlFor="modules" className={classNames({ 'p-error': errors.modules })}>
                    {t(languageKeys.modulesLabel) + ' *'}
                  </label>
                  {loadingModules === true ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                      <ProgressSpinner style={{ width: '40px', height: '40px' }} />
                    </div>
                  ) : (
                    <>
                      <span className="p-float-label">
                        <Controller
                          name="modules"
                          control={control}
                          rules={{ required: t(languageKeys.requiredField) }}
                          render={({ field, fieldState }) => (
                            <MultiSelect
                              id={field.modules}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e.value)
                              }}
                              className={classNames({ 'p-invalid': fieldState.invalid })}
                              options={modules}
                              optionLabel="label"
                              optionValue="value"
                              filter={true}
                              maxSelectedLabels={3}
                              placeholder={t(languageKeys.dropdownPlaceholder)}
                            />
                          )}
                        />
                      </span>
                      {getFormErrorMessage('modules')}
                    </>
                  )}
                </div>
              </div>

              <div className="col-12 flex flex-row-reverse">
                <Button
                  type="submit"
                  label={t(languageKeys.save)}
                  loading={saving}
                  loadingIcon="pi pi-spin pi-spinner"
                />
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default EditSubmenuDialog
