import React, { useContext, useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { TabMenu } from 'primereact/tabmenu'
import { Badge } from 'primereact/badge'
import { useLocation } from 'react-router-dom'
import { useTranslation } from '../../hooks/useTranslation'
import BreadcrumbContext from '../../components/breadcrumb/Breadcrumb'
import CompanyInfo from './components/CompanyInfo'
import CompanyAdmin from './components/CompanyAdmin'
import CompanyService from '../../service/CompanyService'

const CompanyDetails = () => {
  const languageKeys = useMemo(
    () => ({
      tabInfo: 'companyManagement.tabMenu.info',
      tabAdmin: 'companyManagement.tabMenu.admin',
    }),
    []
  )

  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [company, setCompany] = useState(null)

  const mountedRef = useRef(false)

  const { t } = useTranslation(Object.values(languageKeys))
  const location = useLocation()

  const { onConvertFromTo } = useContext(BreadcrumbContext)

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getCompanyInfoAndConvertBradcrumb = useCallback(async () => {
    try {
      const id = location.pathname.split('/').slice(-1)[0]
      if (id !== null && id !== undefined && id !== '') {
        const response = await CompanyService.getCompany(id)
        const name = response.data.name
        if (mountedRef.current === true) setCompany(response.data)

        if (name) {
          onConvertFromTo([{ from: id, to: name }])
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [location.pathname, onConvertFromTo])

  useEffect(() => {
    getCompanyInfoAndConvertBradcrumb()
  }, [getCompanyInfoAndConvertBradcrumb])

  const tabMenuTemplate = (options) => {
    return (
      <div onClick={() => setCurrentTabIndex(options.index)} className="p-menuitem-link relative" role="presentation">
        <div className="p-menuitem-text cursor-pointer">{options.label}</div>
        {options.badge === true ? (
          <div style={{ position: 'absolute', right: '0px', top: '5px' }}>
            <Badge value={options.badgeValue} severity="danger"></Badge>
          </div>
        ) : null}
      </div>
    )
  }

  const onCompanyChange = useCallback((partialCompany) => setCompany((prev) => ({ ...prev, ...partialCompany })), [])

  const buildItems = useMemo(() => {
    const companyInfo = {
      city: company?.city,
      companyAddress: company?.companyAddress,
      companyEmail: company?.companyEmail,
      companyPhone: company?.companyPhone,
      companyTaxnbr: company?.companyTaxnbr,
      companyType: company?.companyType,
      id: company?.id,
      name: company?.name,
      qbId: company?.qbId,
      shortCode: company?.shortCode,
      state: company?.state,
      status: company?.status,
      zipcode: company?.zipcode,
    }

    const companyAdminInfo = {
      adminEmail: company?.adminEmail,
      adminName: company?.adminName,
      adminPhone: company?.adminPhone,
      adminTitle: company?.adminTitle,
      adminUserId: company?.adminUserId,
      id: company?.id,
    }

    return [
      {
        index: 0,
        label: t(languageKeys.tabInfo),
        key: 'INFO',
        template: tabMenuTemplate,
        render: () => <CompanyInfo companyInfo={companyInfo} onCompanyChange={onCompanyChange} />,
      },
      {
        index: 1,
        label: t(languageKeys.tabAdmin),
        key: 'ADMIN',
        template: tabMenuTemplate,
        render: () => <CompanyAdmin companyAdminInfo={companyAdminInfo} onCompanyChange={onCompanyChange} />,
      },
    ]
  }, [company, t, languageKeys, onCompanyChange])

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <TabMenu model={buildItems} activeIndex={currentTabIndex} onTabChange={(e) => setCurrentTabIndex(e.index)} />
          {buildItems[currentTabIndex].render()}
        </div>
      </div>
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(CompanyDetails, comparisonFn)
