import http from '../ServiceConfig'

class ConfigurationManagerService {
  getServiceConfigurationList() {
    return http.get('/service-config')
  }

  getAllRoles() {
    return http.get('users/all-roles')
  }

  addServiceConfiguration(data) {
    return http.post('/service-config', data)
  }

  updateServiceConfiguration(data) {
    return http.put('/service-config', data)
  }

  deleteServiceConfiguration(id) {
    return http.delete(`/service-config/${id}`)
  }
}

export default new ConfigurationManagerService()
