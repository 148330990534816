import React, {useEffect, useRef, useState} from 'react'
import {Button} from 'primereact/button'
import DealersService from '../../service/DealersService'
import {Dropdown} from 'primereact/dropdown'
import {Toast} from "primereact/toast";

const AssignCrmUser = (props) => {
    const {dealerId,dealerStatus} = props
    const [disabled, setDisabled] = useState(dealerStatus !== 'APPROVAL')
    const [crmUsers, setCrmUsers] = useState(null)
    const [selectedCrmUser, setSelectedCrmUser] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState(null)


    const toast = useRef(null);

    const approvalStatus = [
        {label: 'Not Eligible', value: 'NOT_ELIGIBLE'},
        {label: 'Approved', value: 'CONVERTED'},
    ]

    useEffect(() => {
         getCrmUsers()
    }, [])

    const setConvertToCustomer = () => {
        let request = {
            userId: selectedCrmUser,
            status: selectedStatus
        }
        DealersService.assignCrmUser(dealerId,request).then((response) => {
            toast.current.show({severity:'success', summary: 'Success ', detail:'Assign Crm User', life: 3000});
        })
    }


    const getCrmUsers = async() => {
        await DealersService.crmUsers(dealerId).then((response) => {
                setCrmUsers(response.data)
        })
    }

    return (
        <div className="grid crud-demo">
            <Toast ref={toast} />
            <div className="col-12 p-0">
                <div className="card">
                    <div className="grid">
                        <div className="col-4">
                            <Dropdown
                                filter
                                showClear
                                className="min-w-full"
                                value={selectedCrmUser}
                                options={crmUsers}
                                onChange={(e) => setSelectedCrmUser(e.value)}
                                optionLabel="name"
                                optionValue="userId"
                                placeholder="Select a CRM User"
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-4">
                            <Dropdown
                                className="min-w-full"
                                value={selectedStatus}
                                options={approvalStatus}
                                onChange={(e) => setSelectedStatus(e.value)}
                                optionLabel="label"
                                optionValue="value"
                                placeholder="Select a Status"
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-4">
                            <Button label="Convert To Customer" disabled={disabled} onClick={setConvertToCustomer} className="p-button-success min-w-full"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssignCrmUser
