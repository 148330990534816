import classNames from 'classnames'
import { Button } from 'primereact/button'
import { confirmPopup } from 'primereact/confirmpopup'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import React, { useEffect, useState, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import LanguageService from '../service/LanguageService'

const Page = () => {
  const [pages, setPages] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)
  let history = useHistory()
  const toast = useRef(null)

  useEffect(() => {
    getAllPages()
  }, [])

  const defaultValues = {
    id: null,
    pageName: '',
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues: defaultValues })

  const selectedPage = (id) => {
    history.push({
      pathname: `/system-management/lang/` + id,
    })
  }

  const getAllPages = (status) => {
    LanguageService.getAllPages().then((response) => {
      setPages(response.data)
    })
  }

  const onSubmit = async (data) => {
    if (data.id) {
      editItem(data)
    } else {
      addNew(data)
    }
  }

  const addNew = async (data) => {
    LanguageService.addPage(data)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Success Successfully added',
          life: 3000,
        })
        setPages(response.data)
        setShowEditModal(false)
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: '', detail: err.response.data.message, life: 3000 })
      })
  }

  const editItem = async (data) => {
    LanguageService.updatePage(data)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Success Successfully edited',
          life: 3000,
        })
        setPages(response.data)
        setShowEditModal(false)
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: '', detail: err.response.data.message, life: 3000 })
      })
  }

  const deleteItem = (item) => {
    LanguageService.deletePage(item.id)
      .then((response) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Success Successfully deleted',
          life: 3000,
        })
        setShowEditModal(false)
        setPages(response.data)
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: '', detail: err.response.data.message, life: 3000 })
      })
  }

  const editModalOpen = (item) => {
    setShowEditModal(true)
    if (item) {
      setValue('pageName', item.pageName)
      setValue('id', item.id)
    } else {
      reset()
    }
  }

  const deleteConfirm = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteItem(event),
    })
  }

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  return (
    <div className="">
      <Toast ref={toast} />
      <div style={{ marginBottom: '20px' }}>
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 className="m-0">Select Page</h5>
          <div className="flex">
            <Button className="p-button-secondary" onClick={() => editModalOpen()} label="New Page" icon="pi pi-plus" />
          </div>
        </div>
      </div>
      {pages &&
        pages.map((item) => {
          return (
            <div className="grid" key={item.id}>
              <div className="col-10 " style={{ display: 'flex', gap: '10px' }}>
                <Button
                  className="w-full"
                  onClick={() => selectedPage(item.id)}
                  label={item.pageName}
                  icon="pi pi-chevron-right"
                />
                <Button
                  className="p-button-warning"
                  onClick={() => editModalOpen(item)}
                  label="Edit"
                  icon="pi pi-pencil"
                />
                <Button
                  className="p-button-danger"
                  onClick={() => deleteConfirm(item)}
                  label="Delete"
                  icon="pi pi-trash"
                />
              </div>
            </div>
          )
        })}
      <Dialog
        header={'Edit or Save'}
        visible={showEditModal}
        style={{ width: '30vw' }}
        onHide={() => setShowEditModal(false)}>
        <div>
          <div className="">
            <form onSubmit={handleSubmit(onSubmit)} className="grid p-fluid">
              <div className="col-12">
                <div className="field">
                  <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>
                    Page Name*
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name="pageName"
                      control={control}
                      rules={{ required: 'Page Name is required.' }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.pageName}
                          {...field}
                          autoFocus
                          className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                      )}
                    />
                  </span>
                  {getFormErrorMessage('pageName')}
                </div>
              </div>
              <Button type="submit" label="Save" className="mt-2" />
            </form>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(Page, comparisonFn)
