import React, { useMemo } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { InputSwitch } from 'primereact/inputswitch'
import { Controller } from 'react-hook-form'
import { useTranslation } from '../../../hooks/useTranslation'
import { classNames } from 'primereact/utils'

const AddNewOrEditServiceConfigurationDialog = ({
  isVisible,
  onHide,
  control,
  errors,
  setValue,
  getValues,
  onSubmit,
  allRoles,
  loading,
}) => {
  const languageKeys = useMemo(
    () => ({
      titleUpdate: 'configurationManager.dialog.title.update',
      titleAdd: 'configurationManager.dialog.title.add',
      methodNameLabel: 'configurationManager.dialog.methodName.label',
      methodNameRequiredMsg: 'configurationManager.dialog.methodName.requiredMsg',
      allowedRolesLabel: 'configurationManager.dialog.allowedRoles.label',
      allowedRolesRequiredMsg: 'configurationManager.dialog.allowedRoles.requiredMsg',
      allowedRolesPlaceholder: 'configurationManager.dialog.allowedRoles.placeholder',
      controllerNameLabel: 'configurationManager.dialog.controllerName.label',
      controllerNameRequiredMsg: 'configurationManager.dialog.controllerName.requiredMsg',
      isActiveLabel: 'configurationManager.dialog.isActive.label',
      checkLoginLabel: 'configurationManager.dialog.checkLogin.label',
      saveButton: 'general.save',
    }),
    []
  )

  const { t } = useTranslation(Object.values(languageKeys))

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  }

  return (
    <Dialog
      header={getValues('id') ? t(languageKeys.titleUpdate) : t(languageKeys.titleAdd)}
      visible={isVisible}
      style={{ width: '60vw' }}
      onHide={onHide}>
      <hr />
      <div className="mt-5">
        <form onSubmit={onSubmit} className="grid p-fluid">
          <div className="col-12 md:col-6">
            <div className="field">
              <label htmlFor="methodName" className={classNames({ 'p-error': errors.methodName })}>
                {t(languageKeys.methodNameLabel)}
              </label>
              <span className="p-float-label">
                <Controller
                  name="methodName"
                  control={control}
                  rules={{ required: t(languageKeys.methodNameRequiredMsg) }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      placeholder={''}
                      className={classNames({ 'p-invalid': fieldState.invalid })}
                    />
                  )}
                />
              </span>
              {getFormErrorMessage('methodName')}
            </div>
          </div>

          <div className="col-12 md:col-6">
            <div className="field">
              <label htmlFor="allowedRoles" className={classNames({ 'p-error': errors.allowedRoles })}>
                {t(languageKeys.allowedRolesLabel)}
              </label>
              <span className="p-float-label">
                <Controller
                  name="allowedRoles"
                  control={control}
                  rules={{ required: t(languageKeys.allowedRolesRequiredMsg) }}
                  render={({ field, fieldState }) => (
                    <MultiSelect
                      showSelectAll={true}
                      className={classNames({ 'p-invalid': fieldState.invalid })}
                      options={allRoles}
                      onChange={(e) => field.onChange(e.value)}
                      value={field.value}
                      optionLabel="displayName"
                      optionValue="name"
                      placeholder={t(languageKeys.allowedRolesPlaceholder)}
                      // display="chip"
                    />
                  )}
                />
              </span>
              {getFormErrorMessage('allowedRoles')}
            </div>
          </div>

          <div className="col-12 md:col-6">
            <div className="field">
              <label htmlFor="controllerName" className={classNames({ 'p-error': errors.controllerName })}>
                {t(languageKeys.controllerNameLabel)}
              </label>
              <span className="p-float-label">
                <Controller
                  name="controllerName"
                  control={control}
                  rules={{ required: t(languageKeys.controllerNameRequiredMsg) }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      placeholder={''}
                      className={classNames({ 'p-invalid': fieldState.invalid })}
                    />
                  )}
                />
              </span>
              {getFormErrorMessage('controllerName')}
            </div>
          </div>

          <div className="col-12 md:col-6 flex flex-row">
            <div className="field mr-3">
              <label htmlFor="isActive" className={classNames({ 'p-error': errors.isActive })}>
                {t(languageKeys.isActiveLabel)}
              </label>
              <span className="p-float-label">
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputSwitch
                      trueValue={true}
                      falseValue={false}
                      checked={getValues('isActive')}
                      onChange={(e) => setValue('isActive', e.value)}
                    />
                  )}
                />
              </span>
              {getFormErrorMessage('isActive')}
            </div>
            <div className="field">
              <label htmlFor="checkLogin" className={classNames({ 'p-error': errors.checkLogin })}>
                {t(languageKeys.checkLoginLabel)}
              </label>
              <span className="p-float-label">
                <Controller
                  name="checkLogin"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputSwitch
                      trueValue={true}
                      falseValue={false}
                      checked={getValues('checkLogin')}
                      onChange={(e) => setValue('checkLogin', e.value)}
                    />
                  )}
                />
              </span>
              {getFormErrorMessage('checkLogin')}
            </div>
          </div>

          <div className="col-12">
            <Button
              type="submit"
              label={t(languageKeys.saveButton)}
              loading={loading}
              loadingIcon="pi pi-spin pi-spinner"
              className="mt-2"
              style={{ display: 'block' }}
            />
          </div>
        </form>
      </div>
    </Dialog>
  )
}

export default AddNewOrEditServiceConfigurationDialog
