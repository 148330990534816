import { DataTable } from 'primereact/datatable'
import React from 'react'

const AdminDatatable = ({
  dataList,
  header,
  children,
  loading,
  globalFilter,
  reference,
  rows,
  pages,
  paginatorTemplate,
  reportTemplate,
  sortField,
  sortOrder,
  onRowClick,
}) => {
  return (
    <DataTable
      ref={reference}
      value={dataList}
      dataKey="id"
      paginator
      sortField={sortField}
      sortOrder={sortOrder}
      rows={rows ? rows : 3}
      rowsPerPageOptions={pages ? pages : [3, 10, 25, 100, 200]}
      className="datatable-responsive"
      paginatorTemplate={
        paginatorTemplate
          ? paginatorTemplate
          : 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
      }
      currentPageReportTemplate={reportTemplate ? reportTemplate : 'Showing {first} to {last} of {totalRecords} parts'}
      globalFilter={globalFilter}
      emptyMessage="No part found."
      header={header}
      responsiveLayout="scroll"
      selectionMode="single"
      loading={loading}
      onRowClick={onRowClick}>
      {children}
    </DataTable>
  )
}

export default AdminDatatable
