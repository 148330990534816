import React, { useContext } from 'react'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { SortOrder } from '../../helpers/sort-type'
import { dateTimeDDMMYYYYWithPad2AndSeperatorFormat } from '../../helpers/convertor'
import DeleteSuggestionConfirmationDialog from './components/DeleteSuggestionConfirmationDialog'
import AuthContext from '../../context/AuthContext'
import { Role } from '../../helpers/role'
import { useSuggestions } from './useSuggestions'

const Suggestions = () => {
  const {
    deleteSuggestion,
    toast,
    dt,
    suggestions,
    loading,
    t,
    dateSorter,
    isDeleteSuggestionConfirmationDialogVisible,
    onHideDeleteSuggestionConfirmationDialog,
    onConfirmDeleteSuggestion,
  } = useSuggestions()

  const { roleController } = useContext(AuthContext)
  const rolesCanDeleteSuggestion = [Role.Admin]

  const dateCellTemplate = (rowData) => {
    return <p>{dateTimeDDMMYYYYWithPad2AndSeperatorFormat(rowData, 'updateDate', '.')}</p>
  }

  const actionCellTemplate = (rowData) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning mt-2"
          onClick={() => deleteSuggestion(rowData.id)}
        />
      </div>
    )
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={suggestions}
            dataKey="id"
            paginator
            rows={100}
            rowsPerPageOptions={[5, 10, 25, 100, 200]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
            emptyMessage={t('general.table.noRecords')}
            responsiveLayout="scroll"
            selectionMode="single"
            loading={loading}
            sortField="updateDate"
            sortOrder={SortOrder.DESCENDING}>
            <Column
              field="text"
              header={t('suggestions.table.column.suggestion')}
              headerStyle={{ width: '56%', minWidth: '20rem' }}></Column>
            <Column
              field="user"
              sortable
              header={t('suggestions.table.column.user')}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            <Column
              field="updateDate"
              sortable
              sortFunction={dateSorter}
              header={t('suggestions.table.column.date')}
              body={dateCellTemplate}
              headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            {roleController(rolesCanDeleteSuggestion) ? (
              <Column
                field="action"
                header={t('suggestions.table.column.action')}
                body={actionCellTemplate}
                headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
            ) : null}
          </DataTable>
        </div>
      </div>

      <DeleteSuggestionConfirmationDialog
        isVisible={isDeleteSuggestionConfirmationDialogVisible}
        onHide={onHideDeleteSuggestionConfirmationDialog}
        onConfirm={onConfirmDeleteSuggestion}
      />
    </div>
  )
}

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname
}

export default React.memo(Suggestions, comparisonFn)
