import React, { useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { useTranslation } from 'react-i18next'

const PalleteBoxesModal = ({ show, onHide, getValues }) => {
  const [filter, setFilter] = useState('')

  const dt = useRef(null)
  const { t } = useTranslation()

  const exportCSV = (ref) => {
    ref.current.exportCSV()
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{getValues('palleteName') + t('palletes.boxes.title')}</h5>
      <div className="flex">
        <Button
          type="button"
          label={t('palletes.button.export')}
          icon="pi pi-upload"
          className="p-button-help"
          style={{ marginRight: '5px' }}
          onClick={() => exportCSV(dt)}
        />
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={filter}
            onInput={(e) => setFilter(e.target.value)}
            placeholder={t('general.search')}
          />
        </span>
      </div>
    </div>
  )

  return (
    <Dialog visible={show} style={{ width: '70vw' }} onHide={onHide}>
      <div>
        <div className="">
          <div className="col-12">
            <div className="card">
              <DataTable
                ref={dt}
                value={getValues('palleteBoxList')}
                header={header}
                dataKey="id"
                paginator
                rows={100}
                rowsPerPageOptions={[5, 10, 25, 100, 200]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} boxes"
                globalFilter={filter}
                emptyMessage={t('palletes.boxes.table.emptyMsg')}
                responsiveLayout="scroll"
                selectionMode="single"
                loading={false}>
                <Column
                  field="orderName"
                  header={t('palletes.boxes.table.orderName')}
                  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                <Column
                  field="roomName"
                  header={t('palletes.boxes.table.roomName')}
                  headerStyle={{ width: '14%', minWidth: '10rem' }}
                />
                <Column
                  field="boxBarcode"
                  header={t('palletes.boxes.table.boxBarcode')}
                  headerStyle={{ width: '14%', minWidth: '10rem' }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default PalleteBoxesModal
