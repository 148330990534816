import { useParams } from 'react-router-dom'
import React, { useContext, useState } from 'react'
import AuthContext from '../../context/AuthContext'
import { Role } from '../../helpers/role'
import AdminProjectService from '../../service/AdminProjectService'
import { Button } from 'primereact/button'
import { OrderStatus } from '../../helpers/order-status'
import { useIsUploadedPartFiles, useIsUploadedProductionDesignFiles } from '../../hooks/use-is-uploaded-rooms-files'

const CommonCamButton = ({ setLoading, orderStatus, toast, roomList, assignedUser, assignedUserManagerId }) => {
  const { id } = useParams()
  const { roleController, user } = useContext(AuthContext)
  const [managerRoles] = useState([
    Role.Admin,
    Role.CamManager,
    Role.GeneralManager,
    Role.CountrySalesManager,
    Role.RegionSalesManager,
    Role.CrmManager,
  ])
  const [designerRole] = useState([Role.CamDesigner, Role.CamManager])
  const [factoryProductionRoles] = useState([Role.FactoryProductionManager, Role.FactoryProductionPlanningManager])
  const [productionManager] = useState([Role.ProductionPlanningManager, Role.ProductionManager])
  const isUploadedProductionDesignFiles = useIsUploadedProductionDesignFiles(roomList)
  const isUploadedPartFiles = useIsUploadedPartFiles(roomList)

  const onSubmitRequestRevision = () => {
    const req = {
      orderStatus,
      revisionRequest: true,
    }
    AdminProjectService.orderNextStatus(id, req)
      .then((res) => {
        setLoading(true)
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Successfully next status: Request revision',
          life: 3000,
        })
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  const onSubmitDynamicButton = (label) => {
    const req = {
      orderStatus,
      revisionRequest: false,
    }
    AdminProjectService.orderNextStatus(id, req)
      .then((res) => {
        setLoading(true)
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Successfully next status: ' + label,
          life: 3000,
        })
      })
      .catch((err) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data.message, life: 3000 })
      })
  }

  if (
    roleController(managerRoles) &&
    user?.id !== assignedUser?.id &&
    orderStatus === OrderStatus.ProductionDesignPlanning
  ) {
    return (
      <Button
        label="Request Revision"
        disabled={orderStatus !== OrderStatus.ProductionDesignPlanning}
        onClick={onSubmitRequestRevision}
        className="p-button-success ml-2 mb-2"
      />
    )
  } else if (roleController(designerRole)) {
    if (orderStatus === OrderStatus.ProductionDesignPlanned) {
      return (
        <Button
          label="I am on it"
          disabled={orderStatus !== OrderStatus.ProductionDesignPlanned}
          onClick={() => onSubmitDynamicButton('I am on it')}
          className="p-button-success ml-2 mb-2"
        />
      )
    } else if (
      orderStatus === OrderStatus.ProductionDesignInProgress ||
      orderStatus === OrderStatus.ProductionDesignRevisionRequested
    ) {
      let designFilesIsChanged = true
      roomList.forEach((room) => {
        if (!room?.designFormFiles[0]?.sendApproval) {
          designFilesIsChanged = false
          return
        }
      })
      return (
        <Button
          label="Send for Approval"
          disabled={!isUploadedProductionDesignFiles || designFilesIsChanged}
          onClick={() => onSubmitDynamicButton('Send for Approval')}
          className="p-button-success ml-2 mb-2"
        />
      )
    }
  } else if (roleController([...factoryProductionRoles, ...productionManager, Role.Admin])) {
    if (
      orderStatus === OrderStatus.ProductionPlanned &&
      (user?.id === assignedUser?.id || roleController([Role.Admin]))
    ) {
      return (
        <Button
          label="Production Started"
          disabled={orderStatus !== OrderStatus.ProductionPlanned}
          onClick={() => onSubmitDynamicButton('Production Started')}
          className="p-button-success ml-2 mb-2"
        />
      )
    } else if (
      orderStatus === OrderStatus.ProductionStarted &&
      (user?.id === assignedUser?.id || roleController([Role.Admin]))
    ) {
      return (
        <Button
          label="Production Finish"
          disabled={orderStatus !== OrderStatus.ProductionStarted}
          onClick={() => onSubmitDynamicButton('Production Finish')}
          className="p-button-success ml-2 mb-2"
        />
      )
    }
  }

  if (
    (roleController(productionManager) && (user?.id === assignedUser?.id || user?.id === assignedUserManagerId)) ||
    roleController([Role.Admin])
  ) {
    if (orderStatus === OrderStatus.ProductionFinished) {
      return (
        <Button
          label="Ready for Packing"
          disabled={!isUploadedPartFiles}
          onClick={() => onSubmitDynamicButton('Ready for Packing')}
          className="p-button-success ml-2 mb-2"
        />
      )
    }
  }

  return null
}

export default CommonCamButton
