import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { useTranslation } from '../../../hooks/useTranslation'
import SubmenuService from '../../../service/SubmenuService'
import NewSubmenuDialog from './NewSubmenuDialog'
import EditSubmenuDialog from './EditSubmenuDialog'

const Submenus = () => {
  const languageKeys = useMemo(
    () => ({
      toastSuccessSummary: 'general.toast.success.summary',
      toastErrorSummary: 'general.toast.error.summary',
      submenusTableTitle: 'moduleMenuManagement.submenu.table.title',
      newSubmenuButton: 'moduleMenuManagement.button.newSubmenu',
      search: 'general.search',
      noRecordsMsg: 'general.table.noRecords',
      tableSubmenuNameEN: 'moduleMenuManagement.submenu.table.submenuNameEN',
      tableSubmenuNameTR: 'moduleMenuManagement.submenu.table.submenuNameTR',
      tableSubmenuURL: 'moduleMenuManagement.submenu.table.submenuURL',
      tableSubmenuModuleNameEN: 'moduleMenuManagement.submenu.table.submenuModuleNameEN',
      tableSubmenuMainMenuNameEN: 'moduleMenuManagement.submenu.table.submenuMainMenuNameEN',
      tableKey: 'moduleMenuManagement.submenu.table.key',
      toastErrorDetailForTranslations: 'moduleMenuManagement.toast.error.detailForTranslationsSubmenu',
    }),
    []
  )

  const [loading, setLoading] = useState(false)
  const [submenus, setSubmenus] = useState([])
  const [submenusFilter, setSubmenusFilter] = useState('')
  const [isNewSubmenuDialogVisible, setIsNewSubmenuDialogVisible] = useState(false)
  const [isEditSubmenuDialogVisible, setIsEditSubmenuDialogVisible] = useState(false)
  const [selectedSubmenu, setSelectedSubmenu] = useState(null)

  const mounted = useRef(false)
  const toast = useRef(null)
  const dt = useRef(null)

  const { t, getAllTranslationsOfGivenList } = useTranslation(Object.values(languageKeys))

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  })

  const convertSubmenusToDataTable = useCallback(
    async (submenuList) => {
      if (submenuList.length === 0) return []

      try {
        const keyList = submenuList.reduce((acc, submenu) => {
          return [
            ...acc,
            'submenus.' + submenu.key,
            ...submenu.modules.map((module) => 'modules.' + module.key),
            'mainMenus.' + submenu.mainMenu.key,
          ]
        }, [])

        const translations = await getAllTranslationsOfGivenList(keyList)
        const converted = submenuList.map((submenu) => {
          return {
            ...submenu,
            nameEN: translations?.en['submenus.' + submenu.key],
            nameTR: translations?.tr['submenus.' + submenu.key],
            moduleNameEN: submenu.modules.map((module) => translations?.en['modules.' + module.key]).join(', '),
            mainMenuNameEN: translations?.en['mainMenus.' + submenu.mainMenu.key],
          }
        })

        return converted
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: t(languageKeys.toastErrorSummary),
          detail: t(languageKeys.toastErrorDetailForTranslations),
          life: 3000,
        })

        return []
      }
    },
    [getAllTranslationsOfGivenList, t, languageKeys]
  )

  const getSubmenus = useCallback(async () => {
    try {
      if (mounted.current === true) setLoading(true)
      const response = await SubmenuService.getSubmenus()
      const converted = await convertSubmenusToDataTable(response.data)
      if (mounted.current === true) setSubmenus(converted)
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: t(languageKeys.toastErrorSummary),
        detail: error.response.data.message,
        life: 3000,
      })
    } finally {
      if (mounted.current === true) setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageKeys])

  useEffect(() => {
    getSubmenus()
  }, [getSubmenus])

  const onNewSubmenuClick = () => {
    setIsNewSubmenuDialogVisible(true)
  }

  const onSubmenuRowClick = (row) => {
    setSelectedSubmenu(row.data)
    setIsEditSubmenuDialogVisible(true)
  }

  const onHideNewSubmenuDialog = () => {
    setIsNewSubmenuDialogVisible(false)
  }

  const onHideEditSubmenuDialog = () => {
    setSelectedSubmenu(null)
    setIsEditSubmenuDialogVisible(false)
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">
        {t(languageKeys.submenusTableTitle, <ProgressSpinner style={{ width: '40px', height: '40px' }} />)}
      </h5>
      <div className="flex">
        <Button
          label={t(languageKeys.newSubmenuButton)}
          icon="pi pi-plus"
          onClick={onNewSubmenuClick}
          className="p-button-success "
        />
        <span className="block mt-2 md:mt-0 p-input-icon-left ml-2">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setSubmenusFilter(e.target.value)}
            placeholder={t(languageKeys.search)}
          />
        </span>
      </div>
    </div>
  )

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <Toast ref={toast} />
        <DataTable
          ref={dt}
          value={submenus}
          dataKey="id"
          paginator
          rows={100}
          rowsPerPageOptions={[5, 10, 25, 100, 200]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          globalFilter={submenusFilter}
          emptyMessage={t(languageKeys.noRecordsMsg)}
          header={header}
          responsiveLayout="scroll"
          selectionMode="single"
          loading={loading}
          onRowClick={onSubmenuRowClick}>
          <Column
            field="nameEN"
            header={t(languageKeys.tableSubmenuNameEN)}
            headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          <Column
            field="nameTR"
            header={t(languageKeys.tableSubmenuNameTR)}
            headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          <Column
            field="submenuUrl"
            header={t(languageKeys.tableSubmenuURL)}
            headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          <Column
            field="moduleNameEN"
            header={t(languageKeys.tableSubmenuModuleNameEN)}
            headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          <Column
            field="mainMenuNameEN"
            header={t(languageKeys.tableSubmenuMainMenuNameEN)}
            headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
          <Column
            field="key"
            header={t(languageKeys.tableKey)}
            headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
        </DataTable>
      </div>
      <NewSubmenuDialog
        visible={isNewSubmenuDialogVisible}
        onHide={onHideNewSubmenuDialog}
        fetchSubmenus={getSubmenus}
      />
      <EditSubmenuDialog
        visible={isEditSubmenuDialogVisible}
        onHide={onHideEditSubmenuDialog}
        fetchSubmenus={getSubmenus}
        selectedSubmenu={selectedSubmenu}
      />
    </div>
  )
}

export default Submenus
