import axios from 'axios'
const instance = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        'Content-type': 'application/json',
    },
})

instance.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('token')
        const language = sessionStorage.getItem('language')
        config.headers = {
            //'Authorization': `Bearer ${token}`,
            'Language': language
        }
        if(token)
            config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error)
    }
)


instance.interceptors.response.use((response) => {
        return response
    },
    (error) => {
        if (error.response.status === 403 || error.response.status === 401) {
            LogoutByUnauthorized()
        }
        return Promise.reject(error)
    }
)

const LogoutByUnauthorized = () => {
    localStorage.removeItem('token')
    window.location.href = '/login'
}



export default instance
